import {
    SET_USER_PASSWORD
  } from "./actionTypes";

  export const setUserPassword = pass => {
    return {
      type: SET_USER_PASSWORD,
      payload: pass,
    };
  };

 