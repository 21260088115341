import React, { useState, useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import * as Api from "../../General/api";
import { useSelector, useDispatch } from 'react-redux';
import { getFeedersCall, getFeederActivePrioritiesCall, upsertFeederCall, deleteFeederCall, getSubstationsCall } from "../../services/managementServices";
import { CustomLoader } from "../../components/common/CustomLoader";
import AddEditFeeder from "./AddEditFeeder";
import * as Common from "../../General/common";
import JobsDetail from "../JobsDetail";
import { priorities, timeout_for_data_tables } from "../../constants/enums";
import FeedersListing from "./FeedersListing";

function Feeders() {

    const [feedersLoading, setFeedersLoading] = useState(false);
    const [feeders, setFeeders] = useState([]);
    const { jobDetails } = useSelector((state) => state.JobManagement)
    const [feederActivePriorities, setFeederActivePriorities] = useState([]);
    const [feederDetails, setFeederDetails] = useState(null);
    const [adding, setAdding] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [substations, setSubstations] = useState([]);
    const dispatch = useDispatch();

     // add feeder states starts
     const [id, setId] = useState(0);
     const [createdBy, setCreatedBy] = useState("");
     const [modifiedBy, setModifiedBy] = useState("");
     const [notes, setNotes] = useState("");
     const [feederName, setFeederName] = useState("");
     const [feederNumber, setFeederNumber] = useState("");
     const [fk_PriorityID, setFkPriorityId] = useState(feederActivePriorities?.length > 0 ? priorities.Medium : 0);
     const [fk_SubstationID, setFkSubstationId] = useState(0);
     const [ohFt, setOhFt] = useState(0);
     const [ugFt, setUgFt] = useState(0);
     const [ohMi, setOhMi] = useState(0);
     const [ugMi, setUgMi] = useState(0);
     const [totalMiles, setTotalMiles] = useState(0);
     const [meterCount, setMeterCount] = useState(0);
     const [poleCount, setPoleCount] = useState(0);
     const [phase, setPhase] = useState(0);
     const [cafPassings, setCafPassings] = useState(0);

     /* BLURRED STATES START */

     const [feederNameBlurred, setFeederNameBlurred] = useState(false);
     const [feederNumberBlurred, setFeederNumberBlurred] = useState(false);
     const [fk_PriorityIDBlurred, setFkPriorityIdBlurred] = useState(false);
     const [fk_SubstationIDBlurred, setFkSubstationIdBlurred] = useState(false);
     const [ohFtBlurred, setOhFtBlurred] = useState(false);
     const [ugFtBlurred, setUgFtBlurred] = useState(false);
     const [meterCountBlurred, setMeterCountBlurred] = useState(false);
     const [poleCountBlurred, setPoleCountBlurred] = useState(false);
     const [phaseBlurred, setPhaseBlurred] = useState(false);
     const [cafPassingsBlurred, setCafPassingsBlurred] = useState(false);

     /* BLURRED STATES END */

    // function which fetches the feeders
    const getFeeders = async () => {
      setFeedersLoading(true);
      try {
        const { data } = await getFeedersCall(0);
       
        if(data.success){
          // success scenario
          setFeedersLoading(false);
          if(data.result.length > 0){
           setFeeders(data.result);
          }
        }else{
          setFeedersLoading(false);
        }
      }
      catch {
        setFeedersLoading(false);
      }
      finally {
        setFeedersLoading(false);
      }
    };

    // function which fetches the priorities
    const getFeederActivePriorities = async () => {
      try {
        const { data } = await getFeederActivePrioritiesCall();
       
        if(data.success){
          // success scenario
          if(data.result.length > 0){
            
            setFeederActivePriorities(data.result);
          }
        }else{
          
        }
      }
      catch {
        
      }
      finally {
        
      }
  };

  const [substationDropdownLoading, setSubstationDropdownLoading] = useState(false);
  // function which fetches the substations
  const getSubstations = async () => {
    setSubstationDropdownLoading(true);
    try {
      const { data } = await getSubstationsCall(jobDetails.ID);
     
      if(data.success){
        // success scenario
       
        if(data.result.length > 0){
         setSubstations(data.result);
        }
      }else{
        
      }
    }
    catch {
      
    }
    finally {
      
    }
    setSubstationDropdownLoading(false);
};


    // fetch feeders and priorities and substations when component mounts
    useEffect(() => {
      getFeeders();
      getFeederActivePriorities();
      getSubstations();
    }, []);

     // add or edit feeder function starts
     const addorEditFeeder = async (feederData) => {
      setFeeders([]);
      setAdding(true);
      try {
       
          const { data } = await upsertFeederCall(feederData);
          
          
        
          if (data.success) {
              // success scenario 
              Common.showSuccessAlertAutoClose(
                 feederDetails != null ? "Feeder updated successfully!" : "Feeder created successfully!",
                  "success",
                  "Success!",
                  3000
              );
              handleCloseAddFeedersModal();
              setFeederDetails(null);
              getFeeders();
              //window.location.reload(false);
          } else {
              Common.showSuccessAlertAutoClose(
                  data.message,
                  "warning",
                  "Failed!",
                  3000
              );
          }
      }
      catch {

          Common.showSuccessAlertAutoClose(
              "some error occured while processing your request",
              "warning",
              "Failed!",
              3000
          );
      }
      finally {
          getFeeders();
      }
      setAdding(false);
  }
  // add or edit feeder function ends
  const handleDeleteFeeder = (feederId) => {
    var msg = "You want to delete this Feeder.";
    Common.showConfirmation(msg, "", deleteFeeder, null, feederId)

};
  // delete feeder function starts
  const deleteFeeder = async (feederId) => {

    if (feederId > 0) {
      setFeeders([]);
      setDeleting(true);
      try {
        const { data } = await deleteFeederCall(feederId);
       
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               "Feeder deleted successfully!",
                "success",
                "Success!",
                3000
            );

            getFeeders();
            //window.location.reload(false);
         
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
        
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
        getFeeders();
    }
    setDeleting(false);
    }
  }
  // delete feeder function ends

  //console.log("feederss",feeders);
  //console.log("subs",substations);

 
  // for Add Crew modal popup
  const [showAddFeedersModal, setShowAddFeedersModal] = useState(false);
  const handleCloseAddFeedersModal = () => setShowAddFeedersModal(false);
  const handleShowAddFeedersModal = () => setShowAddFeedersModal(true);
  return (
    <>
      <JobsDetail />
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
               <h3 className="mb-0">
                  <img src="/media/svg/custom/feeder.svg" width="30" height="30" className="mr-3" alt="summary"/>
                  Feeders List
                </h3>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setFeederNameBlurred(false);
                    setFeederNumberBlurred(false);
                    setFkPriorityIdBlurred(false);
                    setFkSubstationIdBlurred(false);
                    setOhFtBlurred(false);
                    setUgFtBlurred(false);
                    setMeterCountBlurred(false);
                    setPoleCountBlurred(false);
                    setPhaseBlurred(false);
                    setCafPassingsBlurred(false);
                    setFeederDetails(null);
                    setId(0);
                    setCreatedBy("");
                    setModifiedBy("");
                    setFeederName("");
                    setFeederNumber("");
                    setFkPriorityId(feederActivePriorities?.length > 0 ? priorities.Medium : 0);
                    setNotes("");
                    setUgMi(0);
                    setOhMi(0);
                    setOhFt(0);
                    setUgFt(0);
                    setMeterCount(0);
                    setPoleCount(0);
                    setPhase(0);
                    setCafPassings(0);
                    setTotalMiles(0);
                    setFkSubstationId(0);
                    handleShowAddFeedersModal();
                  }}
                >
                  <i className="fa fa-plus mr-1"></i>Add Feeder
                </button>
              </div>
            </div>
            {feedersLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !feedersLoading && !deleting && !adding && (feeders?.length === 0 || feeders?.filter(x=> substations.map(x=> x.ID).some((element) => element == x.fk_SubstationID))?.length === 0) ? (
                <div className="d-flex justify-content-center p-10">
                  No Feeders
                </div>
              ) : (
                <FeedersListing
                  feeders={feeders}
                  substations={substations}
                  handleDeleteFeeder={handleDeleteFeeder}
                  setFeederDetails={setFeederDetails}
                  handleShowAddFeedersModal={handleShowAddFeedersModal}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add Crew Modal  */}

      <AddEditFeeder 
        showAddFeedersModal={showAddFeedersModal}
        setShowAddFeedersModal={setShowAddFeedersModal}
        handleCloseAddFeedersModal={handleCloseAddFeedersModal}
        handleShowAddFeedersModal={handleShowAddFeedersModal}
        feederDetails={feederDetails}
        setFeederDetails={setFeederDetails}
        addorEditFeeder={addorEditFeeder}
        feederActivePriorities={feederActivePriorities}
        setFeederActivePriorities={setFeederActivePriorities}
        substations={substations}
        id={id}
        setId={setId}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        modifiedBy={modifiedBy}
        setModifiedBy={setModifiedBy}
        notes={notes}
        setNotes={setNotes}
        feederName={feederName}
        setFeederName={setFeederName}
        feederNumber={feederNumber}
        setFeederNumber={setFeederNumber}
        fk_PriorityID={fk_PriorityID}
        setFkPriorityId={setFkPriorityId}
        fk_SubstationID={fk_SubstationID}
        setFkSubstationId={setFkSubstationId}
        ohFt={ohFt}
        setOhFt={setOhFt}
        ugFt={ugFt}
        setUgFt={setUgFt}
        ohMi={ohMi}
        setOhMi={setOhMi}
        ugMi={ugMi}
        setUgMi={setUgMi}
        totalMiles={totalMiles}
        setTotalMiles={setTotalMiles}
        meterCount={meterCount}
        setMeterCount={setMeterCount}
        poleCount={poleCount}
        setPoleCount={setPoleCount}
        phase={phase}
        setPhase={setPhase}
        cafPassings={cafPassings}
        setCafPassings={setCafPassings}
        adding={adding}
        substationDropdownLoading={substationDropdownLoading}
        feederNameBlurred={feederNameBlurred}
        setFeederNameBlurred={setFeederNameBlurred}
        feederNumberBlurred={feederNumberBlurred}
        setFeederNumberBlurred={setFeederNumberBlurred}
        fk_PriorityIDBlurred={fk_PriorityIDBlurred}
        setFkPriorityIdBlurred={setFkPriorityIdBlurred}
        fk_SubstationIDBlurred={fk_SubstationIDBlurred}
        setFkSubstationIdBlurred={setFkSubstationIdBlurred}
        ohFtBlurred={ohFtBlurred}
        setOhFtBlurred={setOhFtBlurred}
        ugFtBlurred={ugFtBlurred}
        setUgFtBlurred={setUgFtBlurred}
        meterCountBlurred={meterCountBlurred}
        setMeterCountBlurred={setMeterCountBlurred}
        poleCountBlurred={poleCountBlurred}
        setPoleCountBlurred={setPoleCountBlurred}
        phaseBlurred={phaseBlurred}
        setPhaseBlurred={setPhaseBlurred}
        cafPassingsBlurred={cafPassingsBlurred}
        setCafPassingsBlurred={setCafPassingsBlurred}
      />

      {/* <Modal
        show={showAddFeedersModal}
        onHide={handleCloseAddFeedersModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Add Feeder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="FeederName">
                Feeder Name
              </label>

              <input
                type="text"
                className="form-control"
                id="FeederName"
                name="FeederName"
                placeholder="Feeder name"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="feederNumber">
                Feeder Number
              </label>
              <input
                type="text"
                className="form-control"
                id="feederNumber"
                name="feederNumber"
                placeholder="Feeder number"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="priority">
                Priority
              </label>
              <select
                className="form-control"
                id="priority"
                name="priority"
                
              >
                <option value="">- Please Select -</option>
                <option value="">Select 1</option>
                <option value="">Select 2</option>
                <option value="">Select 3</option>
                <option value="">Select 4</option>
                <option value="">Select 5</option>
                <option value="">Select 6</option>
              </select>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substation">
                Substation
              </label>
              <select
                className="form-control"
                id="substation"
                name="substation"
                
              >
                <option value="">- Please Select -</option>
                <option value="">Select 1</option>
                <option value="">Select 2</option>
                <option value="">Select 3</option>
                <option value="">Select 4</option>
                <option value="">Select 5</option>
                <option value="">Select 6</option>
              </select>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="meterCount">
                Meter Count
              </label>
              <input
                type="text"
                className="form-control"
                id="meterCount"
                name="meterCount"
                placeholder="Meter count"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="totalMiles">
                Total Miles
              </label>
              <input
                type="text"
                className="form-control"
                id="totalMiles"
                name="totalMiles"
                placeholder="Total miles"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="poleCount">
                Pole Count
              </label>
              <input
                type="text"
                className="form-control"
                id="poleCount"
                name="poleCount"
                placeholder="Pole count"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="phase">
                Phase
              </label>
              <input
                type="text"
                className="form-control"
                id="phase"
                name="phase"
                placeholder="Phase"
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="CafPassing">
                CafPassing
              </label>
              <input
                type="text"
                className="form-control"
                id="CafPassing"
                name="CafPassing"
                placeholder="CafPassing"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="Ohft">
                OhFT
              </label>
              <input
                type="number"
                className="form-control"
                id="Ohft"
                name="Ohft"
                placeholder="oHFT"
              />
            </div>
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="uhft">
                UhFT
              </label>
              <input
                type="number"
                className="form-control"
                id="uhft"
                name="uhft"
                placeholder="UhFT"
              />
            </div>
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="ohmi">
                OhMI
              </label>
              <input
                type="number"
                className="form-control"
                id="ohmi"
                name="ohmi"
                required
                placeholder="OhMI"
              />
            </div>
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="ugmi">
                UgMI
              </label>
              <input
                type="number"
                className="form-control"
                id="ugmi"
                name="ugmi"
                required
                placeholder="UgMI"
              />
            </div>

            <div className="col-12 form-group">
              <label className="form-label" htmlFor="notes">
                Notes
              </label>
              <textarea
                className="form-control"
                rows="4"
                id="notes"
                name="notes"
                placeholder="Add notes"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseAddFeedersModal}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button variant="primary" size="sm">
          <i className="fa fa-save mr-1"></i> Save
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default Feeders;
