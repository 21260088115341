import * as API from "../General/api";

// substations calls starts
export function getSubstationsCall(jobId) {
    return API.getRequest(API.apiUrls.management.substations.getSubstations + `?JobId=${jobId}`);
}
export function getSubstationActivePrioritiesCall() {
    return API.getRequest(API.apiUrls.management.substations.getSubstationActivePriorities);
}
export function upsertSubstationCall(substation) {
    return API.postRequest(API.apiUrls.management.substations.upsertSubstation, substation);
}
export function deleteSubstationCall(substationId) {
    return API.postDeleteRequest(API.apiUrls.management.substations.deleteSubstation, substationId);
}
// substations calls ends

// feeders calls starts
export function getFeedersCall(substationId) {
    substationId = !!substationId ? substationId : 0;
    return API.getRequest(API.apiUrls.management.feeders.getFeeders + `?SubStationId=${substationId}`);
}
export function getFeederActivePrioritiesCall() {
    return API.getRequest(API.apiUrls.management.feeders.getFeederActivePriorities);
}
export function upsertFeederCall(feeder) {
    return API.postRequest(API.apiUrls.management.feeders.upsertFeeder, feeder);
}
export function deleteFeederCall(feederId) {
    return API.postDeleteRequest(API.apiUrls.management.feeders.deleteFeeder, feederId);
}
// feeders calls ends
// parts calls starts

// parts calls end 
export function getPartsCall(JobId) {
    return API.getRequest(`${API.apiUrls.management.parts.getParts}?JobID=${JobId}`);
}
export function getAvailablePartsInWarehouseForJob(JobID) {
    return API.getRequest(API.apiUrls.management.parts.getAvailablePartsInWarehouseForJob + `?JobID=${JobID}`);
}
export function upsertPartCall(part) {
    return API.postRequest(API.apiUrls.management.parts.upsertPart, part);
}
export function deletePartCall(partId) {
    return API.postDeleteRequest(API.apiUrls.management.parts.deletePart, partId);
}

export function getAllUomsCall() {
    return API.getRequest(API.apiUrls.management.uom.getAllUoms);
}
// partCategories calls starts
export function getPartCategoriesCall() {
    return API.getRequest(API.apiUrls.management.partCategories.getPartCategories);
}
export function upsertPartCategoryCall(partCategory) {
    return API.postRequest(API.apiUrls.management.partCategories.upsertPartCategory, partCategory);
}
export function deletePartCategoryCall(partCategoryId) {
    return API.postDeleteRequest(API.apiUrls.management.partCategories.deletePartCategory, partCategoryId);
}
// partCategories call ends

// poleTypes calls starts
export function getPoleTypesCall() {
    return API.getRequest(API.apiUrls.management.poleTypes.getPoleTypes);
}
export function getPoleTypesByIdCall(id) {
    return API.getRequestQry(API.apiUrls.management.poleTypes.getPoleTypesById, `?PoleTypeId=${id}`);
}
export function getPoleTypePartsByPoleTypeIdCall(id) {
    return API.getRequestQry(API.apiUrls.management.poleTypes.getPoleTypePartsByPoleTypeId, `?PoleTypeId=${id}`);
}
export function upsertPoleTypeCall(poleType) {
    return API.postRequest(API.apiUrls.management.poleTypes.upsertPoleType, poleType);
}
export function deletePoleTypeCall(poleTypeId) {
    return API.postDeleteRequest(API.apiUrls.management.poleTypes.deletePoleType, poleTypeId);
}
// poleTypes calls ends

//Customer calls starts
export function getAllCustomerApiCall() {
    return API.getRequest(API.apiUrls.management.customer.getAllCustomers);
}
export function upsertCustomerApiCall(customer) {
    return API.postRequest(API.apiUrls.management.customer.upsertCustomer, customer);
}
export function deleteCustomerApiCall(jobId) {
    return API.postDeleteRequest(API.apiUrls.management.customer.deleteCustomer, jobId);
}
//Customer calls end

// crews calls starts
export function getCrewsCall() {
    return API.getRequest(API.apiUrls.management.crews.getCrews);
}
export function upsertCrewCall(crew) {
    return API.postRequest(API.apiUrls.management.crews.upsertCrew, crew);
}
export function deleteCrewCall(crewId) {
    return API.postDeleteRequest(API.apiUrls.management.crews.deleteCrew, crewId);
}
export function getCrewDetailsByIdCall(id) {
    return API.getRequest(`${API.apiUrls.management.crews.getCrewDetailsById}/${id}`);
}
// crews calls ends