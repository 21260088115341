import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'


function JobInventoryListing({
    InventoryTransactions
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#JobInventoryListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });


    return (
        <>
            <div className="card-body">
                                    <table id="JobInventoryListing" className="table table-bordered table-hover table-striped table-sm w-100">
                                        <thead>
                                            <tr>
                                                <th><i className="fa fa-calendar mr-1"></i> Transaction Date</th>
                                                <th><i className="fa fa-list-ul mr-1"></i> Pole</th>
                                                <th><i className="fa fa-list-ul mr-1"></i> Part </th>
                                                <th><i className="fa fa-list-ul mr-1"></i> Job  </th>
                                                <th><i className="fa fa-list-ol mr-1"></i> Qty</th>
                                                <th><i className="fa fa-bolt mr-1"></i> Transfer Type</th>
                                                <th><i className="fa fa-list-ul mr-1"></i> Crew </th>
                                                <th><i className="fa fa-comments mr-1"></i> Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                InventoryTransactions.map(({ fromLocationName, toLocationName, crewName, jobName, transferType, transactionDate, fk_PoleID, notes, qty, partNumber, poleId }) => {
                                                    var convertedTransactionDate = new Date(transactionDate + 'Z');
                                                    return <tr>
                                                        <td>{format(convertedTransactionDate, 'MM/dd/yyyy')}</td>
                                                        <td>{poleId}</td>
                                                        <td>{partNumber}</td>
                                                        <td>{jobName}</td>
                                                        <td>
                                                            <span className="label label-light-danger label-inline">
                                                                {qty}
                                                            </span>
                                                        </td>
                                                        <td>{TransferTypes[transferType].name}</td>
                                                        <td> {crewName} </td>
                                                        <td> {notes} </td>
                                                    </tr>
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
        </>
    );
}

export default JobInventoryListing;
