import {
    SET_STATS
  } from "./actionTypes";

  const initialState = {
    stats: {}
  };

  const stats = (state = initialState, action) => {
    switch (action.type) {
      case SET_STATS:
        state = { ...state, stats: action.payload };
        break;
    
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  export default stats;
  