import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toInteger } from "lodash";
import { format, parseISO } from 'date-fns'
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import SVG from "react-inlinesvg";
import $ from "jquery";
import ButtonGroupComponent from "./ButtonGroupComponent";
import JobDetailTiles from "./JobDetailTiles";
import JobsDetail from "../JobsDetail";
import { deleteInventoryTransactionApiCall, getAllInventoryTransactions, getTransferReportTransactions, getAllTransferReportTransactions } from "../../services/inventoryTransectionService";
import { getStatsCall } from "../../services/statsService";
import { timeout_for_data_tables, TransferTypes, TransferReportDropdown, TransferTypesObject, no_of_rows_on_single_page } from "../../constants/enums";
import { setStats } from "../../../store/stats/actions";
import JobDetailDashboardListing from "./JobDetailDashboardListing";
import TransferReportDashbaordListing from "./TransferReportDashboardListing";
import { CustomLoader } from "../../components/common/CustomLoader";
import moment from "moment";
import * as Common from "../../General/common";
import { Button, Col, Row } from 'react-bootstrap';
import TransferReportModal from "./TransferReportModal";
import { CSVWriter } from 'csv-writer';
import { createObjectCsvWriter } from 'csv-writer';
import Papa from 'papaparse';
import Swal from "sweetalert2";
import { Parser } from 'csv-parser';
import { Parser as Json2CsvParser } from 'json2csv';
import ReactDOMServer from 'react-dom/server';
import fs from 'fs';


function TransferReportDashboard() {

  const { jobDetails } = useSelector((state) => state.JobManagement)
  const [InventoryTransactions, setInventoryTransactions] = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);
  const stats = useSelector((store) => store.Stats.stats);
  const [deletingTransaction, setDeletingTransaction] = useState(false);

  const [InventoryTransactionsLoading, setInventoryTransactionsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [exportClicked, setExportClicked] = useState(false)

  const [transactionDetail, setTransactionDetail] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [transferReportModal, setTransferReportModal] = useState(false)
  const [oneReportID, setOneReportID] = useState(null)

  const [FromDate, setFromDate] = useState("");
  const [transferTypeOption, setTransferTypeOption] = useState("")
  const [groupedData, setGroupedData] = useState([])
  const [tableData, setTableData] = useState([])
  const [tablePage, setTablePage] = useState(1)

  const changeFromDate = (e) => {
    const startDate = moment(e.target.value)
    const endDate = moment(ToDate)
    setFromDate(e.target.value == "" ? null : e.target.value);

    // const durationInDays = endDate.diff(startDate, 'days');
    // if (durationInDays > 180) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Duration is more than 6 months"
    //   })
    //   e.preventDefault()
    // } else {
    //   setFromDate(e.target.value == "" ? null : e.target.value);
    // }
  }

  const [ToDate, setToDate] = useState("");

  const changeToDate = (e) => {
    const startDate = moment(FromDate)
    const endDate = moment(e.target.value)
    setToDate(e.target.value == "" ? null : e.target.value);

    const durationInDays = endDate.diff(startDate, 'days');

    // if (durationInDays > 180) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Duration is more than 6 months"
    //   })
    //   e.preventDefault()
    // } else {
    //   setToDate(e.target.value == "" ? null : e.target.value);
    // }
  }


  const dispatch = useDispatch();

  const getInventoryTransactions = async () => {
    setInventoryTransactionsLoading(true);
    try {
      const { data } = await getTransferReportTransactions(jobDetails.ID, FromDate, ToDate, transferTypeOption)
      if (data.success && data.result.length) {
        setInventoryTransactions(data.result);
      }
      else {
        setInventoryTransactions([]);
      }
    }
    catch {
      setInventoryTransactions([])
    }
    finally {
      setInventoryTransactionsLoading(false);
    }
  };

  const getStats = async (jobId) => {
    setStatsLoading(true);
    try {
      const { data } = await getStatsCall(jobId);
      if (data.success) {
        setStatsLoading(false);
        dispatch(setStats(data.result));
      }else{
        setStatsLoading(false);
      }
    }
    catch {
      setStatsLoading(false);
    }
    finally {
      setStatsLoading(false);
    }
  };


  useEffect(() => {
    getInventoryTransactions();
    getStats(jobDetails?.ID);

  }, []);


  /* delete transaction code starts */

  const handleDeleteTransaction = (transactionId) => {
    var msg = "You want to delete this Transaction.";
    Common.showConfirmation(msg, "", deleteTransaction, null, transactionId)

};
  const deleteTransaction =async (transactionId) => {
  
    if (transactionId>0) {
      setInventoryTransactions([]);
      setDeletingTransaction(true);
      try {
        const { data } = await deleteInventoryTransactionApiCall(transactionId);
      
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               "Inventory Transaction deleted successfully!",
                "success",
                "Success!",
                3000
            );
            getInventoryTransactions();
            //window.location.reload(false);
         
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
        getInventoryTransactions()
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
       

    }
    setDeletingTransaction(false);
    }
}

  /* delete transaction code ends */

  const handleEditTransactionModal=async (transactionDetail) => {
    await setTransactionDetail(transactionDetail)
    setShowInventoryModal(true);
   }


  const [thisWeekInventoryTransactions, setThisWeekInventoryTransactions] = useState([]);

  // useEffect(() => {
  //   if(InventoryTransactions?.length > 0){
  //     const filteredInventoryTransactionsHere = InventoryTransactions?.filter((el, idx) => {
  //         if(moment().isoWeek() == moment(el?.TransactionDate).isoWeek()){
  //           return el;
  //         }
  //       });
  //     setThisWeekInventoryTransactions(filteredInventoryTransactionsHere);
  //   }
  // }, [InventoryTransactions]);


  const [filteredInventoryTransactions, setFilteredInventoryTransactions] = useState([]);

  useEffect(() => {
      const filteredInventoryTransactionsHere = InventoryTransactions?.filter((el, idx) => {
              return el;
            });
          setFilteredInventoryTransactions(filteredInventoryTransactionsHere);
  }, [InventoryTransactions]);

  const filterTransactionsByDate = () => {
    return new Promise((resolve, reject) => {
      setInventoryTransactionsLoading(true);
      if (InventoryTransactions?.length > 0) {
        const fromDate = moment(FromDate);
        const toDate = moment(ToDate);
        const filterType = transferTypeOption;
        let filteredInventoryTransactionsHere = InventoryTransactions; // Assign original array to variable
        
        filteredInventoryTransactionsHere = filteredInventoryTransactionsHere.filter(el => {
          return (
            moment(el.TransactionDate).isBetween(fromDate, toDate) &&
            el.fk_TransferTypeID == filterType
          );
        });

        // if (filterDate !== null && filterType === "") {
        //   filteredInventoryTransactionsHere = filteredInventoryTransactionsHere.filter(el => {
        //     return moment(moment(el.TransactionDate).format("YYYY-MM-DD")).isSame(moment(filterDate).format("YYYY-MM-DD"));
        //   });
        // } else if (filterDate === null && filterType !== "") {
        //   filteredInventoryTransactionsHere = filteredInventoryTransactionsHere.filter(el => {
        //     return el.fk_TransferTypeID == filterType;
        //   });
        // } else if (filterDate !== null && filterType !== "") {
        //   filteredInventoryTransactionsHere = filteredInventoryTransactionsHere.filter(el => {
        //     return (
        //       (moment(moment(el.TransactionDate).format("YYYY-MM-DD")).isSame(moment(filterDate).format("YYYY-MM-DD"))) ||
        //       (moment(moment(el.TransactionDate).format("YYYY-MM-DD")).isSame(moment(toDate).format("YYYY-MM-DD"))) &&
        //       el.fk_TransferTypeID == filterType
        //     );
        //   });
        // }
        setFilteredInventoryTransactions(filteredInventoryTransactionsHere);
        setTimeout(() => {
          setInventoryTransactionsLoading(false);
          resolve(filteredInventoryTransactionsHere);
        }, 3000);
      } else {
        setFilteredInventoryTransactions([])
        reject(new Error("InventoryTransactions is empty."));
      }
    });
  };
  
  

  const showAllInventoryTransactions = () => {
    setInventoryTransactionsLoading(true);
    setFilteredInventoryTransactions(InventoryTransactions);
    setTimeout(() => {
      setInventoryTransactionsLoading(false);
    }, 3000);
  }

  //console.log("thisweekinventory", thisWeekInventoryTransactions);
  //console.log("inventorytransactions", filteredInventoryTransactions);

  useEffect(() => {
    if(filteredInventoryTransactions.length > 0){
        setInventoryTransactionsLoading(true)
        const groupedData = filteredInventoryTransactions.reduce((acc, obj) => {
            const date = moment(obj.TransactionDate).format("YYYY-MM-DD")
            if (!acc[obj.ID]) {
              acc[obj.ID] = [];
            }
            acc[obj.ID].push(obj);
            return acc;
          }, {});
          setGroupedData(groupedData)
          setInventoryTransactionsLoading(false)
    }else{
      setGroupedData([])
    }

}, [filteredInventoryTransactions])


useEffect(() => {
  if(Object.keys(groupedData).length > 0){
      const temp = []
      Object.keys(groupedData).map((oneKey, i) => {
        const oneKeyData = groupedData[oneKey][0]
        const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = groupedData[oneKey][0]
        const OneTransferType = TransferTypesObject[groupedData[oneKey][0].TransferType]
        const date = moment(groupedData[oneKey][0].TransactionDate).format("MM/DD/YYYY").toString()
          const element = {
                  TransactionDate: `${date}`,
                  JobName: "",
                  TransferType: "",
                  FromLocationName: "",
                  ToLocationName: "",
                  Qty: "",
                  PartNumber: "",
                  CrewName: "",
                  CreatedDate: "",
                  Notes: "",
          }
          temp.push(element)
          groupedData[oneKey].map((one) => {
              const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = one;
              const OneTransferType = TransferTypesObject[TransferType]
              const date = moment(one?.TransactionDate).format("MM/DD/YYYY").toString()
              const element = {
                TransactionDate: `${date}`,
                FromLocationName: (TransferType === TransferTypesObject.REC.abbrev || TransferType === TransferTypesObject.XFER.abbrev || TransferType === TransferTypesObject.WTCREW.abbrev) ? FromLocationName :
                (TransferType === TransferTypesObject.TCREW.abbrev || TransferType === TransferTypesObject.FXFER.abbrev) ? Feeder :
                  (TransferType === TransferTypesObject.FCREW.abbrev || TransferType === TransferTypesObject.TWFCREW.abbrev) ? CrewName : '',
                ToLocationName: (TransferType === TransferTypesObject.REC.abbrev || TransferType === TransferTypesObject.FXFER.abbrev || TransferType === TransferTypesObject.TWFCREW.abbrev) ? ToLocationName :
                (TransferType === TransferTypesObject.XFER.abbrev || TransferType === TransferTypesObject.FCREW.abbrev) ? Feeder :
                  (TransferType === TransferTypesObject.TCREW.abbrev || TransferType === TransferTypesObject.WTCREW.abbrev) ? CrewName : '',
                  CrewName: `${one.FirstName} ${one.LastName}`,
                  JobName: JobName ? JobName : "",
                  TransferType: OneTransferType?.name ? OneTransferType.name : "",
                  Notes: Notes ? Notes: "",
                  Qty: Qty ? Qty : "",
                  PartNumber: PartNumber ? PartNumber : "",
                  CreatedDate: `${date}`
              }
              temp.push(element)
          })
      })
      setTableData(temp)
      if(exportClicked){
        exportToCSV(temp)
      }
  }else{
    setTableData([])
  }
}, [groupedData])

const exportToCSV = (data) => {

  const calculateElementsForPage = (myArray, elementsPerPage, pageNumber) => {
    // Calculate the start and end index of the elements for the specified page
    const startIndex = (pageNumber - 1) * elementsPerPage;
    const endIndex = startIndex + elementsPerPage;
  
    // Extract the elements for the specified page
    const elementsForPage = myArray.slice(startIndex, endIndex);
  
    return elementsForPage;
  };

  // const data = calculateElementsForPage(tableData, no_of_rows_on_single_page, tablePage)
  const csv = Papa.unparse(data);
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.setAttribute('download', 'Transfer Report.csv');
  document.body.appendChild(link);
  link.click();
  setExportClicked(false)
}

async function exportAllRecords(){
  setInventoryTransactionsLoading(true);
    try {
      const { data } = await getAllTransferReportTransactions(jobDetails.ID)
      if (data.success && data.result.length) {
        const allData = data.result
        //Grouping Data
        const groupedData = allData.reduce((acc, obj) => {
          const date = moment(obj.TransactionDate).format("YYYY-MM-DD")
          if (!acc[obj.ID]) {
            acc[obj.ID] = [];
          }
          acc[obj.ID].push(obj);
          return acc;
        }, {});
        //Setting one array so it can be exported
        const temp = []
        Object.keys(groupedData).map((oneKey, i) => {
          const oneKeyData = groupedData[oneKey][0]
          const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = groupedData[oneKey][0]
          const OneTransferType = TransferTypesObject[groupedData[oneKey][0].TransferType]
          const date = moment(groupedData[oneKey][0].TransactionDate).format("MM/DD/YYYY").toString()
            const element = {
                    TransactionDate: `${date}`,
                    JobName: "",
                    TransferType: "",
                    FromLocationName: "",
                    ToLocationName: "",
                    Qty: "",
                    PartNumber: "",
                    CrewName: "",
                    CreatedDate: "",
                    Notes: "",
            }
            temp.push(element)
            groupedData[oneKey].map((one) => {
                const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = one;
                const OneTransferType = TransferTypesObject[TransferType]
                const date = moment(one?.TransactionDate).format("MM/DD/YYYY").toString()
                const element = {
                  TransactionDate: `${date}`,
                  FromLocationName: (TransferType === TransferTypesObject.REC.abbrev || TransferType === TransferTypesObject.XFER.abbrev || TransferType === TransferTypesObject.WTCREW.abbrev) ? FromLocationName :
                  (TransferType === TransferTypesObject.TCREW.abbrev || TransferType === TransferTypesObject.FXFER.abbrev) ? Feeder :
                    (TransferType === TransferTypesObject.FCREW.abbrev || TransferType === TransferTypesObject.TWFCREW.abbrev) ? CrewName : '',
                  ToLocationName: (TransferType === TransferTypesObject.REC.abbrev || TransferType === TransferTypesObject.FXFER.abbrev || TransferType === TransferTypesObject.TWFCREW.abbrev) ? ToLocationName :
                  (TransferType === TransferTypesObject.XFER.abbrev || TransferType === TransferTypesObject.FCREW.abbrev) ? Feeder :
                    (TransferType === TransferTypesObject.TCREW.abbrev || TransferType === TransferTypesObject.WTCREW.abbrev) ? CrewName : '',
                    CrewName: `${one.FirstName} ${one.LastName}`,
                    JobName: JobName ? JobName : "",
                    TransferType: OneTransferType?.name ? OneTransferType.name : "",
                    Notes: Notes ? Notes: "",
                    Qty: Qty ? Qty : "",
                    PartNumber: PartNumber ? PartNumber : "",
                    CreatedDate: `${date}`
                }
                temp.push(element)
            })
        })
        //Exporting the one array
        const csv = Papa.unparse(temp);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.setAttribute('download', 'Transfer Report.csv');
        document.body.appendChild(link);
        link.click();
        setInventoryTransactionsLoading(false)
      }
      else {
        Swal.fire({
          icon: "error",
          text: data.message
        })
        setInventoryTransactionsLoading(false)
      }
    }
    catch(error) {
      Swal.fire({
        icon: "error",
        text: error.message
      })
      setInventoryTransactionsLoading(false)
    }
}

const showSweetAlert = () => {
  const errors = [
    "Transfer Type",
    "From Date",
    "To Date",
  ];

  const errorList = (
    <div>
      <h4 className="mb-3">Following fields are mandatory</h4>
      <ul style={{
      textAlign: "start"
    }} >
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
    </div>
  );

  Swal.fire({
    // title: "Following fields are mandatory",
    html: ReactDOMServer.renderToString(errorList),
    icon: "error",
    confirmButtonText: "OK"
  });
};

  
  return (
    <>
      <JobsDetail />
      
      {/* <div className="row flex-wrap">
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Feeders"
            tileIcon="/media/svg/icons/Home/Home.svg"
            titleValue={!(stats && stats.totalFeeders) ? "0" : toInteger(stats.totalFeeders)}
            tileColor="warning"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Substations"
            tileIcon="/media/svg/icons/Devices/CPU2.svg"
            titleValue={!(stats && stats.totalSubStations) ? "0" : toInteger(stats.totalSubStations)}
            tileColor="info"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Completed Poles"
            tileIcon="/media/svg/icons/Electric/Range-hood.svg"
            titleValue={!(stats && stats.completedPoles) ? "0" : toInteger(stats.completedPoles)}
            tileColor="danger"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Parts"
            tileIcon="/media/svg/icons/Devices/Server.svg"
            titleValue={!(stats && stats.totalParts) ? "0" : toInteger(stats.totalParts)}
            tileColor="success"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Days"
            tileIcon="/media/svg/icons/Home/Clock.svg"
            titleValue={!(stats && stats.totalDays) ? "0" : toInteger(stats.totalDays)}
            tileColor="primary"
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header d-flex align-items-center">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/document.svg" width="30" height="30" className="mr-3" alt="summary" />
                  Summary
                </h3>
              </div>
              <div className="d-flex flex-wrap align-items-center">
              <div className="p-2">
                <span className="ml-1" htmlFor="FilterByDate">
                  Select Transfer Type
                </span>
                <span className="text-danger mr-2">*</span>
                <select
                onChange={(e) => {
                    setTransferTypeOption(e.target.value)
                }}
                value={transferTypeOption}
                >
                    <option value={""}>Select...</option>
                    {
                        TransferReportDropdown.map((one) => {
                            return(
                                <option value={one.id} >{one.name}</option>
                            )
                        })
                    }
                </select>
                </div>
                <div className="p-2">
                <span className="ml-1" htmlFor="FilterByDate">
                  From Date
                </span>
                <span className="text-danger mr-2">*</span>
                <input 
                  type="date"
                  id="FilterByDate"
                  name="FilterByDate"
                  value={FromDate}
                  max={ToDate}
                  onChange={changeFromDate}
                />
                </div>
                <div className="p-2">
                <span className="ml-2" htmlFor="FilterByDate">
                  To Date
                </span>
                <span className="text-danger mr-2">*</span>
                <input 
                  type="date"
                  id="FilterByDate"
                  name="FilterByDate"
                  value={ToDate}
                  min={FromDate}
                  onChange={changeToDate}
                />
                </div>
                
                      <Button className="ml-2 m-2" onClick={async() => {
                        const startDate = moment(FromDate)
                        const endDate = moment(ToDate)                    
                        const durationInDays = endDate.diff(startDate, 'days');
                        if(transferTypeOption == "" || FromDate == "" || ToDate == ""){
                          showSweetAlert()
                        }else{
                          if (durationInDays > 548) {
                            Swal.fire({
                              icon: "error",
                              title: "Date range exceeded",
                              text: "The maximum allowed date range is 18 months"
                            })
                          } else {
                            getInventoryTransactions()
                          }
                        }
                        } }><i className="fa fa-search mr-1"></i>Search Inventories</Button>

                      {/* <Button className="ml-2 m-2" onClick={() => { showAllInventoryTransactions(); } }><i className="fa fa-list"></i>Show All</Button>  */}
                
                
              </div>
             
              {/* <div className="panel-toolbar">
              <ButtonGroupComponent getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} isEdit={isEdit} setIsEdit={setIsEdit} transactionDetail={transactionDetail} setTransactionDetail={setTransactionDetail} showInventoryModal={showInventoryModal} setShowInventoryModal={setShowInventoryModal} />
              </div> */}
            </div>
            <Row className="d-flex justify-content-end mt-2">
                <Col
                className="d-flex justify-content-end"
                style={{
                  marginRight: 35,
                  gap: 20
                }} md="3" >
                <Button color="primary"
                disabled={tableData.length == 0}
                onClick={async() => {
                  const startDate = moment(FromDate)
                        const endDate = moment(ToDate)                    
                        const durationInDays = endDate.diff(startDate, 'days');
                        if(transferTypeOption == "" || FromDate == "" || ToDate == ""){
                          showSweetAlert()
                        }else{
                          if (durationInDays > 548) {
                            Swal.fire({
                              icon: "error",
                              title: "Date range exceeded",
                              text: "The maximum allowed date range is 18 months"
                            })
                          } else {
                            setExportClicked(true)
                            getInventoryTransactions()
                          }
                        }
                }}
                >
                  Export CSV
                </Button>
                <Button color="primary"
                // disabled={tableData.length == 0}
                onClick={() => {
                  exportAllRecords()
                }}
                >
                  Export All Records
                </Button>
                </Col>
              </Row>
            {InventoryTransactionsLoading || adding || deletingTransaction ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !InventoryTransactionsLoading && !adding && !deletingTransaction && filteredInventoryTransactions?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Transactions
                </div>
              ) : (
               <TransferReportDashbaordListing
                InventoryTransactions={filteredInventoryTransactions}
                handleDeleteTransaction={handleDeleteTransaction}
                handleEditTransactionModal={handleEditTransactionModal}
                setInventoryTransactionsLoading={setInventoryTransactionsLoading}
                setIsEdit={setIsEdit}
                setTransferReportModal={setTransferReportModal}
                setOneReportID={setOneReportID}
                setTablePage={setTablePage}
               />
              )
            }
            {
                transferReportModal &&
                <TransferReportModal transferReportModal={transferReportModal}
                setTransferReportModal={setTransferReportModal}
                getInventoryTransactions={getInventoryTransactions}
                getStats={getStats}
                setInventoryTransactions={setInventoryTransactions}
                setAdding={setAdding}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                transactionDetail={transactionDetail}
                setTransactionDetail={setTransactionDetail}
                oneReportID={oneReportID}
                transferReport={true}
                />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferReportDashboard;
