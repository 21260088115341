import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';

const AddEditFeeder = ({
    showAddFeedersModal,
    setShowAddFeedersModal,
    handleCloseAddFeedersModal,
    handleShowAddFeedersModal,
    feederDetails,
    setFeederDetails,
    addorEditFeeder,
    feederActivePriorities,
    setFeederActivePriorities,
    substations,
    id,
    setId,
    createdBy,
    setCreatedBy,
    modifiedBy,
    setModifiedBy,
    notes,
    setNotes,
    feederName,
    setFeederName,
    feederNumber,
    setFeederNumber,
    fk_PriorityID,
    setFkPriorityId,
    fk_SubstationID,
    setFkSubstationId,
    ohFt,
    setOhFt,
    ugFt,
    setUgFt,
    ohMi,
    setOhMi,
    ugMi,
    setUgMi,
    totalMiles,
    setTotalMiles,
    meterCount,
    setMeterCount,
    poleCount,
    setPoleCount,
    phase,
    setPhase,
    cafPassings,
    setCafPassings,
    adding,
    substationDropdownLoading,
    feederNameBlurred,
    setFeederNameBlurred,
    feederNumberBlurred,
    setFeederNumberBlurred,
    fk_PriorityIDBlurred,
    setFkPriorityIdBlurred,
    fk_SubstationIDBlurred,
    setFkSubstationIdBlurred,
    ohFtBlurred,
    setOhFtBlurred,
    ugFtBlurred,
    setUgFtBlurred,
    meterCountBlurred,
    setMeterCountBlurred,
    poleCountBlurred,
    setPoleCountBlurred,
    phaseBlurred,
    setPhaseBlurred,
    cafPassingsBlurred,
    setCafPassingsBlurred
}) => {

    // // add feeder states starts
    // const [id, setId] = useState(0);
    // const [createdBy, setCreatedBy] = useState("");
    // const [modifiedBy, setModifiedBy] = useState("");
    // const [notes, setNotes] = useState("");
    // const [feederName, setFeederName] = useState("");
    // const [feederNumber, setFeederNumber] = useState("");
    // const [fk_PriorityID, setFkPriorityId] = useState(0);
    // const [fk_SubstationID, setFkSubstationId] = useState(0);
    // const [ohFt, setOhFt] = useState(0);
    // const [ugFt, setUgFt] = useState(0);
    // const [ohMi, setOhMi] = useState(0);
    // const [ugMi, setUgMi] = useState(0);
    // const [totalMiles, setTotalMiles] = useState(0);
    // const [meterCount, setMeterCount] = useState(0);
    // const [poleCount, setPoleCount] = useState(0);
    // const [phase, setPhase] = useState(0);
    // const [cafPassings, setCafPassings] = useState(0);

    const [feederData, setFeederData] = useState(null);
    const [addorEditLoading, setAddorEditLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const changeFeederName = (e) => {
        setFeederName(e.target.value);
      }
  
      const changeFeederNumber = (e) => {
        setFeederNumber(e.target.value);
      }
  
      const changeFeederPriority = (e) => {
        setFkPriorityId(parseInt(e.target.value));
      }
  
      const changeNotes = (e) => {
        setNotes(e.target.value);
      }

      const changeSubstation = (e) => {
        setFkSubstationId(parseInt(e.target.value));
      }

      const changeMeterCount = (e) => {
        setMeterCount(e.target.value);
      }

      const changeTotalMiles = (e) => {
        setTotalMiles(e.target.value);
      }

      const changePoleCount = (e) => {
        setPoleCount(e.target.value);
      }

      const changePhase = (e) => {
        setPhase(e.target.value);
      }

      const changeCafPassings = (e) => {
        setCafPassings(e.target.value);
      }

      const changeOhFt = (e) => {
        setOhFt(e.target.value);
      }

      const changeUgFt = (e) => {
        setUgFt(e.target.value);
      }

      const changeOhMi = (e) => {
        setOhMi(e.target.value);
      }

      const changeUgMi = (e) => {
        setUgMi(e.target.value);
      }

      // add feeder states ends

       // formik setup starts

    useEffect(() => {
        formik.setValues({
            notes, 
            feederName, 
            feederNumber, 
            ugFt, 
            ohFt, 
            ugMi, 
            ohMi, 
            meterCount, 
            poleCount, 
            phase, 
            fk_SubstationID, 
            fk_PriorityID, 
            totalMiles, 
            cafPassings,
            id,
            createdBy,
            modifiedBy
        });
      }, [notes, feederName, feederNumber, ugFt, ohFt, ugMi, ohMi, meterCount, poleCount, phase, fk_SubstationID, fk_PriorityID, totalMiles, cafPassings, id]);

      const Schema = Yup.object().shape({
        feederName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Feeder Name is required"),
        feederNumber: Yup.number().required("Feeder Number is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        fk_PriorityID: Yup.number().required("Feeder Priority is required").typeError("Feeder Priority is required").min(1, "Feeder Priority is required"),
        //notes: Yup.string().max(500, "Maximum limit (500  characters)").typeError("Invalid Value").required("Notes required"),
        ugFt: Yup.number().required("UhFt is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        //ohMi: Yup.number().required("OhMi is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        ohFt: Yup.number().required("OhFt is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        //ugMi: Yup.number().required("UgMi is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        meterCount: Yup.number().required("Meter Count is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        poleCount: Yup.number().required("Pole Count is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        phase: Yup.number().required("Phase is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        fk_SubstationID: Yup.number().required("Substation is required").typeError("Invalid Value").min("Substation is required").min(1, "Substation is required"),
        cafPassings: Yup.number().required("Caf Passing is required").typeError("Invalid Value").min(0, "Value should not be negative"),
        //totalMiles: Yup.number().required("Total Miles is required").typeError("Invalid Value").min(0, "Value should not be negative"),

      });

      const formik = useFormik({
        initialValues: {
            notes, 
            feederName, 
            feederNumber, 
            ugFt, 
            ohFt, 
            ugMi, 
            ohMi, 
            meterCount, 
            poleCount, 
            phase, 
            fk_SubstationID, 
            fk_PriorityID, 
            totalMiles, 
            cafPassings,
            id,
            createdBy,
            modifiedBy
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          handleValidSubmit();
          setSubmitting(false);
        },
      });

      // formik setup ends

      useEffect(() => {
        if(feederDetails !== null){
            setId(feederDetails.ID);
            setCreatedBy(feederDetails.CreatedBy);
            setModifiedBy(feederDetails.ModifiedBy);
            setFeederName(feederDetails.FeederName);
            setFeederNumber(feederDetails.FeederNumber);
            setFkPriorityId(feederDetails.fk_PriorityID);
            setNotes(feederDetails.Notes);
            setUgMi(feederDetails.UgMI);
            setOhMi(feederDetails.OhMI);
            setOhFt(feederDetails.OhFT);
            setUgFt(feederDetails.UgFT);
            setMeterCount(feederDetails.MeterCount);
            setPoleCount(feederDetails.PoleCount);
            setPhase(feederDetails.Phase);
            setCafPassings(feederDetails.CafPassings);
            setTotalMiles(feederDetails.TotalMiles);
            setFkSubstationId(feederDetails.fk_SubstationID);
            formik.setValues((old) => {
              return{
                ...old,
                notes: feederDetails.Notes,
                feederName: feederDetails.FeederName,
                feederNumber: feederDetails.FeederNumber,
                ugFt: feederDetails.UgFT,
                ohFt: feederDetails.OhFT,
                ugMi: feederDetails.UgMT,
                ohMi: feederDetails.OhMT,
                meterCount: feederDetails.MeterCount,
                poleCount: feederDetails.PoleCount,
                phase: feederDetails.Phase,
                fkSubstationId: feederDetails.fk_SubstationID,
                fkPriorityId: feederDetails.fk_PriorityID,
                totalMiles: feederDetails.TotalMiles,
                cafPassings: feederDetails.CafPassings,
                id: feederDetails.ID,
                createdBy: feederDetails.CreatedBy,
                modifiedBy: feederDetails.ModifiedBy
              }
            })
        }else if(feederDetails === null){
            setId(0);
            setCreatedBy("");
            setModifiedBy("");
            setFeederName("");
            setFeederNumber("");
            setFkPriorityId(0);
            setNotes("");
            setUgMi(0);
            setOhMi(0);
            setOhFt(0);
            setUgFt(0);
            setMeterCount(0);
            setPoleCount(0);
            setPhase(0);
            setCafPassings(0);
            setTotalMiles(0);
            setFkSubstationId(0);
          formik.resetForm();
        }
    }, [feederDetails]);

    //console.log("fedderfomrik", formik.values);
    //console.log("feederdetails", feederDetails);

    useEffect(() => {
        if(feederName, feederNumber, ugFt >= 0, ohFt >= 0, ugMi >= 0, ohMi >= 0, meterCount >= 0, poleCount >= 0, phase >= 0, fk_SubstationID > 0, fk_PriorityID > 0, totalMiles >= 0, cafPassings >= 0){
            let data = {
                notes, 
                feederName, 
                feederNumber, 
                ugFt, 
                ohFt, 
                ugMi, 
                ohMi, 
                meterCount, 
                poleCount, 
                phase, 
                fk_SubstationID, 
                fk_PriorityID, 
                totalMiles, 
                cafPassings,
                id,
                createdBy,
                modifiedBy
            };
            setFeederData(data);
            setDisableButton(false);
        }
    }, [notes, feederName, feederNumber, ugFt, ohFt, ugMi, ohMi, meterCount, poleCount, phase, fk_SubstationID, fk_PriorityID, totalMiles, cafPassings, id]);


    const handleValidSubmit = async e => {
        //e?.preventDefault();
        if(feederData !== null){
            setAddorEditLoading(true);
            addorEditFeeder(feederData);
            setAddorEditLoading(false);
            // const timeout = setTimeout(() => {
            //   setId(0);
            //   setCreatedBy("");
            //   setModifiedBy("");
            //   setFeederName("");
            //   setFeederNumber("");
            //   setFkPriorityId(0);
            //   setNotes("");
            //   setUgMi(0);
            //   setOhMi(0);
            //   setOhFt(0);
            //   setUgFt(0);
            //   setMeterCount(0);
            //   setPoleCount(0);
            //   setPhase(0);
            //   setCafPassings(0);
            //   setTotalMiles(0);
            //   setFkSubstationId(0);
            // }, 1000);

            // return () => clearTimeout(timeout);
        }
    }

    useEffect(() => {
      
        let ohftInMiles = (ohFt / 5280);
        setOhMi(ohftInMiles);
      
    }, [ohFt]);

    useEffect(() => {
      
        let ugftInMiles = (ugFt / 5280);
        setUgMi(ugftInMiles);
      
    }, [ugFt]);

    useEffect(() => {
      let totalMiles = (ohMi + ugMi);
      setTotalMiles(totalMiles);
    }, [ohMi, ugMi]);

    return(
        <Modal
        show={showAddFeedersModal}
        onHide={handleCloseAddFeedersModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{feederDetails !== null ? "Edit Feeder" : "Add Feeder"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="feederName">
                Feeder Name <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className="form-control"
                id="feederName"
                name="feederName"
                placeholder="Feeder name"
                value={feederName}
                onChange={changeFeederName}
                onBlur={() => { setFeederNameBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {feederNameBlurred && formik.errors.feederName}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="feederNumber">
                Feeder Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="feederNumber"
                name="feederNumber"
                placeholder="Feeder number"
                value={feederNumber}
                onChange={changeFeederNumber}
                onBlur={() => { setFeederNumberBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {feederNumberBlurred && formik.errors.feederNumber}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="fkPriorityId">
                Priority <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                id="fk_PriorityID"
                name="fk_PriorityID"
                value={fk_PriorityID}
                onChange={changeFeederPriority}
                onBlur={() => { setFkPriorityIdBlurred(true)}}
              >
                <option value={0} onClick={() => setFkPriorityId(0)}>- Please Select -</option>
                {
                    feederActivePriorities.length > 0 && <>
                    {
                        feederActivePriorities?.map((feederActivePriority, idx) => {
                            return(
                                <option onClick={() => setFkPriorityId(feederActivePriority.ID)} value={feederActivePriority.ID} key={idx}>{feederActivePriority.Value}</option>
                            )
                        })
                    }
                    </>
                }
              </select>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {fk_PriorityIDBlurred && formik.errors.fk_PriorityID}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="fkSubstationId">
                Substation <span className="text-danger">*</span>
              </label>

              { substationDropdownLoading ?
              <select className='form-control' disabled>
                <option>Loading substations...</option>
              </select>
              :
              <select
                className="form-control"
                id="fk_SubstationID"
                name="fk_SubstationID"
                value={fk_SubstationID}
                onChange={changeSubstation}
                onBlur={() => { setFkSubstationIdBlurred(true)}}
              >
                 <option value={0} onClick={() => setFkSubstationId(0)}>- Please Select -</option>
                {
                    substations.length > 0 && <>
                    {
                        substations?.map((substation, idx) => {
                            return(
                                <option onClick={() => setFkSubstationId(substation.ID)} value={substation.ID} key={idx}>{substation.SubstationName}</option>
                            )
                        })
                    }
                    </>
                }
              </select>
              }
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {fk_SubstationIDBlurred && formik.errors.fk_SubstationID}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="meterCount">
                Meter Count <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="meterCount"
                name="meterCount"
                placeholder="Meter count"
                value={meterCount}
                onChange={changeMeterCount}
                onBlur={() => { setMeterCountBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {meterCountBlurred && formik.errors.meterCount}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="poleCount">
                Pole Count <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="poleCount"
                name="poleCount"
                placeholder="Pole count"
                value={poleCount}
                onChange={changePoleCount}
                onBlur={() => { setPoleCountBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {poleCountBlurred && formik.errors.poleCount}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="phase">
                Phase <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="phase"
                name="phase"
                placeholder="Phase"
                value={phase}
                onChange={changePhase}
                onBlur={() => { setPhaseBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {phaseBlurred && formik.errors.phase}
              </div>
            </div>
            <div className="col-lg-8 form-group">
              <label className="form-label" htmlFor="cafPassings">
                CafPassing <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="cafPassings"
                name="cafPassings"
                placeholder="CafPassing"
                value={cafPassings}
                onChange={changeCafPassings}
                onBlur={() => { setCafPassingsBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {cafPassingsBlurred && formik.errors.cafPassings}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="ohFt">
                OhFT <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="ohFt"
                name="ohFt"
                placeholder="oHFT"
                value={ohFt}
                onChange={changeOhFt}
                onBlur={() => { setOhFtBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {ohFtBlurred && formik.errors.ohFt}
              </div>
            </div>
            <div className="col-md-6 lg-3 form-group">
              <label className="form-label" htmlFor="ugFt">
                UgFT <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="ugFt"
                name="ugFt"
                placeholder="UgFT"
                value={ugFt}
                onChange={changeUgFt}
                onBlur={() => { setUgFtBlurred(true)}}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {ugFtBlurred && formik.errors.ugFt}
              </div>
            </div>
            <div className="col-md-4 lg-4 form-group">
              <label className="form-label" htmlFor="ohMi">
                OhMI
              </label>
              <input
                type="number"
                className="form-control"
                id="ohMi"
                name="ohMi"
                required
                placeholder="OhMI"
                value={ohMi}
                onChange={changeOhMi}
                readOnly={true}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.ohMi}
              </div>
            </div>
            <div className="col-md-4 lg-4 form-group">
              <label className="form-label" htmlFor="ugMi">
                UgMI
              </label>
              <input
                type="number"
                className="form-control"
                id="ugMi"
                name="ugMi"
                required
                placeholder="UgMI"
                value={ugMi}
                onChange={changeUgMi}
                readOnly={true}
              />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.ugMi}
              </div>
            </div>
            <div className="col-md-4 col-lg-4 form-group">
              <label className="form-label" htmlFor="totalMiles">
                Total Miles
              </label>
              <input
                type="number"
                className="form-control"
                id="totalMiles"
                name="totalMiles"
                placeholder="Total miles"
                value={totalMiles}
                onChange={changeTotalMiles}
                readOnly={true}
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.totalMiles}
              </div>
            </div>

            <div className="col-12 form-group">
              <label className="form-label" htmlFor="notes">
                Notes
              </label>
              <textarea
                className="form-control"
                rows="4"
                id="notes"
                name="notes"
                placeholder="Add notes"
                value={notes}
                onChange={changeNotes}
              ></textarea>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.notes}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
                handleCloseAddFeedersModal(); 
                setFeederDetails(null);
                setId(0);
                setCreatedBy("");
                setModifiedBy("");
                setFeederName("");
                setFeederNumber("");
                setFkPriorityId(0);
                setNotes("");
                setUgMi(0);
                setOhMi(0);
                setOhFt(0);
                setUgFt(0);
                setMeterCount(0);
                setPoleCount(0);
                setPhase(0);
                setCafPassings(0);
                setTotalMiles(0);
                setFkSubstationId(0);
            }}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button type="button" onClick={formik.handleSubmit} disabled={disableButton || !formik.isValid || formik.isSubmitting || adding} variant="primary" size="sm">
          <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{feederDetails !== null ? "Update" : "Save"}</span>
            {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default AddEditFeeder;