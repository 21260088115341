import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_SESSION_TIMEOUT_TIME
  } from "./actionTypes";

  const initialState = {
    sessionTimeoutTimeInSeconds: null
  };

  const sessionTimeoutTime = persistReducer(
    { storage, key: "v726-demo1-sessionTimeoutTimeInSeconds", whitelist: ["sessionTimeoutTimeInSeconds"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_SESSION_TIMEOUT_TIME:
        state = { ...state, sessionTimeoutTimeInSeconds: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });
  export default sessionTimeoutTime;
  