import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import { EntityIds } from "../../General/common";
import CheckPermission from "../../General/CheckPermission";
import { userActions, no_of_rows_on_single_page } from "../../constants/enums"


function PartsListing({
    partsList,
    deletePartHandle,
    editPartModal,
    getAttachments,
    setIsEdit,
    handleShowAttachmentsModal
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#PartsListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });

//console.log("parts", partsList);

    return (
        <>
          <div className="pt-5">
                {/* {
                    partsLoading ? (
                        <div className="d-flex justify-content-center">
                            <CustomLoader />
                        </div>
                    ) : (!partsLoading && partsList?.length === 0) ? (
                        <div className="d-flex justify-content-center">
                            No Parts
                        </div>
                    ) : ( */}
                        <CheckPermission
                        currentAction={userActions.Parts.Action_ViewParts}

                        yes={() => (
                            <table id="PartsListing" className="table table-bordered table-hover table-striped table-sm w-100">
                            <thead>
                                <tr>
                                    <th data-priority="1">
                                        <i className="fa fa-users mr-1"></i> Customer Name
                                    </th>
                                    <th>
                                        <i className="fa fa-list-ul mr-1"></i> Customer Part Number
                                    </th>
                                    <th>
                                        <i className="fa fa-list-ul mr-1"></i> Vendor Part Number
                                    </th>
                                    <th>
                                        <i className="fa fa-list-ul mr-1"></i> Client Part Number
                                    </th>
                                    <th>
                                        <i className="fa fa-clock mr-1"></i> UOM
                                    </th>
                                    <th>
                                        <i className="fa fa-th-large mr-1"></i> Category
                                    </th>
                                    <th>
                                        <i className="fa fa-comments mr-1"></i> Notes
                                    </th>
                                    <th data-priority="2" width="100" className="text-center min-width-110">
                                        <i className="fa fa-bolt mr-1"></i> Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {partsList?.map((part) => {

                                    return (
                                        <tr key={part.ID}>
                                            {/*customer Name, UOM Name and Category Name not available in endpoint */}
                                            <td>{part.CustomerName}</td>
                                            <td>{part.CustomerPartNumber}</td>
                                            <td>{part.VendorPartNumber}</td>
                                            <td>{part.ClientPartNumber}</td>
                                            <td>{part.UomName}</td>
                                            <td>{part.CategoryName}</td>
                                            <td>
                                                {part.Notes}
                                            </td>
                                            <td align="center">
                                            <CheckPermission
                                                    currentAction={userActions.Parts.Action_DeleteParts}

                                                    yes={() => (
                                                <OverlayTrigger
                                                    placement={"top"}
                                                    overlay={<Tooltip> Delete Part </Tooltip>}
                                                >
                                                    <button className="btn btn-icon btn-outline-danger btn-xs"
                                                        onClick={() => deletePartHandle(part.ID)}
                                                    >
                                                        <i className="fa fa-trash-alt"></i>
                                                    </button>
                                                </OverlayTrigger>
                                                )}
                                                no={() =>  null}
                                            />
                                                <CheckPermission
                                                    currentAction={userActions.Parts.Action_EditParts}

                                                    yes={() => (
                                                        <OverlayTrigger
                                                            placement={"top"}
                                                            overlay={<Tooltip> Edit Part </Tooltip>}
                                                        >
                                                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3"
                                                                onClick={() => {
                                                                    editPartModal(part);
                                                                    getAttachments(part.ID, EntityIds.Parts);
                                                                    setIsEdit(true);
                                                                }}>
                                                                <i className="fa fa-pen"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    )}
                                                    no={() =>  null}
                                                />

                                                        <OverlayTrigger
                                                            placement={"top"}
                                                            overlay={<Tooltip> View Attachments </Tooltip>}
                                                        >
                                                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3"
                                                                onClick={() => {
                                                                    getAttachments(part.ID, EntityIds.Parts);
                                                                    handleShowAttachmentsModal();
                                                                }}>
                                                                <i className="fa fa-eye"></i>
                                                            </button>
                                                        </OverlayTrigger>

                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        )}
                        no={() => null}
                    />
                        
                    {/* )
                } */}
            </div>
        </>
    );
}

export default PartsListing;
