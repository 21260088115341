import React, { useState, useEffect } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import * as Common from "../../General/common";

const AddEditCrew = ({
  showAddcrewsModal,
  setShowAddCrewsModal,
  handleCloseAddCrewsModal,
  handleShowAddCrewsModal,
  crewDetails,
  setCrewDetails,
  addorEditCrew,
  usersList,
  setUsersList,
  crewUsers,
  setCrewUsers,
  id,
  setId,
  createdBy,
  setCreatedBy,
  modifiedBy,
  setModifiedBy,
  crewName,
  setCrewName,
  notes,
  setNotes,
  adding,
  crewNameBlurred,
  setCrewNameBlurred
}) => {

  //  // add crew states starts
  //  const [id, setId] = useState(0);
  //  const [createdBy, setCreatedBy] = useState("0");
  //  const [modifiedBy, setModifiedBy] = useState("0");
  //  const [crewName, setCrewName] = useState("");
  //  const [notes, setNotes] = useState("");
  const [noOfUsers, setNoOfUsers] = useState(0);

  const [crewUserId, setCrewUserId] = useState(0);
  const [fk_CrewID, setFkCrewId] = useState(0);
  const [fk_UserID, setFkUserId] = useState("");
  const [isCrewLead, setIsCrewLead] = useState(false);


  const [crewData, setCrewData] = useState(null);
  const [addorEditLoading, setAddorEditLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);


  const changeCrewName = (e) => {
    setCrewName(e.target.value);
  }

  const changeNotes = (e) => {
    setNotes(e.target.value);
  }

  const changeFkUserId = (e) => {
    setFkUserId(e.target.value);
  }

  const changeIsCrewLead = (e) => {
    setIsCrewLead(!isCrewLead);
  }

  // add crew states ends

  // formik setup starts

  useEffect(() => {
    formik.setValues({
      notes,
      createdBy,
      modifiedBy,
      id,
      crewName,
      crewUsers
    });
  }, [crewName, notes, crewUsers]);

  const Schema = Yup.object().shape({
    crewName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Crew Name is required"),
    //notes: Yup.string().max(500, "Maximum limit (500  characters)").typeError("Invalid Value").required("Notes required"),
    // crewUsers: Yup.array().required("Atleast 1 user is required").min(1, "Atleast 1 user is required")
  });

  const formik = useFormik({
    initialValues: {
      notes,
      createdBy,
      modifiedBy,
      id,
      crewName,
      crewUsers
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  useEffect(() => {
    if (crewDetails !== null) {
      setId(crewDetails.ID);
      setCreatedBy(crewDetails.CreatedBy);
      setModifiedBy(crewDetails.ModifiedBy);
      setCrewName(crewDetails.CrewName);
      setNotes(crewDetails.Notes);
      formik.setValues((old) => {
        return {
          ...old,
          notes: crewDetails.Notes,
          createdBy: crewDetails.CreatedBy,
          modifiedBy: crewDetails.ModifiedBy,
          id: crewDetails.ID,
          crewName: crewDetails.CrewName,
          crewUsers
        }
      })
    } else if (crewDetails === null) {
      setId(0);
      setCreatedBy("0");
      setModifiedBy("0");
      setCrewName("");
      setNotes("");
      setCrewUsers([]);
      formik.resetForm();
    }
  }, [crewDetails]);

  useEffect(() => {
    if (crewName) {
      let data = {
        crew: {
          notes,
          modifiedBy,
          createdBy,
          id,
          crewName
        },
        crewUser: crewUsers
      };
      setCrewData(data);
      setDisableButton(false);
    }
  }, [id, crewName, notes, crewUsers]);


  const handleValidSubmit = async e => {
    //e?.preventDefault();
    if (crewData !== null) {
      setAddorEditLoading(true);
      addorEditCrew(crewData);
      setAddorEditLoading(false);
      // const timeout = setTimeout(() => {
      //   setId(0);
      //   setCreatedBy("0");
      //   setModifiedBy("0");
      //   setCrewName("");
      //   setNotes("");
      //   setCrewUsers([]);
      // }, 1000);
      // return () => clearTimeout(timeout);
    }
  }

  const addDataInCrewUsers = () => {
    debugger;
    if (crewUserId >= 0) {
      const alreadyExists = crewUsers?.some((el, idx) => { return el.fk_UserID === fk_UserID });
      if (alreadyExists) {
        Common.showSuccessAlertAutoClose(
          "This user already exists in the list",
          "warning",
          "Failed!",
          3000
        );
      } else {
        const userToAdd = usersList.find(c => c.id == fk_UserID);
        let data = {
          id: crewUserId,
          fk_CrewID: id,
          firstName: userToAdd?.firstName,
          lastName: userToAdd?.lastName,
          fk_UserID,
          isCrewLead,
        };
        setCrewUsers([...crewUsers, data]);
        setFkCrewId(0);
        setFkUserId("");
        setCrewUserId(0);
        setIsCrewLead(false);
      }
    }
  }


  return (
    <Modal
      show={showAddcrewsModal}
      onHide={handleCloseAddCrewsModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="py-3" closeButton>
        <Modal.Title>{crewDetails !== null ? "Edit Crew" : "Add Crew"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="form-group">
              <label className="form-label" htmlFor="crewName">Crew Name <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Crew name" id="crewName" name="crewName" required value={crewName} onChange={changeCrewName} onBlur={() => setCrewNameBlurred(true)} />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                {crewNameBlurred && formik.errors.crewName}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-label" htmlFor="crewNotes">Note</label>
              <textarea className="form-control" placeholder="Add notes" id="htmlFor" rows="3" spellcheck="false" value={notes} onChange={changeNotes}></textarea>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                {formik.errors.notes}
              </div>
            </div>
          </div>
        </div>
       
       
        
        
       
      </Modal.Body>
      <Modal.Footer className="py-3">
        <Button variant="secondary" size="sm" onClick={() => {
          handleCloseAddCrewsModal();
          setCrewDetails(null);
          setId(0);
          setCreatedBy("0");
          setModifiedBy("0");
          setCrewName("");
          setNotes("");
          setCrewUsers([]);
        }}>
          <i className="fa fa-times mr-1"></i> Cancel
        </Button>
        <Button onClick={formik.handleSubmit} disabled={disableButton || !formik.isValid || formik.isSubmitting || adding} variant="primary" size="sm">
        <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{crewDetails !== null ? "Update" : "Save"}</span>
            {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEditCrew;