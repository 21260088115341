/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import React, { useEffect, useState } from "react";
 import { Redirect, Switch, Route, useHistory } from "react-router-dom";
 import { shallowEqual, useSelector } from "react-redux";
 import { Layout } from "../_metronic/layout";
 import BasePage from "./BasePage";
 import { Logout, AuthPage } from "./modules/Auth";
 import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
 import jwt_decode from "jwt-decode";
 import { actions } from "../app/modules/Auth/_redux/authRedux";
 import { useDispatch } from "react-redux";
 import { CustomLoader } from "./components/common/CustomLoader";
import { userRoles } from "./constants/enums";
import IdleTimerContainer from "./IdleTimerContainer";
import { setSessionTimeoutTime } from "../store/sessionTimeoutTime/actions";
import { getUserByIdCall } from "../app/services/userManagementService";
import * as Common from "../app/General/common";
import moment from "moment";
 
 export function Routes() {
   const history = useHistory();
   const dispatch = useDispatch();
   const token = localStorage.getItem("accessToken");
   const [isLoading, setIsLoading] = useState(true);
   const user = useSelector((state) => state.auth.user, shallowEqual);

   const [userById, setUserById] = useState(null);
   ////console.log("user", user);
   ////console.log("token", token);
  
   const [isAuthorized, setIsAuthorized] = useState(false);
 
  //  useEffect(() => {
  //    setIsAuthorized(token != null && token != "");
  //  }, [token]);

  useEffect(() => {
    if(token != null || token != ""){
      if(user != undefined && user != null){
        setIsAuthorized(true);
      }else{
        setIsAuthorized(false);
        dispatch(actions.logout());
      }
    }else{
      setIsAuthorized(false);
      dispatch(actions.logout());
    }
  }, [user, token]);

 
   
    useEffect(() => {
  //    if (token != null && token != "") {
  //      //const decoded = jwt_decode(token);
  //      //const timeout = (decoded?.exp - decoded?.iat)/60;
  //      //const timeoutInSeconds = timeout * 60;
  //      //dispatch(setSessionTimeoutTime(timeoutInSeconds));
  //      //const userData = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
  //      //const parsedData = JSON.parse(userData);
 
  //      //const newData = smallKeys(parsedData);

  //       //dispatch(actions.login(token));
  //       //dispatch(actions.setUser(newData));
  //       //dispatch(actions.requestUser(newData));
  //       //dispatch(actions.fulfillUser(newData));
       
  //    }

       const timeout = setTimeout(() => {
         setIsLoading(false);
       }, 500);

    }, [token]);

    const getUserById = async (id) => {
      try {
        const { data, status } = await getUserByIdCall(id);
        ////console.log("datadddd", data);
        if (data.success) {
          // success scenario
         setUserById(data.result);
         //dispatch(setUserDetailById(data.result));
        } else {
        setUserById(null);
        }
      } catch {
      setUserById(null);
      setIsAuthorized(false);
      dispatch(actions.logout());
      Common.showSuccessAlertAutoClose(
        "Your account is deleted!",
        "warning",
        "Failed!",
        3000
      );
      } finally {
     
      }
    };

    useEffect(() => {
      if(user != undefined && user != null){
        if(user?.id > 0){
          getUserById(user?.id);
        }
      }
    }, [user]);
    ////console.log("userddd", user);
    ////console.log("userbyidddd", userById);

    useEffect(() => {
      if (userById !== null && userById !== {} && userById?.length > 0){
        if(userById[0]?.Active == "0"){
          setIsAuthorized(false);
          dispatch(actions.logout());
          Common.showSuccessAlertAutoClose(
            "Your account is deactivated!",
            "warning",
            "Failed!",
            3000
          );
        }
      }
    }, [userById]);

    useEffect(() => {
      if (userById !== null && userById !== {} && userById?.length > 0){
        if(userById[0]?.IsDeleted == "1"){
          setIsAuthorized(false);
          dispatch(actions.logout());
          Common.showSuccessAlertAutoClose(
            "Your account is deleted!",
            "warning",
            "Failed!",
            3000
          );
        }
      }
    }, [userById]);

    useEffect(() => {
      if(user?.id > 0){
        if(moment.utc().isSameOrAfter(moment.utc(user?.tokenExpiryTime))){
          history.replace("/logout");
        }
      }
    });

 
   return (
     isLoading ? <div className="d-flex align-items-center justify-content-center h-100">
       <CustomLoader />
     </div> :
       <Switch>
         {!isAuthorized && (
           /*Render auth page when user at `/auth` and not authorized.*/
           <Route>
             <AuthPage />
           </Route>
         ) }
 
         <Route path="/error" component={ErrorsPage} />
         <Route path="/logout" component={Logout} />
 
         {isAuthorized && (
            <Layout>
            <BasePage />
            <IdleTimerContainer />
          </Layout>
           
         ) }
          
       </Switch>
       
   );
 }