import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'


function SubstationsListing({
    substations,
    substationActivePriorities,
    handleDeleteSubstation,
    setSubstationDetails,
    handleShowAddSubstationsModal
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#SubstationsListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });



    return (
        <>
            <div className="card-body">
              {
                // substationsLoading ? (
                //   <div className="d-flex justify-content-center">
                //     <CustomLoader />
                //   </div>
                // ) : (!substationsLoading && substations?.length === 0) ? (
                //   <div className="d-flex justify-content-center">
                //     No Substations
                //   </div>
                // ) : (
              <table
                id="SubstationsListing"
                className="table table-bordered table-hover table-striped table-sm w-100"
              >
                <thead>
                  <tr>
                    <th data-priority="1">
                      <i className="fa fa-users mr-1"></i> Substation Name
                    </th>
                    <th data-priority="1">
                      <i className="fa fa-list-ul mr-1"></i> Substation Number
                    </th>
                    <th data-priority="1">
                      <i className="fa fa-list-ul mr-1"></i> Priority
                    </th>

                    <th>
                      <i className="fa fa-comments mr-1"></i> Notes
                    </th>
                    <th data-priority="2" className="text-center min-width-110">
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                 
                  {substations?.map((substation) => {
                    return (
                      <tr key={substation.ID}>
                        <td>{substation.SubstationName}</td>
                        <td>{substation.SubstationNumber}</td>
                        <td>{substationActivePriorities?.length > 0 && substationActivePriorities[substation.fk_PriorityID - 1].Value}</td>
                        <td>
                          {substation.Notes}
                        </td>
                        <td align="center">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Delete Substation </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-danger btn-xs" onClick={() => {
                              //setIsDeletingSubstation(true);
                              handleDeleteSubstation(substation.ID);
                              //setIsDeletingSubstation(false);
                            }}>
                              <i className="fa fa-trash-alt"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Edit Substation </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3" onClick={() => {
                              setSubstationDetails(substation);
                              handleShowAddSubstationsModal();
                            }}>
                              <i className="fa fa-pen"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            //  )
            }
            </div>
        </>
    );
}

export default SubstationsListing;
