import React, { useState, useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux';
//import { setLocations } from "../../../store/location/actions";
import { CustomLoader } from "../../components/common/CustomLoader";
import { getAllLocationsApiCall, deleteLocationApiCall, getAllLocationsApiCallCustomeAndNotCustom } from "../../services/locationService";
import AddEditLocation from "./addEditLocation"
import * as Common from "../../General/common"
import JobsDetail from "../JobsDetail";
import LocationsListing from "./LocationsListing";
var addEditLocationRef=null;
function Locations() {

  

  // for Add location modal popup
  //const locationList = useSelector((store) => store.Location.locations);
  const [locationList, setLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [showAddLocationsModal, setShowAddLocationsModal] = useState(false);

  const [locationDetail, setLocationDetail] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  
  const handleCloseAddLocationsModal = () => setShowAddLocationsModal(false);
  const handleShowAddLocationsModal = () => {setShowAddLocationsModal(true);setLocationDetail(null)};

  const dispatch = useDispatch();


     // function which fetches the locations
     const getAllLocation = async (id) => {  
      //  
       setLocationLoading(true);
       try {
         const { data } = await getAllLocationsApiCallCustomeAndNotCustom(id);
       
         if(data.success){
           // success scenario
            setLocationLoading(false);
           if(data.result.length > 0){
             setLocations(data.result);
           }
         }else{
            setLocationLoading(false);
         }
       }
       catch {
          setLocationLoading(false);
       }
       finally {
          setLocationLoading(false);
       }
  };
  const handleEditLocationModal=async (locationDetail) => {
    
   await setLocationDetail(locationDetail)
    handleShowAddLocationsModal();
  }
  const handleDeleteLocation = (locationId) => {
    var msg = "You want to delete this Location.";
    Common.showConfirmation(msg, "", deleteLocation, null, locationId)

};
  const deleteLocation =async (locationId) => {
  
    if (locationId>0) {
      setLocations([]);
      setDeleting(true);
      try {
        const { data } = await deleteLocationApiCall(locationId);
      
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               "Location deleted successfully!",
                "success",
                "Success!",
                3000
            );
            getAllLocation(0);
            //window.location.reload(false);
         
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
        getAllLocation(0)
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
       

    }
    setDeleting(false);
    }
}
  const afterUpsertJob=()=>{
    handleCloseAddLocationsModal();
    getAllLocation(0);
  }

  useEffect(() => {
    getAllLocation(0);
  }, []);
  
  return (
    <>
      <JobsDetail />
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/pin.svg" width="30" height="30" className="mr-3" alt="summary"/>
                  Location List
                </h3>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {handleShowAddLocationsModal(); setIsEdit(false);}}
                >
                  <i className="fa fa-plus mr-1"></i>Add Location
                </button>
              </div>
            </div>
            {locationLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !locationLoading && !deleting && !adding && locationList?.length === 0 ? (
                <div className="d-flex justify-content-center p-5">
                  No Locations
                </div>
              ) : (
                <LocationsListing
                  locationList={locationList}
                  handleDeleteLocation={handleDeleteLocation}
                  handleEditLocationModal={handleEditLocationModal}
                  setIsEdit={setIsEdit}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add location Modal  */}
      <Modal
        show={showAddLocationsModal}
        onHide={handleCloseAddLocationsModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{isEdit ? "Edit Location" : "Add Location"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEditLocation
             location={locationDetail}
             OnSubmit={()=>afterUpsertJob()}
             ref={(ref) => {
              addEditLocationRef = ref;
              }}
              getAllLocation={getAllLocation}
              setLocations={setLocations}
              setAdding={setAdding}
              isEdit={isEdit}
          />
          
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseAddLocationsModal}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button variant="primary" size="sm"
            onClick={() => addEditLocationRef.handleSubmit()}>
              <div className={`w-100 ${(adding) && "pr-5"}`}>
            <span className={` ${(adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{isEdit ? "Update" : "Save"}</span>
            {(adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Locations;
