import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import { TransferTypesObject } from "../../constants/enums";
import { Link } from "react-router-dom";
import { setJobDetails } from "../../../store/jobManagement/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as Common from "../../General/common";


function TransactionBasedReportsListing({filteredValues, handleShowReportDetailModal, setReportDetails, getReportDetailsPartsCall, getInventoryAttachments}) {

    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#TransactionBasedReports").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });

    const dispatch = useDispatch();
 

    return (
        <>
           {/* Transaction Based Reports */}
           <div className="card card-custom mb-5">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="mb-0">
                            <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                            Transaction Based Reports
                        </h3>
                    </div>
                    <div className="card-toolbar">  </div>
                </div>
                <div className="card-body">
                    <table id="TransactionBasedReports" className="table table-bordered table-hover table-striped table-sm w-100">
                        <thead>
                            <tr>
                                <th><i className="fa fa-calendar mr-1"></i> Date</th>
                                <th><i className="fa fa-map-marker-alt mr-1"></i> From</th>
                                <th><i className="fa fa-map-marker-alt mr-1"></i> To</th>
                                <th><i className="fa fa-list-ul mr-1"></i> PartNum</th>
                                <th><i className="fa fa-boxes mr-1"></i> Qty</th>
                                <th><i className="fa fa-bolt mr-1"></i> View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                           {
                               filteredValues?.map((el, idx) => {
                                //var convertedTransactionDate = new Date(el?.TransactionDate + 'Z');
                                   return(
                                    <tr>
                                    <td>{moment(el?.TransactionDate).format("MM/DD/YYYY")}</td>
                                    <td>{(el?.TransferType == TransferTypesObject.REC.abbrev || el?.TransferType == TransferTypesObject.XFER.abbrev|| el?.TransferType == TransferTypesObject.WTCREW.abbrev ) ? el?.FromLocationName :  (el?.TransferType == TransferTypesObject.TCREW.abbrev || el?.TransferType == TransferTypesObject.FXFER.abbrev) ? el?.Feeder : (el?.TransferType == TransferTypesObject.FCREW.abbrev|| el?.TransferType == TransferTypesObject.TWFCREW.abbrev) ? el?.CrewName : ""}</td>
                                    <td>{(el?.TransferType == TransferTypesObject.REC.abbrev || el?.TransferType == TransferTypesObject.FXFER.abbrev|| el?.TransferType == TransferTypesObject.TWFCREW.abbrev) ? el?.ToLocationName : (el?.TransferType == TransferTypesObject.XFER.abbrev || el?.TransferType == TransferTypesObject.FCREW.abbrev) ? el?.Feeder : (el?.TransferType == TransferTypesObject.TCREW.abbrev|| el?.TransferType == TransferTypesObject.WTCREW.abbrev) ? el?.CrewName : ""}</td>
                                    <td>{el?.PARTNUM}</td>
                                    <td>{el?.QTY}</td>
                                    <td align="center">
                                        <OverlayTrigger
                                            placement={"top"}
                                            overlay={<Tooltip> View Detail </Tooltip>}
                                        >
                                            <Link onClick={() => {
                                                getReportDetailsPartsCall(el);
                                                setReportDetails(el);
                                               handleShowReportDetailModal();
                                            }} className="btn btn-icon btn-outline-primary btn-xs">
                                                <i className="fa fa-eye"></i>
                                            </Link>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                                   )
                               })
                           }
                        </tbody>
                    </table>
                </div>
            </div> 
        </>
    );
}

export default TransactionBasedReportsListing;
