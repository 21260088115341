import {
    SET_SESSION_TIMEOUT_TIME
  } from "./actionTypes";

  export const setSessionTimeoutTime = timeInSeconds => {
    return {
      type: SET_SESSION_TIMEOUT_TIME,
      payload: timeInSeconds,
    };
  };

 