import {
    SET_SUBSTATIONS,
    SET_FEEDERS,
    SET_POLE_TYPES,
    SET_CUSTOMERS,
    SET_PART_CATEGORIES,
    SET_PARTS,
    SET_CREWS
  } from "./actionTypes";

  export const setSubstations = substations => {
    return {
      type: SET_SUBSTATIONS,
      payload: substations,
    };
  };

  export const setFeeders = feeders => {
    return {
      type: SET_FEEDERS,
      payload: feeders,
    };
  };

  export const setPoleTypes = poleTypes => {
    return {
      type: SET_POLE_TYPES,
      payload: poleTypes,
    };
  };

  export const setCutomers = customers => {
    return {
      type: SET_CUSTOMERS,
      payload: customers,
    };
  };

  export const setPartCategories = partCategories => {
    return {
      type: SET_PART_CATEGORIES,
      payload: partCategories,
    };
  };

  export const setParts = parts => {
    return {
      type: SET_PARTS,
      payload: parts,
    };
  };

  export const setCrews = crews => {
    return {
      type: SET_CREWS,
      payload: crews,
    };
  };
