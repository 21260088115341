import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'


function CrewTotalReportListing({filteredValues}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#CrewTotalReport").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });



    return (
        <>
            {/* Crew Total Report */}
            <div className="card card-custom mb-5">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="mb-0">
                            <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                            Crew Total Report
                        </h3>
                    </div>
                    <div className="card-toolbar">  </div>
                </div>
                <div className="card-body">
                    <table id="CrewTotalReport" className="table table-bordered table-hover table-striped table-sm w-100">
                        <thead>
                            <tr>
                                <th> <i className="fa fa-home mr-1"></i> Crew</th>
                                <th><i className="fa fa-list-ul mr-1"></i> Substation</th>
                                <th> <i className="fa fa-chart-pie mr-1"></i> Feeder</th>
                                <th> <i className="fa fa-chart-pie mr-1"></i> Warehouse</th>
                                <th>  <i className="fa fa-boxes mr-1"></i> Part Num</th>
                                <th><i className="fa fa-boxes mr-1"></i> Total to Crew</th>
                                <th> <i className="fa fa-boxes mr-1"></i> Qty Returned From Crew</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredValues?.map((el, idx) => {
                                    return(
                                        <tr>
                                            <td>{el?.CREW}</td>
                                            <td>{el?.SUBSTATION}</td>
                                            <td>{el?.FEEDER}</td>
                                            <td>{el?.WAREHOUSE ? el?.WAREHOUSE : ""}</td>
                                            <td>{el?.PARTNUM}</td>
                                            <td>{el?.TOTAL_TO_CREW}</td>
                                            <td>{el?.QTY_RETURNED_FROM_CREW}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div> 
        </>
    );
}

export default CrewTotalReportListing;
