import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import Management from "../store/management/reducer";
import JobManagement from "../store/jobManagement/reducer";
import Stats from "../store/stats/reducer";
import SessionTimeoutTime from "../store/sessionTimeoutTime/reducer";
import Location from "../store/location/reducer";
import UserPassword from "../store/userPassword/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  Management,
  JobManagement,
  Stats,
  SessionTimeoutTime,
  Location,
  UserPassword
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
