import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page, TransferTypesObject } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import moment from "moment";
import { useMemo } from "react";
import DataTable from 'react-data-table-component';


function TransferReportDashbaordListing({
    InventoryTransactions,
    handleDeleteTransaction,
    handleEditTransactionModal,
    setIsEdit,
    setInventoryTransactionsLoading,
    setTransferReportModal,
    setOneReportID,
    setTablePage
}) {

    const [groupedData, setGroupedData] = useState([])
    const [tableData, setTableData] = useState([])

      useEffect(() => {
        if (tableData.length > 0) {
          const dataTable = $("#TransferReportDashbaordListing").DataTable({
            responsive: true,
            paging: true,
            pageLength: no_of_rows_on_single_page,
            destroy: true,
            lengthMenu: [20, 40, 60, 80, 100],
            columnDefs: [
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
              { orderable: true, targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
            ]
          });
    
          dataTable.on('page.dt', (event, datatable, type) => {
            const currentPage = dataTable.page.info().page + 1;
            setTablePage(currentPage)
          });
        }
      }, [tableData]);
      
    //console.log("invemtrottrans", InventoryTransactions);

    useEffect(() => {
        
        if(InventoryTransactions.length > 0){
            setInventoryTransactionsLoading(true)
            const groupedData = InventoryTransactions.reduce((acc, obj) => {
                const date = moment(obj.TransactionDate).format("YYYY-MM-DD")
                if (!acc[obj.ID]) {
                  acc[obj.ID] = [];
                }
                acc[obj.ID].push(obj);
                return acc;
              }, {});
              setGroupedData(groupedData)
              setInventoryTransactionsLoading(false)
        }

    }, [InventoryTransactions])

    useEffect(() => {
        if(Object.keys(groupedData).length > 0){
            const temp = []
            Object.keys(groupedData).map((oneKey) => {
                const element = {
                    TransactionDate: groupedData[oneKey][0].TransactionDate,
                    TransactionID: oneKey,
                    isParent: true,
                    IsReturned:groupedData[oneKey][0].IsReturned
                }
                temp.push(element)
                groupedData[oneKey].map((one) => {
                    const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = one;
                    const element = {
                        FromLocationName: FromLocationName,
                        ToLocationName: ToLocationName,
                        CrewName: CrewName,
                        JobName: JobName,
                        TransferType: TransferType,
                        TransactionDate: TransactionDate,
                        Notes: Notes,
                        Qty: Qty,
                        PartNumber: PartNumber,
                        Feeder: Feeder,
                        TransactionID: null,
                        isParent: false,
                        FirstName: one.FirstName,
                        LastName: one.LastName,
                        IsReturned:one.IsReturned
                    }
                    temp.push(element)
                })
            })
            setTableData(temp)
        }
    }, [groupedData])

    // const rows = useMemo(() => {
    //        return Object.entries(groupedData).reverse().map(([date, items]) => { 
    //             return items.map((one, i) => {
    //                 const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = one
    //                 const OneTransferType = TransferTypesObject[TransferType]
    //                 return(
    //         <tr>
    //         <td>{moment(TransactionDate).format("MM/DD/YYYY")}</td>
    //         <td>{JobName}</td>
    //         {/* <td>{PoleId}</td> */}
    //         <td>{PartNumber}</td>
    //         <td>
    //           <span className="label label-light-danger label-inline">
    //             {Qty}
    //           </span>
    //         </td>
    //         <td>{OneTransferType?.name ? OneTransferType.name : ""}</td>
    //         <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev) ? FromLocationName :  (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.FXFER.abbrev) ? Feeder : (TransferType == TransferTypesObject.FCREW.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? CrewName : ""}</td>
    //         <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.FXFER.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? ToLocationName : (TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.FCREW.abbrev) ? Feeder : (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev ) ? CrewName : ""}</td>
    //         <td>{`${one?.FirstName} ${one?.LastName}`}</td>
    //         <td>{moment(one?.CreatedDate).format("MM/DD/YYYY")}</td>
    //         <td>{Notes}</td>
    //         {(i === items.length - 1) && (
    //   <td align="center" rowSpan={items.length}>
    //     <OverlayTrigger
    //       trigger={`${date}-${i}`}
    //       placement={"top"}
    //       overlay={<Tooltip> Delete Transaction </Tooltip>}
    //     >
    //       <button
    //         id={`${date}-${i}`}
    //         className="btn btn-icon btn-outline-danger btn-xs"
    //         onClick={() => handleDeleteTransaction(one?.DetailId)}
    //       >
    //         <i className="fa fa-trash-alt"></i>
    //       </button>
    //     </OverlayTrigger>
    //   </td>
    // )}
    //       </tr>
    //                 )
    //             })
    //         })
    // }, [groupedData])

    // const rows = useMemo(() => {
    //     return Object.entries(groupedData)
    //     .reverse()
    //     .map(([date, items]) => items.map((item) => ({
    //       ...item,
    //       Date: moment(item.TransactionDate).format("MM/DD/YYYY"),
    //       Qty: <span className="label label-light-danger label-inline">{item.Qty}</span>,
    //       From: item.TransferType == TransferTypesObject.REC.abbrev || item.TransferType == TransferTypesObject.XFER.abbrev || item.TransferType == TransferTypesObject.WTCREW.abbrev ? item.FromLocationName : item.TransferType == TransferTypesObject.TCREW.abbrev || item.TransferType == TransferTypesObject.FXFER.abbrev ? item.Feeder : item.TransferType == TransferTypesObject.FCREW.abbrev || item.TransferType == TransferTypesObject.TWFCREW.abbrev ? item.CrewName : "",
    //       To: item.TransferType == TransferTypesObject.REC.abbrev || item.TransferType == TransferTypesObject.FXFER.abbrev || item.TransferType == TransferTypesObject.TWFCREW.abbrev ? item.ToLocationName : item.TransferType == TransferTypesObject.XFER.abbrev || item.TransferType == TransferTypesObject.FCREW.abbrev ? item.Feeder : item.TransferType == TransferTypesObject.TCREW.abbrev || item.TransferType == TransferTypesObject.WTCREW.abbrev ? item.CrewName : "",
    //       CreatedBy: `${item.FirstName} ${item.LastName}`,
    //       CreatedDate: moment(item.CreatedDate).format("MM/DD/YYYY"),
    //     })))
    //     .flat();
    // }, [groupedData])
      
    // useEffect(() => {
    //     $('#TransferReportDashbaordListing').DataTable().destroy();
    //     $('#TransferReportDashbaordListing').dataTable({
    //         columns: [
    //             { data: 'Date' },
    //             { data: 'Job' },
    //             { data: 'Part' },
    //             { data: 'Qty' },
    //             { data: 'Transfer Type' },
    //             { data: 'From' },
    //             { data: 'To' },
    //             { data: 'CreatedBy' },
    //             { data: 'CreatedDate' },
    //             { data: 'Notes' },
    //             { data: 'Action', orderable: false }
    //           ],
    //         data: rows,
    //         pageLength: no_of_rows_on_single_page
    //     });
    // }, [groupedData, rows])

    return (
        <>
            <div className="card-body">
            <table id="TransferReportDashbaordListing" className="table table-bordered table-hover table-striped table-sm w-100">
                <thead>
                  <tr>
                    <th>
                      <i className="fa fa-calendar mr-1"></i> Date
                    </th>
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Job
                    </th>
                    {/* <th>
                      <i className="fa fa-list-ul mr-1"></i> Pole
                    </th> */}
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Part
                    </th>
                    <th>
                      <i className="fa fa-list-ol mr-1"></i> Qty
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Transfer Type
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> From
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> To
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Created By
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Created Date
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Notes
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                   
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((el, idx) => {
                    const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = el;
                    //var convertedTransactionDate = new Date(TransactionDate + 'Z');
                    // var today = new Date();
                    // var startDay = 0; 
                    // var weekStart = new Date(today.getDate() - (7 + today.getDay() - startDay) % 7);
                    // var weekEnd = new Date(today.getDate() + (7 - today.getDay() - startDay) % 7);
                    const OneTransferType = TransferTypesObject[TransferType]
                    return (
                      <tr key={`${TransactionDate}-${idx}`} >
                        <td>{moment(TransactionDate).format("MM/DD/YYYY")}</td>
                        <td>{JobName}</td>
                        {/* <td>{PoleId}</td> */}
                        <td>{PartNumber}</td>
                        <td>
                            {
                                el.isParent == false ?
                                <span className="label label-light-danger label-inline">
                                    {Qty}
                                </span>
                                :
                                <></>
                            }
                        </td>
                        <td>{OneTransferType?.name ? OneTransferType.name : ""}</td>
                        <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev) ? FromLocationName :  (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.FXFER.abbrev) ? Feeder : (TransferType == TransferTypesObject.FCREW.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? CrewName : ""}</td>
                        <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.FXFER.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? ToLocationName : (TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.FCREW.abbrev) ? Feeder : (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev ) ? CrewName : ""}</td>
                        <td>{
                            el.isParent == false ?
                            `${el?.FirstName} ${el?.LastName}`
                            :
                            <></>
                            }</td>
                        <td>{
                            el.isParent == false ? 
                            moment(el?.CreatedDate).format("MM/DD/YYYY")
                            :
                            <></>
                            }</td>
                        <td>{Notes}</td>
                        <td align="center">
                            {
                                el.TransactionID != null ?
                                <OverlayTrigger
                                placement={"top"}
                                key={idx}
                                target={`return-${idx}`}
                                overlay={el.IsReturned==="1"?<Tooltip> Already Returned</Tooltip>:<Tooltip> Return</Tooltip>}
                            >
                              <div className="btn-group">
                                <button className="btn btn-icon btn-outline-primary btn-xs ml-3"
                                  disabled={el.IsReturned==="1"}
                                onClick={() => {
                              //document.getElementById(`return-${idx}`).blur();
                                    setOneReportID(el.TransactionID)
                                    setTransferReportModal(true)
                                }}>
                                <i className="fa fa-reply"></i>
                                </button>
                                </div>
                            </OverlayTrigger>
                            :
                            <></>
                            }
                        </td>
                      
                      </tr>
                    );
                  })}


                  
                </tbody>
              </table>
            </div>
            
        </>
    );
}

export default TransferReportDashbaordListing;
