import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { data } from "jquery";
import jwt_decode from "jwt-decode";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { setUserPassword } from "../../../../store/userPassword/actions";
import { useDispatch } from "react-redux";
import { getUserByIdCall } from "../../../services/userManagementService";
import { setSessionTimeoutTime } from "../../../../store/sessionTimeoutTime/actions";
import moment from "moment";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [userIsInactive, setUserIsInactive] = useState(false);
  const [userInactiveErrorMessage, setUserInactiveErrorMessage] = useState("");
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  

  // change the first letter of object keys to small

  const smallKeys = (obj) => {
    const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
    const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'
    
    let transformedObj = isArray(obj) ? [] : {}
    
    for (let key in obj) {
      // replace the following with any transform function
      const transformedKey = key.replace(/^\w/, (c, _) => c.toLowerCase())
  
      if (isObject(obj[key]) || isArray(obj[key])) {
        transformedObj[transformedKey] = smallKeys(obj[key])
      } else {
        transformedObj[transformedKey] = obj[key]
      }
    }
    return transformedObj
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      dispatch(setUserPassword(values.password));
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
        .then(({ data }) => {
          //console.log("loginres", data);
             disableLoading();

             if(data.success){
               setUserIsInactive(false);
               //console.log("userdata", data?.result?.userData);
             
               const authToken = data.result.accessToken;
              //  if(data?.result?.userId > 0){
              //   getUserById(authToken, data?.result?.userId);
              //  }
              
               //const decoded = jwt_decode(authToken);
               //const userData = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];

               //const parsedData = JSON.parse(userData);
              
               const newData = smallKeys(data?.result?.userData);
            //console.log("newdaat", newData);
              let newDataHere = {
                ...newData,
                tokenExpiryTime: moment.utc().add(2, 'hours').format()
              };

                localStorage.setItem("accessToken", authToken);
                props.login(authToken);
                props.setUser(newDataHere);
                props.requestUser(newDataHere);
                props.fulfillUser(newDataHere);
                dispatch(setSessionTimeoutTime(120*60));
              
             }else if(!data.success && data.statusCode === 403){
               setUserInactiveErrorMessage(data.message);
               setUserIsInactive(true);
             }else{
               setUserIsInactive(false);
               setStatus(
                 intl.formatMessage({
                   id: "AUTH.VALIDATION.INVALID_LOGIN",
                 })
               );
             }
            
          })
          .catch(() => {
            setUserIsInactive(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin bg-white p-5 p-lg-10 rounded" id="kt_login_signin_form">
      {/* begin::Head */}
      {/* <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div> */}
      <div className="text-center mb-10 mb-lg-20">
        <img
            alt="Logo"
            className="max-h-70px max-w-100"
            src={toAbsoluteUrl("/media/logos/logo.png")}
        />
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible d-none">
            <div className="alert-text ">
              Use account <strong>admin@bigham.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )}

        {
          userIsInactive &&
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{userInactiveErrorMessage}</div>
          </div>
        }

        <div className="form-group fv-plugins-icon-container">
          <label className="">Email<span className="text-danger">*</span></label>
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <label className="">Password<span className="text-danger">*</span></label>
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 d-flex w-100 justify-content-center`}
          >
            <div className="w-100">
            <span className={` ${loading && 'mr-3'}`}>Sign In</span>
            {loading && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
