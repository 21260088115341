import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import CheckPermission from "../../General/CheckPermission";
import { userActions, no_of_rows_on_single_page } from "../../constants/enums";
import { EntityIds } from "../../General/common";

function PoleTypeListing({
    poleTypes,
    handleDeletePoleType,
    setPoleTypeDetails,
    getPoleTypeParts,
    handleShowAddPolesModal,
    getAttachments
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#PoleTypeListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });



    return (
        <>
            <div className="card-body">
              {/* {poleTypesLoading ? (
                <div className="d-flex justify-content-center">
                  <CustomLoader />
                </div>
              ) : !poleTypesLoading && poleTypes?.length === 0 ? (
                <div className="d-flex justify-content-center">
                  No Pole Types
                </div>
              ) : ( */}
                <CheckPermission
                  currentAction={userActions.Pole.Action_ViewPole}
                  yes={() => (
                    <table
                      id="PoleTypeListing"
                      className="table table-bordered table-hover table-striped table-sm w-100"
                    >
                      <thead>
                        <tr>
                          <th data-priority="1">
                            <i class="fa fa-gopuram"></i> Pole Type Name
                          </th>
                          <th>
                            <i className="fa fa-comments mr-1"></i> Notes
                          </th>
                          <th data-priority="2" className="text-center min-width-110">
                            <i className="fa fa-bolt mr-1"></i> Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                                    new Array(10).fill(0).map(index => {
            
                                    })
                                } */}
                        {poleTypes?.map((poleType) => {
                          return (
                            <tr key={poleType.id}>
                              <td>{poleType.typeName}</td>
                              <td>{poleType.notes}</td>
                              <td align="center">
                                <CheckPermission
                                  currentAction={
                                    userActions.Pole.Action_DeletePole
                                  }
                                  yes={() => (
                                    <OverlayTrigger
                                      placement={"top"}
                                      overlay={
                                        <Tooltip> Delete Pole Type </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn btn-icon btn-outline-danger btn-xs"
                                        onClick={() => {
                                          //setIsDeletingPoleType(true);
                                          handleDeletePoleType(poleType.id);
                                          //setIsDeletingPoleType(false);
                                        }}
                                      >
                                        <i className="fa fa-trash-alt"></i>
                                      </button>
                                    </OverlayTrigger>
                                  )}
                                  no={() => null}
                                />
                                <CheckPermission
                                  currentAction={
                                    userActions.Pole.Action_EditPole
                                  }
                                  yes={() => (
                                    <OverlayTrigger
                                      placement={"top"}
                                      overlay={
                                        <Tooltip> Edit Pole Type </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn btn-icon btn-outline-primary btn-xs ml-3"
                                        onClick={() => {
                                          setPoleTypeDetails(poleType);
                                          getPoleTypeParts(poleType.id);
                                          handleShowAddPolesModal();
                                          getAttachments(
                                            poleType.id,
                                            EntityIds.PoleType
                                          );
                                        }}
                                      >
                                        <i className="fa fa-pen"></i>
                                      </button>
                                    </OverlayTrigger>
                                  )}
                                  no={() => null}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  no={() => null}
                />
              {/* )} */}
            </div>
        </>
    );
}

export default PoleTypeListing;
