// export const ApiBaseUrl = "http://127.0.0.1:8000/api/";
export const ApiBaseUrl = "https://bighamapi.reporteq.solutions/api/";

export const apiUrls = {
  jobManagement: {
    job: {
      getAllJobs: "Jobs/GetAllJobs",
      upsertJob: "Jobs/UpsertJob",
      deleteJob: "Jobs/DeleteJob",
      getAllassignedJobs: "Jobs/GetAllUnAssignedJobs",
      assignJobToUser: "Jobs/AssignJobToUser",
      removeUserFromJob: "Jobs/RemoveUserFromJob"
    }
  },

  attachment: {
    getAttachments: "Attachments/GetAttachmentForRecord",
    upsertAttachments: "Attachments/UploadAttachments",
  },
  inventoryTransaction: {
    getAllInventoryTransactions: "InventoryTransactions/GetAllInventoryTransactions",
    getTransferReportTransactions: "InventoryTransactions/GetInventoryTransactionsReport",
    getAllTransferReportTransactions: "InventoryTransactions/GetAllInventoryTransactionsReport",
    getTransferReportDetail: "InventoryTransactions/GetInventoryTransactionDetail/",
    upsertInventoryTransaction: "InventoryTransactions/UpsertInventoryTransaction",
    deleteInventoryTransaction: "InventoryTransactions/Delete"
  },
  getStats: {
    getAllStats: "Statistics/GetDashbordStats"
  },
  getReports: {
    getAllReports: "Reports/GetReport",
    getReportDetails: "Reports/GetReportDetails"
  },
  poleCompletion: {
    upsertPoleCompletion: "PoleCompletion/UpsertPoleCompletion"
  },
  location: {
    getAllLocations: "Location/GetAllLocation",
    upsertLocation: "Location/UpsertLocation",
    deleteLocation: "Location/DeleteLocation"
  },
  management: {
    substations: {
      getSubstations: "SubStation/GetAllSubStations",
      getSubstationActivePriorities: "SubStation/GetAllActivePriorities",
      upsertSubstation: "SubStation/UpsertSubStation",
      deleteSubstation: "SubStation/DeleteSubStation",
    },
    feeders: {
      getFeeders: "Feeder/GetAllFeeders",
      getFeederActivePriorities: "Feeder/GetAllActivePriorities",
      upsertFeeder: "Feeder/UpsertFeeder",
      deleteFeeder: "Feeder/DeleteFeeder",
    },
    parts: {
      getParts: "Part/GetAllParts",
      getAvailablePartsInWarehouseForJob: "Part/GetAvailablePartsInWarehouseForJob",
      upsertPart: "Part/UpsertPart",
      deletePart: "Part/DeletePart",
    },
    partCategories: {
      getPartCategories: "PartCategory/GetAllActivePartCategories",
      upsertPartCategory: "PartCategory/UpsertPartCategory",
      deletePartCategory: "",
    },
    poleTypes: {
      getPoleTypes: "PoleType/GetAllPoleType",
      getPoleTypesById: "PoleType/GetPoleTypeFromPoleTypeId",
      getPoleTypePartsByPoleTypeId: "PoleType/GetPoleTypePartFromPoletypeId",
      upsertPoleType: "PoleType/UpsertPoleTypeAndParts",
      deletePoleType: "PoleType/DeletePoleType"
    },
    customer: {
      getAllCustomers: "Customer/GetAllCustomers",
      upsertCustomer: "Customer/UpsertCustomer",
      deleteCustomer: "Customer/DeleteCustomer",
    },
    uom: {
      getAllUoms: "Uom/GetAllUoms",

    },
    crews: {
      getCrews: "CrewUser/GetAllCrewUsers",
      upsertCrew: "CrewUser/UpsertCrewUsers",
      deleteCrew: "CrewUser/DeleteCrewUser",
      getCrewDetailsById: "CrewUser/GetCrewDetailsByID"
    },
  },
  userManagement: {
    users: {
      getAllusers: "User/GetAllUsers",
      getAllActiveRole: "User/GetAllActiveRoles",
      upsertUser: "User/UpsertUser",
      deleteUser: "User/DeleteUser",
      getUnassignedUsers: "User/GetUnassignedUser",
      resetPassword: "User/ResetPassword",
      resetPasswordByToken: "User/ResetPasswordUsingToken",
      getUserById: "User/GetUserByID",
      verifyEmail: "User/VerifyEmail",
      forgotPassword: "User/ForgotPassword",
      updatePassword: "User/UpdatePassword"
    }
  }







};
