import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toInteger } from "lodash";
import { format, parseISO } from 'date-fns'
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import SVG from "react-inlinesvg";
import $ from "jquery";
import ButtonGroupComponent from "./ButtonGroupComponent";
import JobDetailTiles from "./JobDetailTiles";
import JobsDetail from "../JobsDetail";
import { deleteInventoryTransactionApiCall, getAllInventoryTransactions } from "../../services/inventoryTransectionService";
import { getStatsCall } from "../../services/statsService";
import { timeout_for_data_tables, TransferTypes } from "../../constants/enums";
import { setStats } from "../../../store/stats/actions";
import JobDetailDashboardListing from "./JobDetailDashboardListing";
import { CustomLoader } from "../../components/common/CustomLoader";
import moment from "moment";
import * as Common from "../../General/common";
import { Button } from 'react-bootstrap';

function JobDetailDashboard() {

  const { jobDetails } = useSelector((state) => state.JobManagement)
  const [InventoryTransactions, setInventoryTransactions] = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);
  const stats = useSelector((store) => store.Stats.stats);
  const [deletingTransaction, setDeletingTransaction] = useState(false);

  const [InventoryTransactionsLoading, setInventoryTransactionsLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const [transactionDetail, setTransactionDetail] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInventoryModal, setShowInventoryModal] = useState(false);

  const [FromDate, setFromDate] = useState(null);

  const changeFromDate = (e) => {
    setFromDate(e.target.value == "" ? null : e.target.value);
  }

  const [ToDate, setToDate] = useState(null);

  const changeToDate = (e) => {
    setToDate(e.target.value == "" ? null : e.target.value);
  }


  const dispatch = useDispatch();

  const getInventoryTransactions = async () => {
    setInventoryTransactionsLoading(true);
    try {
      const { data } = await getAllInventoryTransactions(jobDetails.ID);
      if (data.success && data.result.length) {
        setInventoryTransactions(data.result);
      }
      else {
        setInventoryTransactions([]);
      }
    }
    catch {
    }
    finally {
    }
    setInventoryTransactionsLoading(false);
  };

  const getStats = async (jobId) => {
    setStatsLoading(true);
    try {
      const { data } = await getStatsCall(jobId);
      if (data.success) {
        setStatsLoading(false);
        dispatch(setStats(data.result));
      }else{
        setStatsLoading(false);
      }
    }
    catch {
      setStatsLoading(false);
    }
    finally {
      setStatsLoading(false);
    }
  };


  useEffect(() => {
    getInventoryTransactions();
    getStats(jobDetails?.ID);

  }, []);


  /* delete transaction code starts */

  const handleDeleteTransaction = (transactionId) => {
    var msg = "You want to delete this Transaction.";
    Common.showConfirmation(msg, "", deleteTransaction, null, transactionId)

};
  const deleteTransaction =async (transactionId) => {
  
    if (transactionId>0) {
      setInventoryTransactions([]);
      setDeletingTransaction(true);
      try {
        const { data } = await deleteInventoryTransactionApiCall(transactionId);
      
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               "Inventory Transaction deleted successfully!",
                "success",
                "Success!",
                3000
            );
            getInventoryTransactions();
            //window.location.reload(false);
         
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
        getInventoryTransactions()
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
       

    }
    setDeletingTransaction(false);
    }
}

  /* delete transaction code ends */

  const handleEditTransactionModal=async (transactionDetail) => {
    await setTransactionDetail(transactionDetail)
    setShowInventoryModal(true);
   }


  const [thisWeekInventoryTransactions, setThisWeekInventoryTransactions] = useState([]);

  // useEffect(() => {
  //   if(InventoryTransactions?.length > 0){
  //     const filteredInventoryTransactionsHere = InventoryTransactions?.filter((el, idx) => {
  //         if(moment().isoWeek() == moment(el?.TransactionDate).isoWeek()){
  //           return el;
  //         }
  //       });
  //     setThisWeekInventoryTransactions(filteredInventoryTransactionsHere);
  //   }
  // }, [InventoryTransactions]);


  const [filteredInventoryTransactions, setFilteredInventoryTransactions] = useState([]);

  useEffect(() => {
      const filteredInventoryTransactionsHere = InventoryTransactions?.filter((el, idx) => {
              return el;
            });
          setFilteredInventoryTransactions(filteredInventoryTransactionsHere);
  }, [InventoryTransactions]);

  const filterTransactionsByDate = () => {
    setInventoryTransactionsLoading(true);
    if(InventoryTransactions?.length > 0){
      let filteredInventoryTransactionsHere;
        filteredInventoryTransactionsHere = InventoryTransactions?.filter((el, idx) => {
          if(moment(moment(el?.TransactionDate).format("YYYY-MM-DD")).isSameOrAfter(moment(FromDate).format("YYYY-MM-DD")) && moment(moment(el?.TransactionDate).format("YYYY-MM-DD")).isSameOrBefore(moment(ToDate).format("YYYY-MM-DD"))){
            return el;
          }
        });
        setFilteredInventoryTransactions(filteredInventoryTransactionsHere);
    }
    setTimeout(() => {
      setInventoryTransactionsLoading(false);
    }, 3000);
  }

  const showAllInventoryTransactions = () => {
    setInventoryTransactionsLoading(true);
    setFilteredInventoryTransactions(InventoryTransactions);
    setTimeout(() => {
      setInventoryTransactionsLoading(false);
    }, 3000);
  }

  //console.log("thisweekinventory", thisWeekInventoryTransactions);
  //console.log("inventorytransactions", filteredInventoryTransactions);
  
  return (
    <>
      <JobsDetail />
      
      {/* <div className="row flex-wrap">
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Feeders"
            tileIcon="/media/svg/icons/Home/Home.svg"
            titleValue={!(stats && stats.totalFeeders) ? "0" : toInteger(stats.totalFeeders)}
            tileColor="warning"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Substations"
            tileIcon="/media/svg/icons/Devices/CPU2.svg"
            titleValue={!(stats && stats.totalSubStations) ? "0" : toInteger(stats.totalSubStations)}
            tileColor="info"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Completed Poles"
            tileIcon="/media/svg/icons/Electric/Range-hood.svg"
            titleValue={!(stats && stats.completedPoles) ? "0" : toInteger(stats.completedPoles)}
            tileColor="danger"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Parts"
            tileIcon="/media/svg/icons/Devices/Server.svg"
            titleValue={!(stats && stats.totalParts) ? "0" : toInteger(stats.totalParts)}
            tileColor="success"
          />
        </div>
        <div className="col min-width_tiles">
          <JobDetailTiles
            tileTitle="Total Days"
            tileIcon="/media/svg/icons/Home/Clock.svg"
            titleValue={!(stats && stats.totalDays) ? "0" : toInteger(stats.totalDays)}
            tileColor="primary"
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header d-flex align-items-center">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/document.svg" width="30" height="30" className="mr-3" alt="summary" />
                  Summary
                </h3>
              </div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="p-2">
                <span className="mr-2" htmlFor="FilterByDate">
                  From Date
                </span>
                <input 
                  type="date"
                  id="FilterByDate"
                  name="FilterByDate"
                  value={FromDate}
                  onChange={changeFromDate}
                />
                </div>
                <div className="p-2">
                <span className="ml-2 mr-2" htmlFor="FilterByDate">
                  To Date
                </span>
                <input 
                  type="date"
                  id="FilterByDate"
                  name="FilterByDate"
                  value={ToDate}
                  onChange={changeToDate}
                />
                </div>
                
                      <Button className="ml-2 m-2" disabled={(FromDate == null || ToDate == null) ? true : false} onClick={() => { filterTransactionsByDate(); } }><i className="fa fa-search mr-1"></i>Search Inventories</Button>

                      <Button className="ml-2 m-2" onClick={() => { showAllInventoryTransactions(); } }><i className="fa fa-list"></i>Show All</Button> 
                
                
              </div>
              <div className="panel-toolbar">
              <ButtonGroupComponent getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} isEdit={isEdit} setIsEdit={setIsEdit} transactionDetail={transactionDetail} setTransactionDetail={setTransactionDetail} showInventoryModal={showInventoryModal} setShowInventoryModal={setShowInventoryModal} />
              </div>
            </div>
            {InventoryTransactionsLoading || adding || deletingTransaction ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !InventoryTransactionsLoading && !adding && !deletingTransaction && filteredInventoryTransactions?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Transactions
                </div>
              ) : (
               <JobDetailDashboardListing
                InventoryTransactions={filteredInventoryTransactions}
                handleDeleteTransaction={handleDeleteTransaction}
                handleEditTransactionModal={handleEditTransactionModal}
                setIsEdit={setIsEdit}
               />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDetailDashboard;
