import * as API from "../General/api";
 
export function addInventoryTransaction(inventoryTransaction) {
  return API.postRequest(API.apiUrls.inventoryTransaction.upsertInventoryTransaction, inventoryTransaction);
} 
export function getAllInventoryTransactions(jobId) {
  return API.getRequest(API.apiUrls.inventoryTransaction.getAllInventoryTransactions + `?JobId=${jobId}&reportType=3`);
}
export function getTransferReportTransactions(jobId, startDate, endDate, transferType) {
  return API.getRequest(API.apiUrls.inventoryTransaction.getTransferReportTransactions + `?JobId=${jobId}&reportType=3&startDate=${startDate}&endDate=${endDate}&transferType=${transferType}`);
}
export function getAllTransferReportTransactions(jobId, startDate, endDate, transferType) {
  return API.getRequest(API.apiUrls.inventoryTransaction.getAllTransferReportTransactions + `?JobId=${jobId}&reportType=3&startDate=${""}&endDate=${""}&transferType=${""}`);
}
export function getTransferReportDetail(jobId) {
  return API.getRequest(API.apiUrls.inventoryTransaction.getTransferReportDetail + jobId);
}
export function deleteInventoryTransactionApiCall(transactionId) {
  return API.postDeleteRequest(API.apiUrls.inventoryTransaction.deleteInventoryTransaction, transactionId);
}
export function getAllInventoryTransactionsOnClickOfWarehouse(jobId, reportType) {
  return API.getRequest(API.apiUrls.inventoryTransaction.getAllInventoryTransactions + `?JobId=${jobId}&reportType=${reportType}`);
} 
export function addPoleCompletionTransaction(payload) {
  return API.postRequest(API.apiUrls.poleCompletion.upsertPoleCompletion,payload);
} 