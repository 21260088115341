import React, { useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import * as Common from "../General/common";
import { deleteJob } from "../services/jobServices";
import { setJobDetails } from "../../store/jobManagement/actions";
import { useDispatch } from "react-redux";
import CheckPermission from "../General/CheckPermission";
import { userActions } from "../constants/enums";
import { upsertJob } from "../services/jobServices";
import Switch from "react-switch";
function Jobs({ jobDetail, addJobModalShow, refreshJob }) {
  const dispatch = useDispatch();

  const deleteJobHandle = (jobId) => {
    var msg = "You want to delete this job.";
    Common.showConfirmation(msg, "", deleteJobApiCall, null, jobId);
  };
  const deleteJobApiCall = async (jobId) => {
    if (jobId > 0) {
      try {
        const { data } = await deleteJob(jobId);
        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            "Job deleted successfully!",
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
        refreshJob();
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
        // setSubstationsLoading(false);
      }
    }
  };

  // update job details on click of switch
  const upsertJobApiCall = async (detail) => {

    let job = detail;

    try {
        const { data } = await upsertJob(job);

        if (data.success) {
            // success scenario 
            refreshJob();
        } else {
           
        }
    }
    catch {

       
    }
    finally {
        
    }


}


   const [checked, setChecked] = useState(false);
   const handleChange = (nextChecked) => {
     const detailHere = {
       ...jobDetail,
       isCompleted: nextChecked
     };
    // //console.log("detailtopost", detailHere);
     if(detailHere){
       upsertJobApiCall(detailHere);
     }
   };
  return (
    <div className={`bg-light-grey px-6 py-7 rounded-xl mb-7 boxShadow jobBoxMinHeight`}>
      <Link
        to="/jobdetail"
        onClick={() => {
          dispatch(setJobDetails(jobDetail));
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h3 className="text-dark font-weight-bold text-capitalize font-size-h3 dotsAfterTwoLine">
              {jobDetail.JobName}
            </h3>

            <p className="m-0">
              <small className="d-block font-size-sm font-weight-bold text-dark mt-3">
                Customer: {jobDetail.CustomerName}
              </small>
            </p>
          </div>
          {/* Please Remove the class "svg-dark, in case of use any other svg icon here" */}
          {/* <span className="svg-icon svg-dark svg-icon-4x d-block">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}></SVG>
          </span> */}
        </div>
      </Link>
      <div className="d-flex align-items-center justify-content-end">
      {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{jobDetail?.isCompleted ? "Job is Completed" : "Mark job as completed"}</Tooltip>}
        >
        <label className="mb-0 mr-3 d-flex">
          <Switch
            onChange={handleChange}
            checked={jobDetail?.isCompleted ? true : false}
            className="react-switch"
          />
        </label>
        </OverlayTrigger> */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>View Job Detail</Tooltip>}
        >
          <Link
            to="/jobdetail"
            onClick={() => {
              dispatch(setJobDetails(jobDetail));
            }}
            className="btn btn-primary btn-sm btn-icon mr-3"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </OverlayTrigger>
        <CheckPermission
          currentAction={userActions.Jobs.Action_EditJobs}
          yes={() => (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit Job</Tooltip>}
            >
              <button
                className="btn btn-info btn-sm btn-icon mr-3"
                onClick={() => addJobModalShow(jobDetail)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </OverlayTrigger>
          )}
          no={() => null}
        />
        <CheckPermission
          currentAction={userActions.Jobs.Action_DeleteJobs}
          yes={() => (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete Job</Tooltip>}
            >
              <button
                className="btn btn-danger btn-sm btn-icon"
                onClick={() => deleteJobHandle(jobDetail.ID)}
              >
                <i className="fa fa-trash-alt p-0"></i>
              </button>
            </OverlayTrigger>
          )}
          no={() => null}
        />
      </div>
    </div>
  );
}

export default Jobs;
