import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_JOBS,
    SET_JOB_DETAILS
  } from "./actionTypes";

  const initialState = {
    jobs: [],
    jobDetails: {}
  };

  const jobManagement = persistReducer(
    { storage, key: "v726-demo1-jobDetails", whitelist: ["jobDetails"] },
    (state = initialState, action) => {
    switch (action.type) {
      case SET_JOBS:
        state = { ...state, jobs: action.payload };
        break;
      case SET_JOB_DETAILS:
        state = { ...state, jobDetails: action.payload };
        break;
    
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });
  export default jobManagement;
  