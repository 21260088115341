import React, { useState, useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux';
//import { setCutomers } from "../../../store/management/actions";
import { CustomLoader } from "../../components/common/CustomLoader";
import { getAllCustomerApiCall,deleteCustomerApiCall } from "../../services/managementServices";
import AddEditCustomer from "./addEditCustomer"
import * as Common from "../../General/common"
import JobsDetail from "../JobsDetail";
import CustomersListing from "./CustomersListing";
var addEditCustomerRef=null;
function Customers() {

  
  

  // for Add Crew modal popup
  //const customerList = useSelector((store) => store.Management.customers);
  const [customerList, setCustomers] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [showAddCustomersModal, setShowAddCustomersModal] = useState(false);
  // const [customerList, setCustomerList] = useState([]);
  const [customerDetail, setCustomerDetail] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  
  const handleCloseAddCustomersModal = () => setShowAddCustomersModal(false);
  const handleShowAddCustomersModal = () => {setShowAddCustomersModal(true);setCustomerDetail(null)};

  const dispatch = useDispatch();

     // function which fetches the substations
     const getAllCustomer = async () => {
      //  var test=customers;
      //  
      setCustomerLoading(true);
      try {
        const { data } = await getAllCustomerApiCall();
       
        if(data.success){
          // success scenario
           setCustomerLoading(false);
          if(data.result.length > 0){
            setCustomers(data.result);
          //  setCustomerList(data.result)
          }
        }else{
           setCustomerLoading(false);
        }
      }
      catch {
         setCustomerLoading(false);
      }
      finally {
         setCustomerLoading(false);
      }
  };
  const handleEditCustomerModal=async (customerDetail) => {
    
   await setCustomerDetail(customerDetail)
    handleShowAddCustomersModal();
  }
  const handleDeleteCustomer = (jobId) => {
    var msg = "You want to delete this Customer.";
    Common.showConfirmation(msg, "", deleteCustomer, null, jobId)

};
  const deleteCustomer =async (customerId) => {
  
    if (customerId>0) {
      setCustomers([]);
      setDeleting(true);
      try {
        const { data } = await deleteCustomerApiCall(customerId);
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               "Customer deleted successfully!",
                "success",
                "Success!",
                3000
            );
            getAllCustomer();
            //window.location.reload(false);
         
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
        getAllCustomer()
       
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
        // setSubstationsLoading(false);

    }
    setDeleting(false);
    }
}
  const afterUpsertJob=()=>{
    handleCloseAddCustomersModal();
    getAllCustomer();
  }

  useEffect(() => {
    getAllCustomer();
  }, []);
  
  return (
    <>
      {/* <JobsDetail /> */}
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/customer.svg" width="30" height="30" className="mr-3" alt="summary"/>
                  Customer List
                </h3>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {handleShowAddCustomersModal(); setIsEdit(false);}}
                >
                  <i className="fa fa-plus mr-1"></i>Add Customer
                </button>
              </div>
            </div>
            {customerLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !customerLoading && !deleting && !adding && customerList?.length === 0 ? (
                <div className="d-flex justify-content-center">
                  No Customers
                </div>
              ) : (
                <CustomersListing
                  customerList={customerList}
                  handleDeleteCustomer={handleDeleteCustomer}
                  handleEditCustomerModal={handleEditCustomerModal}
                  setIsEdit={setIsEdit}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add Crew Modal  */}
      <Modal
        show={showAddCustomersModal}
        onHide={handleCloseAddCustomersModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{isEdit ? "Edit Customer" : "Add Customer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEditCustomer
             customer={customerDetail}
             OnSubmit={()=>afterUpsertJob()}
             ref={(ref) => {
              addEditCustomerRef = ref;
              }}
              getAllCustomer={getAllCustomer}
              setCustomers={setCustomers}
              setAdding={setAdding}
              isEdit={isEdit}
          />
          
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseAddCustomersModal}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button variant="primary" size="sm"
            disabled={adding}
            onClick={() => addEditCustomerRef.handleSubmit()}>
              <div className={`w-100 ${(adding) && "pr-5"}`}>
            <span className={` ${(adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{isEdit ? "Update" : "Save"}</span>
            {(adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Customers;
