import {
    SET_SUBSTATIONS,
    SET_FEEDERS,
    SET_POLE_TYPES,
    SET_CUSTOMERS,
    SET_PART_CATEGORIES,
    SET_PARTS,
    SET_CREWS
  } from "./actionTypes";

  const initialState = {
    substations: [],
    feeders: [],
    poleTypes: [],
    customers: [],
    partCategories: [],
    parts: [],
    crews: []
  };

  const management = (state = initialState, action) => {
    switch (action.type) {
      case SET_SUBSTATIONS:
        state = { ...state, substations: action.payload };
        break;
      case SET_FEEDERS:
        state = { ...state, feeders: action.payload };
        break;
      case SET_POLE_TYPES:
        state = { ...state, poleTypes: action.payload };
        break;
      case SET_CUSTOMERS:
          state = { ...state, customers: action.payload };
          break;
      case SET_PART_CATEGORIES:
        state = { ...state, partCategories: action.payload };
        break;
      case SET_PARTS:
          state = { ...state, parts: action.payload };
          break;
      case SET_CREWS:
        state = { ...state, crews: action.payload };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  export default management;
  