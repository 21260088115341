import {
    SET_JOBS,
    SET_JOB_DETAILS
  } from "./actionTypes";

  export const setJobs = jobs => {
    return {
      type: SET_JOBS,
      payload: jobs,
    };
  };
  export const setJobDetails = job => {
    return {
      type: SET_JOB_DETAILS,
      payload: job,
    };
  };

 