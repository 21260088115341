import React from 'react';
import { Spinner } from 'react-bootstrap';

export const CustomLoader = () => {
    return (
        <div>
            <Spinner animation="border" role="status">
            </Spinner>
        </div>
    )
}