import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';


const AddEditSubstation = ({
    showAddSubstationsModal,
    setShowAddSubstationsModal,
    handleCloseAddSubstationModal,
    handleShowAddSubstationsModal,
    substationDetails,
    setSubstationDetails,
    addorEditSubstation,
    substationActivePriorities,
    setSubstationActivePriorities,
    id,
        setId,
        createdBy,
        setCreatedBy,
        modifiedBy,
        setModifiedBy,
        substationName,
        setSubstationName,
        substationNumber,
        setSubstationNumber,
        fk_PriorityID,
        setFkPriorityId,
        fk_JobID,
        setFkJobId,
        notes,
        setNotes,
        adding,
        substationNameBlurred,
        setSubstationNameBlurred,
        substationNumberBlurred,
        setSubstationNumberBlurred,
        fk_PriorityIDBlurred,
        setFkPriorityIdBlurred
}) => {

    // add substation states starts
    // const [id, setId] = useState(0);
    // const [createdBy, setCreatedBy] = useState("");
    // const [modifiedBy, setModifiedBy] = useState("");
    // const [substationName, setSubstationName] = useState("");
    // const [substationNumber, setSubstationNumber] = useState("");
    // const [fk_PriorityID, setFkPriorityId] = useState(0);
    // const [fk_JobID, setFkJobId] = useState(0);
    // const [notes, setNotes] = useState("");
    const jobDetails = useSelector((store) => store.JobManagement.jobDetails);

    const [substationData, setSubstationData] = useState(null);
    const [addorEditLoading, setAddorEditLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);


    const changeSubstationName = (e) => {
      setSubstationName(e.target.value);
    }

    const changeSubstationNumber = (e) => {
      setSubstationNumber(e.target.value);
    }

    const changeSubstationPriority = (e) => {
      setFkPriorityId(parseInt(e.target.value));
    }


    const changeNotes = (e) => {
      setNotes(e.target.value);
    }
    // add substation states ends

    // formik setup starts

    useEffect(() => {
        formik.setValues({
          notes,
          createdBy,
          modifiedBy,
          id,
          fk_PriorityID,
          fk_JobID,
          substationName,
          substationNumber
        });
      }, [substationName, substationNumber, fk_PriorityID, notes, fk_JobID, id]);

      const Schema = Yup.object().shape({
        substationName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Substation Name is required"),
        substationNumber: Yup.number().required("Substation Number is required").typeError("Invalid Value").positive("Value must be greater than 0"),
        fk_PriorityID: Yup.number().required("Substation Priority is required").typeError("Substation Priority is required").min(1, "Substation Priority is required"),
        //notes: Yup.string().max(500, "Maximum limit (500  characters)").typeError("Invalid Value").required("Notes required"),
      });

      const formik = useFormik({
        initialValues: {
          notes,
          createdBy,
          modifiedBy,
          id,
          fk_PriorityID,
          fk_JobID,
          substationName,
          substationNumber
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          handleValidSubmit();
          setSubmitting(false);
        },
      });

      // formik setup ends


    useEffect(() => {
        if(substationDetails !== null){
            setId(substationDetails.ID);
            setCreatedBy(substationDetails.CreatedBy);
            setModifiedBy(substationDetails.ModifiedBy);
            setSubstationName(substationDetails.SubstationName);
            setSubstationNumber(substationDetails.SubstationNumber);
            setFkPriorityId(substationDetails.fk_PriorityID);
            setFkJobId(fk_JobID);
            setNotes(substationDetails.Notes);
            formik.setValues((old) => {
              return{
                ...old,
                notes: substationDetails.Notes,
                createdBy: substationDetails.CreatedBy,
                modifiedBy: substationDetails.ModifiedBy,
                id: substationDetails.ID,
                fk_PriorityID: substationDetails.fk_PriorityID,
                fk_JobID: fk_JobID,
                substationName: substationDetails.SubstationName,
                substationNumber: substationDetails.SubstationNumber
              }
            })
        }else if(substationDetails === null){
          setId(0);
          setCreatedBy("");
          setModifiedBy("");
          setSubstationName("");
          setSubstationNumber("");
          setFkPriorityId(0);
          setNotes("");
          formik.resetForm();
        }
    }, [substationDetails]);

    useEffect(() => {
        if(substationName && substationNumber && fk_JobID && fk_PriorityID > 0){
            let data = {
              notes,
              createdBy,
              modifiedBy,
              id,
              fk_PriorityID,
              fk_JobID,
              substationName,
              substationNumber
            };
            setSubstationData(data);
            setDisableButton(false);
        }
    }, [substationName, substationNumber, notes, fk_PriorityID, fk_JobID, id]);

   //console.log("subdataaaa", substationData);

    const handleValidSubmit = async e => {
        //e?.preventDefault();
        if(substationData !== null){
            setAddorEditLoading(true);
            addorEditSubstation(substationData);
            setAddorEditLoading(false);
            // const timeout = setTimeout(() => {
            //   setId(0);
            //   setCreatedBy("");
            //   setModifiedBy("");
            //   setSubstationName("");
            //   setSubstationNumber("");
            //   setFkPriorityId(0);
            //   setNotes("");
            // }, 1000);
            // return () => clearTimeout(timeout);
        }
    }

    useEffect(() => {
        if(jobDetails){
          setFkJobId(jobDetails.ID);
        }
    }, [jobDetails]);

    return(
      <Modal
        show={showAddSubstationsModal}
        onHide={handleCloseAddSubstationModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{substationDetails !== null ? "Edit Substation" : "Add Substation"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substationName">
                Substation Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="substationName"
                name="substationName"
                value={substationName}
                onChange={changeSubstationName}
                onBlur={() => setSubstationNameBlurred(true)}
                placeholder="Substation name"
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {substationNameBlurred && formik.errors.substationName}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substationNumber">
                Substation Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="substationNumber"
                name="substationNumber"
                value={substationNumber}
                onChange={changeSubstationNumber}
                onBlur={() => setSubstationNumberBlurred(true)}
                placeholder="Substation number"
              />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {substationNumberBlurred && formik.errors.substationNumber}
              </div>
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="fk_PriorityID">
                Priority <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                id="fk_PriorityID"
                name="fk_PriorityID"
                required
                value={fk_PriorityID}
                onChange={changeSubstationPriority}
                onBlur={() => setFkPriorityIdBlurred(true)}
              >
                <option value={0} onClick={() => setFkPriorityId(0)}>-- SELECT --</option>
                {
                    substationActivePriorities.length > 0 && <>
                    {
                        substationActivePriorities?.map((substationActivePriority, idx) => {
                            return(
                                <option onClick={() => setFkPriorityId(substationActivePriority.ID)} value={substationActivePriority.ID} key={idx}>{substationActivePriority.Value}</option>
                            )
                        })
                    }
                    </>
                }
              </select>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {fk_PriorityIDBlurred && formik.errors.fk_PriorityID}
              </div>
            </div>
            <div className="col-12 form-group">
              <label className="form-label" htmlFor="notes">
                Notes
              </label>
              <textarea
                className="form-control"
                rows="4"
                id="notes"
                name="notes"
                value={notes}
                onChange={changeNotes}
                placeholder="Add notes"
              ></textarea>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.notes}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
                handleCloseAddSubstationModal(); 
                setSubstationDetails(null);
                setId(0);
                setCreatedBy("");
                setModifiedBy("");
                setSubstationName("");
                setSubstationNumber("");
                setFkPriorityId(0);
                setNotes("");
            }}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button type="button" onClick={formik.handleSubmit} disabled={disableButton || !formik.isValid || formik.isSubmitting || adding} variant="primary" size="sm">
          <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{substationDetails !== null ? "Update" : "Save"}</span>
            {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default AddEditSubstation;