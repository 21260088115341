import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page, TransferTypesObject } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import moment from "moment";


function JobDetailDashboardListing({
    InventoryTransactions,
    handleDeleteTransaction,
    handleEditTransactionModal,
    setIsEdit
}) {

    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#JobDetailDashboardListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
                     
    });

    //console.log("invemtrottrans", InventoryTransactions);

    return (
        <>
            <div className="card-body">
              <table id="JobDetailDashboardListing" className="table table-bordered table-hover table-striped table-sm w-100">
                <thead>
                  <tr>
                    <th>
                      <i className="fa fa-calendar mr-1"></i> Date
                    </th>
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Job
                    </th>
                    {/* <th>
                      <i className="fa fa-list-ul mr-1"></i> Pole
                    </th> */}
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Part
                    </th>
                    <th>
                      <i className="fa fa-list-ol mr-1"></i> Qty
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Transfer Type
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> From
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> To
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Created By
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Created Date
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Notes
                    </th>
                    <th>
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                  {InventoryTransactions.map((el, idx) => {
                    const { FromLocationName, ToLocationName, CrewName, JobName, TransferType, TransactionDate, fk_PoleID, Notes, Qty, PartNumber, PoleId, Feeder } = el;
                    //var convertedTransactionDate = new Date(TransactionDate + 'Z');
                    // var today = new Date();
                    // var startDay = 0; 
                    // var weekStart = new Date(today.getDate() - (7 + today.getDay() - startDay) % 7);
                    // var weekEnd = new Date(today.getDate() + (7 - today.getDay() - startDay) % 7);
                    const OneTransferType = TransferTypesObject[TransferType]
                    return (
                      <tr>
                        <td>{moment(TransactionDate).format("MM/DD/YYYY")}</td>
                        <td>{JobName}</td>
                        {/* <td>{PoleId}</td> */}
                        <td>{PartNumber}</td>
                        <td>
                          <span className="label label-light-danger label-inline">
                            {Qty}
                          </span>
                        </td>
                        <td>{OneTransferType?.name ? OneTransferType.name : ""}</td>
                        <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev) ? FromLocationName :  (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.FXFER.abbrev) ? Feeder : (TransferType == TransferTypesObject.FCREW.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? CrewName : ""}</td>
                        <td>{(TransferType == TransferTypesObject.REC.abbrev || TransferType == TransferTypesObject.FXFER.abbrev || TransferType == TransferTypesObject.TWFCREW.abbrev) ? ToLocationName : (TransferType == TransferTypesObject.XFER.abbrev || TransferType == TransferTypesObject.FCREW.abbrev) ? Feeder : (TransferType == TransferTypesObject.TCREW.abbrev || TransferType == TransferTypesObject.WTCREW.abbrev ) ? CrewName : ""}</td>
                        <td>{`${el?.FirstName} ${el?.LastName}`}</td>
                        <td>{moment(el?.CreatedDate).format("MM/DD/YYYY")}</td>
                        <td>{Notes}</td>
                        <td align="center">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Delete Transaction </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-danger btn-xs"
                             onClick={()=>handleDeleteTransaction(el?.DetailId)}
                            >
                              <i className="fa fa-trash-alt"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Edit Transaction </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3"
                            onClick={()=>{handleEditTransactionModal(el); setIsEdit(true);}}>
                              <i className="fa fa-pen"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
        </>
    );
}

export default JobDetailDashboardListing;
