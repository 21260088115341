import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { UsersPage } from "./modules/manageusers/userspage";
import { JobUsersPage } from "./modules/manageusers/jobuserspage";
import JobsDetail from "./pages/JobsDetail";
import SystemSettingsPage from "./pages/systemsettings";

import JobDetailDashboard from "./pages/JobDetailsViews/JobDetailDashboard";
import JobInventory from "./pages/JobDetailsViews/JobInventory";
//import UsersPage from "../modules/manageusers/userspage";
import Crews from "./pages/JobManagementViews/Crews";
import PoleType from "./pages/JobManagementViews/poletype";
import Substations from "./pages/JobManagementViews/Substations";
import Parts from "./pages/JobManagementViews/Parts";
import Customers from "./pages/JobManagementViews/Customers";
import Locations from "./pages/JobManagementViews/Locations";
import Feeders from "./pages/JobManagementViews/Feeders";
import Reports from "./pages/JobDetailsViews/reports";
import AccountInformation from "./modules/UserProfile/AccountInformation";
import ChangePassword from "./modules/UserProfile/ChangePassword";
import PersonaInformation from "./modules/UserProfile/PersonaInformation";
import EmailSettings from "./modules/UserProfile/EmailSettings";
import CheckPermission from "../app/General/CheckPermission";
import { userActions } from "../app/constants/enums";
import NewReports from "./pages/JobDetailsViews/newreports";
import TransferReportDashboard from "./pages/JobDetailsViews/TransferReportDashboard";
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   //console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          //<Redirect exact from="/" to="/" />
        }
        <Route path="/dashboard" exact component={DashboardPage} />
        {/* <CheckPermission
          currentAction={userActions.userManagment.Action_ViewUser}
          yes={() => <Route path="/systemsetting" exact component={SystemSettingsPage} />}
          no={() => null}
        /> */}
        {/* <CheckPermission
          currentAction={userActions.userManagment.Action_ViewUser}
          yes={() => <Route path="/user" exact component={UsersPage} />}
          no={() => null}
        /> */}
        <Route path="/user" exact component={UsersPage} />
        <Route path="/jobdetail" exact component={JobDetailDashboard} />
        <Route path="/transfer-report" exact component={TransferReportDashboard} />
        {/* <Route path="/systemsetting" exact component={SystemSettingsPage} /> */}
        <Route path="/my-page" exact component={MyPage} />
        <Route path="/user-profile" exact component={UserProfilepage} />

        <Route exact path="/jobdetaildashboard">
          <JobDetailDashboard />
        </Route>
        <Route exact path="/inventory">
          <JobInventory />
        </Route>
        <Route exact path="/management">
          Management Screen
        </Route>
        <Route exact path="/JobUsers">
          <JobUsersPage />
        </Route>
        {/* <Route exact path="/reports">
          <Reports />
        </Route> */}
        <Route exact path="/crews">
          <Crews />
        </Route>
        <Route exact path="/poletype">
          <PoleType />
        </Route>
        <Route exact path="/substations">
          <Substations />
        </Route>
        <Route exact path="/feeders">
          <Feeders />
        </Route>
        <Route exact path="/customers">
          <Customers />
        </Route>
        <Route exact path="/locations">
          <Locations />
        </Route>
        <Route exact path="/parts">
          <Parts />
        </Route>
        <Route exact path="/reports">
          <NewReports />
        </Route>

        {/* <Route
          path="/user-profile/account-information"
          exact
          component={AccountInformation}
        /> */}
        <Route
          path="/user-profile/change-password"
          exact
          component={ChangePassword}
        />
        {/* <Route
          path="/user-profile/email-settings"
          exact
          component={EmailSettings}
        />
        <Route
          path="/user-profile/personal-information"
          exact
          component={PersonaInformation}
        /> */}

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
