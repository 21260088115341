import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setCutomers } from "../../store/management/actions";
import * as Common from "../General/common";
// import { setJobs } from "../../store/jobManagement/actions";
import Jobs from "./Jobs";
import { getAllAssignedJobs, getAllJobsCall } from "../services/jobServices";
import { getAllCustomerApiCall } from "../services/managementServices";
import { resetUserPasswordCall, getUserByIdCall } from "../services/userManagementService";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddEditJob from "./JobManagementViews/addEditJob";
import CheckPermission from "../General/CheckPermission";
import { userActions, userRoles } from "../constants/enums";
import { actions } from "../../app/modules/Auth/_redux/authRedux";
import { setUserPassword } from "../../store/userPassword/actions";


var addEditJobRef = null;
export function DashboardPage() {

  const user = useSelector((state) => state.auth.user, shallowEqual); 
  
  //const userDetail = useSelector((store) => store.User.userDetail);

  const [userById, setUserById] = useState(null);

  const [jobsLoading, setJobsLoading] = useState(false);
  // const jobs = useSelector((store) => store.JobManagement.jobs);
  const customers = useSelector((store) => store.Management.customers);
  const [showAddEditJob, setshowAddEditJob] = useState(false);
  const [jobsList, setjobsList] = useState([]);
  const [jobDetail, setjobDetail] = useState(null);

  const [addingJob, setAddingJob] = useState(false);

  const [userId, setUserId] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [userAllData, setUserAllData] = useState(null);
  const [resettingLoading, setResettingLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [updatingPassword, setUpdatingPassword] = useState(false);

  const [searchJob, setSearchJob] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    setFilteredJobs(jobsList);
  }, [jobsList]);

  const changeUserId = (e) => {
    setUserId(parseInt(e.target.value));
  };

  const changeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const changeConfirmPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  useEffect(() => {
    formik.setValues({
      userId,
      newPassword,
      confirmNewPassword,
    });
  }, [userId, newPassword, confirmNewPassword]);

  const Schema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Minimum limit (8 characters)") 
      .max(20, "Maximum limit (20 characters)")
      .typeError("Invalid Value")
      .required("New Password is required"),
    confirmNewPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      userId,
      newPassword,
      confirmNewPassword,
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  useEffect(() => {
    if (user?.id && newPassword && confirmNewPassword) {
      let data = {
        userId: user?.id,
        newPassword,
      };
      setUserAllData(data);
      setDisableButton(false);
    }
  }, [user, newPassword, confirmNewPassword]);

  // reset password function
  const resetPassword = async (userData) => {
    setUpdatingPassword(true);
    try {

      const { data } = await resetUserPasswordCall(userData);
     
      

      if (data.success) {
        // success scenario
        Common.showSuccessAlertAutoClose(
          data.message,
          "success",
          "Success!",
          3000
        );

        dispatch(setUserPassword(userData.newPassword));
        getUserById(user?.id);
        handleCloseSetPasswordModal();
        //getUserById(user?.id);
      } else {
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch {
      Common.showSuccessAlertAutoClose(
        "some error occured while processing your request",
        "warning",
        "Failed!",
        3000
      );
    } finally {
    }
    setUpdatingPassword(false);
  };

  const handleValidSubmit = async (e) => {
    //e?.preventDefault();
  
    if (userAllData !== null) {
      setResettingLoading(true);
      resetPassword(userAllData);
      setResettingLoading(false);
      // const timeout = setTimeout(() => {
      //   setNewPassword("");
      //   setConfirmNewPassword("");
      // }, 1000);
      //return () => clearTimeout(timeout);
    }
  };

  const dispatch = useDispatch();
  const handleAddAndEditModalClose = () => setshowAddEditJob(false);
  const handleAddAndEditJobModal = async (jobDetail) => {
    await setjobDetail(jobDetail);
    await setshowAddEditJob(true);
  };

  const [showSetPasswordModal, setShowSetPasswordModal] = useState(false);

  const handleCloseSetPasswordModal = () => setShowSetPasswordModal(false);
  const handleShowSetPasswordModal = () => setShowSetPasswordModal(true);

  useEffect(() => {
    if(user?.fk_RoleID == userRoles.Role_CrewLead){
      getAllUnAssignedJobs();
    }else{
      getAllJobs();
    }
    getAllCustomer();
  }, [user]);

  useEffect(() => {
    if(user?.id > 0){
      getUserById(user?.id);
    }
  }, [user]);

  const getAllJobs = async () => {
    setJobsLoading(true);
    try {
      const { data } = await getAllJobsCall(user.id);

      

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          await setjobsList(data.result);
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
      setJobsLoading(false);
    }
  };
  const getAllCustomer = async () => {
    setJobsLoading(true);
    try {
      const { data } = await getAllCustomerApiCall();

      if (data.success) {
        // success scenario
        setJobsLoading(false);
        if (data.result.length > 0) {
          dispatch(setCutomers(data.result));
        }
      } else {
        setJobsLoading(false);
      }
    } catch {
      setJobsLoading(false);
    } finally {
      setJobsLoading(false);
    }
  };


  const getAllUnAssignedJobs = async () => {
    setJobsLoading(true);
    try {
      const dataAllJobs = await (await getAllJobsCall(user.id)).data;

      const { data } = await getAllAssignedJobs(user?.id);

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          const filteredOutAssignedJobs = data?.result?.filter((el, idx) => {
            if(el?.fk_UserID == user?.id){
              return el;
            }
          });
        
          if(dataAllJobs?.result?.length > 0 && filteredOutAssignedJobs?.length > 0){
            const filteredOutAssignedJobsHere = dataAllJobs?.result?.filter((allJob) => {
              return filteredOutAssignedJobs?.some((assignJob) => {
                return assignJob?.ID == allJob?.ID;
              })
            });
            await setjobsList(filteredOutAssignedJobsHere);
          }else{
            await setjobsList([]);
          }
          //await setjobsList(filteredOutAssignedJobs);
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
      setJobsLoading(false);
    }
  };

  const afterUpsertJob = () => {
    getAllJobs();
    setshowAddEditJob(false);
  };


   const getUserById = async (id) => {
    
     try {
       const { data } = await getUserByIdCall(id);
      
       if (data.success) {
         // success scenario
        setUserById(data.result);
        //dispatch(setUserDetailById(data.result));

       } else {
       
       }
     } catch {
     
     } finally {
    
     }
   };
  

  // useEffect(() => {
  //   if (userById !== null && userById !== {} && userById?.length > 0) {
  //      if (userById[0].PasswordSet == "0") {
  //        handleShowSetPasswordModal();
  //      }else{
  //        handleCloseSetPasswordModal();
  //      }
  //   }
  // }, [userById]);

  useEffect(() => {
    if (user) {
      if (user?.id) {
        setUserId(user.id);
      }
    }
  }, [user]);


  const [sortedJobs, setSortedJobs] = useState([]);


   useEffect(() => {
       const filteredJobsHere = sortedJobs?.filter((el, idx) => {
         return ((el?.JobName?.toLowerCase()).includes(searchJob.toLowerCase()));
       });
       setFilteredJobs(filteredJobsHere);
   }, [searchJob]);

   useEffect(() => {
     if(jobsList?.length > 0){
       let dscN = jobsList.sort((f, s) => s.ID - f.ID );
       setSortedJobs(dscN);
     }
   }, [jobsList]);


  return (
    <>
      <div className="card mb-5">
        <div className="d-flex align-items-center justify-content-between card-body p-3">
          <div className="input-group mb-0 jobSearchInput">
            <div className="input-group-prepend">
              <button className="btn btn-secondary" type="button" id="search">
                <i className="fa fa-search"></i>
                <span className="hideOnMedium ml-2">Search</span>
              </button>
            </div>
            <input
              type="search"
              className="form-control"
              aria-label="Search"
              placeholder="Search Jobs"
              value={searchJob}
              onChange={(e) => {
                setSearchJob(e.target.value);
              }}
            />
          </div>
          <div>
          <CheckPermission
          currentAction={userActions.Jobs.Action_AddJobs}
          yes={() => (
            <button
              className="btn btn-primary btn-sm m-2"
              onClick={() => {
                setshowAddEditJob(true);
                setjobDetail(null);
              }}
            >
              <i className="fa fa-plus p-0"></i>
              <span className="hideOnMedium ml-2">Add New Job</span>
            </button>
            )}
            no={() => null}
          />
          </div>
        </div>
      </div>
      {jobsLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <CheckPermission
          currentAction={userActions.Jobs.Action_ViewJobs}
          yes={() => (
            <div className="row">
              {filteredJobs?.length === 0 ? 
                <div className="col-lg-12 text-center p-10">
                  No Jobs
                </div>
                :
              filteredJobs?.map((job) => {
                return (
                  <div className="col-md-4 col-lg-3 " key={job.ID}>
                    <Jobs
                      jobDetail={job}
                      addJobModalShow={handleAddAndEditJobModal}
                      refreshJob={getAllJobs}
                    />
                  </div>
                );
              })}
            </div>
          )}
          no={() => null}
        />
      )}
      {/* add and edit Job modal */}

      <Modal
        show={showAddEditJob}
        onHide={handleAddAndEditModalClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{jobDetail !== null ? "Edit Job" : "Add New Job"}</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <AddEditJob
            job={jobDetail}
            customers={customers}
            OnSubmit={() => afterUpsertJob()}
            ref={(ref) => {
              addEditJobRef = ref;
            }}
            addingJob={addingJob}
            setAddingJob={setAddingJob}
          />
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleAddAndEditModalClose}
          >
            <i className="fa fa-times mr-1"></i>Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => addEditJobRef.handleSubmit()}
            disabled={addingJob}
          >
            <div className={`w-100 ${(addingJob) && "pr-5"}`}>
            <span className={` ${(addingJob) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{jobDetail !== null ? "Update" : "Save"}</span>
            {(addingJob) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Set Password modal */}

      <Modal
        show={showSetPasswordModal}
        onHide={handleCloseSetPasswordModal}
        backdrop="static"
        keyboard={false}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3">
          <Modal.Title>Set Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="form-group">
            <label className="form-label" htmlFor="newPassword">New Password</label>
            <input type="password" className="form-control" id="newPassword" name="newPassword" required placeholder="New Password" value={newPassword} onChange={changeNewPassword} />
            <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.newPassword}
              </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="confirmNewPassword">Confirm Password</label>
            <input type="password" className="form-control" id="confirmNewPassword" name="confirmNewPassword" required placeholder="Confirm Password" value={confirmNewPassword} onChange={changeConfirmPassword} />
            <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.confirmNewPassword}
              </div>
          </div>

        </Modal.Body>
        <Modal.Footer className="py-3">
          {/* <Button variant="secondary" size="sm" onClick={handleCloseSetPasswordModal}>
            <i className="fa fa-times mr-1"></i>Cancel
          </Button> */}
          <Button type="button" onClick={formik.handleSubmit} disabled={disableButton || !formik.isValid || formik.isSubmitting || updatingPassword} variant="primary" size="sm">
          <div className={`w-100 ${(formik.isSubmitting || updatingPassword) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || updatingPassword) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>Update</span>
            {(formik.isSubmitting || updatingPassword) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
