import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    SET_USER_PASSWORD
  } from "./actionTypes";

  const initialState = {
    userPassw: ""
  };

  const userPassword = persistReducer(
    { storage, key: "v726-demo1-userPassw", whitelist: ["userPassw"] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_PASSWORD:
        state = { ...state, userPassw: action.payload };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  });
  export default userPassword;
  