import React from "react";
import { Form } from "react-bootstrap";
import * as Common from "../../General/common"
import { upsertCustomerApiCall } from "../../services/managementServices";
const customerModel = {
    id: 0,
    customerName: '',
    notes: ''

}
export default class AddEditCustomer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            isLoading: false,
            Customer: JSON.parse(
                JSON.stringify(
                    this.props.customer != null
                        ? {
                            id: this.props.customer.ID,
                            customerName: this.props.customer.CustomerName,
                            notes: this.props.customer.Notes
                        }
                        : customerModel
                )
            ),
        };
        
    }

    handleSubmit = () => {
        
        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            this.upsertCustomer();
        }
    };

    upsertCustomer = async () => {
        this.props.setCustomers([]);
        this.props.setAdding(true);
        // this.isLoading(false);
        let job = this.state.Customer;
        try {
            const { data } = await upsertCustomerApiCall(job);

            if (data.success) {
                // success scenario 
                Common.showSuccessAlertAutoClose(
                    this.props.isEdit ? "Customer updated successfully!" : "Customer created successfully!",
                    "success",
                    "Success!",
                    3000
                );
                this.props.OnSubmit();
                this.props.getAllCustomer();
                //window.location.reload(false);
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        }
        catch {

            Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
            );
        }
        finally {
            // setSubstationsLoading(false);

        }

        this.props.setAdding(false);
    }
    render() {
        return (
            <>


                <Form
                    ref={(ref) => {
                        this.form = ref;
                    }}
                    validated={this.state.formValidated}
                    
                >
                    <div className="row">
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="customerName">
                                Customer Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="customerName"
                                name="customerName"
                                required
                                defaultValue={this.state.Customer.customerName}
                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Customer,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                placeholder="Customer name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Customer name is required
                            </Form.Control.Feedback>

                        </div>
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="customerNotes">
                                Notes
                            </label>
                            <textarea className="form-control"
                                name="notes"
                                defaultValue={this.state.Customer.notes}

                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Customer,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                rows="4" id="customerNotes" 
                                placeholder="Add notes"
                                ></textarea>
                        </div>

                    </div>
                </Form>
            </>
        );
    }
}