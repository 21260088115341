import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Common from "../../../General/common";
import { resetUserPasswordByTokenCall } from "../../../services/userManagementService";

function ResetPassword() {

  const { token } = useParams();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [resetData, setResetData] = useState(null);

  const [resetting, setResetting] = useState(false);


  const resetPasswordByToken = async (values) => {
      setResetting(true);
      try {
       
          const { data } = await resetUserPasswordByTokenCall(values);
       
          if (data.success) {
              // success scenario 
              Common.showSuccessAlertAutoClose(
                 "Password changed successfully!",
                  "success",
                  "Success!",
                  3000
              );

              history.replace("/auth/login");
             
          } else {
              Common.showSuccessAlertAutoClose(
                  data.message,
                  "warning",
                  "Failed!",
                  3000
              );
          }
      }
      catch {

          Common.showSuccessAlertAutoClose(
              "some error occured while processing your request",
              "warning",
              "Failed!",
              3000
          );
      }
      finally {
      
      }
      setResetting(false);
  }

  // formik setup starts

  useEffect(() => {
    formik.setValues({
      token,
      newPassword,
      confirmNewPassword
    });
  }, [token, newPassword, confirmNewPassword]);

  const Schema = Yup.object().shape({
    newPassword: Yup.string()
      .max(45, "Maximum limit (45 characters)")
      .typeError("Invalid Value")
      .required("New Password is required"),
    confirmNewPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      token,
      newPassword,
      confirmNewPassword
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  useEffect(() => {
    if(token !== null && newPassword !== "" && confirmNewPassword !== ""){
        let data = {
          token,
          newPassword
        };
        setResetData(data);
    }
}, [token, newPassword, confirmNewPassword]);

const handleValidSubmit = async e => {
  //e?.preventDefault();
  if(resetData !== null){   
    resetPasswordByToken(resetData);
  }
}


const getInputClasses = (fieldname) => {
  if (formik.touched[fieldname] && formik.errors[fieldname]) {
    return "is-invalid";
  }

  if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    return "is-valid";
  }

  return "";
};

  return (
    <>
      <div className="login-form login-forgot bg-white p-10 rounded" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter new password
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible d-none">
            <div className="alert-text font-weight-bold">

            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label class="">New password<span class="text-danger">*</span></label>
            <input 
                type="password" 
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "newPassword"
                )}`} 
                name="newPassword" 
                value={newPassword} 
                onChange={(e) => setNewPassword(e.target.value)} 
                // {...formik.getFieldProps("newPassword")}
            />
            {formik.errors.newPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.newPassword}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label class="">Confirm New password<span class="text-danger">*</span></label>
            <input 
              type="password" 
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "confirmNewPassword"
              )}`}
              name="confirmNewPassword" 
              value={confirmNewPassword} 
              onChange={(e) => setConfirmNewPassword(e.target.value)} 
              // {...formik.getFieldProps("confirmNewPassword")}
            />
            {formik.errors.confirmNewPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button id="kt_login_reset_submit" type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={!formik.isValid || formik.isSubmitting || resetting}
              >
                <div className="w-100">
            <span className={` ${(formik.isSubmitting || resetting) && 'mr-3'}`}>Submit</span>
            {(formik.isSubmitting || resetting) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_reset_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>

    </>
  );
}

export default ResetPassword;
