import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const IdleTimerContainer = () => {

    const history = useHistory();
    const idleTimerRef = useRef(null);
    const sessionTimeoutTimeInSeconds = useSelector((store) => store.SessionTimeoutTime.sessionTimeoutTimeInSeconds);

    const onIdle = () => {
        history.replace("/logout");
    }

    return(
        <>
            <IdleTimer
                ref={idleTimerRef}
                timeout={sessionTimeoutTimeInSeconds * 1000}
                onIdle={onIdle}
                crossTab={true}
            ></IdleTimer>
        </>
    )
}

export default IdleTimerContainer;