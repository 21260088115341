import React, { useState, useEffect, useRef } from "react";

import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import PartsList from "./partslist"
import PartsCategory from "./partscategory"
import AddEditPart from "./addEditPart";
import { useSelector, useDispatch } from "react-redux";
//import { setPartCategories } from "../../../store/management/actions";
import { getPartCategoriesCall, upsertPartCategoryCall,
   deletePartCategoryCall,getAllUomsCall,getPartsCall } from "../../services/managementServices";
import { upsertAttachmentCall } from "../../services/attachmentService";
import * as Common from "../../General/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import JobsDetail from "../JobsDetail";
import CheckPermission from "../../General/CheckPermission";
import { userActions } from "../../constants/enums"
import { CustomLoader } from "../../components/common/CustomLoader";
import { getAllCustomerApiCall } from "../../services/managementServices";
var addEditPartRef = null;
function Parts() {
    const attachmentsRef = useRef()
    const [partCategoriesLoading, setPartCategoriesLoading] = useState(false);
    const [partCategoryDetails, setPartCategoryDetails] = useState(null);
    const [isDeletingPartCategory, setIsDeletingPartCategory] = useState(false);
    //const partCategories = useSelector((store) => store.Management.partCategories);
    const [partCategories, setPartCategories] = useState([]);
    //const customers = useSelector((store) => store.Management.customers);
    const [customers, setCustomersList] = useState([]);
    const [AttachmentFormData, setAttachmentFormData] = useState(undefined);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { jobDetails } = useSelector((state) => state.JobManagement);

    const [partsList, setParts] = useState([]);
    const [adding, setAdding] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [addingCategory, setAddingCategory] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState(false);

    const [partsLoading, setPartsLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [partAttachments, setPartAttachments] = useState([]);
    const [loadingPartAttachments, setLoadingPartAttachments] = useState(false);

    const onSelectAttatchmentsFiels = async (e) => {
      let files = e.target.files
      
      if (files) {
        let fd = new FormData()
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          fd.append("files", file)
        } 
        setAttachmentFormData(fd);
        setSelectedFiles([...files]);
      }
  
    }

    const dispatch = useDispatch();

    // partCategory states starts
    const [partCategoryId, setPartCategoryId] = useState(0);
    const [partCategoryName, setPartCategoryName] = useState("");
    const [partCategoryNotes, setPartCategoryNotes] = useState("");
    const [activeCategory, setActiveCategory] = useState(true);
    const [uomList, setUomsList] = useState([]);

    
    const [partCategoryData, setPartCategoryData] = useState(null);
    const [addorEditPartCategoryLoading, setAddorEditPartCategoryLoading] = useState(false);
    const [disablePartCategoryButton, setDisablePartCategoryButton] = useState(false);

    /* BLURRED STATES START */

    const [partCategoryNameBlurred, setPartCategoryNameBlurred] = useState(false);

    /* BLURRED STATES END */

    const changePartCategoryName = (e) => {
      setPartCategoryName(e.target.value);
    }

    const changePartCategoryNotes = (e) => {
      setPartCategoryNotes(e.target.value);
    }

   
    // partCategory states ends

     // formik setup starts

     useEffect(() => {
      formikCategory.setValues({
        partCategoryId,
        partCategoryName,
        partCategoryNotes,
        activeCategory
      });
    }, [partCategoryName, partCategoryNotes, activeCategory, partCategoryId]);

    const Schema = Yup.object().shape({
      partCategoryName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Category Name is required"),
      //notes: Yup.string().max(500, "Maximum limit (500  characters)").typeError("Invalid Value").required("Notes required"),
    });

    const formikCategory = useFormik({
      initialValues: {
        partCategoryId,
        partCategoryName,
        partCategoryNotes,
        activeCategory
      },
      validationSchema: Schema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        handleValidSubmitCategory();
        setSubmitting(false);
      },
    });

    // formik setup ends

    useEffect(() => {
      if(partCategoryDetails !== null){
          setPartCategoryId(partCategoryDetails.ID);
          setPartCategoryName(partCategoryDetails.Name);
          setPartCategoryNotes(partCategoryDetails.Notes);
          setActiveCategory(partCategoryDetails.Active)
          formikCategory.setValues((old) => {
            return{
              ...old,
              partCategoryId: partCategoryDetails.ID,
              partCategoryName: partCategoryDetails.Name,
              partCategoryNotes: partCategoryDetails.Notes,
              activeCategory: partCategoryDetails.Active
            }
          })
      }else if(partCategoryDetails === null){
        setPartCategoryId(0);
        setPartCategoryName("");
        setPartCategoryNotes("");
        setActiveCategory(true);
        formikCategory.resetForm();
      }
  }, [partCategoryDetails]);
   
  useEffect(() => {
    if(partCategoryName){
        let data = {
          id: partCategoryId,
          name: partCategoryName,
          notes: partCategoryNotes,
          active: activeCategory
        };
        setPartCategoryData(data);
        setDisablePartCategoryButton(false);
    }
}, [partCategoryName, partCategoryNotes, partCategoryId, activeCategory]);

const handleValidSubmitCategory = async e => {
  //e?.preventDefault();
  if(partCategoryData !== null){
      setAddorEditPartCategoryLoading(true);
      addorEditPartCategory(partCategoryData);
      setAddorEditPartCategoryLoading(false);
  }
}

    // function which fetches the partCategories
    const [categoriesDropdownLoading, setCategoriesDropdownLoading] = useState(false);
    const getPartCategories = async () => {
      setPartCategoriesLoading(true);
      setCategoriesDropdownLoading(true);
      try {
        const { data } = await getPartCategoriesCall();
       
        if(data.success){
          // success scenario
          setPartCategoriesLoading(false);
          if(data.result.length > 0){
           setPartCategories(data.result);
          }
        }else{
          setPartCategoriesLoading(false);
        }
      }
      catch {
        setPartCategoriesLoading(false);
      }
      finally {
        setPartCategoriesLoading(false);
      }
      setCategoriesDropdownLoading(false);
  };
     // function which fetches the parts
     const getParts = async () => {
      setPartsLoading(true);
      try {
        const { data } = await getPartsCall(jobDetails?.fk_CustomerID);
       
        if(data.success){
          // success scenario
          setPartsLoading(false);
          if(data.result.length > 0){
              // setPartsList(data.result);
             setParts(data.result);
          }
        }else{
          setPartsLoading(false);
        }
      }
      catch {
        setPartsLoading(false);
      }
      finally {
        setPartsLoading(false);
      }
  };
      // function which fetches the uoms
      const [uomDropdownLoading, setUomDropdownLoading] = useState(false);
     const getAllUoms = async () => {
        setPartCategoriesLoading(true);
        setUomDropdownLoading(true);
        try {
          const { data } = await getAllUomsCall();
         
          if(data.success){
            // success scenario
            setPartCategoriesLoading(false);
            if(data.result.length > 0){
            await setUomsList(data.result);
            }
          }else{
            setPartCategoriesLoading(false);
          }
        }
        catch {
          setPartCategoriesLoading(false);
        }
        finally {
          setPartCategoriesLoading(false);
        }
        setUomDropdownLoading(false);
    };

    const [customersDropdownLoading, setCustomersDropdownLoading] = useState(false);
    const getAllCustomer = async () => {
      //setJobsLoading(true);
      setCustomersDropdownLoading(true);
      try {
        const { data } = await getAllCustomerApiCall();
  
        if (data.success) {
          // success scenario
          //setJobsLoading(false);
          if (data.result.length > 0) {
            setCustomersList(data.result);
          }
        } else {
          //setJobsLoading(false);
        }
      } catch {
        //setJobsLoading(false);
      } finally {
        //setJobsLoading(false);
      }
      setCustomersDropdownLoading(false);
    };
   // fetch partCategories when component mounts
   useEffect(() => {
    getPartCategories();
    getParts();
    getAllUoms();
    getAllCustomer();
  }, []);

   // add or edit part category function starts
   const addorEditPartCategory = async (partCategoryData) => {
     setPartCategories([]);
     setAddingCategory(true);
    try {
     
        const { data } = await upsertPartCategoryCall(partCategoryData);
        
        
      
        if (data.success) {
            // success scenario 
            Common.showSuccessAlertAutoClose(
               partCategoryDetails != null ? "Category updated successfully!" : "Category created successfully!",
                "success",
                "Success!",
                3000
            );
            handleCloseAddCategoryModal();
            setPartCategoryDetails(null);
            getPartCategories();
            //window.location.reload(false);
        } else {
            Common.showSuccessAlertAutoClose(
                data.message,
                "warning",
                "Failed!",
                3000
            );
        }
    }
    catch {

        Common.showSuccessAlertAutoClose(
            "some error occured while processing your request",
            "warning",
            "Failed!",
            3000
        );
    }
    finally {
        getPartCategories();
    }
    setAddingCategory(false);
}
// add or edit part category function ends

// delete part category function starts
const deletePartCategory = async (partCategoryId) => {

  if (partCategoryId > 0) {
    setPartCategories([]);
    setDeletingCategory(true);
    try {
      const { data } = await deletePartCategoryCall(partCategoryId);
     
      if (data.success) {
          // success scenario 
          Common.showSuccessAlertAutoClose(
             "Category deleted successfully!",
              "success",
              "Success!",
              3000
          );

          //window.location.reload(false);
       
      } else {
          Common.showSuccessAlertAutoClose(
              data.message,
              "warning",
              "Failed!",
              3000
          );
      }
      getPartCategories();
  }
  catch {

      Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
      );
  }
  finally {
      getPartCategories();
  }
  setDeletingCategory(false);
  }
}
// delete part category function ends

  // for Add Part modal popup
  const [showAddPartsModal, setShowAddPartsModal] = useState(false);
  const [partDetail, setPartDetail] = useState(null);

  const handleCloseAddPartsModal = () => setShowAddPartsModal(false);
  const handleShowAddPartsModal = () => setShowAddPartsModal(true);
  // for Add Category modal popup
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const handleCloseAddCategoryModal = () => setShowAddCategoryModal(false);
  const handleShowAddCategoryModal = () => setShowAddCategoryModal(true);

   // for show attachments modal popup
   const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
   const handleCloseAttachmentsModal = () => setShowAttachmentsModal(false);
   const handleShowAttachmentsModal = () => setShowAttachmentsModal(true);

  // handle buttons to add part and category
  const [showParts, setShowPart] = useState(true);
  const handleTabChange = (value) => setShowPart(value !== 'Category');
  const handleEditPartModal =async (partDetail) => {
   
    await  setPartDetail(partDetail)
    await setShowAddPartsModal(true);
   }
  const afterUpsertPart = () => {
     getParts();
    setShowAddPartsModal(false);
  }
  return (
    <>
      <JobsDetail />
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/parts.svg" width="30" height="30" className="mr-3" alt="summary"/>
                  Parts Management
                </h3>
              </div>
              <div className="card-toolbar">
                {showParts &&
                <CheckPermission
                currentAction={userActions.Parts.Action_AddParts}
                yes={() => (
                  <button className="btn btn-sm btn-primary" onClick={() => {handleShowAddPartsModal(); setPartDetail(null); setIsEdit(false);}}>
                    <i className="fa fa-plus mr-1"></i>Add Parts
                  </button>
                  )}
                  no={() => null}
                />
                }
                {!showParts &&
                <CheckPermission
                currentAction={userActions.CategoriesList.Action_AddCategory}
                yes={() => (
                  <button className="btn btn-sm btn-primary" onClick={() => {
                    setPartCategoryNameBlurred(false);
                    setPartCategoryDetails(null);
                    setPartCategoryName("");
                    setPartCategoryNotes("");
                    handleShowAddCategoryModal();
                  }}
                    >
                    <i className="fa fa-plus mr-1"></i>Add Category
                  </button>
                   )}
                   no={() => null}
                 />
                }
              </div>
            </div>
            <div className="card-body">
              <Tabs defaultActiveKey="Parts" transition={false} onSelect={handleTabChange} id="noanim-tab-example">
                <Tab eventKey="Parts" title="Parts" >
                  <PartsList editPartModal={handleEditPartModal} refreshPart={getParts} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} partsLoading={partsLoading} setPartsLoading={setPartsLoading} isEdit={isEdit} setIsEdit={setIsEdit} partsList={partsList} setParts={setParts} adding={adding} setAdding={setAdding} deleting={deleting} setDeleting={setDeleting} handleShowAttachmentsModal={handleShowAttachmentsModal} setPartAttachments={setPartAttachments} setLoadingPartAttachments={setLoadingPartAttachments} />
                </Tab>
                <Tab eventKey="Category" title="Category">
                {partCategoriesLoading || deletingCategory || addingCategory ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !partCategoriesLoading && !deletingCategory && !addingCategory && partCategories?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Categories
                </div>
              ) : (
                <PartsCategory 
                    showAddCategoryModal={showAddCategoryModal}
                    setShowAddCategoryModal={setShowAddCategoryModal}
                    handleCloseAddCategoryModal={handleCloseAddCategoryModal}
                    handleShowAddCategoryModal={handleShowAddCategoryModal}
                    partCategoryDetails={partCategoryDetails}
                    setPartCategoryDetails={setPartCategoryDetails}
                    isDeletingPartCategory={isDeletingPartCategory}
                    setIsDeletingPartCategory={setIsDeletingPartCategory}
                    partCategoriesLoading={partCategoriesLoading}
                    partCategories={partCategories}
                    deletePartCategory={deletePartCategory}
                  />
              )
            }
                  
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>


      {/* add Parts Modal  */}
      <Modal
        show={showAddPartsModal}
        onHide={handleCloseAddPartsModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{isEdit ? "Edit Parts" : "Add Parts"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <AddEditPart 
         part={partDetail}
         customers={customers} 
         categories={partCategories} 
         uomList={uomList}
         selectedFiles={selectedFiles}
         setSelectedFiles={setSelectedFiles}
         OnSubmit={() => afterUpsertPart()}
         ref={(ref) => {
          addEditPartRef = ref;
        }}
        AttachmentFormData={AttachmentFormData}
        attachmentsRef={attachmentsRef}
        onSelectAttatchmentsFiels={onSelectAttatchmentsFiels}
        refreshPart={getParts}
        partsList={partsList}
        setParts={setParts}
        adding={adding}
        setAdding={setAdding}
        deleting={deleting}
        setDeleting={setDeleting}
        jobDetails={jobDetails}
        customersDropdownLoading={customersDropdownLoading}
        uomDropdownLoading={uomDropdownLoading}
        categoriesDropdownLoading={categoriesDropdownLoading}
        isEdit={isEdit}
         />
          
          
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseAddPartsModal}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button variant="primary" size="sm"
          disabled={adding}
          onClick={() => addEditPartRef.handleSubmit()}
          >
             <div className={`w-100 ${(adding) && "pr-5"}`}>
            <span className={` ${(adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{isEdit ? "Update" : "Save"}</span>
            {(adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* add Category Modal  */}
      <Modal
        show={showAddCategoryModal}
        onHide={handleCloseAddCategoryModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{partCategoryDetails !== null ? "Edit Category" : "Add Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 form-group">
              <label className="form-label" htmlFor="partCategoryName"> Category Name <span className="text-danger">*</span> </label>
              <input type="text" className="form-control" placeholder="Category name" id="partCategoryName" name="partCategoryName" required value={partCategoryName} onChange={changePartCategoryName} onBlur={() => setPartCategoryNameBlurred(true)} />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {partCategoryNameBlurred && formikCategory.errors.partCategoryName}
              </div>
            </div>

            <div className="col-12 form-group">
              <label className="form-label" htmlFor="partCategoryNotes">
                Notes
              </label>
              <textarea className="form-control" rows="4" id="partCategoryNotes" name="partCategoryNotes" placeholder="Add notes" value={partCategoryNotes} onChange={changePartCategoryNotes}></textarea>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formikCategory.errors.partCategoryNotes}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              handleCloseAddCategoryModal(); 
              setPartCategoryDetails(null);
              setPartCategoryId(0);
              setPartCategoryName("");
              setPartCategoryNotes("");
              setActiveCategory(true);
            }}
          >
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button type="button" disabled={disablePartCategoryButton || !formikCategory.isValid || formikCategory.isSubmitting || addingCategory} onClick={formikCategory.handleSubmit} variant="primary" size="sm">
          <div className={`w-100 ${(formikCategory.isSubmitting || addingCategory) && "pr-5"}`}>
            <span className={` ${(formikCategory.isSubmitting || addingCategory) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{partCategoryDetails !== null ? "Update" : "Save"}</span>
            {(formikCategory.isSubmitting || addingCategory) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>


       {/* show attachments Modal  */}
       <Modal
        show={showAttachmentsModal}
        onHide={handleCloseAttachmentsModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Part Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {
          loadingPartAttachments ? 
          <div>
            Loading attachments...
          </div>
          :
           !loadingPartAttachments && partAttachments?.length === 0 ?
           <div>
             No Attachment
           </div>
           :
            partAttachments?.length > 0 && 
            <table className="table table-borderd table-sm w-100">
            <tbody>
                {
                    partAttachments?.map((el, idx) => {
                        return(
                            <tr key={idx}>
                                <td>{el?.path.split("/").pop()}</td>
                                <td>
                                    <span className="label label-light-primary label-inline text-uppercase">
                                        {(el?.path.split("/").pop()).split('.').pop()}
                                    </span>
                                </td>
                                <td>
                                    <a href={el?.path} download target="_blank">Download File</a>
                                </td>
                            </tr>
                        )
                    })
                }
              
            </tbody>
        </table>
        }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Parts;
