import React, { useState, useEffect, useRef} from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import * as Common from "../../General/common";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import {
  getPartsCall,
  getAvailablePartsInWarehouseForJob,
} from "../../services/managementServices";
import { addInventoryTransaction } from "../../services/inventoryTransectionService";
import { upsertAttachmentCall } from "../../services/attachmentService";
import { TransferTypes, userRoles, transferType_Location_From, TransferTypesArray, TransferTypesObject, locationTypesObject, locationTypes, TransferTypesArrayFiltered } from "../../constants/enums";
import { toInteger } from "lodash";
import { getCrewsCall, getSubstationsCall, getFeedersCall } from "../../services/managementServices";
import { getAllLocationsApiCall, getAllLocationsApiCallCustomeAndNotCustom } from "../../services/locationService";
import moment from "moment";
import { filterInt } from "../../General/common";
import { getAttachmentsCall } from "../../services/attachmentService";

export default function InventoryTransferModal({ 
  closeCallback,
  getInventoryTransactions,
  getStats,
  setInventoryTransactions,
  setAdding,
  isEdit,
  setIsEdit,
  transactionDetail,
  setTransactionDetail,
  showInventoryModal,
  setShowInventoryModal
}) {
  const handleClose = () => {
    if (typeof closeCallback === "function") {
      closeCallback();
      setIsEdit(false);
      setTransactionDetail(null);
      setShowInventoryModal(false);
    }
  };


  const attachmentsRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const { jobDetails } = useSelector((state) => state.JobManagement);
  const [AttachmentFormData, setAttachmentFormData] = useState(undefined);
  const [attachments, setAttachments] = useState([]);
  const [parts, setParts] = useState([]);
  const [availableParts, setAvailableParts] = useState([]);
  const [selectedParts, setselectedParts] = useState([]);
  const [crews, setCrews] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [feeders, setFeeders] = useState([]);
  const [locationsNotCustom, setLocationsNotCustom] = useState([]);
  const [locationsCustom, setLocationsCustom] = useState([]);
  const [locationsAllV, setLocationsAllV] = useState([]);
  const [locationsAllW, setLocationsAllW] = useState([]);

  const [editAttachments, setEditAttachments] = useState([]);
  const [editAttachmentsLoading, setEditAttachmentsLoading] = useState(false);

  const [fkpartId, setfkpartId] = useState(0);
  const [Qty, setQty] = useState(0);

  const [isEditPart, setIsEditPart] = useState(false);

  const [optionValue, setOptionValue] = useState(null);

  useEffect(() => {
    getParts();
    getAvailablePartsInWarehouse(jobDetails.ID);
    getCrews();
    getSubstations();
    getFeeders();
    //getLocationsAll();
    getLocationsAll(locationTypesObject.V);
    getLocationsAll(locationTypesObject.W);
  }, []);


  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [partsDropdownLoading, setPartsDropdownLoading] = useState(false);

  const getParts = async () => {
    setPartsDropdownLoading(true);
    try {
      const { data } = await getPartsCall(jobDetails?.fk_CustomerID);
      if (data.success && data.result.length) {
        setParts(data.result);
      }
    } catch {
    } finally {
    }
    setPartsDropdownLoading(false);
  };
  const getCrews = async () => {
    setDropdownLoading(true);
    try {
      const { data } = await getCrewsCall();
      if (data.success) {
        setCrews(data.result);
      } else {
        setCrews([]);
      }
    } catch {
      setCrews([]);
    } finally {
    }
    setDropdownLoading(false);
  };
  const getSubstations = async () => {
    try {
      const { data } = await getSubstationsCall(jobDetails?.ID);
      if (data.success && data.result.length) {
        setSubstations(data.result);
      }
    }
    catch {
    }
    finally {
    }
  };
  const getFeeders = async () => {
    setDropdownLoading(true);
    try {
      const { data } = await getFeedersCall(0);
      if (data.success && data.result.length) {
        setFeeders(data.result);
      }
    }
    catch {
    }
    finally {
    }
    setDropdownLoading(false);
  };
  
  const getLocationsAll = async (id) => {
    setDropdownLoading(true);
    try {
      const { data } = await getAllLocationsApiCallCustomeAndNotCustom(id);
      if (data.success && data.result.length) {
        if(id == locationTypesObject.V){
          setLocationsAllV(data.result);
        }else if(id == locationTypesObject.W){
          setLocationsAllW(data.result);
        }
      }
    }
    catch {
    }
    finally {
    }
    setDropdownLoading(false);
  };

  const getAvailablePartsInWarehouse = async (JobID) => {
    try {
      const { data } = await getAvailablePartsInWarehouseForJob(JobID);
      if (data.success && data.result.length) {
        setAvailableParts(data.result);
      }
    } catch {
    } finally {
    }
  };


  const addPart = () => {
    let qty = Qty;
    //let fk_PartID = document.getElementById("part").value;
    let fk_PartID = fkpartId;

    if (!(qty > 0)) {
      Common.showSuccessAlertAutoClose(
        "Please enter valid quantity",
        "warning",
        "Warning!",
        3000
      );
      return;
    }
    // if (TransferType.id === TransferTypes.TTT.id) {
    //   const { availableQty } = availableParts.find(x => x.ID == fk_PartID)
    //   if (qty > toInteger(availableQty)) {
    //     Common.showSuccessAlertAutoClose(
    //       `Only ${toInteger(availableQty)} quantity is available for this parts.`,
    //       "warning",
    //       "Warning!",
    //       3000
    //     );
    //     return;
    //   }
    // }
    let index = selectedParts.findIndex((x) => x.fk_PartID === fk_PartID);
    if (index >= 0 && !isEditPart) {
      selectedParts[index].Qty = selectedParts[index].Qty + qty;
    } else if (index >= 0 && isEditPart) {
      selectedParts[index].Qty = qty;
    } else {
      selectedParts.push({ fk_PartID, Qty: qty });
    }
    setselectedParts([...selectedParts]);

    setOptionValue(null);
    setfkpartId(0);
    setQty(0);
    setIsEditPart(false);
  };
  const removeSelectedPart = (fk_PartID) => {
    let _parts = selectedParts.filter((x) => x.fk_PartID !== fk_PartID);
    setselectedParts([..._parts]);
  };
  const onSelectAttatchments = async (e) => {
    let files = e.target.files;

    if (files) {
      let fd = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fd.append("files", file);
      }
      setAttachmentFormData(fd);
      setAttachments([...files]);
    }
  };

  const [transactionDate, setTransactionDate] = useState(moment().format("YYYY-MM-DD"));

  const changeTransactionDate = (e) => {
    setTransactionDate(e.target.value == "" ? null : e.target.value);
      //setFromDate(e.target.value == "" ? null : format(new Date(e.target.value), "yyyy-MM-dd"));
  }

  const [initialValues, setInitialValues] = useState({
    ID: 0,
    fk_FromLocationID: '',
    fk_ToLocationID: '',
    //fk_CrewID: null,
    fk_JobID: jobDetails.ID,
    fk_TransferTypeID: '',
    //fk_PoleTypeID: null,
    //fk_PoleID: null,
    //fk_SubstationID: null,
    //fk_FeederID: null,
    //CustomerPoleID: "",
    TransactionDate: transactionDate,
    Parts: [],
    Notes: "",
    //transactionType: ""
  });

  useEffect(() => {
    if(isEdit){
      setInitialValues({
          ID: isEdit ? transactionDetail?.ID : 0,
          fk_FromLocationID: isEdit ? (transactionDetail?.TransferType == TransferTypesObject.REC.abbrev || transactionDetail?.TransferType == TransferTypesObject.XFER.abbrev || transactionDetail.TransferType == TransferTypesObject.WTCREW.abbrev) ? transactionDetail?.fk_FromLocationID :  (transactionDetail?.TransferType == TransferTypesObject.TCREW.abbrev || transactionDetail?.TransferType == TransferTypesObject.FXFER.abbrev) ? transactionDetail?.fk_FeederID : (transactionDetail?.TransferType == TransferTypesObject.FCREW.abbrev || transactionDetail.TransferType == TransferTypesObject.TWFCREW.abbrev) ? transactionDetail?.fk_CrewID : "" : '',
          fk_ToLocationID: isEdit ? (transactionDetail?.TransferType == TransferTypesObject.REC.abbrev || transactionDetail?.TransferType == TransferTypesObject.FXFER.abbrev || transactionDetail.TransferType == TransferTypesObject.TWFCREW.abbrev) ? transactionDetail?.fk_ToLocationID : (transactionDetail?.TransferType == TransferTypesObject.XFER.abbrev || transactionDetail?.TransferType == TransferTypesObject.FCREW.abbrev) ? transactionDetail?.fk_FeederID : (transactionDetail?.TransferType == TransferTypesObject.TCREW.abbrev || transactionDetail.TransferType == TransferTypesObject.WTCREW.abbrev) ? transactionDetail?.fk_CrewID : "" : '',
          //fk_CrewID: null,
          fk_JobID: isEdit ? transactionDetail?.fk_JobID : jobDetails.ID,
          fk_TransferTypeID: isEdit ? transactionDetail?.fk_TransferTypeID : '',
          //fk_PoleTypeID: null,
          //fk_PoleID: null,
          //fk_SubstationID: null,
          //fk_FeederID: null,
          //CustomerPoleID: "",
          TransactionDate: isEdit ? moment(transactionDetail?.TransactionDate).format("YYYY-MM-DD") : transactionDate,
          Parts: isEdit ? setselectedParts([{ fk_PartID: transactionDetail?.fk_PartID, Qty : transactionDetail?.Qty }]) : [],
          Notes: isEdit ? transactionDetail?.Notes : "",
          //transactionType: ""
      });
    }else{
      setInitialValues({
        ID: 0,
        fk_FromLocationID: '',
        fk_ToLocationID: '',
        //fk_CrewID: null,
        fk_JobID: jobDetails.ID,
        fk_TransferTypeID: '',
        //fk_PoleTypeID: null,
        //fk_PoleID: null,
        //fk_SubstationID: null,
        //fk_FeederID: null,
        //CustomerPoleID: "",
        TransactionDate: transactionDate,
        Parts: [],
        Notes: "",
        //transactionType: ""
      })
    }
  }, [isEdit]);


  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  // const initialValues = {
  //   ID: isEdit ? transactionDetail?.ID : 0,
  //   fk_FromLocationID: isEdit ? (transactionDetail?.TransferType == TransferTypesObject.REC.abbrev || transactionDetail?.TransferType == TransferTypesObject.XFER.abbrev) ? transactionDetail?.fk_FromLocationID :  (transactionDetail?.TransferType == TransferTypesObject.TCREW.abbrev || transactionDetail?.TransferType == TransferTypesObject.FXFER.abbrev) ? transactionDetail?.fk_FeederID : (transactionDetail?.TransferType == TransferTypesObject.FCREW.abbrev) ? transactionDetail?.fk_CrewID : "" : '',
  //   fk_ToLocationID: isEdit ? (transactionDetail?.TransferType == TransferTypesObject.REC.abbrev || transactionDetail?.TransferType == TransferTypesObject.FXFER.abbrev) ? transactionDetail?.fk_ToLocationID : (transactionDetail?.TransferType == TransferTypesObject.XFER.abbrev || transactionDetail?.TransferType == TransferTypesObject.FCREW.abbrev) ? transactionDetail?.fk_FeederID : (transactionDetail?.TransferType == TransferTypesObject.TCREW.abbrev) ? transactionDetail?.fk_CrewID : "" : '',
  //   //fk_CrewID: null,
  //   fk_JobID: isEdit ? transactionDetail?.fk_JobID : jobDetails.ID,
  //   fk_TransferTypeID: isEdit ? transactionDetail?.fk_TransferTypeID : '',
  //   //fk_PoleTypeID: null,
  //   //fk_PoleID: null,
  //   //fk_SubstationID: null,
  //   //fk_FeederID: null,
  //   //CustomerPoleID: "",
  //   TransactionDate: isEdit ? moment(transactionDetail?.TransactionDate).format("YYYY-MM-DD") : transactionDate,
  //   Parts: [],
  //   Notes: isEdit ? transactionDetail?.Notes : "",
  //   //transactionType: ""
  // };

 

  useEffect(() => {
    if(transactionDate == ""){
        setTransactionDate(null);
    }
}, [transactionDate]);

 

  //console.log("ini", initialValues);
  const schema = Yup.object().shape({
    fk_FromLocationID: Yup.string()
      .required("From Location is required")
      .min(1, "Please select from location"),
    fk_ToLocationID: Yup.string()
      .required("To Location is required")
      .min(1, "Please select to location"),
    fk_TransferTypeID: Yup.string()
      .required("Transfer Type is required")
      .min(1, "Please select transfer type"),
    // fk_JobID: Yup.string()
    //   .required("Job is required")
    //   .min(1, "Please select job"),
    // fk_CrewID: Yup.string().nullable().when("ps", {
    //   is: ps => TransferType.id !== TransferTypes.REC.id && !TransferTypes.MA.id,
    //   then: Yup.string().required("Crew is required")
    //     .min(1, "Please select crew").nullable(),
    // }),
    TransactionDate: Yup.string().required("Transaction Date is required"),
    // fk_FeederID: Yup.string().nullable().when("p", {
    //   is: p => (TransferType.id !== TransferTypes.MA.id && showPole_Substation_Feeder_Fields),
    //   then: Yup.string().required("Feeder is required").nullable()
    // }),
    //fk_SubstationID: Yup.string().nullable().when("p", {
    //  is: p => showPole_Substation_Feeder_Fields,
    //  then: Yup.string().required("Substation is required").nullable()
    //}),
    // CustomerPoleID: Yup.string().when("ps", {
    //   is: ps => (TransferType.id !== TransferTypes.MA.id && showPole_Substation_Feeder_Fields),
    //   then: Yup.string().required("Customer Pole is required")
    // }),
    //Parts: Yup.array().min(1, "Please add atleast 1 part"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!(selectedParts.length > 0)) {
        //setStatus('Please add atleast 1 part before saving an inventory transection');

        Common.showSuccessAlertAutoClose(
          "Please add atleast 1 part before saving a inventory transection",
          "warning",
          "Warning!",
          3000
        );
      } else {
        values.Parts = [...selectedParts];
        values.Notes = document.getElementById("Notes").value;
        setInventoryTransactions([]);
        setAdding(true);
        //console.log("valstoadd", values);
        let dataToPost = {
          cdata:
          {
          ID: values.ID,
          fk_FromLocationID: (formik.values.fk_TransferTypeID == TransferTypesObject.REC.id || formik.values.fk_TransferTypeID == TransferTypesObject.XFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.WTCREW.id) ? values.fk_FromLocationID : null,
          fk_ToLocationID: (formik.values.fk_TransferTypeID == TransferTypesObject.REC.id || formik.values.fk_TransferTypeID == TransferTypesObject.FXFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.TWFCREW.id) ? values.fk_ToLocationID : null,
          fk_JobID: values.fk_JobID,
          fk_TransferTypeID: values.fk_TransferTypeID,
          fk_CrewID: (formik.values.fk_TransferTypeID == TransferTypesObject.FCREW.id || formik.values.fk_TransferTypeID == TransferTypesObject.TWFCREW.id) ? values.fk_FromLocationID : (formik.values.fk_TransferTypeID == TransferTypesObject.TCREW.id || formik.values.fk_TransferTypeID == TransferTypesObject.WTCREW.id) ? values.fk_ToLocationID : null,
          fk_FeederID: (formik.values.fk_TransferTypeID == TransferTypesObject.FXFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.TCREW.id) ? values.fk_FromLocationID : (formik.values.fk_TransferTypeID == TransferTypesObject.XFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.FCREW.id) ? values.fk_ToLocationID : null,
          TransactionDate: values.TransactionDate,
          Parts: values.Parts,
          Notes: values.Notes,
          CreatedBy: user?.id,
          CreatedDate: moment.utc(moment()).format(),
          ModifiedBy: "0",
          ModifiedDate: moment.utc(moment()).format(),
        }
      };
        const { data } = await addInventoryTransaction(dataToPost);

        if (data.success) {
          if(!AttachmentFormData){
           Common.showSuccessAlertAutoClose(
            isEdit ? "Inventory Transaction updated successfully!" : "Inventory Transaction added successfully!",
             "success",
             "Success!",
             3000
           );
          }
          getInventoryTransactions();
          getStats(jobDetails?.ID);
          //const  _data = await upsertAttachmentCall(attachments, data.result.id, Common.EntityIds.InventoryTransfer);
          if (AttachmentFormData) {
             const _data = await upsertAttachmentCall(
               AttachmentFormData,
               data.result[0].ID,
               Common.EntityIds.InventoryTransfer
             );

             if (_data.data.success) {
               // success scenario
               Common.showSuccessAlertAutoClose(
                 //"Attachments uploaded successfully.",
                 isEdit ? "Inventory Transaction updated successfully!" : "Inventory Transaction added successfully!",
                 "success",
                 "Success!",
                 3000
               );
             } else {
               Common.showSuccessAlertAutoClose(
                 _data.data.message,
                 "error",
                 "Opps!",
                 3000
               );
             }
          }
          handleClose();
          //window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "error",
            "Opps!",
            3000
          );
          getInventoryTransactions();
          getStats(jobDetails?.ID);
        }

        setAdding(false);
      }
      setSubmitting(false);
    },
  });

  //console.log("formikerrs", formik.errors);
  //console.log("formikvals", formik.values);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      TransactionDate: transactionDate
    });
  }, [transactionDate]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const filteredOptions = parts?.map(
      ({
        ID,
        CustomerPartNumber,
        ClientPartNumber,
        VendorPartNumber,
      }) => (
        { value: ID, label: `${CustomerPartNumber} / ${VendorPartNumber} / ${ClientPartNumber}` }
      )
    );

    const latestFilteredOptions = [
      // {value: 0, label: "- Please Select -"},
      ...filteredOptions
    ];

    setOptions(latestFilteredOptions);
  }, [availableParts, parts]);


  useEffect(() => {
    formik.setValues({
      ...formik.values,
      fk_FromLocationID: '',
      fk_ToLocationID: ''
    });
  }, [formik.values.fk_TransferTypeID]);

  useEffect(() => {
    if(formik.values.fk_TransferTypeID > 0){
      formik.setValues({
        ...formik.values,
        transactionType: formik.values.fk_TransferTypeID == TransferTypesObject.REC.id ? "REC" : formik.values.fk_TransferTypeID == TransferTypesObject.XFER.id ? "XFER" : formik.values.fk_TransferTypeID == TransferTypesObject.TCREW.id ? "TCREW" : formik.values.fk_TransferTypeID == TransferTypesObject.FCREW.id ? "FCREW" : formik.values.fk_TransferTypeID == TransferTypesObject.FXFER.id ? "FXFER" : formik.values.fk_TransferTypeID == TransferTypesObject.WTCREW.id? "WTCREW" : formik.values.fk_TransferTypeID == TransferTypesObject.TWFCREW.id ? "TWFCREW" : ""
      });
    }else{
      formik.setValues({
        ...formik.values,
        transactionType: ""
      });
    }
  }, [formik.values.fk_TransferTypeID]);

  //console.log("locations", locationsAll);
  //console.log("crews", crews);
  //console.log("feedrs", feeders);

  const getInventoryAttachments = async (recordId, entityId) => {
    setEditAttachmentsLoading(true);
    try {
        const { data } = await getAttachmentsCall(recordId, entityId);
        
        if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              setEditAttachments(data.result);
            }
        } else {

        }
    }
    catch {

    }
    finally {
      setEditAttachmentsLoading(false);
    }
};

  useEffect(() => {
    if(isEdit){
      if(transactionDetail != null){
          getInventoryAttachments(transactionDetail?.ID, Common.EntityIds.InventoryTransfer);
      }
    }
  }, [isEdit, transactionDetail]);


  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title><i className="fa fa-shipping-fast mr-2"></i> {isEdit ? "Edit" : "Add"} Inventory Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="InventoryTransectionForm" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 form-group">
              <label  className="form-label" htmlFor="fk_TransferTypeID">
                  Transfer Type <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control"
                  id="fk_TransferTypeID"
                  name="fk_TransferTypeID"
                  {...formik.getFieldProps("fk_TransferTypeID")}
                >
                  <option value=''>- Please Select -</option>
                  {
                      TransferTypesArrayFiltered?.map(({ id, name, abbrev }) => (
                        <option value={id}>{name} ({abbrev})</option>
                      ))
                  }
                </select>

                {formik.touched.fk_TransferTypeID &&
                  formik.errors.fk_TransferTypeID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.fk_TransferTypeID}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label" htmlFor="TransactionDate">
                  Date <span className="text-danger">*</span>
                </label>
                <input 
                  type="date"
                  className="form-control"
                  id="TransactionDate"
                  name="TransactionDate"
                  value={transactionDate}
                  onChange={changeTransactionDate}
                  //{...formik.getFieldProps("TransactionDate")}
                  //defaultValue={moment().format("yyyy-MM-dd")}
                />
                {formik.touched.TransactionDate &&
                  formik.errors.TransactionDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.TransactionDate}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 form-group">
                <label  className="form-label" htmlFor="fk_FromLocationID">
                  From <span className="text-danger">*</span>
                </label>

                { dropdownLoading ? 
                <select className="form-control" disabled>
                  <option>Loading...</option>
                </select>
                :
                <select
                  className="form-control"
                  id="fk_FromLocationID"
                  name="fk_FromLocationID"
                  {...formik.getFieldProps("fk_FromLocationID")}
                >
                  <option value=''>- Please Select -</option>
                  {   (formik.values.fk_TransferTypeID == TransferTypesObject.REC.id) ?
                      locationsAllV?.map(({ ID, LocationName }) => (
                        <option value={ID}>{LocationName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.XFER.id) ?
                      locationsAllW?.map(({ ID, LocationName }) => (
                        <option value={ID}>{LocationName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.FXFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.TCREW.id) ?
                      feeders?.filter(x=> substations?.map(x=> x.ID).some((element) => element == x.fk_SubstationID)).map(({ ID, FeederName, SubstationName, FeederNumber }) => (
                        <option value={ID}>{`${SubstationName} - ${FeederNumber}`}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.FCREW.id) ?
                      crews?.map(({ ID, CrewName }) => (
                        <option value={ID}>{CrewName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.WTCREW.id) ?
                      locationsAllW?.map(({ ID, LocationName }) => (
                        <option value={ID}>{LocationName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.TWFCREW.id) ?
                      crews?.map(({ ID, CrewName }) => (
                        <option value={ID}>{CrewName}</option>
                      ))
                      :
                      <></>
                  }
                </select>
                }

                {formik.touched.fk_FromLocationID &&
                  formik.errors.fk_FromLocationID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.fk_FromLocationID}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label" htmlFor="fk_ToLocationID">
                  To <span className="text-danger">*</span>
                </label>

                { dropdownLoading ?
                <select className="form-control" disabled>
                  <option>Loading...</option>
                </select>
                :
                <select
                  className="form-control"
                  id="fk_ToLocationID"
                  name="fk_ToLocationID"
                  {...formik.getFieldProps("fk_ToLocationID")}
                >
                  <option value=''>- Please Select -</option>
                  {   (formik.values.fk_TransferTypeID == TransferTypesObject.REC.id || formik.values.fk_TransferTypeID == TransferTypesObject.FXFER.id) ?
                      locationsAllW?.map(({ ID, LocationName }) => (
                        <option value={ID}>{LocationName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.XFER.id || formik.values.fk_TransferTypeID == TransferTypesObject.FCREW.id) ?
                      feeders?.filter(x=> substations?.map(x=> x.ID).some((element) => element == x.fk_SubstationID)).map(({ ID, FeederName, SubstationName, FeederNumber }) => (
                        <option value={ID}>{`${SubstationName} - ${FeederNumber}`}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.TCREW.id) ?
                      crews?.map(({ ID, CrewName }) => (
                        <option value={ID}>{CrewName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.WTCREW.id) ?
                      crews?.map(({ ID, CrewName }) => (
                        <option value={ID}>{CrewName}</option>
                      ))
                      :
                      (formik.values.fk_TransferTypeID == TransferTypesObject.TWFCREW.id) ?
                      locationsAllW?.map(({ ID, LocationName }) => {
                        return (
                            <option value={ID}>{LocationName}</option>
                        )
                      })
                      :
                      <></>
                  }
                </select>
                }
                {formik.touched.fk_ToLocationID &&
                  formik.errors.fk_ToLocationID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.fk_ToLocationID}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 form-group">
                <label className="form-label" htmlFor="Notes">Transfer Notes</label>
                <textarea
                  className="form-control"
                  rows="4"
                  id="Notes"
                  name="Notes"
                  placeholder="Add notes"
                ></textarea>
              </div>
            </div>
            <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <div className="row align-items-end">
              <div className="col-md-5">
                <div className="form-group">
                <label className="form-label" htmlFor="partNum">
                    Part Number
                  </label>
                  {/* <select
                    className="form-control"
                    id="part"
                    name="part"
                    placeholder="Part"
                    onChange={(e) => {}}
                  >
                    {(TransferType.id === TransferTypes.TTT.id
                      ? availableParts
                      : parts
                    ).map(
                      ({
                        id,
                        customerPartNumber,
                        clientPartNumber,
                        vendorPartNumber,
                      }) => (
                        <option
                          value={id}
                        >{`${customerPartNumber} / ${vendorPartNumber} / ${clientPartNumber}`}</option>
                      )
                    )}
                  </select> */}
                  { partsDropdownLoading ?
                  <Select
                  id="Parts"
                  name="Parts"
                  placeholder="Loading parts..."
                  value="Loading parts..."
                  isDisabled={true}
                />
                :
                  <Select
                    id="Parts"
                    name="Parts"
                    placeholder="Part"
                    value={optionValue}

                    onChange={(val) => {
                      setOptionValue(val);
                      setfkpartId(val.value);
                    }}
                    options={options}
                    isDisabled={isEdit}


                  />
                  }
                  {/* <input
                  type="text"
                  className="form-control"
                  id="partNum"
                  placeholder="Part#"
                /> */}
                  {/* <Select
                  //id="partNum"
                  //name="partNum"
                  value={SelectedPart}
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]}
                  onChange={(val) => {
                    //console.log(val); 
                  }}
                /> */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                <label className="form-label" htmlFor="qty">
                    Qty
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="qty"
                    value={Qty}
                    onChange={(e) => {
                      setQty(parseInt(e.target.value));
                    }}
                    placeholder="Add quantity"
                    min={0}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    disabled={fkpartId <= 0 || (Qty <= 0)}
                    onClick={addPart}
                  >
                    {isEditPart ? "Edit" : isEdit ? "Edit" : "Add"}
                  </button>
                </div>
              </div>
            </div>

            {selectedParts?.length <= 0 ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please add atleast 1 part</div>
              </div>
            ) : null}

            <table
              id="UserRecordsGrid"
              className="table table-bordered table-hover table-striped table-sm w-100"
            >
              <thead>
                <tr>
                  <th>
                    <i className="fa fa-comments mr-1"></i> Part Description
                  </th>
                  <th>
                    <i className="fa fa-list-ul mr-1"></i> Part Number
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Qty
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {parts?.length > 0 && selectedParts?.map((element, index, array) => {
                  const { fk_PartID, Qty } = element;
                  const {
                    CustomerPartNumber,
                    ClientPartNumber,
                    VendorPartNumber,
                    Description,
                  } = parts?.find((x) => x.ID == fk_PartID);
                  return (
                    <tr>
                      <td>{Description}</td>
                      <td>{`${CustomerPartNumber} / ${VendorPartNumber} / ${ClientPartNumber}`}</td>
                      <td>
                        <span className="label label-light-danger label-inline">
                          {Qty}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn btn-icon btn-outline-danger btn-xs"
                          type="button"
                          onClick={() => removeSelectedPart(fk_PartID)}
                          style={{ display: isEdit ? "none" : ""}}
                        >
                          <i className="fa fa-trash-alt"></i>
                        </button>
                        <button
                          className="btn btn-icon btn-outline-primary btn-xs ml-3"
                          type="button"
                          onClick={() => {
                            setIsEditPart(true);
                            setOptionValue({
                              value: fk_PartID,
                              label: `${CustomerPartNumber} / ${VendorPartNumber} / ${ClientPartNumber}`
                            });
                            setfkpartId(fk_PartID);
                            setQty(Qty);
                          }}
                        >
                          <i className="fa fa-pen"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <div className="row">
              <div className="col-12">
                <h5 className="mb-3">Files</h5>
              </div>
              <div className="col form-group" style={{ display: isEdit ? "none" : "" }}>
              <label className="form-label" htmlFor="Attachments">
                  Attach File
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="Attachments"
                  name="Attachments"
                  //multiple="multiple"
                  onChange={onSelectAttatchments}
                  ref={attachmentsRef}
                />
              </div>
              <div className="col-12">
                <table className="table table-borderd table-sm w-100">
                <tbody>
                    {attachments.map(({ name, type }) => {
                      return (
                        <tr>
                          <td>{name}</td>
                          <td>
                            <span className="label label-light-primary label-inline text-uppercase">
                              {name.split('.').pop()}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {
                isEdit &&
                <div className="col-12">
                <table className="table table-borderd table-sm w-100">
                <tbody>
                    {editAttachmentsLoading ?
                    <div>
                      Loading attachments...
                    </div>
                    :
                    !editAttachmentsLoading && editAttachments?.length == 0 ?
                    <div>
                      No Attachments
                    </div>
                    :
                    editAttachments?.map((el, idx) => {
                      return (
                        <tr key={idx}>
                                <td>{el?.path.split("/").pop()}</td>
                                <td>
                                    <span className="label label-light-primary label-inline text-uppercase">
                                        {(el?.path.split("/").pop()).split('.').pop()}
                                    </span>
                                </td>
                                <td>
                                    <a href={el?.path} download target="_blank">Download File</a>
                                </td>
                            </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              }

            </div>
            <input
              id="InventoryTransectionFormSubmitBtn"
              type="submit"
              value="submit"
              style={{ display: "none" }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>

          <Button
            variant="primary"
            size="sm"
            className={formik.isSubmitting && "pr-9"}
            disabled={!formik.isValid || formik.isSubmitting || selectedParts?.length <= 0}
            onClick={() => {
              document
                .getElementById("InventoryTransectionFormSubmitBtn")
                .click();
            }}
          >
            <i className="fa fa-save mr-1"></i> <span>{isEdit ? "Update" : "Save"}</span>{" "}
            {formik.isSubmitting && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
