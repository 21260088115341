import React, { useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomLoader } from "../../components/common/CustomLoader";
import CheckPermission from "../../General/CheckPermission";
import { timeout_for_data_tables, userActions, no_of_rows_on_single_page } from "../../constants/enums";
function PartsCategory({
  showAddCategoryModal,
  setShowAddCategoryModal,
  handleCloseAddCategoryModal,
  handleShowAddCategoryModal,
  partCategoryDetails,
  setPartCategoryDetails,
  isDeletingPartCategory,
  setIsDeletingPartCategory,
  partCategoriesLoading,
  partCategories,
  deletePartCategory,
}) {

   useEffect(() => {
    // const timeout = setTimeout(() => {
      $("#partsCategoryList").DataTable({
        responsive: true,
        pageLength: no_of_rows_on_single_page,
        destroy: true,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        dom:
          "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          {
            extend: "csvHtml5",
            text: "CSV",
            titleAttr: "Generate CSV",
            className: "btn btn-sm btn-primary mr-2",
            exportOptions: {
              columns: ':not(:last-child)',
            }
          },
          {
            extend: "copyHtml5",
            text: "Copy",
            titleAttr: "Copy to clipboard",
            className: "btn btn-sm btn-light",
          },
        ],
      });
    // }, timeout_for_data_tables);

    // return () => clearTimeout(timeout);
   
   });

  return (
    <>
      <div className="pt-5">
        {/* {partCategoriesLoading ? (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        ) : !partCategoriesLoading && partCategories?.length === 0 ? (
          <div className="d-flex justify-content-center">No Categories</div>
        ) : ( */}
          <CheckPermission
            currentAction={userActions.CategoriesList.Action_ViewCategory}
            yes={() => (
              <table
                id="partsCategoryList"
                className="table table-bordered table-hover table-striped table-sm w-100"
              >
                <thead>
                  <tr>
                    <th data-priority="1">
                      <i className="fa fa-cube mr-1"></i> Category ID
                    </th>
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Category Name
                    </th>
                    <th>
                      <i className="fa fa-comments mr-1"></i> Notes
                    </th>
                    <th data-priority="2" className="text-center min-width-110">
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {partCategories?.map((partCategory) => {
                    return (
                      <tr key={partCategory.ID}>
                        <td>{partCategory.ID}</td>
                        <td>{partCategory.Name}</td>
                        <td>{partCategory.Notes}</td>
                        <td align="center">
                          {/* <OverlayTrigger placement={"top"} overlay={<Tooltip> Delete Category </Tooltip>}>
                                            <button className="btn btn-icon btn-outline-danger btn-xs" onClick={() => {
                                                setIsDeletingPartCategory(true);
                                                deletePartCategory(partCategory.id);
                                                setIsDeletingPartCategory(false);
                                            }}>
                                                <i className="fa fa-trash-alt"></i>
                                            </button>
                                        </OverlayTrigger> */}
                           <CheckPermission
                        currentAction={userActions.CategoriesList.Action_EditCategory}

                        yes={() => (
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Edit Category </Tooltip>}
                          >
                            <button
                              className="btn btn-icon btn-outline-primary btn-xs ml-3"
                              onClick={() => {
                                setPartCategoryDetails(partCategory);
                                handleShowAddCategoryModal();
                              }}
                            >
                              <i className="fa fa-pen"></i>
                            </button>
                          </OverlayTrigger>
                          )}
                        no={() => null}
                    />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            no={() => null}
          />
        {/* )} */}
      </div>
    </>
  );
}

export default PartsCategory;
