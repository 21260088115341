/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import { checkIsActive } from "../../../../_helpers";
import CheckPermission from "../../../../../app/General/CheckPermission";
import { userActions } from "../../../../../app/constants/enums";
export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/dashboard">
                <i className="fa fa-wrench text-white mr-2"></i><span className="menu-text">Jobs</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            
            <CheckPermission
          currentAction={userActions.userManagment.Action_ViewUser}
          yes={() =>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/user')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/user">
                <i className="fa fa-users-cog text-white mr-2"></i>  <span className="menu-text">Users</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            }
            no={() => null}
          />
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/customers')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/customers">
                <i className="fa fa-users text-white mr-2"></i>  <span className="menu-text">Customer List</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/locations')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/locations">
                <i className="fa fa-map-marker-alt text-white mr-2"></i>  <span className="menu-text">Locations</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/reports')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/reports">
                <i className="fa fa-file-alt text-white mr-2"></i>  <span className="menu-text">Reports</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/newreports')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/reports">
                <i className="fa fa-file-alt text-white mr-2"></i>  <span className="menu-text">Reports</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
           {/* <CheckPermission
          currentAction={userActions.userManagment.Action_ViewUser}
          yes={() =>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/systemsetting')}`}>
                <NavLink className="menu-link d-flex align-items-center" to="/systemsetting">
                <i className="fa fa-cogs text-white mr-2"></i> <span className="menu-text">System Settings</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            }
            no={() => null}
          /> */}
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
