import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes } from "../../constants/enums";
import { getReportsCall } from "../../services/reportsService";
import { useSelector, shallowEqual } from "react-redux";
import { format, parseISO } from 'date-fns'
import ReportsListing from "./reportsListing";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllLocationsApiCallCustomeAndNotCustom } from "../../services/locationService";
import { getAllJobsCall } from "../../services/jobServices";
import Select from "react-select";
import {
    getPoleTypesByIdCall,
    getCrewsCall,
    getSubstationsCall,
    getFeedersCall
  } from "../../services/managementServices";
import { setFeeders } from "../../../store/management/actions";

function Reports() {

    const user = useSelector((state) => state.auth.user, shallowEqual); 

    const jobDetails = useSelector((store) => store.JobManagement.jobDetails);

    const [filteredValues, setFilteredValues] = useState(null);

    const [locationsAll, setLocationsAll] = useState([]);
    const [jobsList, setjobsList] = useState([]);
    const [poleTypes, setPoleTypes] = useState([]);
    const [crews, setCrews] = useState([]);
    const [substations, setSubstations] = useState([]);
    const [feeders, setFeeders] = useState([]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [partNumber, setPartNumber] = useState(null);
    const [fk_FromLocationID, setfk_FromLocationID] = useState(null);
    const [fk_ToLocationID, setfk_ToLocationID] = useState(null);
    const [fk_TransferTypeID, setfk_TransferTypeID] = useState(null);
    const [fk_JobID, setfk_JobID] = useState(null);
    const [poleTypeIDs, setPoleTypeIDs] = useState([]);
    const [crewIDs, setCrewIDs] = useState([]);
    const [substationIDs, setSubstationIDs] = useState([]);
    const [feederIDs, setFeederIDs] = useState([]);

    const [dataValues, setDataValues] = useState(null);

    const [reportsLoading, setReportsLoading] = useState(false);


    const changeFromDate = (e) => {
        setFromDate(e.target.value == "" ? null : format(new Date(e.target.value), "yyyy-MM-dd"));
    }

    const changeToDate = (e) => {
        setToDate(e.target.value == "" ? null : format(new Date(e.target.value), "yyyy-MM-dd"));
    }

    const changePartNumber = (e) => {
        setPartNumber(e.target.value);
    }

    const changeFromLocationID = (e) => {
        setfk_FromLocationID(parseInt(e.target.value));
    }

    const changeToLocationID = (e) => {
        setfk_ToLocationID(parseInt(e.target.value));
    }

    const changeTransferTypeID = (e) => {
        setfk_TransferTypeID(parseInt(e.target.value));
    }

    const changeJobID = (e) => {
        setfk_JobID(parseInt(e.target.value));
    }


    useEffect(() => {
        let dataHere = {
            fromDate,
            toDate,
            partNumber, 
            fk_FromLocationID, 
            fk_ToLocationID, 
            fk_TransferTypeID, 
            fk_JobID, 
            poleTypeIDs, 
            crewIDs, 
            substationIDs, 
            feederIDs
        };

        setDataValues(dataHere);
    }, [fromDate, toDate, partNumber, fk_FromLocationID, fk_ToLocationID, fk_TransferTypeID, fk_JobID, poleTypeIDs, crewIDs, substationIDs, feederIDs]);

    useEffect(() => {
        if(fromDate == ""){
            setFromDate(null);
        }
    }, [fromDate]);

    useEffect(() => {
        if(toDate == ""){
            setToDate(null);
        }
    }, [toDate]);

    useEffect(() => {
        if(partNumber == "" || partNumber <= 0){
            setPartNumber(null);
        }
    }, [partNumber]);

    useEffect(() => {
        if(fk_FromLocationID == "" || fk_FromLocationID <= 0){
            setfk_FromLocationID(null);
        }
    }, [fk_FromLocationID]);

    useEffect(() => {
        if(fk_ToLocationID == "" || fk_ToLocationID <= 0){
            setfk_ToLocationID(null);
        }
    }, [fk_ToLocationID]);

    useEffect(() => {
        if(fk_TransferTypeID == "" || fk_TransferTypeID <= 0){
            setfk_TransferTypeID(null);
        }
    }, [fk_TransferTypeID]);

    useEffect(() => {
        if(fk_JobID == "" || fk_JobID <= 0){
            setfk_JobID(null);
        }
    }, [fk_JobID]);


     // function which fetches the reports
     const getReports = async (values) => {
        setReportsLoading(true);
        try {
          const { data } = await getReportsCall(values);
          setReportsLoading(false);
          if(data.success){
            // success scenario
           
            if(data.result.length >= 0){
             setFilteredValues(data.result);
            }
          }else{
            setReportsLoading(false);
            setFilteredValues(null);
          }
        }
        catch {
            setReportsLoading(false);
            setFilteredValues(null);
        }
        finally {
            setReportsLoading(false);
        }
    };

    useEffect(() => {
        formik.setValues({
            fromDate, 
            toDate, 
            partNumber, 
            fk_FromLocationID, 
            fk_ToLocationID, 
            fk_TransferTypeID, 
            fk_JobID, 
            poleTypeIDs, 
            crewIDs, 
            substationIDs, 
            feederIDs
        });
      }, [fromDate, toDate, partNumber, fk_FromLocationID, fk_ToLocationID, fk_TransferTypeID, fk_JobID, poleTypeIDs, crewIDs, substationIDs, feederIDs]);

      const Schema = Yup.object().shape({
        fromDate: Yup.string().nullable().required("From Date is required"),
              //.typeError('Invalid Value')
        toDate: Yup.string().nullable().required("To Date is required")
            
              //.typeError('Invalid Value')
              .test("cantBeLessThanFrom", "To Date should not be before From Date", function (item) {
                try {
                  return (fromDate <= toDate);
                } catch {
                  return false;
                }
              })
      });

      const formik = useFormik({
        initialValues: {
            fromDate,
            toDate,
            partNumber, 
            fk_FromLocationID, 
            fk_ToLocationID, 
            fk_TransferTypeID, 
            fk_JobID, 
            poleTypeIDs, 
            crewIDs, 
            substationIDs, 
            feederIDs
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          handleValidSubmit();
          setSubmitting(false);
        },
      });

      // formik setup ends

      const handleValidSubmit = async e => {
        //e?.preventDefault();
        if(dataValues != null){
            getReports(dataValues);
        }
    }


    // get dropdowns values 

    const getLocationsAll = async () => {
        try {
          const { data } = await getAllLocationsApiCallCustomeAndNotCustom();
          if (data.success && data.result.length) {
            setLocationsAll(data.result);
          }
        }
        catch {
        }
        finally {
        }
      };


      const getAllJobs = async () => {
        
        try {
          const { data } = await getAllJobsCall(user.id);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setjobsList(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
      };


      const getAllPoleTypes = async () => {
        try {
          const { data } = await getPoleTypesByIdCall(0);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              
              var unAssingPoleType = [];
    
              data.result.map((poleType) => {
                unAssingPoleType.push({ value: poleType.id, label: poleType.typeName });
              });
              setPoleTypes(unAssingPoleType);

    
             
            }
          } else {
          }
        } catch {
        } finally {
        }
      };

      const getAllCrews = async () => {
        try {
          const { data } = await getCrewsCall();
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              
              var unAssingCrews = [];
    
              data.result.map((crews) => {
                unAssingCrews.push({ value: crews.id, label: crews.crewName });
              });
              setCrews(unAssingCrews);

    
              
            }
          } else {
          }
        } catch {
        } finally {
        }
      };

      const getAllSubstations = async () => {
        try {
          const { data } = await getSubstationsCall(fk_JobID);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              
              var unAssingSubstations = [];
    
              data.result.filter(x => x.fk_JobID == fk_JobID).map((substations) => {
                unAssingSubstations.push({ value: substations.id, label: `${substations.substationName} - ${substations.substationNumber}` });
              });
              setSubstations(unAssingSubstations);

            }
          } else {
          }
        } catch {
        } finally {
        }
      };

      const getAllFeeders = async () => {
        try {
          const { data } = await getFeedersCall();
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              
              var unAssingFeeders = [];
    
              data.result.filter(x => substationIDs.some(substation => substation.value == x.fk_SubstationID)).map((feeders) => {
                  
                unAssingFeeders.push({ value: feeders.id, label: `${feeders.feederName} - ${feeders.feederNumber}` });
              });
              setFeeders(unAssingFeeders);

    
              
            }
          } else {
          }
        } catch {
        } finally {
        }
      };

    useEffect(() => {
        getLocationsAll();
        getAllJobs();
        getAllPoleTypes();
        getAllCrews();
    }, []);

    useEffect(() => {
        if(fk_JobID > 0){
            getAllSubstations();
        }
    }, [fk_JobID]);

    useEffect(() => {
        if(substationIDs?.length > 0){
            getAllFeeders();
        }
    }, [substationIDs]);

    return (
        <>
        {/* <JobsDetail /> */}
            <div className="row">
                <div className="col-12">
                    <div className="card card-custom mb-5">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="mb-0">
                                    <img src="/media/svg/custom/report.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Reports
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body pt-3">
                            <div role="alert" className="fade alert bg-light-primary show font-weight-bold mb-5">
                                <i className="fa fa-info-circle mr-4 text-primary"></i>
                                Fill out the search filters to view report.
                            </div>
                            <div className="row">
                                
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FromDateReport">From Date</label>
                                        <input type="date" className="form-control" id="FromDateReport" name="FromDateReport" value={fromDate} onChange={changeFromDate} />
                                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fromDate}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="ToDateReport">To Date</label>
                                        <input type="date" className="form-control" id="ToDateReport" name="ToDateReport" value={toDate} onChange={changeToDate} />
                                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.toDate}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="partNumber">Part Number</label>
                                        <input type="text" className="form-control" id="partNumber" name="partNumber" value={partNumber} onChange={changePartNumber} />
                                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.partNumber}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="fk_FromLocationID">
                                      From Location
                                    </label>

                                    <select
                                      className="form-control"
                                      id="fk_FromLocationID"
                                      name="fk_FromLocationID"
                                      value={fk_FromLocationID}
                                      onChange={changeFromLocationID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        locationsAll?.map(({ id, locationName }) => (
                                          <option value={id}>{locationName}</option>
                                        ))
                                      }
                                    </select>
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_FromLocationID}
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="fk_ToLocationID">
                                      To Location
                                    </label>

                                    <select
                                      className="form-control"
                                      id="fk_ToLocationID"
                                      name="fk_ToLocationID"
                                      value={fk_ToLocationID}
                                      onChange={changeToLocationID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        locationsAll?.map(({ id, locationName }) => (
                                          <option value={id}>{locationName}</option>
                                        ))
                                      }
                                    </select>
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_ToLocationID}
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="fk_TransferTypeID">
                                      Transfer Type
                                    </label>

                                    <select
                                      className="form-control"
                                      id="fk_TransferTypeID"
                                      name="fk_TransferTypeID"
                                      value={fk_TransferTypeID}
                                      onChange={changeTransferTypeID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                    <option value={TransferTypes.REC.id}>{TransferTypes.REC.name}</option>
                                    <option value={TransferTypes.TTT.id}>{TransferTypes.TTT.name}</option>
                                    <option value={TransferTypes.BTW.id}>{TransferTypes.BTW.name}</option>
                                    <option value={TransferTypes.JOB.id}>{TransferTypes.JOB.name}</option>
                                    <option value={TransferTypes.MA.id}>{TransferTypes.REC.name}</option>
                                    </select>
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_TransferTypeID}
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="fk_JobID">
                                      Job
                                    </label>

                                    <select
                                      className="form-control"
                                      id="fk_JobID"
                                      name="fk_JobID"
                                      value={fk_JobID}
                                      onChange={changeJobID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        jobsList?.map(({ id, jobName }) => (
                                          <option value={id}>{jobName}</option>
                                        ))
                                      }
                                    </select>
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_JobID}
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="PoleType">
                                         Pole Type
                                        </label>
                                        <Select
                                          isMulti="true"
                                          value={poleTypeIDs}
                                          onChange={setPoleTypeIDs}
                                          hideSelectedOptions={true}
                                          placeholder={"Select Pole Types"}
                                          options={poleTypes}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="Crews">
                                         Crew
                                        </label>
                                        <Select
                                          isMulti="true"
                                          value={crewIDs}
                                          onChange={setCrewIDs}
                                          hideSelectedOptions={true}
                                          placeholder={"Select Crews"}
                                          options={crews}
                                        />
                                    </div>
                                </div>

                                {fk_JobID > 0 &&
                                    <>
                                    <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="Substations">
                                         Substation
                                        </label>
                                        <Select
                                          isMulti="true"
                                          value={substationIDs}
                                          onChange={setSubstationIDs}
                                          hideSelectedOptions={true}
                                          placeholder={"Select Substations"}
                                          options={substations}
                                        />
                                    </div>
                                </div>

                                    <div className="col-lg-4">
                                    <div className="form-group">
                                    <label className="form-label" htmlFor="Feeders">
                                         Feeder
                                        </label>
                                        <Select
                                          isMulti="true"
                                          value={feederIDs}
                                          onChange={setFeederIDs}
                                          hideSelectedOptions={true}
                                          placeholder={"Select Feeders"}
                                          options={feeders}
                                        />
                                    </div>
                                    </div>
                                    </>
                                }
                               
                               
                                <div className="col-lg-4 my-auto">
                                    <button disabled={jobDetails?.ID <= 0 || !formik.isValid || formik.isSubmitting || reportsLoading} className="btn btn-primary btn-md mt-4" onClick={() => {setFilteredValues(null); formik.handleSubmit();}}>
                                        <span>Search Result</span>
                                        {reportsLoading && <span className="ml-2 pl-3 spinner spinner-white"></span>}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {
                !reportsLoading && filteredValues == null &&
                <div className="row">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Search Results will appear below!
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No results
                        </div>
                    </div>
                </div>
            </div>
            }
             {
                reportsLoading &&
                <div className="row">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Searching Results
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               searching...
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                (!reportsLoading && filteredValues != null)  &&
                <>
                {
                filteredValues?.length > 0 ?
                <ReportsListing
                    filteredValues={filteredValues}
                />
                :
                <>
                <div className="row">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Search Results
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No reports found!
                        </div>
                    </div>
                </div>
            </div>
                </>
                }
                </>
            }
           
         
        </>
    );
}

export default Reports;
