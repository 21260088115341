import React, { useState, useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getCrewsCall,
  upsertCrewCall,
  deleteCrewCall,
  getCrewDetailsByIdCall,
} from "../../services/managementServices";
import { getUnassignedUsersCall } from "../../services/userManagementService";
import { CustomLoader } from "../../components/common/CustomLoader";
import AddEditCrew from "./AddEditCrew";
import * as Common from "../../General/common";
import JobsDetail from "../JobsDetail";
import CheckPermission from "../../General/CheckPermission";
import { userActions, timeout_for_data_tables } from "../../constants/enums";
import CrewsListing from "./CrewsListing";
import moment from "moment";
export function Crews() {
  const [crewsLoading, setCrewsLoading] = useState(false);
  const [crews, setCrews] = useState([]);
  const [crewDetails, setCrewDetails] = useState(null);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // add crew states starts
  const [id, setId] = useState(0);
  const [createdBy, setCreatedBy] = useState("0");
  const [modifiedBy, setModifiedBy] = useState("0");
  const [crewName, setCrewName] = useState("");
  const [notes, setNotes] = useState("");

  const [crewUsers, setCrewUsers] = useState([]);

  const [usersList, setUsersList] = useState([]);

  /* BLURRED STATES START */

  const [crewNameBlurred, setCrewNameBlurred] = useState(false);

  /* BLURRED STATES END */

  const dispatch = useDispatch();

  // function which fetches the crews
  const getCrews = async () => {
    setCrewsLoading(true);
    try {
      const { data } = await getCrewsCall();
      //console.log("crewsres", data);
      if (data.success) {
        // success scenario
        setCrewsLoading(false);
        if (data.result.length > 0) {
          setCrews(data.result);
        }
      } else {
        setCrewsLoading(false);
      }
    } catch {
      setCrewsLoading(false);
    } finally {
      setCrewsLoading(false);
    }
  };

  // function which fetches the crewdetails
  const getCrewDetails = async (id) => {
    try {
      const { data } = await getCrewDetailsByIdCall(id);

      if (data.success) {
        // success scenario
        setCrewUsers(data.result.crewUser);
      } else {
      }
    } catch {
    } finally {
    }
  };

  // function which fetches the userslist
  const getUnassignedUsers = async () => {
    try {
      const { data } = await getUnassignedUsersCall();

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          setUsersList(data.result);
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  // fetch crews and userslist when component mounts
  useEffect(() => {
    getCrews();
    getUnassignedUsers();
  }, []);


  // add or edit crew function starts
  const addorEditCrew = async (crewData) => {
    setCrews([]);
    setAdding(true);
    try {
      //console.log("crewdata", crewData);
      let dataToPost = {
        cdata: {
          Crew: {
            Notes: crewData.crew.notes,
            CreatedBy: crewData.crew.createdBy,
            CreatedDate: moment.utc(moment()).format(),
            ModifiedBy: crewData.crew.modifiedBy,
            ModifiedDate: moment.utc(moment()).format(),
            ID: crewData.crew.id,
            CrewName: crewData.crew.crewName
          },
          CrewUser: crewData.crewUser
        }
      };
      const { data } = await upsertCrewCall(dataToPost);
      

      if (data.success) {
        // success scenario
        Common.showSuccessAlertAutoClose(
          crewDetails != null ? "Crew updated successfully!" : "Crew created successfully!",
          "success",
          "Success!",
          3000
        );
        handleCloseAddCrewsModal();
        setCrewDetails(null);
        getCrews();
        //window.location.reload(false);
      } else {
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch {
      Common.showSuccessAlertAutoClose(
        "some error occured while processing your request",
        "warning",
        "Failed!",
        3000
      );
    } finally {
      getCrews();
    }
    setAdding(false);
  };
  // add or edit crew function ends
  const handleDeleteCrew = (crewId) => {
    var msg = "You want to delete this Crew.";
    Common.showConfirmation(msg, "", deleteCrew, null, crewId)

};
  // delete crew function starts
  const deleteCrew = async (crewId) => {
    if (crewId > 0) {
      setCrews([]);
      setDeleting(true);
      try {
       
        const { data } = await deleteCrewCall(crewId);

        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            "Crew deleted successfully!",
            "success",
            "Success!",
            3000
          );
          //window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
        getCrews();
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
        getCrews();
      }
      setDeleting(false);
    }
  };
  // delete crew function ends

 
  // for Add Crew modal popup
  const [showAddcrewsModal, setShowAddCrewsModal] = useState(false);
  const handleCloseAddCrewsModal = () => setShowAddCrewsModal(false);
  const handleShowAddCrewsModal = () => setShowAddCrewsModal(true);

  //console.log("crews", crews);

  return (
    <>
      <JobsDetail />
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img
                    src="/media/svg/custom/team.svg"
                    width="30"
                    height="30"
                    className="mr-3"
                    alt="summary"
                  />
                  Crews List
                </h3>
              </div>
              <div className="card-toolbar">
                <CheckPermission
                  currentAction={userActions.Crews.Action_AddCrews}
                  yes={() => (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setCrewNameBlurred(false);
                        setCrewDetails(null);
                        setId(0);
                        setCreatedBy("0");
                        setModifiedBy("0");
                        setCrewName("");
                        setNotes("");
                        setCrewUsers([]);
                        handleShowAddCrewsModal();
                      }}
                    >
                      <i className="fa fa-plus mr-1"></i>Add Crew
                    </button>
                  )}
                  no={() => null}
                />
              </div>
            </div>
            {crewsLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !crewsLoading && !deleting && !adding && crews?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Crews
                </div>
              ) : (
                <CrewsListing
                  crews={crews}
                  handleDeleteCrew={handleDeleteCrew}
                  setCrewDetails={setCrewDetails}
                  getCrewDetails={getCrewDetails}
                  handleShowAddCrewsModal={handleShowAddCrewsModal}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add Crew Modal  */}

      <AddEditCrew
        showAddcrewsModal={showAddcrewsModal}
        setShowAddCrewsModal={setShowAddCrewsModal}
        handleCloseAddCrewsModal={handleCloseAddCrewsModal}
        handleShowAddCrewsModal={handleShowAddCrewsModal}
        crewDetails={crewDetails}
        setCrewDetails={setCrewDetails}
        addorEditCrew={addorEditCrew}
        usersList={usersList}
        setUsersList={setUsersList}
        crewUsers={crewUsers}
        setCrewUsers={setCrewUsers}
        id={id}
        setId={setId}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        modifiedBy={modifiedBy}
        setModifiedBy={setModifiedBy}
        crewName={crewName}
        setCrewName={setCrewName}
        notes={notes}
        setNotes={setNotes}
        adding={adding}
        crewNameBlurred={crewNameBlurred}
        setCrewNameBlurred={setCrewNameBlurred}
      />

      {/* <Modal
      show={showAddcrewsModal}
      onHide={handleCloseAddCrewsModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="py-3" closeButton>
        <Modal.Title>Add Crew</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="form-group">
              <label className="form-label" htmlFor="CrewName">Crew Name</label>
              <input type="text" className="form-control" placeholder="Crew name" id="CrewName" name="CrewName" required />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-label" htmlFor="crewNotes">Note</label>
              <textarea className="form-control" placeholder="Add notes" id="htmlFor" rows="3" spellcheck="false"></textarea>
            </div>
          </div>
        </div>
        <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
        <h5 className="mb-3">Crew Members</h5>
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="form-group">
              <label className="form-label" htmlFor="CrewMembers">Add users</label>
              <input type="hidden" id="HiddenUserRoleId" name="UserRole" value="" />
              <select className="form-control" id="CrewMembers" name="CrewMembers" required>
                <option value="">- Please Select -</option>
                <option value="">User Name</option>
                <option value="">User Name 2</option>
              </select>
            </div>
          </div>
        </div>
        <table id="createCrewList" className="table table-bordered table-hover table-striped table-sm w-100">
          <thead>
            <tr>
              <th><i className="fa fa-number mr-1"></i> User ID</th>
              <th><i className="fa fa-user mr-1"></i> User Name</th>
              <th><i className="fa fa-user-plus mr-1"></i> Crew lead</th>
            </tr>
          </thead>
          <tbody>
            {
              new Array(10).fill(0).map(index => {
                return <tr>
                  <td>12586</td>
                  <td>Job Name </td>
                  <td align="center">
                    <label className="checkbox checkbox-single">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="py-3">
        <Button variant="secondary" size="sm" onClick={handleCloseAddCrewsModal}>
          <i className="fa fa-times mr-1"></i> Cancel
        </Button>
        <Button variant="primary" size="sm"><i className="fa fa-save mr-1"></i> Save</Button>
      </Modal.Footer>
    </Modal> */}
    </>
  );
}

export default Crews;
