import React, { useState, useEffect, useCallback } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { getAllAssignedJobs, getAllJobsCall } from "../../services/jobServices";
import { getCrewsCall } from "../../services/managementServices";
import {
  getAllActiveUsersCall,
  getAllUsersCall,
  upsertUser,
  deleteUser,
} from "../../services/userManagementService";
import $ from "jquery";
import * as Common from "../../General/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JobsDetail from "../../pages/JobsDetail";
import { useLocation } from "react-router-dom";
import { add, min } from "lodash";
import CheckPermission from "../../General/CheckPermission";
import { timeout_for_data_tables, userActions } from "../../constants/enums";
import { shallowEqual, useSelector } from "react-redux";
import { userRoles } from '../../constants/enums'
import UsersPageListing from "./UsersPageListing";
import { CustomLoader } from "../../components/common/CustomLoader";
const animatedComponents = makeAnimated();

export function UsersPage() {
  const location = useLocation();
  const [jobsLoading, setJobsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const jobDetails = useSelector((store) => store.JobManagement.jobDetails);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [usersLoading, setUsersLoading] = useState(false);


  /* CHECK IF FIELD IS BLURRED STATES START */

  const [userfirstNameBlurred, setuserfirstNameBlurred] = useState(false);
  const [userlastNameBlurred, setuserlastNameBlurred] = useState(false);
  const [useractiveBlurred, setuseractiveBlurred] = useState(false);
  const [useremailBlurred, setuseremailBlurred] = useState(false);
  const [userphoneNumberBlurred, setuserphoneNumberBlurred] = useState(false);
  const [userpasswordBlurred, setuserpasswordBlurred] = useState(false);
  const [userconfirmpasswordBlurred, setuserconfirmpasswordBlurred] = useState(false);
  const [userfk_RoleIdBlurred, setuserfk_RoleIdBlurred] = useState(false);

  /* CHECK IF FIELD IS BLURRED STATES END */


  //  ******************** //
  //  Getting ALl job List //
  //  ******************** //
  const [jobsList, setjobsList] = useState([]);
  const [userjobIDs, setuserjobIDs] = useState([]);
  const getAllJobs = async (userId = null) => {
    try {
       const { data } = await getAllAssignedJobs(userId);
      //const { data } = await getAllJobsCall(userId);
      //console.log("datacheck", data);
      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          //  setjobsList(
          //    data.result.map((job) => ({ value: job.ID, label: job.JobName }))
          //  );
          var unAssingJob = [];
          var assingJob = [];
          //console.log("jobsdata", data.result);
          data.result.map((job) => {
            if (job.fk_UserID != null) {
              assingJob.push({ value: job.ID, label: job.JobName });
              unAssingJob.push({ value: job.ID, label: job.JobName });
            } else {
              unAssingJob.push({ value: job.ID, label: job.JobName });
            }
          });
          //console.log("unassignedjobs", unAssingJob);
          //console.log("assignedjobs", assingJob);
          setjobsList(unAssingJob);
          setuserjobIDs(assingJob);

          // //console.log(data.result, "all job list");
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  
  //console.log("jobslist", jobsList);
  //console.log("userjobids", userjobIDs);

  //  ******************** //
  //  Getting All Crew List //
  //  ******************** //
  // const [crewList, setcrewList] = useState([]);
  // const getAllCrewList = async () => {
  //   try {
  //     const { data } = await getCrewsCall();

  //     if (data.success) {
  //       if (data.result.length > 0) {
  //         await setcrewList(data.result);
  //         //console.log(data.result, "all Cre list");
  //       }
  //     } else {
  //     }
  //   } catch {
  //   } finally {
  //   }
  // };
  //  *************************** //
  //  Getting All User ActiveROle //
  //  *************************** //
  const [activeUserRole, setactiveUserRole] = useState([]);
  const getAllActiveUsers = async () => {
    try {
      const { data } = await getAllActiveUsersCall();

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          await setactiveUserRole(data.result);
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
    }
  };
  //  ******************** //
  //  Getting All User //
  //  ******************** //
  const [allUsers, setallUsers] = useState([]);
  const getAllUsers = async () => {
    setUsersLoading(true);
    try {
      const { data } = await getAllUsersCall();

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          await setallUsers(data.result);

          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
      setUsersLoading(false);
    }
  };
  //  ************************* //
  //  Post request for add user //
  //  ************************* //
  // const [firstNameErr, setfirstNameErr] = useState("")
  // const userFormValidation = ()=>{
  //   if(userfirstName.trim().length < 0){
  //     setfirstNameErr("this is testing")

  //   }

  // }
  const [IsEdit, setIsEdit] = useState(false);
  const [userfirstName, setuserfirstName] = useState("");
  const [userlastName, setuserlastName] = useState("");
  const [useractive, setuseractive] = useState(false);
  const [useremail, setuseremail] = useState("");
  const [userphoneNumber, setuserphoneNumber] = useState("");
  const [userpassword, setuserpassword] = useState("");
  const [userconfirmpassword, setuserconfirmpassword] = useState("");
  // const [usercrewName, setusercrewName] = useState("");
  const [userfk_RoleId, setuserfk_RoleId] = useState("");
  const [userIds, setuserIds] = useState("");
  

  const [userRoleIdToEdit, setUserRoleIdToEdit] = useState("");

  const phoneNumberRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  // formik setup starts

  useEffect(() => {
    formik.setValues({
      firstName: userfirstName,
      lastName: userlastName,
      active: useractive,
      email: useremail,
      phone: userphoneNumber,
      password: userpassword,
      confirmpassword: userconfirmpassword,
      fk_RoleId: userfk_RoleId,
      jobIDs: userjobIDs,
    });
  }, [
    userfirstName,
    userlastName,
    useractive,
    useremail,
    userphoneNumber,
    userfk_RoleId,
    userjobIDs,
    userpassword,
    userconfirmpassword
  ]);

  const Schema = Yup.object().shape({
    firstName: Yup.string()
      .max(45, "Maximum limit (45 characters)")
      .typeError("Invalid Value")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(45, "Maximum limit (45 characters)")
      .typeError("Invalid Value")
      .required("Last Name is required"),
    email: Yup.string()
      .max(255, "Maximum limit (255 characters)")
      .typeError("Invalid Value")
      .email("Email is not valid")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneNumberRegEx, "Phone number is not valid")
      .min(11, "11 digits required")
      .max(11, "11 digits required"),
    fk_RoleId: Yup.string()
      .required("User Role is required")
      .typeError("Invalid value"),
    password: Yup.string().when("ps", {
      is: ps => (
        (!IsEdit && userfk_RoleId != userRoles.Role_CrewMember) ||
        (IsEdit && userfk_RoleId != userRoles.Role_CrewMember && userRoleIdToEdit == userRoles.Role_CrewMember)
      ),
      then: Yup.string()
        .required("New Password is required")
        .min(8, "Minimum limit (8 characters)")
        .max(20, "Maximum limit (20 characters)")
        .typeError("Invalid Value"),
    }),
    confirmpassword: Yup.string().when("cps", {
      is: cps => ((!IsEdit && userfk_RoleId != userRoles.Role_CrewMember) || (IsEdit && userfk_RoleId != userRoles.Role_CrewMember && userpassword !== "")),
      then: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      firstName: userfirstName,
      lastName: userlastName,
      active: useractive,
      email: useremail,
      phone: userphoneNumber,
      password: userpassword,
      confirmpassword: userconfirmpassword,
      // crewName: usercrewName,
      fk_RoleId: userfk_RoleId,
      jobIDs: userjobIDs,
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  const handleValidSubmit = async (e) => {
    //e?.preventDefault();
    AddUser();

    const timeout = setTimeout(() => { }, 1000);
    return () => clearTimeout(timeout);
  };

  const AddUser = async () => {
    // setShow(false);

    let userData = {
      firstName: userfirstName,
      lastName: userlastName,
      active: useractive,
      email: useremail,
      phone: userphoneNumber,
      password: ((!IsEdit && userfk_RoleId != userRoles.Role_CrewMember) || (IsEdit && userfk_RoleId != userRoles.Role_CrewMember && userpassword !== "")) ? userpassword : null,
      // crewName: usercrewName,
      fk_RoleId: userfk_RoleId,
      jobIDs: [],
    };
    if (userjobIDs?.length > 0) {
      var jobIds = [];
      userjobIDs.map((job) => {
        jobIds.push(job.value);
      });
      userData.jobIDs = jobIds;
    }
    if (IsEdit === true) {
      userData["id"] = userIds;
    }
    setallUsers([]);
    setAdding(true);
    try {
      const { data, status } = await upsertUser(userData);
     
      if (data.success) {
        if(data.message !== 302){
        
        Common.showSuccessAlertAutoClose(
          IsEdit ? "User updated successfully!" : "User created successfully!",
          "success",
          "Success!",
          3000
        );
        getAllUsers();
        setShow(false);
        //window.location.reload(false);
        }else if(data.message === 302){
          Common.showSuccessAlertAutoClose(
            data.result,
            "warning",
            "Failed!",
            3000
          );
        }
      } else {
        
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch {
      
      Common.showSuccessAlertAutoClose(
        "some error occured while processing your request",
        "warning",
        "Failed!",
        3000
      );
    } finally {
      getAllUsers();
    }
    setAdding(false);
  };
  //  ************************* //
  //  Delete request for Delete user //
  //  ************************* //

  const deleteUserHandle = (jobId) => {
    var msg = "You want to delete this User.";
    Common.showConfirmation(msg, "", deleteUserApiCall, null, jobId);
  };
  const deleteUserApiCall = async (jobId) => {
    if (jobId > 0) {
      setallUsers([]);
      setDeleting(true);
      try {
        const { data } = await deleteUser(jobId);
        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            "User deleted successfully!",
            "success",
            "Success!",
            3000
          );
          getAllUsers();
          // window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
        // setSubstationsLoading(false);
        getAllUsers();
      }
      setDeleting(false);
    }
  };

  useEffect(() => {
    getAllJobs(user?.id);
    getAllActiveUsers();
    // getAllCrewList();
    getAllUsers();
  }, [user]);



  // for Add user modal popup
  const [show, setShow] = useState(false);
  const resetUserForm = () => {
    setUserRoleIdToEdit("");
    setuserfirstName("");
    setuserlastName("");
    setuseremail("");
    setuserphoneNumber("");
    setuserpassword("");
    setuserconfirmpassword("");
    // setusercrewName("");
    setuserfk_RoleId("");
    setuserIds("");
    setuserjobIDs("");
  };
  const handleClose = () => {
    setIsEdit(false);
    setShow(false);
    resetUserForm();
  };
  const handleShow = async (usersList) => {
    //console.log("userslistsss", usersList);
    setuserfk_RoleIdBlurred(false);
    setuserfirstNameBlurred(false);
    setuserlastNameBlurred(false);
    setuseremailBlurred(false);
    setuserpasswordBlurred(false);
    setuserconfirmpasswordBlurred(false);
    setuserphoneNumberBlurred(false);
    if (usersList.id > 0) {
      setIsEdit(true);
      setUserRoleIdToEdit(usersList.fk_RoleID);
      setuserfirstName(usersList.FirstName);
      setuserlastName(usersList.LastName);
      setuseractive(usersList.Active);
      setuseremail(usersList.email);
      setuserphoneNumber(usersList.Phone);
      setuserpassword("");
      setuserconfirmpassword("");
      // setusercrewName(usersList.crewName);
      setuserfk_RoleId(usersList.fk_RoleID);
      setuserIds(usersList.id);
      setuserjobIDs(usersList.JobIDs);
      await getAllJobs(usersList.id);
    } else {
      setIsEdit(false);
      setUserRoleIdToEdit("");
      await getAllJobs(0);
    }
    setShow(true);
    // //console.log(usersList);
  };

  // for Edit user modal popup
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleCloseEditUser = () => setShowEditUserModal(false);
  const handleShowEditUser = () => setShowEditUserModal(true);

  // for grant permission modal popup
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const handleClosePermissionModal = () => setShowPermissionModal(false);
  const handleShowPermissionModal = () => setShowPermissionModal(true);

  const userJobsSelect = useCallback(() => {
    // get user assigned items
    const items = jobsList.filter((job) =>
      userjobIDs?.some((userJob) => userJob == job.value)
    );
    return (
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        defaultValue={items}
        onChange={(e) => {
          setuserjobIDs(e.map((c) => c.value));
        }}
        options={jobsList}
      />
    );
  }, [jobsList, animatedComponents]);

  //console.log("fomriktouched", formik.touched.fk_RoleId);
  //console.log("formikerror", formik.errors.fk_RoleId);

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="mb-0">
              <img
                src="/media/svg/custom/team.svg"
                width="30"
                height="30"
                className="mr-3"
                alt="summary"
              />
              Users List
            </h3>
          </div>
          <div className="card-toolbar">
            <div className="example-tools">
              <CheckPermission
                currentAction={userActions.userManagment.Action_AddUser}
                yes={() => (
                  <button
                    onClick={handleShow}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    <i className="fa fa-plus"></i> New User
                  </button>
                )}
                no={() => null}
              />
            </div>
          </div>
        </div>
        {usersLoading || deleting || adding ? (
          <div className="d-flex justify-content-center p-10">
            <CustomLoader />
          </div>
        ) : !usersLoading && !deleting && !adding && allUsers?.length === 0 ? (
          <div className="d-flex justify-content-center p-10">
            No Users
          </div>
        ) : (
          <UsersPageListing
            allUsers={allUsers}
            deleteUserHandle={deleteUserHandle}
            handleShow={handleShow}
          />
        )
        }
      </div>

      {/* ads user modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>{IsEdit ? "Edit User" : "Add User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form autoComplete="off">
            {/* disable auto form filling code starts */}
            <input type="text" autoComplete="false" style={{ display: "none" }} />
            <input type="password" autoComplete="new-password" style={{ display: "none" }} />
            {/* disable auto form filling code ends */}
          <div className="row">
            <div className={IsEdit ? "col-lg-6" : "col-lg-12"}>
              <div className="form-group">
                <label className="form-label" htmlFor="UserRole">
                  User Role <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control"
                  id="UserRole"
                  name="fk_RoleId"
                  onChange={(e) => {
                    setuserfk_RoleId(e.target.value);
                  }}
                  onBlur={() => setuserfk_RoleIdBlurred(true)}
                  defaultValue={userfk_RoleId}
                >
                  <option value="">- Please Select -</option>
                  {activeUserRole?.map((role) => {
                    //if (role.ID != userRoles.Role_SuperAdmin)
                      return (
                        <option value={role.ID} key={role.ID}>
                          {role.Name}
                        </option>
                      );
                  })}
                </select>
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {userfk_RoleIdBlurred && formik.errors.fk_RoleId}
                </div>
              </div>
            </div>
            {IsEdit && (
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="checkbox checkbox-single mt-10">
                    <input
                      type="checkbox"
                      id="UserIsActive"
                      name="active"
                      checked={IsEdit == true ? (useractive == "0" ? false : true) : true}
                      onChange={(e) => {
                        setuseractive(e.target.checked);
                      }}
                    />
                    <label className="mr-5 mb-0" htmlFor="Active">
                      Is Active?
                    </label>
                    <span></span>
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="firstName">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  id="firstName"
                  name="firstName"
                  value={userfirstName}
                  onChange={(e) => {
                    setuserfirstName(e.target.value);
                  }}
                  onBlur={() => setuserfirstNameBlurred(true)}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {userfirstNameBlurred && formik.errors.firstName}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="lastName">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  value={userlastName}
                  onChange={(e) => {
                    setuserlastName(e.target.value);
                  }}
                  onBlur={() => setuserlastNameBlurred(true)}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {userlastNameBlurred && formik.errors.lastName}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="Email">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="Email"
                  name="email"
                  disabled={IsEdit === true ? true : false}
                  value={useremail}
                  onChange={(e) => {
                    setuseremail(e.target.value);
                  }}
                  onBlur={() => setuseremailBlurred(true)}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {useremailBlurred && formik.errors.email}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="MobileNo">
                  Mobile Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  id="PrimaryPhoneNumber"
                  name="phone"
                  im-insert="true"
                  data-inputmask="'mask': '(999) 999-9999'"
                  min="10"
                  max="10"
                  value={userphoneNumber}
                  onChange={(e) => {
                    setuserphoneNumber(e.target.value);
                  }}
                  onBlur={() => setuserphoneNumberBlurred(true)}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {userphoneNumberBlurred && formik.errors.phone}
                </div>
              </div>
            </div>



            {userfk_RoleId != userRoles.Role_CrewMember &&

              <>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Password">
                      Password {(!IsEdit || (IsEdit && userRoleIdToEdit == userRoles.Role_CrewMember)) && <span className="text-danger">*</span>}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      id="Password"
                      name="password"
                      value={userpassword}
                      onChange={(e) => {
                        debugger;
                        setuserpassword(e.target.value);
                      }}
                      onBlur={() => setuserpasswordBlurred(true)}
                    />
                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                      {userpasswordBlurred && formik.errors.password}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="ConfirmPassword">
                      Confirm Password {((!IsEdit) || (IsEdit && userpassword !== "")) && <span className="text-danger">*</span>}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      id="ConfirmPassword"
                      name="confirmpassword"
                      value={userconfirmpassword}
                      onChange={(e) => {
                        setuserconfirmpassword(e.target.value);
                      }}
                      onBlur={() => setuserconfirmpasswordBlurred(true)}
                    />
                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                      {userconfirmpasswordBlurred && formik.errors.confirmpassword}
                    </div>
                  </div>
                </div>
              </>
            }


            <div className="col-12" style={{ display: (userfk_RoleId == userRoles.Role_SuperAdmin || userfk_RoleId == userRoles.Role_Admin) ? "none" : "" }}>
              <div className="form-group">
                <label className="form-label" htmlFor="UserRole">
                  Job Visibility
                </label>
                <Select
                  isMulti="true"
                  value={userjobIDs}
                  onChange={setuserjobIDs}
                  hideSelectedOptions={true}
                  placeholder={"Select job visibility"}
                  options={jobsList}
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="crewName">
                  Crew Name
                </label>

                <select
                  className="form-control"
                  id="crewName"
                  name="crewName"
                  onChange={(e) => {
                    setusercrewName(e.target.value);
                  }}
                  defaultValue={usercrewName}
                >
                  <option>- Please Select -</option>

                  {crewList?.map((crewList) => {
                    return (
                      <option value={crewList.id} key={crewList.id}>
                        {crewList.crewName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div> */}
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || formik.isSubmitting || adding}
          >
            <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
              <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i> Save</span>
              {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal popu */}
      {/* 
      <Modal
        show={showEditUserModal}
        onHide={handleCloseEditUser}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="UserRole">
                  User Role
                </label>
                <input
                  type="hidden"
                  id="HiddenUserRoleId"
                  name="UserRole"
                  value=""
                />
                <select
                  className="form-control"
                  id="UserRole"
                  name="UserRole"
                  required
                >
                  <option value="">- Please Select -</option>
                  <option value="">Admin</option>
                  <option value="">User</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <div className="custom-control custom-checkbox mt-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Active"
                    name="Active"
                    value="true"
                    checked
                  />
                  <label className="custom-control-label" htmlFor="Active">
                    Is Active?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="FirstName">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  id="FirstName"
                  name="FirstName"
                  required
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="LastName">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  id="LastName"
                  required
                  name="LastName"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="Email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="Email"
                  name="Email"
                  required
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="MobileNo">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  id="PrimaryPhoneNumber"
                  name="PrimaryPhoneNumber"
                  im-insert="true"
                  data-inputmask="'mask': '(999) 999-9999'"
                  required
                  min="10"
                  max="10"
                />
              </div>
            </div>
          </div>
          <h4 className="my-3"> Permissions </h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-control custom-switch pl-0 mb-2">
                <span className="mr-23">Can this user access reports?</span>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="accessReports"
                />
                <label
                  className="custom-control-label"
                  htmlFor="accessReports"
                ></label>
              </div>
              <div className="custom-control custom-switch pl-0">
                <span className="mr-15">
                  Give access to all future projects?
                </span>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="futureProjectAccess"
                />
                <label
                  className="custom-control-label"
                  htmlFor="futureProjectAccess"
                ></label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-between">
                <div>
                  <h5>Jobs</h5>
                </div>
                <div>
                  <span className="mr-10">Jobs Access: 5</span>
                  <button
                    onClick={handleShowPermissionModal}
                    className="btn btn-secondary btn-xs btn-icon"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleCloseEditUser}>
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* For granting job access/Permissions */}

      <Modal
        show={showPermissionModal}
        onHide={handleClosePermissionModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            id="permissionGrantList"
            className="table table-bordered table-hover table-striped table-sm w-100"
          >
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Job Name</th>
                <th>Give Access</th>
              </tr>
            </thead>
            <tbody>
              {/* {new Array(10).fill(0).map((index) => {
                return (
                  <tr>
                    <td>12586</td>
                    <td>Job Name </td>
                    <td align="center">
                      <label className="checkbox checkbox-single">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          </table>
          <div className="frame-wrap">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                className="custom-control-input"
                id="defaultInline1Radio"
                name="inlineDefaultRadiosExample"
              />
              <label className="custom-control-label" for="defaultInline1Radio">
                Add to all Jobs
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                className="custom-control-input"
                id="defaultInline2Radio"
                name="inlineDefaultRadiosExample"
              />
              <label className="custom-control-label" for="defaultInline2Radio">
                Remove from all Jobs
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClosePermissionModal}
          >
            Close
          </Button>
          <Button variant="primary" size="sm">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UsersPage;
