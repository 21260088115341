import React, { useEffect, useState } from "react";
import JobDetailModal from "./JobDetailModal";
import { Button, ButtonGroup } from "react-bootstrap";
import JobDetailPoleCompletionModal from "./JobDetailPoleCompletionModal";
import { TransferTypes } from "../../constants/enums";
import InventoryTransferModal from "./InventoryTransferModal";
function ButtonGroupComponent({
  getInventoryTransactions, 
  getStats, 
  setInventoryTransactions, 
  setAdding,
  isEdit,
  setIsEdit,
  transactionDetail,
  setTransactionDetail,
  showInventoryModal,
  setShowInventoryModal
}) {
  const [jobDetailModel, showJobDetailModel] = useState(undefined);
  const [inventoryTransferModel, setInventoryTransferModel] = useState(undefined);
  const [jobPoleCompletionModel, setjobPoleCompletionModel] = useState( undefined );
  const handleCloseJobDetailModel = () => showJobDetailModel(undefined);
  const handleCloseInventoryTransferModel = () => setInventoryTransferModel(undefined);
  const handleCloseJobPoleCompletionModal = () => setjobPoleCompletionModel(undefined);
  const handleshowJobDetailModel = ({ TransferType }) =>  showJobDetailModel( <JobDetailModal TransferType={TransferType} closeCallback={handleCloseJobDetailModel}  getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} />);
  const handleshowJobPoleCompletionModel = ({ TransferType }) => setjobPoleCompletionModel( <JobDetailPoleCompletionModal TransferType={TransferType} closeCallbackPoleCompletion={handleCloseJobPoleCompletionModal}  getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} /> );
  const handleshowInventoryTransferModel = () =>  setInventoryTransferModel( <InventoryTransferModal closeCallback={handleCloseInventoryTransferModel} getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} isEdit={isEdit} setIsEdit={setIsEdit} transactionDetail={transactionDetail} setTransactionDetail={setTransactionDetail} showInventoryModal={showInventoryModal} setShowInventoryModal={setShowInventoryModal} />);


  useEffect(() => {
    if(showInventoryModal){
      handleshowInventoryTransferModel();
    }
  }, [showInventoryModal]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-end flex-wrap btn-group-custom">
        {/* <Button className="m-1" onClick={() => handleshowJobDetailModel({ TransferType: TransferTypes.REC })}><i className="fa fa-sign-in-alt mr-1"></i>Receive</Button>
        <Button className="m-1" onClick={() => handleshowJobDetailModel({ TransferType: TransferTypes.TTT })}><i className="fa fa-shipping-fast mr-1"></i>To the truck</Button>
        <Button className="m-1" onClick={() => handleshowJobDetailModel({ TransferType: TransferTypes.BTW })}><i className="fa fa-warehouse mr-1"></i>Back to warehouse</Button>
        <Button className="m-1" onClick={() => handleshowJobPoleCompletionModel({ TransferType: TransferTypes.JOB })}><i className="fa fa-check-double mr-1"></i>Pole Completion</Button>
        <Button className="m-2" onClick={() => handleshowJobDetailModel({ TransferType: TransferTypes.MA })}><i className="fa fa-edit mr-1"></i>Manual Adjustments</Button> */}
        <Button className="" onClick={() => { setIsEdit(false); setTransactionDetail(null); handleshowInventoryTransferModel(); } }><i className="fa fa-shipping-fast mr-1"></i>Inventory Transfer</Button>
      </div>
      {jobDetailModel}
      {jobPoleCompletionModel}
      {inventoryTransferModel}
    </>
  );
}

export default ButtonGroupComponent;
