import * as API from "../General/api";

//job calls starts
export function getAllJobsCall(Id) {
  return API.getRequest(API.apiUrls.jobManagement.job.getAllJobs+ "?UserId=" + Id);
}
export function getAllAssignedJobs(userId) {
    if(userId >= 0){
        return API.getRequest(
          API.apiUrls.jobManagement.job.getAllassignedJobs + "?fk_UserID=" + userId
        );
    }else{
        return API.getRequest(
          API.apiUrls.jobManagement.job.getAllassignedJobs
        );
    }
}
export function upsertJob(job) {
  return API.postRequest(API.apiUrls.jobManagement.job.upsertJob, job);
}
export function deleteJob(jobId) {
  return API.postDeleteRequest(API.apiUrls.jobManagement.job.deleteJob, jobId);
}
export function assignJobToUserCall(job) {
  return API.postRequest(API.apiUrls.jobManagement.job.assignJobToUser, job);
}
export function removeUserFromJobCall(job) {
  return API.postRequest(API.apiUrls.jobManagement.job.removeUserFromJob, job);
}
//job calls end
