export const Locations = Object.freeze({
  Source: Object.freeze({ name: "Source", id: 1 }),
  Warehouse: Object.freeze({ name: "Warehouse ", id: 2 }),
  TruckORCrew: Object.freeze({ name: "Truck/Crew", id: 3 }),
  Pole: Object.freeze({ name: "Pole", id: 4 }),
});

export const TransferTypes = Object.freeze({
  REC: Object.freeze({
    name: "Receive to Warehouse",
    id: 1,
    location: Object.freeze({
      from: [Locations.Source],
      to: [Locations.Warehouse],
    }),
  }),
  TTT: Object.freeze({
    name: "To the Truck ",
    id: 2,
    location: Object.freeze({
      from: [Locations.Warehouse],
      to: [Locations.TruckORCrew],
    }),
  }),
  BTW: Object.freeze({
    name: "Truck back to Warehouse",
    id: 3,
    location: Object.freeze({
      from: [Locations.TruckORCrew],
      to: [Locations.Warehouse],
    }),
  }),
  JOB: Object.freeze({
    name: "Pole Completion",
    id: 4,
    location: Object.freeze({
      from: [Locations.TruckORCrew],
      to: [Locations.Pole],
    }),
  }),
  MA: Object.freeze({
    name: "Manual Adjustments",
    id: 5,
    location: Object.freeze({
      from: [
        Locations.Source,
        Locations.Warehouse,
        Locations.TruckORCrew,
        Locations.Pole,
      ],
      to: [
        Locations.Source,
        Locations.Warehouse,
        Locations.TruckORCrew,
        Locations.Pole,
      ],
    }),
  }),
});
export const TransferTypesObject = Object.freeze({
  REC: Object.freeze({
    name: "Receive to Warehouse",
    abbrev: "REC",
    id: 1,
  }),
  TCREW: Object.freeze({
    name: "Transfer to Crew",
    abbrev: "TCREW",
    id: 2,
  }),
  FCREW: Object.freeze({
    name: "Transfer from Crew",
    abbrev: "FCREW",
    id: 3,
  }),
  XFER: Object.freeze({
    name: "Transfer to Feeder",
    abbrev: "XFER",
    id: 4,
  }),
  FXFER: Object.freeze({
    name: "Transfer from Feeder",
    abbrev: "FXFER",
    id: 5,
  }),
  WTCREW: Object.freeze({
    name: "Warehouse to Crew",
    abbrev: "WTCREW",
    id: 6,
  }),
  TWFCREW: Object.freeze({
    name: "Crew to Warehouse",
    abbrev: "TWFCREW",
    id: 7,
  }),
});
export const TransferReportDropdown = [
  Object.freeze({
    abbrev: "TCREW",
    name: "Transfer to Crew",
    id: 2,
  }),
  Object.freeze({
    abbrev: "XFER",
    name: "Transfer to Feeder",
    id: 4,
  }),
  Object.freeze({
    abbrev: "WTCREW",
    name: "Warehouse to Crew",
    id: 6,
  }),
];
export const TransferTypesArray = [
  Object.freeze({
    abbrev: "REC",
    name: "Receive to Warehouse",
    id: 1,
  }),
  Object.freeze({
    abbrev: "TCREW",
    name: "Transfer to Crew",
    id: 2,
  }),
  Object.freeze({
    abbrev: "FCREW",
    name: "Transfer from Crew",
    id: 3,
  }),
  Object.freeze({
    abbrev: "XFER",
    name: "Transfer to Feeder",
    id: 4,
  }),
  Object.freeze({
    abbrev: "FXFER",
    name: "Transfer from Feeder",
    id: 5,
  }),
  Object.freeze({
    abbrev: "WTCREW",
    name: "Warehouse to Crew",
    id: 6,
  }),
  Object.freeze({
    abbrev: "TWFCREW",
    name: "Crew to Warehouse",
    id: 7,
  }),
];
export const TransferTypesArrayFiltered = [
  Object.freeze({
    abbrev: "REC",
    name: "Receive to Warehouse",
    id: 1,
  }),
  Object.freeze({
    abbrev: "TCREW",
    name: "Transfer to Crew",
    id: 2,
  }),
  // Object.freeze({
  //   abbrev: "FCREW",
  //   name: "Transfer from Crew",
  //   id: 3,
  // }),
  Object.freeze({
    abbrev: "XFER",
    name: "Transfer to Feeder",
    id: 4,
  }),
  // Object.freeze({
  //   abbrev: "FXFER",
  //   name: "Transfer from Feeder",
  //   id: 5,
  // }),
  Object.freeze({
    abbrev: "WTCREW",
    name: "Warehouse to Crew",
    id: 6,
  }),
  // Object.freeze({
  //   abbrev: "TWFCREW",
  //   name: "Crew to Warehouse",
  //   id: 7,
  // }),
];
export const userRolesWithAction = {
  SuperAdmin: [
    { actionName: "ViewInventory" },
    { actionName: "EditInventory" },
    { actionName: "ViewPole" },
    { actionName: "EditPole" },
    { actionName: "DeletePole" },
    { actionName: "AddPole" },
    { actionName: "ViewParts" },
    { actionName: "EditParts" },
    { actionName: "ViewJobs" },
    { actionName: "EditJobs" },
    { actionName: "AddJobs" },
    { actionName: "ViewCrews" },
    { actionName: "EditCrews" },
    { actionName: "AddCrews" },
    { actionName: "DeleteCrews" },
    { actionName: "ViewUserRole" },
    { actionName: "EditUserRole" },
    { actionName: "ViewReports" },
    { actionName: "EditReports" },
    { actionName: "DeleteParts" },
    { actionName: "AddParts" },
    { actionName: "DeleteJobs" },
    { actionName: "ViewCategory" },
    { actionName: "AddCategory" },
    { actionName: "EditCategory" },
    { actionName: "DeleteCategory" }, 
    { actionName: "ViewSubstation" },
    { actionName: "EditSubstation" },
    { actionName: "DeleteSubstation" },
    { actionName: "AddSubstation" },
    { actionName: "AddUser" },
    { actionName: "ViewUser" },
    { actionName: "EditUser" },
    { actionName: "DeleteUser" },
    { actionName: "JobUserActions" },
    { actionName: "ViewManagement" },
  ],
  Admin: [
    { actionName: "ViewInventory" },
    { actionName: "EditInventory" },
    { actionName: "ViewPole" },
    { actionName: "EditPole" },
    { actionName: "DeletePole" },
    { actionName: "AddPole" },
    { actionName: "ViewParts" },
    { actionName: "EditParts" },
    { actionName: "ViewJobs" },
    { actionName: "EditJobs" },
    { actionName: "AddJobs" },
    { actionName: "ViewCrews" },
    { actionName: "EditCrews" },
    { actionName: "AddCrews" },
    { actionName: "DeleteCrews" },
    { actionName: "ViewUserRole" },
    { actionName: "EditUserRole" },
    { actionName: "ViewReports" },
    { actionName: "EditReports" },
    { actionName: "DeleteParts" },
    { actionName: "AddParts" },
    { actionName: "DeleteJobs" },
    { actionName: "ViewCategory" },
    { actionName: "AddCategory" },
    { actionName: "EditCategory" },
    { actionName: "DeleteCategory" }, 
    { actionName: "ViewSubstation" },
    { actionName: "EditSubstation" },
    { actionName: "DeleteSubstation" },
    { actionName: "AddSubstation" },
    { actionName: "JobUserActions" },
    { actionName: "ViewManagement" },
  ],
  CrewMember: [
    { actionName: "ViewInventory" },
    { actionName: "MarkJobsCompleted" },
    { actionName: "AddPole" },
    { actionName: "ViewPendingJob" },
    { actionName: "InventoryTransfer" },
    { actionName: "UpdateInventory" },
    { actionName: "ViewJobs" },
  ],
  CrewLead: [
    { actionName: "ViewInventory" },
    { actionName: "MarkJobsCompleted" },
    { actionName: "AddPole" },
    { actionName: "ViewPendingJob" },
    { actionName: "InventoryTransfer" },
    { actionName: "UpdateInventory" },
    { actionName: "ViewJobs" },
  ],
};
export const userRoles = {
  Role_SuperAdmin: 1,
  Role_Admin: 2,
  Role_CrewMember: 3,
  Role_CrewLead: 4
};
export const userActions = {
  Pole: {
    Action_ViewPole: "ViewPole",
    Action_EditPole: "EditPole",
    Action_AddPole: "AddPole",
    Action_DeletePole: "DeletePole",
  },
  Parts: {
    Action_ViewParts: "ViewParts",
    Action_AddParts: "AddParts",
    Action_EditParts: "EditParts",
    Action_DeleteParts: "DeleteParts",
  },
  CategoriesList: {
    Action_ViewCategory: "ViewCategory",
    Action_AddCategory: "AddCategory",
    Action_EditCategory: "EditCategory",
    Action_DeleteCategory: "DeleteCategory",
  },
  Crews: {
    Action_ViewCrews: "ViewCrews",
    Action_AddCrews: "AddCrews",
    Action_EditCrews: "EditCrews",
    Action_DeleteCrews: "DeleteCrews",
  },
  UserRole: {
    Action_ViewUserRole: "ViewUserRole",
    Action_EditUserRole: "EditUserRole",
  },
  Reports: {
    Action_ViewReports: "ViewReports",
  },
  Jobs: {
    Action_ViewJobs: "ViewJobs",
    Action_AddJobs: "AddJobs",
    Action_EditJobs: "EditJobs",
    Action_DeleteJobs: "DeleteJobs",
    Action_MarkJobsCompleted: "MarkJobsCompleted",
    Action_ViewPendingJob: "ViewPendingJob",
  },
  Inventory: {
    Action_InventoryTransfer: "InventoryTransfer",
    Action_UpdateInventory: "UpdateInventory",
    Action_ViewInventory: "ViewInventory",
    Action_EditInventory: "EditInventory",
  },
  userManagment: {
    Action_ViewManagement: "ViewManagement",
    Action_AddUser: "AddUser",
    Action_JobUserActions: "JobUserActions",
    Action_ViewUser: "ViewUser",
    Action_EditUser: "EditUser",
    Action_DeleteUser: "DeleteUser",
  },
};
export const transferType_Location_From = {
  Source: 1,
  Warehouse: 2,
  Truck_Crew: 3,
  Pole: 4
};

export const timeout_for_data_tables = 3000; 

export const report_For = {
  Inventory : 1,
  Jobs : 2,
  Customers : 3,
  Feeders : 4,
  Substations : 5,
  Crews : 6,
  Parts : 7,
  Poles : 8,
};

export const no_of_rows_on_single_page = 40;

export const priorities = {
  Low: 1,
  Medium: 2,
  High: 3,
  Urgent: 4
};


export const reportTypes = [
  {
    id: 1,
    name: "Warehouse"
  },
  {
    id: 2,
    name: "Part"
  },
  {
    id: 3,
    name: "Substation"
  },
  {
    id: 4,
    name: "Feeder"
  },
  {
    id: 5,
    name: "Crew"
  }
];

export const reportTypesObject = {
  Warehouse : 1,
  Part : 2,
  Substation : 3,
  Feeder : 4,
  Crew : 5
};

export const viewTypes = [
  {
    id: 1,
    name: "Transaction"
  },
  {
    id: 2,
    name: "Totals"
  }
];

export const viewTypesObject = {
  Transaction : 1,
  Totals : 2
};

export const locationTypes = [
  {
    id: 1,
    name: "V"
  },
  {
    id: 2,
    name: "W"
  }
];

export const locationTypesObject = {
  V : 1,
  W : 2
};