import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Common from "../../General/common"
import {
    deletePartCall
} from "../../services/managementServices";
import { CustomLoader } from "../../components/common/CustomLoader";
import { getAttachmentsCall } from "../../services/attachmentService";
import { EntityIds } from "../../General/common";
import CheckPermission from "../../General/CheckPermission";
import { timeout_for_data_tables, userActions } from "../../constants/enums"
import PartsListing from "./PartsListing";




function PartsList({ editPartModal, refreshPart, selectedFiles, setSelectedFiles, partsLoading, setPartsLoading, isEdit, setIsEdit, partsList, setParts, adding, setAdding, deleting, setDeleting, handleShowAttachmentsModal, setPartAttachments, setLoadingPartAttachments }) {
   
    // const [partsList, setPartsList] = useState([]);
    //const partsList = useSelector((store) => store.Management.parts);
    

    const getAttachments = async (recordId, entityId) => {
        setLoadingPartAttachments(true);
        try {
            const { data } = await getAttachmentsCall(recordId, entityId);

            if (data.success) {
                // success scenario
                if (data.result.length > 0) {
                    const filesList = data.result?.map((el, idx) => {
                        return el;
                        // return {
                        //     id: el.id,
                        //     name: el.fileUrl,
                        //     url: el.fileUrl,
                        //     type: el.fileType,
                        //     size: el.fileSize
                        // };
                    });
                    setSelectedFiles(filesList);
                    setPartAttachments(filesList);
                }
            } else {

            }
        }
        catch {

        }
        finally {
            setLoadingPartAttachments(false);
        }
    };

    
    const deletePartHandle = (partId) => {
        var msg = "You want to delete this part.";
        Common.showConfirmation(msg, "", deletePartApiCall, null, partId)

    };
    const deletePartApiCall = async (partId) => {

        if (partId > 0) {
            setParts([]);
            setDeleting(true);
            try {
                const { data } = await deletePartCall(partId);
                if (data.success) {
                    // success scenario 
                    Common.showSuccessAlertAutoClose(
                        "Part deleted successfully!",
                        "success",
                        "Success!",
                        3000
                    );
                    refreshPart();
                    //window.location.reload(false);

                } else {
                    Common.showSuccessAlertAutoClose(
                        data.message,
                        "warning",
                        "Failed!",
                        3000
                    );
                }
                refreshPart()
            }
            catch {

                Common.showSuccessAlertAutoClose(
                    "some error occured while processing your request",
                    "warning",
                    "Failed!",
                    3000
                );
            }
            finally {
                // setSubstationsLoading(false);
                refreshPart();
            }
            setDeleting(false);
        }
    }
    return (
        <>
             {partsLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !partsLoading && !deleting && !adding && partsList?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Parts
                </div>
              ) : (
                <PartsListing
                    partsList={partsList}
                    deletePartHandle={deletePartHandle}
                    editPartModal={editPartModal}
                    getAttachments={getAttachments}
                    setIsEdit={setIsEdit}
                    handleShowAttachmentsModal={handleShowAttachmentsModal}
                />
              )
            }
        </>
    );
}

export default PartsList;
