import React from "react";
import { Form } from "react-bootstrap";
import * as Common from "../../General/common"
import { upsertJob } from "../../services/jobServices";
const jobModel = {
    id: 0,
    jobName: '',
    fk_CustomerID:null,
    isCompleted:false,
    notes: '',

}
export default class AddEditJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            isLoading: false,
            Job: JSON.parse(
                JSON.stringify(
                    this.props.job != null
                        ? {
                            id: this.props.job.ID,
                            jobName: this.props.job.JobName,
                            fk_CustomerID: this.props.job.fk_CustomerID,
                            isCompleted: this.props.job.IsCompleted,
                            notes: this.props.job.Notes
                        }
                        : jobModel
                )
            ),
        };

    }

    

    handleSubmit = () => {
        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            this.UpsertJobApiCall();
        }
    };

    UpsertJobApiCall = async () => {
        this.props.setAddingJob(true);
        // this.isLoading(false);
        let job = this.state.Job;
        //console.log("jobdet", job);
        try {
            const { data } = await upsertJob(job);

            if (data.success) {
                // success scenario 
                Common.showSuccessAlertAutoClose(
                    this.props.job != null ? "Job updated successfully!" : "Job created successfully!",
                    "success",
                    "Success!",
                    3000
                );
                this.props.OnSubmit();
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        }
        catch {

            Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
            );
        }
        finally {
            // setSubstationsLoading(false);

        }

        this.props.setAddingJob(false);
    }
    render() {
        return (
            <>


                <Form
                    ref={(ref) => {
                        this.form = ref;
                    }}
                    validated={this.state.formValidated}
                >
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label className="form-label" htmlFor="jobName">
                                Job Name <span className="text-danger">*</span>
                            </label>
                            <input required type="text"
                                name="jobName"
                                defaultValue={this.state.Job.jobName}
                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Job,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                className="form-control form-control-sm fs-md" id="jobName" placeholder="Enter job name" />
                            <Form.Control.Feedback type="invalid">
                                Job name is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="form-label" htmlFor="customerName">
                                Customer Name <span className="text-danger">*</span>
                            </label>

                            <select
                                className="form-control form-control-sm fs-md"
                                id="customerName"
                                name="fk_CustomerID"
                                value={Common.replaceNullWithString(
                                    this.state.Job.fk_CustomerID
                                  )}
                                  required
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Job,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                            >
                                <option value="">- Please Select -</option>
                                {
                                    this.props.customers.length > 0 && <>
                                        {
                                            this.props.customers?.map((customer, idx) => {
                                                return (
                                                    <option value={customer.ID} key={idx}>{customer.CustomerName}</option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                            <Form.Control.Feedback type="invalid">
                                Customer name is required
                            </Form.Control.Feedback>
                        </div>
                        {this.state.Job.id>0 &&        
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        {/* check fot job completed */}
                    {/* <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="m-1"
                          id="IsCompleted "
                          name="isCompleted"
                          defaultChecked={
                            this.state.Job.isCompleted 
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Job,
                              e,
                              this,
                              this.state
                            )
                          }
                        />
                        <span className="mr-3" />
                        Is Completed 
                      </label>
                    </div> */}
                  </div>}
                    </div>
                    <div className="row">
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="jobNote">
                                Add Notes
                            </label>
                            <textarea
                                className="form-control form-control-sm fs-md"
                                id="jobNote"
                                name="notes"
                                defaultValue={this.state.Job.notes}

                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Job,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                rows="5"
                                placeholder="Add job notes"
                            ></textarea>
                        </div>

                    </div>
                </Form>
            </>
        );
    }
}