import React, { useState, useEffect, useCallback } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { assignJobToUserCall, getAllAssignedJobs, removeUserFromJobCall } from "../../services/jobServices";
import { getCrewsCall } from "../../services/managementServices";
import {
  getAllActiveUsersCall,
  getAllJobUsersCall,
  upsertUser,
  deleteUser,
} from "../../services/userManagementService";
import $ from "jquery";
import * as Common from "../../General/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JobsDetail from "../../pages/JobsDetail";
import { useLocation } from "react-router-dom";
import { min } from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import { timeout_for_data_tables, userRoles } from '../../constants/enums'
import { userActions } from "../../constants/enums";
import CheckPermission from "../../../app/General/CheckPermission";
import JobUsersPageListing from "./JobUsersPageListing";
import { CustomLoader } from "../../components/common/CustomLoader";
const animatedComponents = makeAnimated();

export function JobUsersPage() {
  const location = useLocation();
  const [jobsLoading, setJobsLoading] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const jobDetails = useSelector((store) => store.JobManagement.jobDetails);
  const [id, setId] = useState(0);
  const [fk_UserID, setfk_UserId] = useState(0);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [usersLoading, setUsersLoading] = useState(false);

  /* BLURRED STATES START */

  const [fk_UserIDBlurred, setfk_UserIdBlurred] = useState(false);

  /* BLURRED STATES END */

  //  ******************** //
  //  Getting ALl job List //
  //  ******************** //
  const [jobsList, setjobsList] = useState([]);
  const getAllJobs = async (userId = null) => {
    try {
      const { data } = await getAllAssignedJobs(userId);

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          // setjobsList(
          //   data.result.map((job) => ({ value: job.id, label: job.jobName }))
          // );
          var unAssingJob = [];
          var assingJob = [];

          data.result.map((job) => {
            if (job.fk_UserID != null) {
              assingJob.push({ value: job.id, label: job.jobName });
            } else {
              unAssingJob.push({ value: job.id, label: job.jobName });
            }
          });
          setjobsList(unAssingJob);
          //setuserjobIDs(assingJob);

          // //console.log(data.result, "all job list");
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  //  ******************** //
  //  Getting All Crew List //
  //  ******************** //
  const [crewList, setcrewList] = useState([]);
  const getAllCrewList = async () => {
    try {
      const { data } = await getCrewsCall();

      if (data.success) {

        if (data.result.length > 0) {
          await setcrewList(data.result);
          //console.log(data.result, "all Cre list")
        }
      } else {
      }
    } catch {
    } finally {
    }
  };
  //  *************************** //
  //  Getting All User ActiveROle //
  //  *************************** //
  const [activeUserRole, setactiveUserRole] = useState([]);
  const getAllActiveUsers = async () => {
    try {
      const { data } = await getAllActiveUsersCall();

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          await setactiveUserRole(data.result);
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
    }
  };
  //  ******************** //
  //  Getting All User //
  //  ******************** //
  const [allUsers, setallUsers] = useState([]);
  const getAllUsers = async () => {
    setUsersLoading(true);
    try {
      const { data } = await getAllJobUsersCall(jobDetails?.ID);

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          await setallUsers(data.result);
         
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
      setUsersLoading(false);
    }
  };

  //console.log("allusers", allUsers);

  // get all users list
  const [allUsersList, setallUsersList] = useState([]);
  const getAllUsersList = async () => {
    setJobsLoading(true);
    try {
      const { data } = await getAllJobUsersCall(0);

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          await setallUsersList(data.result);
        
          // dispatch(setJobs(data.result));
        }
      } else {
      }
    } catch {
    } finally {
      setJobsLoading(false);
    }
  };

  // remove user from list
  const removeUserFromJob = async (userIdHere) => {
    //setJobsLoading(true);
    let userData = {
      id,
      fk_UserID: userIdHere,
      fk_JobID: jobDetails?.ID,
    };


    if (userIdHere > 0) {
      setallUsers([]);
      setDeleting(true);
      try {
        const { data } = await removeUserFromJobCall(userData);
        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            "User removed successfully from job!",
            "success",
            "Success!",
            3000
          );
          setShow(false);
          getAllUsers();
          //window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
        // setSubstationsLoading(false);
        getAllUsers();
      }
      setDeleting(false);
    }
  };
  //  ************************* //
  //  Post request for add user //
  //  ************************* //
  // const [firstNameErr, setfirstNameErr] = useState("")
  // const userFormValidation = ()=>{
  //   if(userfirstName.trim().length < 0){
  //     setfirstNameErr("this is testing")

  //   }

  // }
  const [IsEdit, setIsEdit] = useState(false);
  // const [userfirstName, setuserfirstName] = useState("");
  // const [userlastName, setuserlastName] = useState("");
  // const [useractive, setuseractive] = useState(false);
  // const [useremail, setuseremail] = useState("");
  // const [userphoneNumber, setuserphoneNumber] = useState("");
  // const [usercrewName, setusercrewName] = useState("");
  // const [userfk_RoleId, setuserfk_RoleId] = useState("");
  // const [userIds, setuserIds] = useState("");
  // const [userjobIDs, setuserjobIDs] = useState([]);

  const phoneNumberRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  // formik setup starts

  useEffect(() => {
    formik.setValues({
      // firstName: userfirstName,
      // lastName: userlastName,
      // active: useractive,
      // email: useremail,
      // phone: userphoneNumber,
      // fk_RoleId: userfk_RoleId,
      // jobIDs: userjobIDs,
      id,
      fk_UserID
    });
  }, [fk_UserID, id]);

  const Schema = Yup.object().shape({
    // firstName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("First Name is required"),
    // lastName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Last Name is required"),
    // email: Yup.string().max(255, "Maximum limit (255 characters)").typeError("Invalid Value").email("Email is not valid").required("Email is required"),
    // phone: Yup.string().required("Phone number is required").matches(phoneNumberRegEx, 'Phone number is not valid').min(11, "11 digits required").max(11, "11 digits required"),
    // fk_RoleId: Yup.string().required("User Role is required").typeError("Invalid value"),
    fk_UserID: Yup.number().required("User is required").min(1, "User is required"),
  });

  const formik = useFormik({
    initialValues: {
      // firstName: userfirstName,
      // lastName: userlastName,
      // active: useractive,
      // email: useremail,
      // phone: userphoneNumber,
      // crewName: usercrewName,
      // fk_RoleId: userfk_RoleId,
      // jobIDs: userjobIDs,
      fk_UserID
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  const handleValidSubmit = async e => {
    //e?.preventDefault();
    AddUser();

    const timeout = setTimeout(() => {

    }, 1000);
    return () => clearTimeout(timeout);
  }


  const AddUser = async () => {
    // setShow(false);

    let userData = {
      // firstName: userfirstName,
      // lastName: userlastName,
      // active: useractive,
      // email: useremail,
      // phone: userphoneNumber,
      // crewName: usercrewName,
      // fk_RoleId: userfk_RoleId,
      // jobIDs: [],
      id,
      fk_UserID,
      fk_JobID: jobDetails?.ID,
    };
    // if (userjobIDs?.length > 0) {
    //   var jobIds = [];
    //   userjobIDs.map((job) => {
    //     jobIds.push(job.value);
    //   });
    //   userData.jobIDs = jobIds;
    // }
    // if (IsEdit === true) {
    //   userData["id"] = userIds;
    // }
    setallUsers([]);
    setAdding(true);
    try {
      const { data } = await assignJobToUserCall(userData);
      if (data.success) {
        Common.showSuccessAlertAutoClose(
          "Job has been assigned successfully to user!",
          "success",
          "Success!",
          3000
        );
        setShow(false);
        getAllUsers();
        //window.location.reload(false);

      } else {
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch {
      Common.showSuccessAlertAutoClose(
        "some error occured while processing your request",
        "warning",
        "Failed!",
        3000
      );
    } finally {
      getAllUsers();
    }
    setAdding(false);
  };
  //  ************************* //
  //  Delete request for Delete user //
  //  ************************* //

  const deleteUserHandle = (jobId) => {
    var msg = "You want to remove this User from job.";
    Common.showConfirmation(msg, "", removeUserFromJob, null, jobId);
  };
  const deleteUserApiCall = async (jobId) => {
    if (jobId > 0) {
      try {
        const { data } = await deleteUser(jobId);
        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            data.message,
            "success",
            "Success!",
            3000
          );

          getAllUsers();
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
        // setSubstationsLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllJobs(user?.id);
    getAllActiveUsers();
    getAllCrewList();
    getAllUsers();
    getAllUsersList();

  }, [user]);

   

  // for Add user modal popup
  const [show, setShow] = useState(false);
  const resetUserForm = () => {
    // setuserfirstName("");
    // setuserlastName("");
    // setuseremail("");
    // setuserphoneNumber("");
    // setusercrewName("");
    // setuserfk_RoleId("");
    // setuserIds("");
    // setuserjobIDs("");
    setfk_UserId(0);
  }
  const handleClose = () => {
    setShow(false);
    resetUserForm();
  };
  const handleShow = async (usersList) => {
    setfk_UserIdBlurred(false);

    if (usersList.id) {
      // setuserfirstName(usersList.firstName);
      // setuserlastName(usersList.lastName);
      // setuseractive(usersList.active);
      // setuseremail(usersList.email);
      // setuserphoneNumber(usersList.phone);
      // setusercrewName(usersList.crewName);
      // setuserfk_RoleId(usersList.fk_RoleId);
      // setuserIds(usersList.id);
      // setuserjobIDs(usersList.jobIDs);

      await getAllJobs(usersList.id);
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setShow(true);
    // //console.log(usersList);
  };

  // for Edit user modal popup
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleCloseEditUser = () => setShowEditUserModal(false);
  const handleShowEditUser = () => setShowEditUserModal(true);

  // for grant permission modal popup
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const handleClosePermissionModal = () => setShowPermissionModal(false);
  const handleShowPermissionModal = () => setShowPermissionModal(true);

  // const userJobsSelect = useCallback(() => {
  //   // get user assigned items
  //   const items = jobsList.filter((job) =>
  //     userjobIDs?.some((userJob) => userJob == job.value)
  //   );
  //   return (
  //     <Select
  //       closeMenuOnSelect={false}
  //       components={animatedComponents}
  //       isMulti
  //       defaultValue={items}
  //       onChange={(e) => {
  //         setuserjobIDs(e.map((c) => c.value));
  //       }}
  //       options={jobsList}
  //     />
  //   );
  // }, [jobsList, animatedComponents]);

  return (
    <>
      <JobsDetail />
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="mb-0">
              <img
                src="/media/svg/custom/team.svg"
                width="30"
                height="30"
                className="mr-3"
                alt="summary"
              />
              Job Users List
            </h3>
          </div>
          <div className="card-toolbar">
            <div className="example-tools">

              <CheckPermission
                currentAction={userActions.userManagment.Action_JobUserActions}
                yes={() => (<>
                  <button
                    onClick={handleShow}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    <i className="fa fa-plus"></i> New Job User
                  </button></>)}
                no={() => null}
              />

             
            </div>
          </div>
        </div>
        {usersLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !usersLoading && !deleting && !adding && allUsers?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Job Users
                </div>
              ) : (
                <JobUsersPageListing
                  allUsers={allUsers}
                  deleteUserHandle={deleteUserHandle}
                />
              )
            }
      </div>

      {/* ads user modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Add Job User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className={IsEdit ? "col-lg-6" : "col-lg-12"}>
              <div className="form-group">
                <label className="form-label" htmlFor="UserID">
                  Users List <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control"
                  id="UserID"
                  name="fk_UserID"
                  onChange={(e) => {
                    setfk_UserId(e.target.value);
                  }}
                  onBlur={() => setfk_UserIdBlurred(true)}
                  defaultValue={fk_UserID}
                >
                  <option value={0}>- Please Select -</option>
                  {(allUsersList?.filter(o1 => !allUsers?.some(o2 => o1.id === o2.id)))?.map((userli) => {
                    return (
                      <option value={userli.id} key={userli.id}>
                        {userli.FirstName} {userli.LastName}
                      </option>
                    );
                  })}
                </select>
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                  {fk_UserIDBlurred && formik.errors.fk_UserID}
                </div>
              </div>
            </div>
            {/* {
              IsEdit && 
              <div className="col-lg-6">
              <div className="form-group">
                <label className="checkbox checkbox-single mt-10">
                  <input
                    type="checkbox"
                    id="UserIsActive"
                    name="active"
                    checked={IsEdit == true ? useractive : true}
                    onChange={(e) => {
                      setuseractive(e.target.checked);
                    }}
                  />
                  <label className="mr-5 mb-0" htmlFor="Active">
                    Is Active?
                  </label>
                  <span></span>
                </label>
              </div>
            </div>
            } */}
          </div>
          <div className="row">
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="firstName">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  id="firstName"
                  name="firstName"
                  value={userfirstName}
                  onChange={(e) => {
                    setuserfirstName(e.target.value);
                  }}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.firstName}
              </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="lastName">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  value={userlastName}
                  onChange={(e) => {
                    setuserlastName(e.target.value);
                  }}
                />
                 <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.lastName}
              </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="Email">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="Email"
                  name="email"
                  disabled={IsEdit === true ? true : false}
                  value={useremail}
                  onChange={(e) => {
                    setuseremail(e.target.value);
                  }}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.email}
              </div>
              </div>
            </div> */}

            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="MobileNo">
                  Mobile Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  id="PrimaryPhoneNumber"
                  name="phone"
                  im-insert="true"
                  data-inputmask="'mask': '(999) 999-9999'"
                  min="10"
                  max="10"
                  value={userphoneNumber}
                  onChange={(e) => {
                    setuserphoneNumber(e.target.value);
                  }}
                />
                <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.phone}
              </div>
              </div>
            </div> */}
            <div className={IsEdit ? "col-lg-6" : "col-lg-12"}>
              <div className="form-group">
                <label className="form-label" htmlFor="jobVisibility">
                  Job Visibility
                </label>

                <select
                  className="form-control"
                  id="jobVisibility"
                  name="jobVisibility"
                  disabled
                >
                  <option>{jobDetails?.JobName}</option>
                </select>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="crewName">
                  Crew Name
                </label>

                <select
                  className="form-control"
                  id="crewName"
                  name="crewName"
                  onChange={(e) => {
                    setusercrewName(e.target.value);
                  }}
                  defaultValue={usercrewName}
                >
                  <option>- Please Select -</option>
                  
                      { crewList?.map((crewList)=>{
                        return(<option value={crewList.id} key={crewList.id}>
                          {crewList.crewName}
                        </option>)
                      })
                        
                      }
                      
                   
                </select>
                
              </div>
            </div> */}

          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <i className="fa fa-times mr-1"></i> Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting || adding}>
          <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i> Save</span>
            {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal popu */}
      {/* 
      <Modal
        show={showEditUserModal}
        onHide={handleCloseEditUser}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="UserRole">
                  User Role
                </label>
                <input
                  type="hidden"
                  id="HiddenUserRoleId"
                  name="UserRole"
                  value=""
                />
                <select
                  className="form-control"
                  id="UserRole"
                  name="UserRole"
                  required
                >
                  <option value="">- Please Select -</option>
                  <option value="">Admin</option>
                  <option value="">User</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <div className="custom-control custom-checkbox mt-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Active"
                    name="Active"
                    value="true"
                    checked
                  />
                  <label className="custom-control-label" htmlFor="Active">
                    Is Active?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="FirstName">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  id="FirstName"
                  name="FirstName"
                  required
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="LastName">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  id="LastName"
                  required
                  name="LastName"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="Email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="Email"
                  name="Email"
                  required
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-label" htmlFor="MobileNo">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  id="PrimaryPhoneNumber"
                  name="PrimaryPhoneNumber"
                  im-insert="true"
                  data-inputmask="'mask': '(999) 999-9999'"
                  required
                  min="10"
                  max="10"
                />
              </div>
            </div>
          </div>
          <h4 className="my-3"> Permissions </h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-control custom-switch pl-0 mb-2">
                <span className="mr-23">Can this user access reports?</span>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="accessReports"
                />
                <label
                  className="custom-control-label"
                  htmlFor="accessReports"
                ></label>
              </div>
              <div className="custom-control custom-switch pl-0">
                <span className="mr-15">
                  Give access to all future projects?
                </span>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="futureProjectAccess"
                />
                <label
                  className="custom-control-label"
                  htmlFor="futureProjectAccess"
                ></label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-between">
                <div>
                  <h5>Jobs</h5>
                </div>
                <div>
                  <span className="mr-10">Jobs Access: 5</span>
                  <button
                    onClick={handleShowPermissionModal}
                    className="btn btn-secondary btn-xs btn-icon"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleCloseEditUser}>
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* For granting job access/Permissions */}

      <Modal
        show={showPermissionModal}
        onHide={handleClosePermissionModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            id="permissionGrantList"
            className="table table-bordered table-hover table-striped table-sm w-100"
          >
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Job Name</th>
                <th>Give Access</th>
              </tr>
            </thead>
            <tbody>
              {/* {new Array(10).fill(0).map((index) => {
                return (
                  <tr>
                    <td>12586</td>
                    <td>Job Name </td>
                    <td align="center">
                      <label className="checkbox checkbox-single">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          </table>
          <div className="frame-wrap">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                className="custom-control-input"
                id="defaultInline1Radio"
                name="inlineDefaultRadiosExample"
              />
              <label className="custom-control-label" for="defaultInline1Radio">
                Add to all Jobs
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                className="custom-control-input"
                id="defaultInline2Radio"
                name="inlineDefaultRadiosExample"
              />
              <label className="custom-control-label" for="defaultInline2Radio">
                Remove from all Jobs
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClosePermissionModal}
          >
            Close
          </Button>
          <Button variant="primary" size="sm">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobUsersPage;
