import React from 'react'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
function JobDetailTiles({ tileIcon, tileTitle, titleValue, tileColor, reportTypeId, getInventoryTransactionsOnClickOfWarehouse }) {
    return (
        <div className={`bg-light-${tileColor} px-6 py-4 rounded-xl mb-7 boxShadow`} onClick={() => {
            if(tileTitle==="Out of warehouse" || tileTitle==="Available in warehouse"){
                getInventoryTransactionsOnClickOfWarehouse(reportTypeId);
            }
        }} style={{cursor: (tileTitle==="Out of warehouse" || tileTitle==="Available in warehouse") ? 'pointer' : ''}}>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="text-primary font-weight-bold font-size-xl m-0">{titleValue}</h1>
                <span className={`svg-icon svg-icon-6x svg-icon-${tileColor} d-block my-2`}>
                    <SVG src={toAbsoluteUrl(tileIcon)}></SVG>
                </span>
            </div>
            <h3 className="text-primary font-weight-bold font-size-h3">
                {tileTitle}
            </h3>
        </div>
    )
}

export default JobDetailTiles
