import axios from "axios";
import { ApiBaseUrl, apiUrls as ApiUrls } from "../constants/endpoints";
export const LOGIN_URL = ApiBaseUrl + "Account/Authenticate";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const ME_URL = "me";

const getAuthToken = () => {
  var token = localStorage.getItem("accessToken");
  if (token === null || token === "") return "";
  return "Bearer " + token;
};
export const axiosInstance = axios.create();

function Logout(errorCode = "ex") {
  window.location.assign("/logout?se=" + errorCode);
}

 axiosInstance.interceptors.request.use(
   function (config) {
     const token = getAuthToken();
     if (token != null) {
       config.headers.Authorization = token;
     }

     return config;
   },
   function (err) {
     return Promise.reject(err);
   }
 );

 // Add a response interceptor
 axiosInstance.interceptors.response.use(
   function (response) {
     // // 
     // Any status code that lie within the range of 2xx cause this function to trigger
     // Do something with response data
     return response;
   },
   function (error) {
     try {
       if (error.response.status === 401){
        //Logout();
        window.location.href("/logout");
       }
     } catch (ex) {
       //Logout("apid");
     //   Common.LogError(ex, "Api Call in api.js file", "Intercepter");
     }
     // Any status codes that falls outside the range of 2xx cause this function to trigger
     // Do something with response error
     return Promise.reject(error);
   }
 );

export function login(email, password) {
  axios.post(LOGIN_URL, {
    Username: email,
    Password: password,
  });
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
export function getUserByToken() {
  return axios.get(ME_URL);
}
export function getRequest(url, bindBaseUrl = true) {
  return axiosInstance.get(bindBaseUrl ? ApiBaseUrl + url : url);
}
export function getRequestById(url, id) {
  return axiosInstance.get(ApiBaseUrl + url + "?id=" + id);
}

export function getRequestQry(url, stringQuery) {
  return axiosInstance.get(ApiBaseUrl + url + stringQuery);
}

export function postRequest(url, requestParameters, bindBaseUrl = true) {
  return axiosInstance.post(
    bindBaseUrl ? ApiBaseUrl + url : url,
    requestParameters
  );
}
export function postWithFilesRequest(url, requestParameters, bindBaseUrl = true) {
  return axiosInstance.post(
    bindBaseUrl ? ApiBaseUrl + url : url,
    requestParameters,{
      headers: {
          'Content-Type': 'multipart/form-data',
          'accept': '*/*',
      }
  }
  );
}

export function deleteRequest(url, id, bindBaseUrl = true) {
  return axiosInstance.delete(
    bindBaseUrl ? ApiBaseUrl + url+"/"+id : url+"/"+id
  );
}

export function postDeleteRequest(url, id, bindBaseUrl = true) {
  return axiosInstance.post(
    bindBaseUrl ? ApiBaseUrl + url+"/"+id : url+"/"+id
  );
}

export function putRequest(url, requestParameters) {
  if (requestParameters) {
    return axiosInstance.put(ApiBaseUrl + url, requestParameters);
  } else {
    return axiosInstance.put(ApiBaseUrl + url);
  }
}
export const apiUrls = ApiUrls;