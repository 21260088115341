import * as API from "../General/api";

// attachment call starts
export function getAttachmentsCall(recordId, entityId) {
    return API.getRequest(`${API.apiUrls.attachment.getAttachments}?entity_id=${recordId}&type=${entityId}`);
}
export function upsertAttachmentCall(attachments, recordId, entityId) {
    return API.postWithFilesRequest(`${API.apiUrls.attachment.upsertAttachments}?entity_id=${recordId}&type=${entityId}`, attachments); 
}

// attachment call ends