import * as API from "../General/api";

//Locations calls starts
export function getAllLocationsApiCall(isCustom) {
    return API.getRequest(`${API.apiUrls.location.getAllLocations}?custom=${isCustom}`);
}
export function getAllLocationsApiCallCustomeAndNotCustom(locationTypeId) {
    return API.getRequest(`${API.apiUrls.location.getAllLocations}?locationtype=${locationTypeId}`);
}
export function upsertLocationApiCall(location) {
    return API.postRequest(API.apiUrls.location.upsertLocation, location);
}
export function deleteLocationApiCall(locationId) {
    return API.postDeleteRequest(API.apiUrls.location.deleteLocation, locationId);
}
//Locations calls end