import React from "react";
import { NavDropdown, Navbar, Nav } from "react-bootstrap";
//import JobDetailDashboard from "./JobDetailsViews/JobDetailDashboard";
import JobInventory from "./JobDetailsViews/JobInventory";
import UsersPage from "../modules/manageusers/userspage";
import Crews from "./JobManagementViews/Crews";
import PoleType from "./JobManagementViews/poletype";
import Substations from "./JobManagementViews/Substations";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import Parts from "./JobManagementViews/Parts";
import Customers from "./JobManagementViews/Customers";
import Feeders from "./JobManagementViews/Feeders";
import InventoryReports from "./JobDetailsViews/reports";
import { userActions } from "../constants//enums";
import CheckPermission from "../../app/General/CheckPermission";

const JobsDetail = () => {

  const itemClicked = () => document.getElementById("menuDropDown").click();

  return (
    <div className="row jobDetailViewTabsContainer">
      <div className="col-12">
        {/* <Router> */}
        <div>
          <Navbar
            collapseOnSelect
            className=" font-weight-bold mb-5"
            expand="sm"
            bg="white"
            variant="light"
          >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <li className="nav-item">
                  <Link className="nav-link text-primary" to="/jobdetaildashboard">
                    Dashboard
                  </Link>

                </li>
                {/* use inventory dashboard, only uncommented this code */}
                {/* <li className="nav-item">
                  <Link className="nav-link text-primary" to="/inventory">
                    Inventory
                  </Link>
                </li> */}
                <CheckPermission
                  currentAction={userActions.userManagment.Action_JobUserActions}
                  yes={() => (<>
                    <NavDropdown
                      rootCloseEvent={"click"}
                      title="Management"
                      className="custom_PrimaryColor"
                      // className={dropDownSetting ? "custom_PrimaryColor dropdown nav-item show" : "custom_PrimaryColor dropdown nav-item"}
                      id="menuDropDown"
                    >
                      <li className="nav-item">
                        <Link
                          className="dropdown-item"
                          onClick={itemClicked}
                          to="/crews"
                        >
                          Crews
                        </Link>
                      </li>
                      {/* use Poletype functionlity, only uncommented this code */}
                      {/* <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/poletype">
                          Pole Type
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/substations">
                          Substations
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/feeders">
                          Feeders
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/locations">
                        Locations
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/customers">
                          Customers
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className="dropdown-item" onClick={itemClicked} to="/parts">
                          Parts
                        </Link>
                      </li>
                    </NavDropdown></>)}
                  no={() => null}
                />

                <li className="nav-item">
                  <Link className="nav-link text-primary" to="/JobUsers">
                    Job Users
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link text-primary" to="/transfer-report">
                    Transfer Report
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link text-primary" to="/reports">
                    Reports
                  </Link>
                </li> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          {/* <Switch>
              <Route path="/jobdetail">
                <JobDetailDashboard />
              </Route>
              <Route path="/inventory">
                <JobInventory />
              </Route>
              <Route path="/management">Management Screen</Route>
              <Route path="/users">
                <UsersPage />
              </Route>
              <Route path="/reports">
                <InventoryReports />
              </Route>
              <Route path="/crews">
                <Crews />
              </Route>
              <Route path="/poletype">
                <PoleType />
              </Route>
              <Route path="/substations">
                <Substations />
              </Route>
              <Route path="/feeders">
                <Feeders />
              </Route>
              <Route path="/customers">
                <Customers />
              </Route>
              <Route path="/parts">
                <Parts />
              </Route>
            </Switch> */}
        </div>
        {/* </Router> */}
      </div>
    </div>
  );
};

export default JobsDetail;
