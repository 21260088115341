import {
    SET_LOCATIONS
  } from "./actionTypes";

  const initialState = {
    locations: []
  };

  const location = (state = initialState, action) => {
    switch (action.type) {
      case SET_LOCATIONS:
        state = { ...state, locations: action.payload };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  export default location;
  