import React, { useState, useEffect } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from 'react-redux';

const AddEditPoleType = ({
  showAddPolesModal,
  setShowAddPolesModal,
  handleCloseAddPolesModal,
  handleShowAddPolesModal,
  poleTypeDetails,
  setPoleTypeDetails,
  addorEditPoleType,
  selectedFiles,
  setSelectedFiles,
  partNumbers,
  setPartNumbers,
  poleTypeParts,
  setPoleTypeParts,
  onSelectAttatchmentsFiels,
  attachmentsRef,
  Id,
  setId,
  CreatedBy,
  setCreatedBy,
  ModifiedBy,
  setModifiedBy,
  Notes,
  setNotes,
  TypeName,
  setTypeName,
  IsPole,
  setIsPole,
  Active,
  setActive,
  adding
}) => {

  // // add poletype states starts
  // const [Id, setId] = useState(0);
  // const [CreatedBy, setCreatedBy] = useState("0");
  // const [ModifiedBy, setModifiedBy] = useState("0");
  // const [Notes, setNotes] = useState("");
  // const [TypeName, setTypeName] = useState("");
  // const [IsPole, setIsPole] = useState(true);
  // const [Active, setActive] = useState(true);


  const [poleTypePartId, setPoleTypePartId] = useState(0);
  const [fk_PoleTypeID, setfk_PoleTypeId] = useState(0);
  const [fk_PartID, setfk_PartId] = useState(0);
  const [Qty, setQty] = useState(0);

  const [isEditPart, setIsEditPart] = useState(false);


  const [poleTypeData, setPoleTypeData] = useState(null);
  const [addorEditLoading, setAddorEditLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const changeNotes = (e) => {
    setNotes(e.target.value);
  }

  const changeTypeName = (e) => {
    setTypeName(e.target.value);
  }

  const changePartId = (e) => {
    setfk_PartId(parseInt(e.target.value));
  }

  const changeQty = (e) => {
    setQty(parseInt(e.target.value));
  }

  // add poletype states ends

  // formik setup starts

  useEffect(() => {
    formik.setValues({
      Notes,
      CreatedBy,
      ModifiedBy,
      Id,
      TypeName,
      IsPole,
      Active,
      selectedFiles,
      poleTypeParts,
    });
  }, [Notes, TypeName, IsPole, Active, poleTypeParts]);

  const Schema = Yup.object().shape({
    TypeName: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Type Name is required"),
    //notes: Yup.string().max(500, "Maximum limit (500  characters)").typeError("Invalid Value").required("Notes required"),
    poleTypeParts: Yup.array().required("Atleast 1 PoleType Part is required").min(1, "Atleast 1 PoleType Part is required").max(7, "PoleType Parts should not be more than 7"),
  });

  const formik = useFormik({
    initialValues: {
      Notes,
      CreatedBy,
      ModifiedBy,
      Id,
      TypeName,
      IsPole,
      Active,
      selectedFiles,
      poleTypeParts,
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleValidSubmit();
      setSubmitting(false);
    },
  });

  // formik setup ends

  useEffect(() => {
    if (poleTypeDetails !== null) {
      setId(poleTypeDetails.id);
      setCreatedBy(poleTypeDetails.createdBy);
      if (poleTypeDetails.modifiedBy === null) {
        setModifiedBy("0");
      } else {
        setModifiedBy(poleTypeDetails.modifiedBy);
      }
      setTypeName(poleTypeDetails.typeName);
      setNotes(poleTypeDetails.notes);
      setIsPole(poleTypeDetails.isPole);
      setActive(poleTypeDetails.active);
      //setSelectedFiles(poleTypeDetails.file);
      formik.setValues((old) => {
        return {
          ...old,
          Notes: poleTypeDetails.notes,
          CreatedBy: poleTypeDetails.createdBy,
          ModifiedBy: poleTypeDetails.modifiedBy === null ? "0" : poleTypeDetails.modifiedBy,
          Id: poleTypeDetails.id,
          TypeName: poleTypeDetails.typeName,
          IsPole: poleTypeDetails.isPole,
          Active: poleTypeDetails.active,
          //selectedFiles: poleTypeDetails.poleType.file,
          poleTypeParts
        }
      })
    } else if (poleTypeDetails === null) {
      setId(0);
      setCreatedBy("0");
      setModifiedBy("0");
      setTypeName("");
      setNotes("");
      setIsPole(true);
      setActive(true);
      setPoleTypeParts([]);
      setSelectedFiles([]);
      formik.resetForm();
    }
  }, [poleTypeDetails]);

  useEffect(() => {
    if (TypeName) {
      let data = {
        PoleType: {
          Notes,
          CreatedBy,
          ModifiedBy,
          Id,
          TypeName,
          IsPole,
          Active,
          //selectedFile
        },
        PoleTypePart: poleTypeParts
      };
      setPoleTypeData(data);
      setDisableButton(false);
    }
  }, [Notes, Id, TypeName, IsPole, Active, poleTypeParts, selectedFiles]);

  const handleValidSubmit = async e => {
    //e?.preventDefault();
    if (poleTypeData !== null) {
      setAddorEditLoading(true);
      addorEditPoleType(poleTypeData);
      setAddorEditLoading(false);
      // const timeout = setTimeout(() => {
      //   setId(0);
      //   setCreatedBy("0");
      //   setModifiedBy("0");
      //   setTypeName("");
      //   setNotes("");
      //   setIsPole(true);
      //   setActive(true);
      //   setPoleTypeParts([]);
      //   setSelectedFiles([]);
      // }, 1000);

      // return () => clearTimeout(timeout);
    }
  }


  const addDataInPoleTypeParts = () => {
    if (fk_PartID && Qty) {
      let data = {
        Notes,
        CreatedBy,
        ModifiedBy,
        Id: poleTypePartId,
        fk_PoleTypeID: Id,
        fk_PartID,
        Qty
      };

      let index = poleTypeParts.findIndex((x) => x.fk_PartID === fk_PartID);
      if (index >= 0 && !isEditPart) {
        poleTypeParts[index].Qty = poleTypeParts[index].Qty + Qty;
      }else if(index >=0 && isEditPart){
        poleTypeParts[index].Qty = Qty;
      } else {
        poleTypeParts.push(data);
      }
      setPoleTypeParts([...poleTypeParts]);


      //setPoleTypeParts([...poleTypeParts, data]);
      setfk_PartId(0);
      setQty(0);
      setIsEditPart(false);
    }
  }

 

  return (
    <Modal
      show={showAddPolesModal}
      onHide={handleCloseAddPolesModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="py-3" closeButton>
        <Modal.Title>{poleTypeDetails !== null ? "Edit Pole Type" : "Add Pole Type"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="form-group">
              <label className="form-label" htmlFor="CrewName">Pole Type Name</label>
              <input type="text" className="form-control" placeholder="Pole type name" id="TypeName" name="TypeName" required value={TypeName} onChange={changeTypeName} />
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                {formik.errors.TypeName}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="notes">Note</label>
              <textarea className="form-control" placeholder="Add notes" id="htmlFor" rows="3" spellcheck="false" value={Notes} onChange={changeNotes}></textarea>
              <div style={{ fontSize: ".8rem" }} className="text-danger">
                {formik.errors.Notes}
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>
        <h5 className="mb-5">Pole Parts</h5>
      
        <div className="row align-items-end">
          <div className="col-md-5 form-group mb-2">
            <label className="form-label" htmlFor="partNum">Part Number</label>
            {/* <input type="text" className="form-control" id="partNum" placeholder="Part#" value={fk_PartId} onChange={changePartId} /> */}
            <select
              className="form-control"
              id="fk_PartID"
              name="fk_PartID"
              required
              value={fk_PartID}
              onChange={changePartId}
            >
              <option value={0} onClick={() => setfk_PartId(0)}>-- SELECT --</option>
              {
                partNumbers.length > 0 && <>
                  {
                    partNumbers?.map((partNumber, idx) => {
                      const { categoryName, clientPartNumber, customerPartNumber, customerName, vendorPartNumber } = partNumber
                      return (
                        <option onClick={() => setfk_PartId(partNumber.id)} value={partNumber.id} key={idx}> {`${customerPartNumber} / ${clientPartNumber} / ${vendorPartNumber}`} </option>
                      )
                    })
                  }
                </>
              }
            </select>
            {/* <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.fk_PartID}
              </div> */}

          </div>
          <div className="col-md-5 form-group mb-2">
            <label className="form-label" htmlFor="qty"> Qty</label>
            <input type="number" className="form-control" id="Qty" placeholder="Add quantity" min={0} value={Qty} onChange={changeQty} />
            {/* <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.qty}
              </div> */}
            <div style={{ fontSize: ".8rem" }} className="text-danger">
              {Qty > 9999999 ? "Quantity value is too large" : ""}
            </div>
          </div>
          <div className="col-md-2 mb-2">
            <button className="btn btn-primary btn-sm" disabled={!fk_PartID || !Qty || Qty > 9999999} onClick={() => {
              addDataInPoleTypeParts();
            }}><i className="fa fa-plus"></i>{isEditPart ? "Edit" : "Add"}</button>
          </div>
        </div>
        <div style={{ fontSize: ".8rem" }} className="text-danger">
          {formik.errors.poleTypeParts}
        </div> 

        <table className="table table-bordered table-hover table-striped table-sm w-100">
        <thead>
                <tr>
                  <th>
                    <i className="fa fa-list-ul mr-1"></i> Part Number
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Qty
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Action
                  </th>
                </tr>
              </thead>
          <tbody>
            {poleTypeParts?.map((poleTypePart, idx) => {
              const { categoryName, clientPartNumber, customerPartNumber, customerName, vendorPartNumber } = partNumbers.find(x => x.ID == poleTypePart.fk_PartID)
              return (
                <tr key={idx}>
                  <td>{`${customerPartNumber} / ${clientPartNumber} / ${vendorPartNumber}`}</td>
                  <td>
                    <span className="label label-light-danger label-inline">
                      {poleTypePart.Qty}
                    </span>
                  </td>
                  <td>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip> Delete Pole Type Part </Tooltip>}>
                      <button className="btn btn-icon btn-outline-danger btn-xs" onClick={() => {
                        const newPoleTypeParts = poleTypeParts?.filter((el, idxs) => {
                          return (idx !== idxs);
                        });
                        setPoleTypeParts(newPoleTypeParts);
                      }}>
                        <i className="fa fa-trash-alt"></i>
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip> Edit Pole Type Part </Tooltip>}>
                      <button className="btn btn-icon btn-outline-primary btn-xs ml-3" onClick={() => {
                        setIsEditPart(true);
                        setfk_PartId(poleTypePart.fk_PartID);
                        setQty(poleTypePart.Qty);
                      }}>
                        <i className="fa fa-pen"></i>
                      </button>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

       
        <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>

        <div className="row">
          <div className="col-12">
            <h5 className="mb-3">Files</h5>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="form-label" htmlFor="attachFile"> Attach File </label>
              <input type="file" className="form-control" id="attachFile" multiple="multiple" onChange={onSelectAttatchmentsFiels} ref={attachmentsRef} />
              {/* <div style={{ fontSize: ".8rem" }} className="text-danger">
                    {formik.errors.selectedFile}
              </div> */}
            </div>
          </div>
          {
            selectedFiles?.length > 0 &&
            <div className="col-12">
              <table className="table table-borderd table-sm w-100">
                <tbody>
                  {
                    selectedFiles?.map((selectedFile, idx) => {
                      return <tr key={idx}>
                        <td>{selectedFile.name}</td>
                        <td>
                          <span className="label label-light-primary label-inline">
                            {selectedFile.type}
                          </span>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          }
        </div>


      </Modal.Body>
      <Modal.Footer className="py-3">
        <Button variant="secondary" size="sm" onClick={() => {
          handleCloseAddPolesModal();
          setPoleTypeDetails(null);
          setId(0);
          setCreatedBy("0");
          setModifiedBy("0");
          setTypeName("");
          setNotes("");
          setIsPole(true);
          setActive(true);
          setPoleTypeParts([]);
        }}>
          <i className="fa fa-times mr-1"></i> Cancel
        </Button>
        <Button type="button" onClick={formik.handleSubmit} disabled={disableButton || !formik.isValid || formik.isSubmitting || adding} variant="primary" size="sm">
        <div className={`w-100 ${(formik.isSubmitting || adding) && "pr-5"}`}>
            <span className={` ${(formik.isSubmitting || adding) && 'mr-3'}`}><i className="fa fa-save mr-1"></i>{poleTypeDetails !== null ? "Update" : "Save"}</span>
            {(formik.isSubmitting || adding) && <span className="ml-3 m-auto spinner spinner-white"></span>}
            </div>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEditPoleType;