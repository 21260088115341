import React from "react";
import { Form } from "react-bootstrap";
import * as Common from "../../General/common"
import { upsertPartCall } from "../../services/managementServices";
import { upsertAttachmentCall } from "../../services/attachmentService";
import { EntityIds } from "../../General/common";
const partModel = {
    id: 0,
    fk_CustomerID: null,
    customerPartNumber: '',
    vendorPartNumber: '',
    clientPartNumber: '',
    fk_CategoryID: null,
    fk_UomID : null,
    description: '',
    notes: '',
    createdBy:'',
    JobId: null
}
export default class AddEditPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            isLoading: false,
            Part: JSON.parse(
                JSON.stringify(
                    this.props.part != null
                        ? {
                            id: this.props.part.ID,
                            fk_CustomerID: this.props.part.fk_CustomerID,
                            customerPartNumber: this.props.part.CustomerPartNumber,
                            vendorPartNumber: this.props.part.VendorPartNumber,
                            clientPartNumber: this.props.part.ClientPartNumber,
                            fk_CategoryID: this.props.part.fk_CategoryID,
                            fk_UomID : this.props.part.fk_UomID,
                            description: this.props.part.Description,
                            notes: this.props.part.Notes,
                            createdBy: this.props.part.CreatedBy,
                            JobId: this.props.part.fk_JobID,
                            modifiedBy: "0"
                        }
                        : {
                            ...partModel,
                            JobId: this.props.jobDetails.ID,
                            createdBy: "0",
                            modifiedBy: "0"
                        }
                )
            ),
        };
    }

    handleSubmit = () => {
        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            this.upsertPart();
        }
    };

   

    upsertPart = async () => {
        this.props.setParts([]);
        this.props.setAdding(true);
        // this.isLoading(false);
        let job = this.state.Part;
        try {
            const { data } = await upsertPartCall(job);
           
            if (data.success) {
                // success scenario 
                Common.showSuccessAlertAutoClose(
                    this.props.isEdit ? "Part updated successfully!" : "Part created successfully!",
                    "success",
                    "Success!",
                    3000
                );
                this.props.OnSubmit();
                this.props.refreshPart();
                if (this.props.AttachmentFormData) {
                 const _data = await upsertAttachmentCall(this.props.AttachmentFormData, data.result[0].ID, Common.EntityIds.Parts);
                    
                 if (_data.data.success) {
                   // success scenario  
                   Common.showSuccessAlertAutoClose(
                     'Attachments uploaded successfully.',
                     "success",
                     "Success!",
                     3000
                   );
                 } else {
                   Common.showSuccessAlertAutoClose(
                     _data.data.message,
                     "error",
                     "Opps!",
                     3000
                   );
                 }
                  }
                  //window.location.reload(false);
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        }
        catch {

            Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
            );
        }
        finally {
            // setSubstationsLoading(false);
            this.props.refreshPart();
        }
        this.props.setAdding(false);

    }

    render() {
        return (
            <>


                <Form
                    ref={(ref) => {
                        this.form = ref;
                    }}
                    validated={this.state.formValidated}
                >
                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="customerName"> Customer Name <span className="text-danger">*</span></label>

                            { this.props.customersDropdownLoading ?
                            <select className="form-control" disabled>
                                <option>Loading customers...</option>
                            </select>
                            :
                            <select className="form-control" id="customerName"  required 
                             name="fk_CustomerID"
                             value={Common.replaceNullWithString(
                                 this.state.Part.fk_CustomerID
                               )}
                               required
                               onChange={(e) =>
                                 Common.updateInputValueInState(
                                   this.state.Part,
                                   e,
                                   this,
                                   this.state
                                 )
                               }
                            >
                            <option value="">- Please Select -</option>
                                {
                                    this.props.customers.length > 0 && <>
                                        {
                                            this.props.customers?.map((customer, idx) => {
                                                return (
                                                    <option value={customer.ID} key={idx}>{customer.CustomerName}</option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                            }
                            <Form.Control.Feedback type="invalid">
                                Customer name is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="customerPartnum"> Customer Part Number <span className="text-danger">*</span> </label>
                            <input type="text" className="form-control" id="customerPartnum" 
                            required
                            name="customerPartNumber" 
                            defaultValue={this.state.Part.customerPartNumber}
                            onChange={(e) =>
                                Common.updateInputValueInState(
                                    this.state.Part,
                                    e,
                                    this,
                                    this.state
                                )
                            }
                            placeholder="Customer Part Number"
                            />
                            <Form.Control.Feedback type="invalid">
                                Customer part number is required
                            </Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="vendorPartnum"> Vendor Part Number <span className="text-danger">*</span> </label>
                            <input type="text" className="form-control" id="vendorPartnum" 
                            required
                            name="vendorPartNumber" 
                            defaultValue={this.state.Part.vendorPartNumber}
                            onChange={(e) =>
                                Common.updateInputValueInState(
                                    this.state.Part,
                                    e,
                                    this,
                                    this.state
                                )
                            }
                            placeholder="Vendor Part Number" />
                            <Form.Control.Feedback type="invalid">
                                Vendor part number is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="clientPartnum">Client Part Number <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="clientPartnum" 
                            required
                            name="clientPartNumber"
                            defaultValue={this.state.Part.clientPartNumber}
                            onChange={(e) =>
                                Common.updateInputValueInState(
                                    this.state.Part,
                                    e,
                                    this,
                                    this.state
                                )
                            }
                            placeholder="Client Part Number" />
                            <Form.Control.Feedback type="invalid">
                                Client part number is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="uom"> UOM <span className="text-danger">*</span> </label>

                            { this.props.uomDropdownLoading ?
                            <select className="form-control" disabled>
                                <option>Loading UOMs...</option>
                            </select>
                            :
                            <select className="form-control" id="uom" 
                               name="fk_UomID"
                               value={Common.replaceNullWithString(
                                   this.state.Part.fk_UomID 
                                 )}
                                 required
                                 onChange={(e) =>
                                   Common.updateInputValueInState(
                                     this.state.Part,
                                     e,
                                     this,
                                     this.state
                                   )
                                 }
                            >
                                <option value="">- Please Select -</option>
                                {
                                    this.props.uomList.length > 0 && <>
                                        {
                                            this.props.uomList?.map((uom, idx) => {
                                                return (
                                                    <option value={uom.ID} key={idx}>{uom.Value}</option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                            }
                            <Form.Control.Feedback type="invalid">
                                UOM is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-lg-6 form-group">
                            <label className="form-label" htmlFor="category"> Category <span className="text-danger">*</span></label>

                            { this.props.categoriesDropdownLoading ?
                            <select className="form-control" disabled>
                                <option>Loading categories...</option>
                            </select>
                            :
                            <select className="form-control" id="category" name="category"
                               name="fk_CategoryID"
                               value={Common.replaceNullWithString(
                                   this.state.Part.fk_CategoryID
                                 )}
                                 required
                                 onChange={(e) =>
                                   Common.updateInputValueInState(
                                     this.state.Part,
                                     e,
                                     this,
                                     this.state
                                   )
                                 }
                            
                            >
                                <option value="">- Please Select -</option>
                                {
                                    this.props.categories.length > 0 && <>
                                        {
                                            this.props.categories?.map((category, idx) => {
                                                return (
                                                    <option value={category.ID} key={idx}>{category.Name}</option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                            }
                            <Form.Control.Feedback type="invalid">
                                Category is required
                            </Form.Control.Feedback>
                        </div>
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="description"> Description </label>
                            <textarea className="form-control" rows="4" id="description" 
                            name="description" 
                            defaultValue={this.state.Part.description}
   
                            onChange={(e) =>
                                Common.updateInputValueInState(
                                    this.state.Part,
                                    e,
                                    this,
                                    this.state
                                )
                            }
                            placeholder="Add description"></textarea>
                        </div>
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="description">Notes </label>
                            <textarea className="form-control" rows="4" id="notes" 
                                   name="notes"
                                   defaultValue={this.state.Part.notes}
   
                                   onChange={(e) =>
                                       Common.updateInputValueInState(
                                           this.state.Part,
                                           e,
                                           this,
                                           this.state
                                       )
                                   }
                            placeholder="Add notes"></textarea>
                        </div>
                        <div className="col-12">
                            <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
                            <h5 className="mb-3">Files </h5>
                            <div className="form-group">
                                <label className="form-label" htmlFor="attachment"> Add Attachment</label>
                                <input type="file" className="form-control" id="attachment" name="attachment" /*multiple="multiple"*/ onChange={this.props.onSelectAttatchmentsFiels} ref={this.props.attachmentsRef} />
                            </div>
                            {
                                this.props.selectedFiles.length > 0 && 
                                <table className="table table-borderd table-sm w-100">
                                <tbody>
                                    {
                                        this.props.selectedFiles?.map((selectedFile, idx) => {
                                            return(
                                                <tr key={idx}>
                                                    <td>{selectedFile.name}</td>
                                                    <td>
                                                        <span className="label label-light-primary label-inline text-uppercase">
                                                            {selectedFile.name.split('.').pop()}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                  
                                </tbody>
                            </table>
                            }
                          
                        </div>

                    </div>
                </Form>
            </>
        );
    }
}