import { report_For } from "../constants/enums";
import * as API from "../General/api";
import moment from "moment";

// Reports calls starts
export function getReportsCall(data) {
    let url = `${API.apiUrls.getReports.getAllReports}?ReportTypeId=${data.reportTypeChecked}&ViewTypeId=${data.viewTypeChecked}${data.fromDate != null ? `&FromDate=${data.fromDate}` : ""}${data.toDate != null ? `&ToDate=${data.toDate}` : ""}${data.fk_JobID != null ? `&SearchByJobId=${data.fk_JobID}` : ""}${data.fk_PartID != null ? `&SearchByPartId=${data.fk_PartID}` : ""}${data.fk_SubstationID != null ? `&SearchBySubStationId=${data.fk_SubstationID}` : ""}${data.fk_FeederID != null ? `&SearchByFeederId=${data.fk_FeederID}` : ""}${data.fk_CrewID != null ? `&SearchByCrewId=${data.fk_CrewID}` : ""}${data.fk_WarehouseID != null ? `&SearchByWareHouse=${data.fk_WarehouseID}` : ""}${data.fk_FromlocationID != null ? `&SearchByFromWareHouse=${data.fk_FromlocationID}` : ""}${data.fk_TolocationID != null ? `&SearchByToWareHouse=${data.fk_TolocationID}` : ""}${data.notes != null ? `&SearchByNotes=${data.notes}` : ""}`;
    
    //console.log("url", url);
    return API.getRequest(url);
}
export function getReportDetails(data) {
    let newData = {
        TransactionDate: moment(data.TransactionDate).format("YYYY-MM-DD"),
        SearchByFromLocationId: data.fk_FromLocationID,
        SearchByToLocationId: data.fk_ToLocationID,
        SearchByPartId: data.fk_PartID,
        SearchByFeederId: data.fk_FeederID,
        SearchByCrewId: data.fk_CrewID,
        SearchByTransferTypeId: data.fk_TransferTypeID,
        SearchByJobId: data.fk_JobID
    };
    return API.postRequest(API.apiUrls.getReports.getReportDetails, newData);
}
// Reports calls ends