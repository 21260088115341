import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { userRoles, userRolesWithAction } from '../constants/enums'



const check = (currentAction, user) => {
//  //console.log(currentAction, userRolesWithAction);
  var test = user;
  var testc = userRoles;
  let isAvailable = false
  
  if (user?.fk_RoleID == userRoles.Role_SuperAdmin) {
    const action = userRolesWithAction.SuperAdmin.find((obj) => {
////console.log(obj);
      return obj.actionName.trim().toLowerCase() == currentAction.toLowerCase()
    });
    if (action) {
      isAvailable = true;
    }

  } else if (user?.fk_RoleID == userRoles.Role_Admin) {
    const action = userRolesWithAction.Admin.find((obj) => {

      return obj.actionName.trim().toLowerCase() == currentAction.toLowerCase()
    });
    if (action) {
      isAvailable = true;
    }
  }
  else if (user?.fk_RoleID == userRoles.Role_CrewMember) {
    const action = userRolesWithAction.CrewMember.find((obj) => {

      return obj.actionName.trim().toLowerCase() == currentAction.toLowerCase()
    });
    if (action) {
      isAvailable = true;
    }
  }
  else if (user?.fk_RoleID == userRoles.Role_CrewLead) {
    const action = userRolesWithAction.CrewLead.find((obj) => {

      return obj.actionName.trim().toLowerCase() == currentAction.toLowerCase()
    });
    if (action) {
      isAvailable = true;
    }
  } else {
    isAvailable = false;
  }
return isAvailable;
  //   let isAvailable = false

  //  if(userRoles){
  //   const role = roles?.roleList.find((role)=>{
  //     return typeof(userRoles) == "string" ? userRoles == role?.fk_RoleID : userRoles.includes(role?.fk_RoleID)
  //   });

  //   if(role){
  //     return true;
  //   }
  //  }

  //   const modules = roles.moduleList;
  //   if(permission != null && currentModule != null){
  //     if (modules != null) {
  //       const module = modules.find((mod) => {
  //         return mod.moduleName.toLowerCase() == currentModule.toLowerCase()
  //       })

  //       if (module) {
  //         if (module.actionList != null) {

  //           const actionlist = module.actionList;
  //           const action = actionlist.find((obj) => {

  //             return obj.actionName.trim().toLowerCase() == permission.toLowerCase()
  //           });

  //           if (action) {
  //             isAvailable = true;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return isAvailable;
};
const CheckPermission = (props) => {

  const user = useSelector((state) => state.auth.user, shallowEqual);



  return (
    check(props.currentAction, user)
      ? props.yes()
      : props.no()
  )

}


export default CheckPermission;
