import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from 'date-fns';
import Select from "react-select";
import { getPartsCall } from "../../services/managementServices";
import { addPoleCompletionTransaction } from "../../services/inventoryTransectionService";
import { upsertAttachmentCall } from "../../services/attachmentService";
import {
  getAvailablePartsInWarehouseForJob,
} from "../../services/managementServices";
import { TransferTypes, userRoles, transferType_Location_From } from "../../constants/enums";
import { getFeedersCall, getSubstationsCall, getPoleTypesCall, getPoleTypePartsByPoleTypeIdCall, getCrewsCall } from "../../services/managementServices";
import * as Common from "../../General/common";
function JobDetailPoleCompletionModal({ closeCallbackPoleCompletion, TransferType, getInventoryTransactions, getStats, setInventoryTransactions, setAdding }) {
  const handleClose = () => {
    if (typeof closeCallbackPoleCompletion === "function") {
      closeCallbackPoleCompletion();
    }
  };
  const attachmentsRef = useRef()
  const { user } = useSelector((state) => state.auth)
  const { jobDetails } = useSelector((state) => state.JobManagement)
  const [AttachmentFormData, setAttachmentFormData] = useState(undefined);
  const [attachments, setAttachments] = useState([]);
  const [availableParts, setAvailableParts] = useState([]);
  const [parts, setParts] = useState([]);
  const [crews, setCrews] = useState([]);
  const [selectedParts, setselectedParts] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [feeders, setFeeders] = useState([]);
  const [poleTypes, setPoleTypes] = useState([]);
  const [poleTypeParts, setPoleTypeParts] = useState([]);

  const [fkpartId, setfkpartId] = useState(0);
  const [Qty, setQty] = useState(0);

  const [isEditPart, setIsEditPart] = useState(false);

  const [optionsValue, setOptionsValue] = useState(null);

  useEffect(() => { getParts(); getSubstations(); getFeeders(); getAvailablePartsInWarehouse(jobDetails?.ID); getCrews(); getPoleTypes(); }, []);
  const getParts = async () => {
    try {
      const { data } = await getPartsCall();
      if (data.success && data.result.length) {
        setParts(data.result);
      }
    }
    catch {
    }
    finally {
    }
  };
  const getCrews = async () => {
    try {
      const { data } = await getCrewsCall();
      if (data.success) {
        setCrews(data.result);
      } else {
        setCrews([]);
      }
    } catch {
      setCrews([]);
    } finally {
    }
  };
  const getSubstations = async () => {
    try {
      const { data } = await getSubstationsCall(jobDetails.ID);
      if (data.success && data.result.length) {
        setSubstations(data.result);
      }
    }
    catch {
    }
    finally {
    }
  };
  const getFeeders = async () => {
    try {
      const { data } = await getFeedersCall();
      if (data.success && data.result.length) {
        setFeeders(data.result);
      }
    }
    catch {
    }
    finally {
    }
  };
  const getPoleTypes = async () => {
    try {
      const { data } = await getPoleTypesCall();
      if (data.success && data.result.length) {
        setPoleTypes(data.result);
        //getPoleTypeParts(data.result[0].id)
      }
    }
    catch {
    }
    finally {
    }
  };
  const getAvailablePartsInWarehouse = async (JobID) => {
    try {
      const { data } = await getAvailablePartsInWarehouseForJob(JobID);
      if (data.success && data.result.length) {
        setAvailableParts(data.result);
      }
    } catch {
    } finally {
    }
  };
  const getPoleTypeParts = async (id) => {
    try {

      const { data } = await getPoleTypePartsByPoleTypeIdCall(id);
      if (data.success && data.result.length) {
        setPoleTypeParts(data.result);
        let sParts = data.result.map(x => { return { fk_PartID: x.fk_PartID, qty: x.qty } });
        setselectedParts([...sParts]);
      }
      else {
        setPoleTypeParts([]);
        setselectedParts([]);
      }
    }
    catch {
    }
    finally {
    }
  };

  const onSelectAttatchments = async (e) => {
    let files = e.target.files

    if (files) {
      let fd = new FormData()
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fd.append("Files", file)
      }
      setAttachmentFormData(fd);
      setAttachments([...files]);
    }
  }
  const initialValues = {
    "ID": 0,
    "fk_FromLocationID": TransferType.location.from[0].id,
    "fk_ToLocationID": TransferType.location.to[0].id,
    "fk_CrewID": [userRoles.Role_SuperAdmin, userRoles.Role_Admin].includes(user.fk_RoleId) ? "" : user.crewID,
    "fk_JobID": jobDetails.ID,
    "fk_TransferTypeID": TransferType.id,
    "fk_PoleTypeID": null,
    "fk_PoleID": null,
    "fk_FeederID": "",
    "CustomerPoleID": "",
    "MapNumber": "",
    "TransactionDate": format(new Date(), 'yyyy-MM-dd'),
    "Parts": [],
    "Notes": "",
  };
  const schema = Yup.object().shape({
    fk_FromLocationID: Yup.string().required("From Location is required"),
    fk_ToLocationID: Yup.string().required("To Location is required"),
    fk_TransferTypeID: Yup.string().required("Transfer Type is required"),
    fk_JobID: Yup.string().required("Job is required"),
    fk_CrewID: Yup.string().required("Crew is required"),
    fk_FeederID: Yup.string().required("Feeder is required"),
    //fk_PoleTypeID: Yup.string().nullable().required("Pole Type is required"),
    CustomerPoleID: Yup.string().required("Customer Pole is required"),
    TransactionDate: Yup.string().required("Transaction Date is required"),
    MapNumber: Yup.string().required("Map Number is required"),
    //Parts: Yup.array.min(1, "Please add atleast 1 part"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!(selectedParts.length > 0)) {
        Common.showSuccessAlertAutoClose(
          'Selected Pole Type hase no parts please select other Pole Type',
          "warning",
          "Warning!",
          3000
        );
      } else {
        values.Parts = [...selectedParts];
        values.Notes = document.getElementById("Notes").value;
        setInventoryTransactions([]);
        setAdding(true);
        //console.log("values", values);
        const { data } = await addPoleCompletionTransaction(values);
        //console.log("res", data);

        if (data.success) {
          Common.showSuccessAlertAutoClose(
            'Inventory Transaction added successfully',
            "success",
            "Success!",
            3000
          );
          getInventoryTransactions();
          getStats(jobDetails?.ID);
          if (AttachmentFormData) {
            const _data = await upsertAttachmentCall(AttachmentFormData, data.result.id, Common.EntityIds.PoleCompletion);
            if (_data.data.success) {
              // success scenario  
              Common.showSuccessAlertAutoClose(
                'Attachments uploaded successfully.',
                "success",
                "Success!",
                3000
              );
            } else {
              Common.showSuccessAlertAutoClose(
                _data.data.message,
                "error",
                "Opps!",
                3000
              );
             
            }
          
          }
          handleClose();
          //window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "error",
            "Opps!",
            3000
          );
          getInventoryTransactions();
          getStats(jobDetails?.ID);
        }
        setAdding(false);
      }
      setSubmitting(false);
    },
  });


  useEffect(() => {
    if(feeders.filter(x => x.fk_SubstationID == document.getElementById("fk_SubstationID").value)?.length <= 0){
      formik.setValues({
        ...formik.values,
        "fk_FeederID": ""
      });
    }
  }, [feeders]);

  const addPart = () => {
    let qty = Qty;
    //let fk_PartID = document.getElementById("part").value;
    let fk_PartID = fkpartId;
    
    if (!(qty > 0)) {
      Common.showSuccessAlertAutoClose(
        "Please enter valid quantity",
        "warning",
        "Warning!",
        3000
      );
      return;
    }
    // if (TransferType.id === TransferTypes.TTT.id) {
    //   const { availableQty } = availableParts.find(x => x.ID == fk_PartID)
    //   if (qty > toInteger(availableQty)) {
    //     Common.showSuccessAlertAutoClose(
    //       `Only ${toInteger(availableQty)} quantity is available for this parts.`,
    //       "warning",
    //       "Warning!",
    //       3000
    //     );
    //     return;
    //   }
    // }
    let index = selectedParts.findIndex((x) => x.fk_PartID === fk_PartID);
    if (index >= 0 && !isEditPart) {
      selectedParts[index].qty = selectedParts[index].qty + qty;
    }else if(index >= 0 && isEditPart){
      selectedParts[index].qty = qty;
    } else {
      selectedParts.push({ fk_PartID, qty });
    }
    setselectedParts([...selectedParts]);

    setOptionsValue(null);
    setfkpartId(0);
    setQty(0);
    setIsEditPart(false);
  };

  const removeSelectedPart = (fk_PartID) => {
    let _parts = selectedParts.filter((x) => x.fk_PartID !== fk_PartID);
    setselectedParts([..._parts]);
  };


  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Pole Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="PoleForm" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="fk_FromLocationID">
                  From
                </label>
                <select
                  className="form-control"
                  id="fk_FromLocationID"
                  name="fk_FromLocationID"
                  {...formik.getFieldProps("fk_FromLocationID")}
                >
                  {
                    TransferType.location.from.map(({ id, name }) =>
                      <option value={id}>{name}</option>
                    )
                  }
                </select>

                {formik.touched.fk_FromLocationID && formik.errors.fk_FromLocationID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_FromLocationID}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="fk_ToLocationID">
                  To
                </label>

                <select
                  className="form-control"
                  id="fk_ToLocationID"
                  name="fk_ToLocationID"
                  {...formik.getFieldProps("fk_ToLocationID")}
                >
                  {
                    TransferType.location.to.map(({ id, name }) =>
                      <option value={id}>{name}</option>
                    )
                  }
                </select>
                {formik.touched.fk_FromLfk_ToLocationIDocationID && formik.errors.fk_ToLocationID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_ToLocationID}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="TransactionDate">
                  Date
                </label>

                <input
                  type="date"
                  className="form-control"
                  id="TransactionDate"
                  name="TransactionDate"
                  {...formik.getFieldProps("TransactionDate")}
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                />
                {formik.touched.TransactionDate && formik.errors.TransactionDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.TransactionDate}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="fk_CrewID">
                  Crew
                </label>
                <select
                  className="form-control"
                  id="fk_CrewID"
                  name="fk_CrewID"
                  {...formik.getFieldProps("fk_CrewID")}
                >
                     {[userRoles.Role_SuperAdmin, userRoles.Role_Admin].includes(
                      user.fk_RoleId
                    ) ? (
                      <>
                      <option value='' >- Please Select -</option>
                      {
                      crews.map(({ id, crewName }) => (
                        <option value={id}>{crewName}</option>
                      ))
                      }
                      </>
                    ) : (
                      <option value={user.crewID}>{user.crewName}</option>
                    )}
                </select>
                {formik.touched.fk_CrewID && formik.errors.fk_CrewID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_CrewID}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="fk_SubstationID">
                  Substation
                </label>

                <select
                  className="form-control"
                  id="fk_SubstationID"
                  name="fk_SubstationID"
                  {...formik.getFieldProps("fk_SubstationID")}
                  onChange={() => { setFeeders([...feeders]) }}
                >
                   <option value='' >- Please Select -</option>
                  {
                    substations.filter(x => x.fk_JobID == jobDetails.ID).map(({ id, substationName, substationNumber }) => {
                      return (<option value={id}> {`${substationName} - ${substationNumber}`}</option>);
                    })
                  }
                </select>
                {formik.touched.fk_SubstationID && formik.errors.fk_SubstationID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_SubstationID}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="poleCompletionFeeder">
                  Feeder
                </label>

                <select
                  className="form-control"
                  id="fk_FeederID"
                  name="fk_FeederID"
                  {...formik.getFieldProps("fk_FeederID")}
                >
                  <option value="">- Please Select -</option>
                  {
                    feeders.filter(x => x.fk_SubstationID == document.getElementById("fk_SubstationID").value).map(({ id, feederName, feederNumber }, index) => {
                      return (<option value={id} > {`${feederName} - ${feederNumber}`}</option>);
                    })
                  }
                </select>
                {formik.touched.fk_FeederID && formik.errors.fk_FeederID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_FeederID}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="fk_PoleTypeID">
                  Pole Type
                </label>
                <select className="form-control"
                  id="fk_PoleTypeID"
                  name="fk_PoleTypeID"
                  {...formik.getFieldProps("fk_PoleTypeID")}
                  onChange={(e) => {setselectedParts([]); getPoleTypeParts(e.target.value); formik.getFieldProps("fk_PoleTypeID").onChange(e); }}
                >
                  <option value="">- Please Select -</option>
                  {
                    poleTypes.map(({ id, typeName }) => {
                      return (<option value={id}> {`${typeName}`}</option>);
                    })
                  }
                </select>
                {formik.touched.fk_PoleTypeID && formik.errors.fk_PoleTypeID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fk_PoleTypeID}</div>
                  </div>
                ) : null}
              </div> */}
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="CustomerPoleID">
                  Customer Pole ID
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="CustomerPoleID"
                  name="CustomerPoleID"
                  placeholder="Customer pole ID"
                  {...formik.getFieldProps("CustomerPoleID")}
                />
                {formik.touched.CustomerPoleID && formik.errors.CustomerPoleID ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.CustomerPoleID}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label" htmlFor="MapNumber">
                  Map Number
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="MapNumber"
                  name="MapNumber"
                  placeholder="Map number"
                  {...formik.getFieldProps("MapNumber")}
                />
                {formik.touched.MapNumber && formik.errors.MapNumber ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.MapNumber}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12 form-group">
                <label className="form-label" htmlFor="transferNotes">
                  Transfer Notes
                </label>
                <textarea
                  className="form-control"
                  rows="4"
                  id="Notes"
                  name="Notes"
                  placeholder="Add notes"
                ></textarea>
              </div>
            </div>
            <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <div className="row align-items-end">
              <div className="col-md-5">
                <div className="form-group">
                  <label className="form-label" htmlFor="partNum">
                    Part
                  </label>
                  <Select 
                  id="Parts"
                  name="Parts"
                  placeholder="Part"
                  value={optionsValue}
                  onChange={(val) => {
                    setfkpartId(val.value);
                    setOptionsValue(val);
                  }}
                    options={(TransferType.id === TransferTypes.TTT.id
                      ? availableParts
                      : parts
                    ).map(
                      ({
                        id,
                        customerPartNumber,
                        clientPartNumber,
                        vendorPartNumber,
                      }) => (
                        {value: id, label: `${customerPartNumber} / ${vendorPartNumber} / ${clientPartNumber}`}
                      )
                    )}
                  
                  />
                  {/* <input
                  type="text"
                  className="form-control"
                  id="partNum"
                  placeholder="Part#"
                /> */}
                  {/* <Select
                  //id="partNum"
                  //name="partNum"
                  value={SelectedPart}
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]}
                  onChange={(val) => {
                    //console.log(val); 
                  }}
                /> */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label className="form-label" htmlFor="qty">
                    Qty
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="qty"
                    value={Qty}
                    onChange={(e) => {
                      setQty(parseInt(e.target.value));
                    }}
                    placeholder="Add quantity"
                    min={0}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    disabled={fkpartId <= 0 || Qty <= 0}
                    onClick={addPart}
                  >
                    {isEditPart ? "Edit" : "Add"}
                  </button>
                </div>
              </div>
            </div>

            {selectedParts?.length <= 0 ? (
                   <div className="fv-plugins-message-container">
                     <div className="fv-help-block">Please add atleast 1 part</div>
                   </div>
                 ) : null}   
            

            <table
              id="UserRecordsGrid"
              className="table table-bordered table-hover table-striped table-sm w-100"
            >
              <thead>
                <tr>
                  <th>
                    <i className="fa fa-comments mr-1"></i> Part Description
                  </th>
                  <th>
                    <i className="fa fa-list-ul mr-1"></i> Part Number
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Qty
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedParts?.map((element, index, array) => {
                  const { fk_PartID, qty } = element;
                  const part = parts?.find(x => x.ID == fk_PartID);
                  const { customerPartNumber, clientPartNumber, vendorPartNumber, description } = part;
                  return (
                    <tr>
                      <td>{description}</td>
                      <td>{`${customerPartNumber} / ${vendorPartNumber} / ${clientPartNumber}`}</td>
                      <td>
                        <span className="label label-light-danger label-inline">
                          {qty}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn btn-icon btn-outline-danger btn-xs"
                          type="button"
                          onClick={() => removeSelectedPart(fk_PartID)}
                        >
                          <i className="fa fa-trash-alt"></i>
                        </button>
                        <button
                          className="btn btn-icon btn-outline-primary btn-xs ml-3"
                          type="button"
                          onClick={() => {
                            setIsEditPart(true);
                            setOptionsValue({
                              value: fk_PartID,
                              label: `${customerPartNumber} / ${vendorPartNumber} / ${clientPartNumber}`
                            });
                            setfkpartId(fk_PartID);
                            setQty(qty);
                          }}
                        >
                          <i className="fa fa-pen"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <div className="row">
              <div className="col-12">
                <h5 className="mb-3">Files</h5>
              </div>
              <div className="col form-group">
                <label className="form-label" htmlFor="selectDate">
                  Attach File
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="Attachments"
                  name="Attachments"
                  multiple="multiple"
                  onChange={onSelectAttatchments}
                  ref={attachmentsRef}
                />
              </div>
              <div className="col-12">
                <table className="table table-borderd table-sm w-100">
                  <tbody>
                    {
                      attachments.map(({ name, type }) => {
                        return (<tr>
                          <td>{name}</td>
                          <td>
                            <span className="label label-light-primary label-inline">
                              {type}
                            </span>
                          </td>
                        </tr>)
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <input id="PoleFormSubmitBtn" type="submit" value="submit" style={{ display: "none" }} />
          </form>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <i class="fa fa-times mr-1"></i>Cancel
          </Button>
          <Button type="button" variant="primary" size="sm" className={formik.isSubmitting && "pr-9"} disabled={!formik.isValid || formik.isSubmitting || selectedParts?.length <= 0} onClick={() => { document.getElementById("PoleFormSubmitBtn").click() }}>
            <i className="fa fa-save mr-1"></i> <span>Save</span> {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobDetailPoleCompletionModal;
