import * as API from "../General/api";

//users calls starts
export function getAllActiveUsersCall() {
    return API.getRequest(API.apiUrls.userManagement.users.getAllActiveRole);
}
export function getAllUsersCall() {
    return API.getRequest(API.apiUrls.userManagement.users.getAllusers + `?JobId=0`);
}
export function getAllJobUsersCall(jobId) {
    return API.getRequest(API.apiUrls.userManagement.users.getAllusers + `?JobId=${jobId}`);
}
export function getUserByIdCall(id) {
    return API.getRequest(API.apiUrls.userManagement.users.getUserById + `?ID=${id}`);
}
export function upsertUser(userData) {
    return API.postRequest(API.apiUrls.userManagement.users.upsertUser,userData);
}
export function deleteUser(UserId) {
    return API.postDeleteRequest(API.apiUrls.userManagement.users.deleteUser,UserId);
}
export function getUnassignedUsersCall() {
    return API.getRequest(API.apiUrls.userManagement.users.getUnassignedUsers);
}
export function resetUserPasswordCall(userData) {
    return API.postRequest(API.apiUrls.userManagement.users.resetPassword,userData);
}
export function resetUserPasswordByTokenCall(userData) {
    return API.postRequest(API.apiUrls.userManagement.users.forgotPassword,userData);
}
export function updateUserPasswordCall(userData) {
    return API.postRequest(API.apiUrls.userManagement.users.updatePassword,userData);
}
//users calls end
