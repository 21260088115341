import {
    SET_STATS
  } from "./actionTypes";

  export const setStats = stats => {
    return {
      type: SET_STATS,
      payload: stats,
    };
  };

 