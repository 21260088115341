import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Api from "../../General/api";
import { useSelector, useDispatch } from "react-redux";
import { setPoleTypes } from "../../../store/management/actions";
import {
  deletePoleTypeCall,
  getPoleTypesByIdCall,
  getPoleTypePartsByPoleTypeIdCall,
  upsertPoleTypeCall,
  getPartsCall,
} from "../../services/managementServices";
import {
  getAttachmentsCall,
  upsertAttachmentCall,
} from "../../services/attachmentService";
import { CustomLoader } from "../../components/common/CustomLoader";
import AddEditPoleType from "./AddEditPoleType";
import * as Common from "../../General/common";
import { EntityIds } from "../../General/common";
import JobsDetail from "../JobsDetail";
import CheckPermission from "../../General/CheckPermission";
import { timeout_for_data_tables, userActions } from "../../constants/enums";
import PoleTypeListing from "./PoleTypeListing";

function PoleType() {
  const attachmentsRef = useRef();

  const [poleTypesLoading, setPoleTypesLoading] = useState(false);
  const poleTypes = useSelector((store) => store.Management.poleTypes);
  const [poleTypeDetails, setPoleTypeDetails] = useState(null);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [poleTypeParts, setPoleTypeParts] = useState([]);

  const [partNumbers, setPartNumbers] = useState([]);
  const [AttachmentFormData, setAttachmentFormData] = useState(undefined);
  const [selectedFiles, setSelectedFiles] = useState([]);

   // add poletype states starts
   const [Id, setId] = useState(0);
   const [CreatedBy, setCreatedBy] = useState("0");
   const [ModifiedBy, setModifiedBy] = useState("0");
   const [Notes, setNotes] = useState("");
   const [TypeName, setTypeName] = useState("");
   const [IsPole, setIsPole] = useState(true);
   const [Active, setActive] = useState(true);

  const onSelectAttatchmentsFiels = async (e) => {
    let files = e.target.files;
    
    if (files) {
      let fd = new FormData();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fd.append("Files", file);
      }
      setAttachmentFormData(fd);
      setSelectedFiles([...files]);
    }
  };

  const dispatch = useDispatch();

  // function which fetches the poleTypes
  const getPoleTypes = async () => {
    setPoleTypesLoading(true);
    try {
      const { data } = await getPoleTypesByIdCall(0);

      if (data.success) {
        // success scenario
        setPoleTypesLoading(false);
        if (data.result.length > 0) {
          dispatch(setPoleTypes(data.result));
        }
      } else {
        setPoleTypesLoading(false);
      }
    } catch {
      setPoleTypesLoading(false);
    } finally {
      setPoleTypesLoading(false);
    }
  };

  // function which fetches the poleTypeParts
  const getPoleTypeParts = async (id) => {
    try {
      const { data } = await getPoleTypePartsByPoleTypeIdCall(id);

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          const dataList = data.result?.map((el, idx) => {
            return {
              Notes: el.notes,
              CreatedBy: el.createdBy,
              ModifiedBy: el.modifiedBy === null ? "0" : el.modifiedBy,
              Id: el.id,
              fk_PoleTypeID: el.fk_PoleTypeID,
              fk_PartID: el.fk_PartID,
              Qty: el.qty,
            };
          });
          setPoleTypeParts(dataList);
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  // function which fetches the partNumbers
  const getPartNumbers = async () => {
    try {
      const { data } = await getPartsCall();
      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          setPartNumbers(data.result);
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  // fetch poleTypes and partnumbers when component mounts
  useEffect(() => {
    getPoleTypes();
    getPartNumbers();
  }, []);

  // add or edit poletype function starts
  const addorEditPoleType = async (poleTypeData) => {
    dispatch(setPoleTypes([]));
    setAdding(true);
    try {
     
      const { data } = await upsertPoleTypeCall(poleTypeData);

      

      if (data.success) {
        // success scenario
        Common.showSuccessAlertAutoClose(
          data.message,
          "success",
          "Success!",
          3000
        );
        if (AttachmentFormData) {
          const _data = await upsertAttachmentCall(
            AttachmentFormData,
            data.result.id,
            Common.EntityIds.PoleType
          );
          
          if (_data.data.success) {
            // success scenario
            Common.showSuccessAlertAutoClose(
              "Attachments uploaded successfully.",
              "success",
              "Success!",
              3000
            );
          } else {
            Common.showSuccessAlertAutoClose(
              _data.data.message,
              "error",
              "Opps!",
              3000
            );
          }
        }
        handleCloseAddPolesModal();
        setPoleTypeDetails(null);
        getPoleTypes();
        //window.location.reload(false);
      } else {
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch {
      Common.showSuccessAlertAutoClose(
        "some error occured while processing your request",
        "warning",
        "Failed!",
        3000
      );
    } finally {
      getPoleTypes();
    }
    setAdding(false);
  };
  // add or edit poletype function ends
  const handleDeletePoleType = (poletypeId) => {
    var msg = "You want to delete this Pole Type.";
    Common.showConfirmation(msg, "", deletePoleType, null, poletypeId)

};
  // delete poletype function starts
  const deletePoleType = async (poleTypeId) => {
    if (poleTypeId > 0) {
      dispatch(setPoleTypes([]));
      setDeleting(true);
      try {
        const { data } = await deletePoleTypeCall(poleTypeId);

        if (data.success) {
          // success scenario
          Common.showSuccessAlertAutoClose(
            data.message,
            "success",
            "Success!",
            3000
          );
          //window.location.reload(false);
        } else {
          Common.showSuccessAlertAutoClose(
            data.message,
            "warning",
            "Failed!",
            3000
          );
        }
        getPoleTypes();
      } catch {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      } finally {
       getPoleTypes();
      }
      setDeleting(false);
    }
  };
  // delete substation function ends

  const getAttachments = async (recordId, entityId) => {
    try {
      const { data } = await getAttachmentsCall(recordId, entityId);

      if (data.success) {
        // success scenario
        if (data.result.length > 0) {
          const filesList = data.result?.map((el, idx) => {
            return {
              id: el.id,
              name: el.fileUrl,
              url: el.fileUrl,
              type: el.fileType,
              size: el.fileSize,
            };
          });
          setSelectedFiles(filesList);
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  

  // for Add Crew modal popup
  const [showAddPolesModal, setShowAddPolesModal] = useState(false);
  const handleCloseAddPolesModal = () => setShowAddPolesModal(false);
  const handleShowAddPolesModal = () => setShowAddPolesModal(true);

  return (
    <>
      <JobsDetail />

      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img
                    src="/media/svg/custom/pole.svg"
                    width="30"
                    height="30"
                    className="mr-3"
                    alt="summary"
                  />
                  Pole Type List
                </h3>
              </div>
              <div className="card-toolbar">
                <CheckPermission
                  currentAction={userActions.Pole.Action_AddPole}
                  yes={() => (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setPoleTypeDetails(null);
                        setId(0);
                        setCreatedBy("0");
                        setModifiedBy("0");
                        setTypeName("");
                        setNotes("");
                        setIsPole(true);
                        setActive(true);
                        setPoleTypeParts([]);
                        setSelectedFiles([]);
                        handleShowAddPolesModal();
                      }}
                    >
                      <i className="fa fa-plus mr-1"></i>Add Pole Type
                    </button>
                  )}
                  no={() => null}
                />
              </div>
            </div>
            {poleTypesLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !poleTypesLoading && !deleting && !adding && poleTypes?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No PoleTypes
                </div>
              ) : (
                <PoleTypeListing
                  poleTypes={poleTypes}
                  handleDeletePoleType={handleDeletePoleType}
                  setPoleTypeDetails={setPoleTypeDetails}
                  getPoleTypeParts={getPoleTypeParts}
                  handleShowAddPolesModal={handleShowAddPolesModal}
                  getAttachments={getAttachments}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add Crew Modal  */}

      <AddEditPoleType
        showAddPolesModal={showAddPolesModal}
        setShowAddPolesModal={setShowAddPolesModal}
        handleCloseAddPolesModal={handleCloseAddPolesModal}
        handleShowAddPolesModal={handleShowAddPolesModal}
        poleTypeDetails={poleTypeDetails}
        setPoleTypeDetails={setPoleTypeDetails}
        addorEditPoleType={addorEditPoleType}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        partNumbers={partNumbers}
        setPartNumbers={setPartNumbers}
        poleTypeParts={poleTypeParts}
        setPoleTypeParts={setPoleTypeParts}
        onSelectAttatchmentsFiels={onSelectAttatchmentsFiels}
        attachmentsRef={attachmentsRef}
        Id={Id}
        setId={setId}
        CreatedBy={CreatedBy}
        setCreatedBy={setCreatedBy}
        ModifiedBy={ModifiedBy}
        setModifiedBy={setModifiedBy}
        Notes={Notes}
        setNotes={setNotes}
        TypeName={TypeName}
        setTypeName={setTypeName}
        IsPole={IsPole}
        setIsPole={setIsPole}
        Active={Active}
        setActive={setActive}
        adding={adding}
      />

      {/* <Modal
      show={showAddPolesModal}
      onHide={handleCloseAddPolesModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="py-3" closeButton>
        <Modal.Title>Add Pole Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="form-group">
              <label className="form-label" htmlFor="CrewName">Pole Type Name</label>
              <input type="text" className="form-control" placeholder="Pole type name" id="CrewName" name="CrewName" required />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="crewNotes">Note</label>
              <textarea className="form-control" placeholder="Add notes" id="htmlFor" rows="3" spellcheck="false"></textarea>
            </div>
          </div>
        </div>
        <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>
        <h5 className="mb-5">Pole Parts</h5>
        <table className="table border-0 table-sm w-100">
          <tbody>
            {new Array(2).fill(0).map((index) => {
              return (
                <tr>
                  <td>Widget A</td>
                  <td>
                    <span className="label label-light-danger label-inline">
                      05
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="row align-items-end">
          <div className="col-md-5 form-group">
            <label className="form-label" htmlFor="partNum">Part Number</label>
            <input type="text" className="form-control" id="partNum" placeholder="Part#" />
          </div>
          <div className="col-md-5 form-group">
            <label className="form-label" htmlFor="qty"> Qty</label>
            <input type="number" className="form-control" id="qty" placeholder="Add quantity" />
          </div>
          <div className="col-md-2 mb-2">
            <button className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Add</button>
          </div>
        </div>
        <div className="mt-3">
          <Link className="text-primary">
            <i className="fa fa-plus-circle mr-1 text-primary"></i> Add More parts
          </Link>
        </div>
        <div class="separator separator-solid separator-border-1 separator-primary my-4"></div>

        <div className="row">
        <div className="col-12">
              <h5 className="mb-3">Files</h5>
            </div>
          <div className="col">
            <div className="form-group">
              <label className="form-label" htmlFor="selectDate"> Attach File </label>
              <input type="file" className="form-control" id="selectDate" />
            </div>
          </div>
          <div className="col-12">
          <table className="table table-borderd table-sm w-100">
          <tbody>
            <tr>
              <td>File name A</td>
              <td>
                <span className="label label-light-primary label-inline">
                  Pdf
                </span>
              </td>
            </tr>
            <tr>
              <td>File name A</td>
              <td>
                <span className="label label-light-primary label-inline">
                  Jpg
                </span>
              </td>
            </tr>
          </tbody>
        </table>
          </div>
        </div>
        

      </Modal.Body>
      <Modal.Footer className="py-3">
        <Button variant="secondary" size="sm" onClick={handleCloseAddPolesModal}>
         <i className="fa fa-times mr-1"></i> Cancel
        </Button>
        <Button variant="primary" size="sm"> <i className="fa fa-save mr-1"></i> Save</Button>
      </Modal.Footer>
    </Modal> */}
    </>
  );
}

export default PoleType;
