import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'


function FeedersListing({
    feeders,
    substations,
    handleDeleteFeeder,
    setFeederDetails,
    handleShowAddFeedersModal
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#FeedersListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });

    //console.log("feeders", feeders);


    return (
        <>
          <div className="card-body">
              {/* {
                feedersLoading ? (
                  <div className="d-flex justify-content-center">
                    <CustomLoader />
                  </div>
                ) : (!feedersLoading && (feeders?.length === 0 || feeders?.filter(x=> substations.map(x=> x.ID).some((element) => element == x.fk_SubstationID))?.length === 0)) ? (
                  <div className="d-flex justify-content-center">
                    No Feeders 
                  </div>
                ) : ( */}
              <table
                id="FeedersListing"
                className="table table-bordered table-hover table-striped table-sm w-100"
              >
                <thead>
                  <tr>
                    <th data-priority="1">
                      <i className="fa fa-users mr-1"></i> Feeder Name
                    </th>
                    <th>
                      <i className="fa fa-list-ul mr-1"></i> Feeder Number
                    </th>
                    <th>
                      <i className="fa fa-dot-circle mr-1"></i> Substation
                    </th>
                    <th>
                      <i className="fa fa-dot-circle mr-1"></i> Meter Count
                    </th>
                    <th>
                      <i className="fa fa-dot-circle mr-1"></i> Total Miles
                    </th>
                    <th>
                      <i className="fa fa-dot-circle mr-1"></i> Pole Count
                    </th>
                    <th>
                      <i className="fa fa-dot-circle mr-1"></i> CafPassing
                    </th>
                    <th>
                      <i className="fa fa-comments mr-1"></i> Notes
                    </th>
                    <th data-priority="2" className="text-center min-width-110">
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                  {feeders?.filter(x=> substations.map(x=> x.ID).some((element) => element == x.fk_SubstationID)).map((feeder) => {
                    return (
                      <tr key={feeder.ID}>
                        <td>{feeder.FeederName}</td>
                        <td>{feeder.FeederNumber}</td>
                        <td>{feeder.SubstationName}</td>
                        <td>
                          <span className="label label-light-danger label-inline">
                            {feeder.MeterCount}
                          </span>
                        </td>
                        <td>
                          <span className="label label-light-info label-inline">
                            {feeder.TotalMiles}
                          </span>
                        </td>
                        <td>
                          <span className="label label-light-success label-inline">
                            {feeder.PoleCount}
                          </span>
                        </td>
                        <td>{feeder.CafPassings}</td>

                        <td>
                          {feeder.Notes}
                        </td>
                        <td align="center">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Delete Feeder </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-danger btn-xs" onClick={() => {
                              //setIsDeletingFeeder(true);
                              handleDeleteFeeder(feeder.ID);
                              //setIsDeletingFeeder(false);
                            }}>
                              <i className="fa fa-trash-alt"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Edit Feeder </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3" onClick={() => {
                              setFeederDetails(feeder);
                              handleShowAddFeedersModal();
                            }}>
                              <i className="fa fa-pen"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* )
            } */}
            </div>
        </>
    );
}

export default FeedersListing;
