import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'
import { userActions } from "../../constants/enums";
import CheckPermission from "../../../app/General/CheckPermission";


function JobUsersPageListing({
   allUsers,
   deleteUserHandle
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#JobUsersPageListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });



    return (
        <>
            <div className="card-body">
          {/* {jobsLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : ( */}
            <table
              id="JobUsersPageListing"
              className="table table-bordered table-hover table-striped table-sm w-100"
            >
              <thead>
                <tr>
                  <th>
                    <i className="fa fa-user-plus mr-1"></i> User Role
                  </th>
                  <th>
                    <i className="fa fa-user mr-1"></i> Full Name
                  </th>
                  <th>
                    <i className="fa fa-envelope mr-1"></i> Email
                  </th>
                  {/* <th>
                    <i className="fa fa-users mr-1"></i> Crew Name
                  </th> */}
                  <th>
                    <i className="fa fa-mobile-alt mr-1"></i> Mobile{" "}
                  </th>
                  <th>
                    <i className="fa fa-toggle-on mr-1"></i> Is Active?
                  </th>
                  <th className="text-center min-width-110">
                    <i className="fa fa-bolt mr-1"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allUsers?.map((usersList) => {
                  return (
                    <tr key={usersList.id}>
                      <td>
                        <span className="label label-primary label-inline">
                          {usersList.RoleName}
                        </span>
                      </td>
                      <td>{usersList.FirstName + " " + usersList.LastName}</td>
                      <td>{usersList.email}</td>
                      {/* <td>{usersList.CrewName ? usersList.CrewName : "---"}</td> */}
                      <td>{usersList.Phone}</td>
                      <td>
                        <span
                          className={`label label-light-${usersList.Active === "1" ? "success" : "danger"
                            } label-inline`}
                        >
                          {usersList.Active === "1" ? "Active" : "Deactivated"}
                        </span>
                      </td>
                      <td align="center">

                        <CheckPermission
                          currentAction={userActions.userManagment.Action_JobUserActions}
                          yes={() => (<>
                            <OverlayTrigger
                              placement={"top"}
                              overlay={<Tooltip> Remove User From Job </Tooltip>}
                            >
                              <button
                                className="btn btn-icon btn-outline-danger btn-xs"
                                onClick={() => deleteUserHandle(usersList.id)}
                              >
                                <i className="fa fa-trash-alt"></i>
                              </button>
                            </OverlayTrigger></>)}
                          no={() => null}
                        />

                        {/* <OverlayTrigger
                          placement={"top"}
                          overlay={<Tooltip> Edit User </Tooltip>}
                        >
                          <button
                            // onClick={handleShowEditUser}
                            onClick={() => handleShow(usersList)}
                            className="btn btn-icon btn-outline-primary btn-xs ml-3"
                          >
                            <i className="fa fa-pen"></i>
                          </button>
                        </OverlayTrigger> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          {/* )} */}
        </div>
        </>
    );
}

export default JobUsersPageListing;
