import React, { useEffect, useState } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import JobsDetail from "../JobsDetail";
import { report_For, timeout_for_data_tables, TransferTypes, no_of_rows_on_single_page } from "../../constants/enums";
import { format, parseISO } from 'date-fns'


function CustomersListing({
    customerList,
    handleDeleteCustomer,
    handleEditCustomerModal,
    setIsEdit
}) {

  
    useEffect(() => {
     // const timeout = setTimeout(() => {
            $("#CustomersListing").DataTable({
                              responsive: true,
                              pageLength: no_of_rows_on_single_page,
                              destroy: true,
                              columnDefs: [
                                  { responsivePriority: 1, targets: 0 },
                                  { responsivePriority: 2, targets: -1 },
                              ],
                              dom:
                                  "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                                  "<'row'<'col-sm-12'tr>>" +
                                  "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                              buttons: [
                                  {
                                      extend: "csvHtml5",
                                      text: "CSV",
                                      titleAttr: "Generate CSV",
                                      className: "btn btn-sm btn-primary mr-2",
                                      exportOptions: {
                                        columns: ':not(:last-child)',
                                      }
                                  },
                                  {
                                      extend: "copyHtml5",
                                      text: "Copy",
                                      titleAttr: "Copy to clipboard",
                                      className: "btn btn-sm btn-light",
                                  },
                              ],
                          });
                   //     }, 500);

                     //   return () => clearTimeout(timeout);
    });

//console.log("customerlist", customerList);

    return (
        <>
              <div className="card-body">
            {/* {
                customerLoading ? (
                  <div className="d-flex justify-content-center">
                    <CustomLoader />
                  </div>
                ) : (!customerLoading && customerList?.length === 0) ? (
                  <div className="d-flex justify-content-center">
                    No Customers
                  </div>
                ) : ( */}
              <table
                id="CustomersListing"
                className="table table-bordered table-hover table-striped table-sm w-100"
              >
                <thead>
                  <tr>
                    <th data-priority="1">
                      <i className="fa fa-users mr-1"></i> Customer Name
                    </th>
                    
                    <th>
                      <i className="fa fa-comments mr-1"></i> Notes
                    </th>
                    <th data-priority="2" className="text-center min-width-110">
                      <i className="fa fa-bolt mr-1"></i> Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {
                        new Array(10).fill(0).map(index => {

                        })
                    } */}
                  {/* {new Array(50).fill(0).map((index) => { */}
                    {customerList?.map((customer) => {
                    return (
                      <tr key={customer.ID}>
                        <td>{customer.CustomerName}</td>
                        
                        <td>
                        {customer.Notes}
                        </td>
                        <td align="center">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Delete Customer </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-danger btn-xs"
                             onClick={()=>handleDeleteCustomer(customer.ID)}
                            >
                              <i className="fa fa-trash-alt"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"top"}
                            overlay={<Tooltip> Edit Customer </Tooltip>}
                          >
                            <button className="btn btn-icon btn-outline-primary btn-xs ml-3"
                            onClick={()=>{handleEditCustomerModal(customer); setIsEdit(true);}}>
                              <i className="fa fa-pen"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
                {/* )} */}
            </div>
        </>
    );
}

export default CustomersListing;
