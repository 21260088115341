import React, { useState, useEffect } from "react";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { getSubstationsCall, upsertSubstationCall, getSubstationActivePrioritiesCall, deleteSubstationCall } from "../../services/managementServices";
import { CustomLoader } from "../../components/common/CustomLoader";
import AddEditSubstation from "./AddEditSubstation";
import * as Common from "../../General/common";
import JobsDetail from "../JobsDetail";
import { timeout_for_data_tables, priorities } from "../../constants/enums";
import SubstationsListing from "./SubstationsListing";

function Substations() {

    const [substationsLoading, setSubstationsLoading] = useState(false);
    const { jobDetails } = useSelector((state) => state.JobManagement)
    const [substationDetails, setSubstationDetails] = useState(null);
    const [substationActivePriorities, setSubstationActivePriorities] = useState([]);
    const [adding, setAdding] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [substations, setSubstations] = useState([]);

    const [id, setId] = useState(0);
    const [createdBy, setCreatedBy] = useState("");
    const [modifiedBy, setModifiedBy] = useState("");
    const [substationName, setSubstationName] = useState("");
    const [substationNumber, setSubstationNumber] = useState("");
    const [fk_PriorityID, setFkPriorityId] = useState(substationActivePriorities?.length > 0 ? priorities.Medium : 0);
    const [fk_JobID, setFkJobId] = useState(0);
    const [notes, setNotes] = useState("");

    /* BLURRED STATES STARTS */

    const [substationNameBlurred, setSubstationNameBlurred] = useState(false);
    const [substationNumberBlurred, setSubstationNumberBlurred] = useState(false);
    const [fk_PriorityIDBlurred, setFkPriorityIdBlurred] = useState(false);

    /* BLURRED STATES ENDS */

    const dispatch = useDispatch();

    // function which fetches the substations
    const getSubstations = async () => {
      setSubstationsLoading(true);
      try {
        const { data } = await getSubstationsCall(jobDetails.ID);
       
        if(data.success){
          // success scenario
          setSubstationsLoading(false);
          if(data.result.length > 0){
           setSubstations(data.result);
          }
        }else{
          setSubstationsLoading(false);
        }
      }
      catch {
        setSubstationsLoading(false);
      }
      finally {
        setSubstationsLoading(false);
      }
  };

    // function which fetches the priorities
    const getSubstationActivePriorities = async () => {
      try {
        const { data } = await getSubstationActivePrioritiesCall();
       
        if(data.success){
          // success scenario
          if(data.result.length > 0){
            
            setSubstationActivePriorities(data.result);
          }
        }else{
          
        }
      }
      catch {
        
      }
      finally {
        
      }
  };

  // fetch substations and active priorities when component mounts
  useEffect(() => {
    getSubstations();
    getSubstationActivePriorities();
  }, []);

    // add or edit substation function starts
   
    const addorEditSubstation = async (substationData) => {
      setSubstations([]);
      setAdding(true);
        try {
          //console.log("subdat", substationData);
            const { data } = await upsertSubstationCall(substationData);
            //console.log("data", data);
            
          
            if (data.success) {
                // success scenario 
                Common.showSuccessAlertAutoClose(
                   substationDetails != null ? "Substation updated successfully!" : "Substation created successfully!",
                    "success",
                    "Success!",
                    3000
                );
                handleCloseAddSubstationModal();
                setSubstationDetails(null);
                getSubstations();
                //window.location.reload(false);
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        }
        catch {

            Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
            );
        }
        finally {
        
        }
        setAdding(false);
    }
    // add or edit substation function ends

    const handleDeleteSubstation = (substationId) => {
      var msg = "You want to delete this Substation.";
      Common.showConfirmation(msg, "", deleteSubstation, null, substationId)
  
  };

    // delete substation function starts
    const deleteSubstation = async (substationId) => {
      
      if (substationId > 0) {
        setSubstations([]);
      setDeleting(true);
        try {
          const { data } = await deleteSubstationCall(substationId);
         
          if (data.success) {
              // success scenario 
              Common.showSuccessAlertAutoClose(
                 "Substation deleted successfully!",
                  "success",
                  "Success!",
                  3000
              );

              getSubstations();
              //window.location.reload(false);
           
          } else {
              Common.showSuccessAlertAutoClose(
                  data.message,
                  "warning",
                  "Failed!",
                  3000
              );
          }
          
      }
      catch {
  
          Common.showSuccessAlertAutoClose(
              "some error occured while processing your request",
              "warning",
              "Failed!",
              3000
          );
      }
      finally {
        
      }
      setDeleting(false);
      }
      
    }
    // delete substation function ends

   
    
      // for Add Crew modal popup
      const [showAddSubstationsModal, setShowAddSubstationsModal] = useState(false);
      const handleCloseAddSubstationModal = () => setShowAddSubstationsModal(false);
      const handleShowAddSubstationsModal = () => setShowAddSubstationsModal(true);

     

  return (
    <>
      <JobsDetail />
      <div className="row">
        <div className="col-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <h3 className="mb-0">
                  <img src="/media/svg/custom/substation.svg" width="30" height="30" className="mr-3" alt="summary"/>
                  Substations List
                </h3>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSubstationNameBlurred(false);
                    setSubstationNumberBlurred(false);
                    setFkPriorityIdBlurred(false);
                    setSubstationDetails(null);
                    setId(0);
                    setCreatedBy("");
                    setModifiedBy("");
                    setSubstationName("");
                    setSubstationNumber("");
                    setFkPriorityId(substationActivePriorities?.length > 0 ? priorities.Medium : 0);
                    setNotes("");
                    handleShowAddSubstationsModal();
                  }}
                >
                  <i className="fa fa-plus mr-1"></i>Add Substation
                </button>
              </div>
            </div>
             {substationsLoading || deleting || adding ? (
                <div className="d-flex justify-content-center p-10">
                  <CustomLoader />
                </div>
              ) : !substationsLoading && !deleting && !adding && substations?.length === 0 ? (
                <div className="d-flex justify-content-center p-10">
                  No Substations
                </div>
              ) : (
                <SubstationsListing
                  substations={substations}
                  substationActivePriorities={substationActivePriorities}
                  handleDeleteSubstation={handleDeleteSubstation}
                  setSubstationDetails={setSubstationDetails}
                  handleShowAddSubstationsModal={handleShowAddSubstationsModal}
                />
              )
            }
          </div>
        </div>
      </div>

      {/* add Crew Modal  */}

      <AddEditSubstation
        showAddSubstationsModal={showAddSubstationsModal}
        setShowAddSubstationsModal={setShowAddSubstationsModal}
        handleCloseAddSubstationModal={handleCloseAddSubstationModal}
        handleShowAddSubstationsModal={handleShowAddSubstationsModal}
        substationDetails={substationDetails}
        setSubstationDetails={setSubstationDetails}
        addorEditSubstation={addorEditSubstation}
        substationActivePriorities={substationActivePriorities}
        setSubstationActivePriorities={setSubstationActivePriorities}
        id={id}
        setId={setId}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        modifiedBy={modifiedBy}
        setModifiedBy={setModifiedBy}
        substationName={substationName}
        setSubstationName={setSubstationName}
        substationNumber={substationNumber}
        setSubstationNumber={setSubstationNumber}
        fk_PriorityID={fk_PriorityID}
        setFkPriorityId={setFkPriorityId}
        fk_JobID={fk_JobID}
        setFkJobId={setFkJobId}
        notes={notes}
        setNotes={setNotes}
        adding={adding}
        substationNameBlurred={substationNameBlurred}
        setSubstationNameBlurred={setSubstationNameBlurred}
        substationNumberBlurred={substationNumberBlurred}
        setSubstationNumberBlurred={setSubstationNumberBlurred}
        fk_PriorityIDBlurred={fk_PriorityIDBlurred}
        setFkPriorityIdBlurred={setFkPriorityIdBlurred}
      />

      {/* <Modal
        show={showAddSubstationsModal}
        onHide={handleCloseAddSubstationModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substationName">
                Substation Name
              </label>
              <input
                type="text"
                className="form-control"
                id="substationName"
                name="substationName"
                value={substationName}
                onChange={changeSubstationName}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substationNumber">
                Substation Number
              </label>
              <input
                type="text"
                className="form-control"
                id="substationNumber"
                name="substationNumber"
                value={substationNumber}
                onChange={changeSubstationNumber}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label className="form-label" htmlFor="substationPriority">
                Priority
              </label>
              <select
                className="form-control"
                id="substationPriority"
                name="substationPriority"
                required
                value={substationPriority}
                onChange={changeSubstationPriority}
              >
                <option value="">- Please Select -</option>
                <option value="">Select 1</option>
                <option value="">Select 2</option>
                <option value="">Select 3</option>
                <option value="">Select 4</option>
                <option value="">Select 5</option>
                <option value="">Select 6</option>
              </select>
            </div>
            <div className="col-12 form-group">
              <label className="form-label" htmlFor="customerNotes">
                Notes
              </label>
              <textarea
                className="form-control"
                rows="4"
                id="customerNotes"
                name="customerNotes"
                value={customerNotes}
                onChange={changeCustomerNotes}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseAddSubstationModal}
          >
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default Substations;
