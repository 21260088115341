import axios from "axios";
import { ApiBaseUrl, apiUrls } from "../../../constants/endpoints";

export const axiosInstanceHere = axios.create();

// const getAuthToken = () => {
//   var token = JSON.parse(localStorage.getItem("AccessToken"));
//   if (token === null || token === "") return "";
//   return "Bearer " + token;
// };



//  axiosInstanceHere.interceptors.request.use(
//    function (config) {
//      const token = getAuthToken();

//      if (token != null) {
//        config.headers.Authorization = token;
//      }

//      return config;
//    },
//    function (err) {
//      return Promise.reject(err);
//    }
//  );

export const LOGIN_URL = `${window._env_.API_BASE_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${ApiBaseUrl}${apiUrls.userManagement.users.verifyEmail}`;
export const ME_URL = `${window._env_.API_BASE_URL}/auth/me`;

export const LOGIN_NEW_URL = `${ApiBaseUrl}Auth/Login`;

export function login(email, password) {
  return axiosInstanceHere.post(LOGIN_NEW_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axiosInstanceHere.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
