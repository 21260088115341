import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toInteger } from "lodash";
import $ from "jquery";
import JobDetailTiles from './JobDetailTiles';
import ButtonGroupComponent from './ButtonGroupComponent';
import JobsDetail from "../JobsDetail";
import CheckPermission from "../../General/CheckPermission";
import { timeout_for_data_tables, userActions, TransferTypes } from "../../constants/enums"
import { getAllInventoryTransactions, getAllInventoryTransactionsOnClickOfWarehouse } from "../../services/inventoryTransectionService";
import { getStatsCall } from "../../services/statsService";
import { format, parseISO } from 'date-fns'
import { setStats } from "../../../store/stats/actions";
import JobInventoryListing from "./JobInventoryListing";
import { CustomLoader } from "../../components/common/CustomLoader";

function JobInventory() {
    const { jobDetails } = useSelector((state) => state.JobManagement)
    const [InventoryTransactions, setInventoryTransactions] = useState([]);
    const [statsLoading, setStatsLoading] = useState(false);
    const stats = useSelector((store) => store.Stats.stats);
    const [InventoryTransactionsLoading, setInventoryTransactionsLoading] = useState(false);
    const [adding, setAdding] = useState(false);

    const dispatch = useDispatch();

    const getInventoryTransactions = async () => {
        setInventoryTransactionsLoading(true);
        try {
            const { data } = await getAllInventoryTransactions(jobDetails.ID);
            if (data.success && data.result.length) {
                setInventoryTransactions(data.result);
            }
            else {
                setInventoryTransactions([]);
            }
        }
        catch {
        }
        finally {
        }
        setInventoryTransactionsLoading(false);
    };

    const getInventoryTransactionsOnClickOfWarehouse = async (reportTypeId) => {
        setInventoryTransactions([]);
        setInventoryTransactionsLoading(true);
        try {
            const { data } = await getAllInventoryTransactionsOnClickOfWarehouse(jobDetails.ID, reportTypeId);
            if (data.success && data.result.length) {
                setInventoryTransactions(data.result);
            }
        }
        catch {
        }
        finally {
        }
        setInventoryTransactionsLoading(false);
    };

    const getStats = async (jobId) => {
        setStatsLoading(true);
        try {
          const { data } = await getStatsCall(jobId);
          if (data.success) {
            setStatsLoading(false);
            dispatch(setStats(data.result));
          }
          else {
            setStatsLoading(false);
          }
        }
        catch {
          setStatsLoading(false);
        }
        finally {
          setStatsLoading(false);
        }
      };

    useEffect(() => {
        getInventoryTransactions();
        getStats(jobDetails?.ID);
         
    }, []);
    

    return (
        <>
            <JobsDetail />
            <ButtonGroupComponent getInventoryTransactions={getInventoryTransactions} getStats={getStats} setInventoryTransactions={setInventoryTransactions} setAdding={setAdding} />
            <div className="row flex-wrap">
                <div className="col min-width_tiles">   
                    <JobDetailTiles tileTitle="Out of warehouse" tileIcon="/media/svg/icons/Home/Home.svg" titleValue={!(stats && stats.outOfWarehouse) ? "0" : toInteger(stats.outOfWarehouse)} tileColor="warning" reportTypeId={1} getInventoryTransactionsOnClickOfWarehouse={getInventoryTransactionsOnClickOfWarehouse} />
                </div>
                <div className="col min-width_tiles">
                    <JobDetailTiles tileTitle="Available in warehouse" tileIcon="/media/svg/icons/Devices/CPU2.svg" titleValue={!(stats && stats.availableInWarehouse) ? "0" : toInteger(stats.availableInWarehouse)} tileColor="info" reportTypeId={2} getInventoryTransactionsOnClickOfWarehouse={getInventoryTransactionsOnClickOfWarehouse} />
                </div>
                <div className="col min-width_tiles">
                    <JobDetailTiles tileTitle="Jobs in progress" tileIcon="/media/svg/icons/Electric/Range-hood.svg" titleValue={!(stats && stats.jobsInProgress) ? "0" : toInteger(stats.jobsInProgress)} tileColor="danger" />
                </div>
                <div className="col min-width_tiles">
                    <JobDetailTiles tileTitle="Total Crews" tileIcon="/media/svg/icons/Devices/Server.svg" titleValue={!(stats && stats.totalCrews) ? "0" : toInteger(stats.totalCrews)}  tileColor="success" />
                </div>
                <div className="col min-width_tiles">
                    <JobDetailTiles tileTitle="Completed Jobs" tileIcon="/media/svg/icons/Home/Clock.svg" titleValue={!(stats && stats.completedJobs) ? "0" : toInteger(stats.completedJobs)} tileColor="primary" />
                </div>
            </div>
            <CheckPermission
                currentAction={userActions.Inventory.Action_ViewInventory}

                yes={() => (
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="mb-0">
                                            <img src="/media/svg/custom/inventory.svg" width="30" height="30" className="mr-3" alt="summary" />
                                            Inventory List
                                        </h3>
                                    </div>
                                </div>
                                {InventoryTransactionsLoading || adding ? (
                                    <div className="d-flex justify-content-center p-10">
                                      <CustomLoader />
                                    </div>
                                  ) : !InventoryTransactionsLoading && !adding && InventoryTransactions?.length === 0 ? (
                                    <div className="d-flex justify-content-center p-10">
                                      No Transactions
                                    </div>
                                  ) : (
                                   <JobInventoryListing
                                    InventoryTransactions={InventoryTransactions}
                                   />
                                  )
                                }

                            </div>
                        </div>
                    </div>
                )}
                no={() => null}
            />

        </>
    )
}

export default JobInventory
