import React from "react";
import { Form } from "react-bootstrap";
import * as Common from "../../General/common"
import { upsertLocationApiCall } from "../../services/locationService";
import { locationTypes } from "../../constants/enums";
const locationModel = {
    id: 0,
    locationName: '',
    notes: '',
    createdBy: "0",
    modifiedBy: "0",
    isCustom: true,
    locationType: "0"
}
export default class AddEditLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            isLoading: false,
            Location: JSON.parse(
                JSON.stringify(
                    this.props.location != null
                        ? {
                            id: this.props.location.ID,
                            locationName: this.props.location.LocationName,
                            notes: this.props.location.Notes,
                            createdBy: this.props.location.CreatedBy,
                            modifiedBy: this.props.location.ModifiedBy,
                            isCustom: this.props.location.IsCustom,
                            locationType: this.props.location.LocationType
                        }
                        : locationModel
                )
            ),
        };
    }

    handleSubmit = () => {
        
        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            this.upsertLocation();
        }
    };

    upsertLocation = async () => {
        this.props.setLocations([]);
        this.props.setAdding(true);
        // this.isLoading(false);
        let location = this.state.Location;
        try {
            const { data } = await upsertLocationApiCall(location);

            if (data.success) {
                // success scenario 
                Common.showSuccessAlertAutoClose(
                    this.props.isEdit ? "Location updated successfully!" : "Location created successfully!",
                    "success",
                    "Success!",
                    3000
                );
                this.props.OnSubmit();
                this.props.getAllLocation(0);
                //window.location.reload(false);
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        }
        catch {

            Common.showSuccessAlertAutoClose(
                "some error occured while processing your request",
                "warning",
                "Failed!",
                3000
            );
        }
        finally {
          

        }
        this.props.setAdding(false);

    }
    render() {
        return (
            <>


                <Form
                    ref={(ref) => {
                        this.form = ref;
                    }}
                    validated={this.state.formValidated}
                >
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="form-label" htmlFor="locationName">
                                Location Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="locationName"
                                name="locationName"
                                required
                                defaultValue={this.state.Location.locationName}
                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Location,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                placeholder="Location name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Location name is required
                            </Form.Control.Feedback>

                        </div>
                        <div className="col-6 form-group">
                            <label className="form-label" htmlFor="locationType">
                                Location Type <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              id="locationType"
                              name="locationType"
                              required
                              value={this.state.Location.locationType}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                    this.state.Location,
                                    e,
                                    this,
                                    this.state
                                )
                            }
                            >
                                <option value={''} onClick={() => this.setState({
                                    Location: {
                                        ...this.state.Location,
                                        locationType: '0'
                                    }
                                })}>-- SELECT --</option>
                              {
                                  locationTypes.length > 0 && <>
                                  {
                                      locationTypes?.map((lt, idx) => {
                                          return(
                                              <option value={lt.id} key={idx}>{lt.name}</option>
                                          )
                                      })
                                  }
                                  </>
                              }
                            </select>
                            <Form.Control.Feedback type="invalid">
                                Location type is required
                            </Form.Control.Feedback>

                        </div>
                        <div className="col-12 form-group">
                            <label className="form-label" htmlFor="locationNotes">
                                Notes
                            </label>
                            <textarea className="form-control"
                                name="notes"
                                defaultValue={this.state.Location.notes}

                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.Location,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                                rows="4" id="locationNotes" 
                                placeholder="Add notes"
                                ></textarea>
                        </div>

                    </div>
                </Form>
            </>
        );
    }
}