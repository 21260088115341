import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import TransactionBasedReportsListing from "./TransactionBasedReportsListing";
import WarehouseandPartTotalReportListing from "./WarehouseandPartTotalReportListing";
import SubstationandFeederTotalReportListing from "./SubstationandFeederTotalReportListing";
import CrewTotalReportListing from "./CrewTotalReportListing";
import { getCrewsCall, getFeedersCall, getPartsCall, getSubstationsCall } from "../../services/managementServices";
import { useSelector, shallowEqual } from "react-redux";
import { format, parseISO } from 'date-fns'
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllJobsCall } from "../../services/jobServices";
import { getAllLocationsApiCallCustomeAndNotCustom } from "../../services/locationService";
import { locationTypesObject, reportTypes, reportTypesObject, TransferTypes, TransferTypesObject, viewTypes, viewTypesObject } from "../../constants/enums";
import { getReportDetails, getReportsCall } from "../../services/reportsService";
import { CustomLoader } from "../../components/common/CustomLoader";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import Select from 'react-select';
import { getAttachmentsCall } from "../../services/attachmentService";
import * as Common from "../../General/common";
import { getUserByIdCall } from "./../../services/userManagementService";
import {
  getAllUsersCall,
} from "./../../services/userManagementService";

function NewReports() {

    const user = useSelector((state) => state.auth.user, shallowEqual); 

    const jobDetails = useSelector((store) => store.JobManagement.jobDetails);

    const [transactionReportsLoading, setTransactionReportsLoading] = useState(false);
    const [warehouseandpartTotalReportsLoading, setwarehouseandpartTotalReportsLoading] = useState(false);
    const [substationandfeederTotalReportsLoading, setsubstationandfeederTotalReportsLoading] = useState(false);
    const [crewTotalReportsLoading, setcrewTotalReportsLoading] = useState(false);

    const [filteredTransactionReports, setFilteredTransactionReports] = useState(null);
    const [warehouseandpartFilteredTotalReports, setwarehouseandpartFilteredTotalReports] = useState(null);
    const [substationandfeederFilteredTotalReports, setsubstationandfeederFilteredTotalReports] = useState(null);
    const [crewFilteredTotalReports, setcrewFilteredTotalReports] = useState(null);
    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [dataValues, setDataValues] = useState(null);
    const [jobsList, setjobsList] = useState([]);
    const [crews, setCrews] = useState([]);
    const [substations, setSubstations] = useState([]);
    const [feeders, setFeeders] = useState([]);
    const [parts, setParts] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [fromLocations, setFromLocations] = useState([]);
    const [toLocations, setToLocations] = useState([]);

    const [fk_PartID, setfk_PartID] = useState(null);
    const [fk_JobID, setfk_JobID] = useState(null);
    const [fk_SubstationID, setfk_SubstationID] = useState(null);
    const [fk_FeederID, setfk_FeederID] = useState(null);
    const [fk_CrewID, setfk_CrewID] = useState(null);
    const [fk_WarehouseID, setfk_WarehouseID] = useState(null);
    const [fk_FromlocationID, setfk_FromlocationID] = useState(null);
    const [fk_TolocationID, setfk_TolocationID] = useState(null);
    const [notes, setNotes] = useState(null);

    const [optionValueOfWarehouse, setOptionValueOfWarehouse] = useState(null);
    const [optionValueOfFromLocation, setOptionValueOfFromLocation] = useState(null);
    const [optionValueOfToLocation, setOptionValueOfToLocation] = useState(null);
    const [optionValueOfJob, setOptionValueOfJob] = useState(null);
    const [optionValueOfPart, setOptionValueOfPart] = useState(null);
    const [optionValueOfSubstation, setOptionValueOfSubstation] = useState(null);
    const [optionValueOfFeeder, setOptionValueOfFeeder] = useState(null);
    const [optionValueOfCrew, setOptionValueOfCrew] = useState(null);

    const [reportTypeChecked, setReportTypeChecked] = useState(1);
    const [viewTypeChecked, setViewTypeChecked] = useState(1);

    const [reportDetailModal, setReportDetailModal] = useState(false);
    const handleCloseReportDetailModal = () => setReportDetailModal(false);
    const handleShowReportDetailModal = () =>  setReportDetailModal(true);

    const [reportDetails, setReportDetails] = useState(null);
    const [reportDetailsParts, setReportDetailsParts] = useState([]);

    const [inventoryAttachments, setInventoryAttachments] = useState([]);


    const [customerID, setCustomerID] = useState(null);


    const changeFromDate = (e) => {
      setFromDate(e.target.value == "" ? null : e.target.value);
        //setFromDate(e.target.value == "" ? null : format(new Date(e.target.value), "yyyy-MM-dd"));
    }

    const changeToDate = (e) => {
      setToDate(e.target.value == "" ? null : e.target.value);
        //setToDate(e.target.value == "" ? null : format(new Date(e.target.value), "yyyy-MM-dd"));
    }

  //   const changePartID = (e) => {
  //       setfk_PartID(parseInt(e.target.value));
  //   }

  //   const changeJobID = (e) => {
  //       setfk_JobID(parseInt(e.target.value));
  //   }

  //   const changeSubstationID = (e) => {
  //       setfk_SubstationID(parseInt(e.target.value));
  //   }

  //   const changeFeederID = (e) => {
  //       setfk_FeederID(parseInt(e.target.value));
  //   }

  //   const changeCrewID = (e) => {
  //       setfk_CrewID(parseInt(e.target.value));
  //   }

  //   const changeWarehouseID = (e) => {
  //     setfk_WarehouseID(parseInt(e.target.value));
  // }

    const changeNotes = (e) => {
        setNotes(e.target.value);
      }

    useEffect(() => {
      let dataHere;
      dataHere = {
        fromDate,
        toDate,
        fk_JobID, 
        fk_PartID, 
        fk_SubstationID, 
        fk_FeederID, 
        fk_CrewID, 
        fk_WarehouseID,
        fk_FromlocationID,
        fk_TolocationID,
        reportTypeChecked,
        viewTypeChecked,
        notes
    };
      // if(viewTypeChecked == viewTypesObject.Totals){
      //   dataHere = {
      //       reportTypeChecked,
      //       viewTypeChecked
      //   };
      // }else if(viewTypeChecked == viewTypesObject.Transaction){
      //   dataHere = {
      //       fromDate,
      //       toDate,
      //       fk_JobID, 
      //       fk_PartID, 
      //       fk_SubstationID, 
      //       fk_FeederID, 
      //       fk_CrewID, 
      //       fk_WarehouseID,
      //       reportTypeChecked,
      //       viewTypeChecked,
      //       notes
      //   };
      // }

        setDataValues(dataHere);
    }, [fromDate, toDate, fk_JobID, fk_PartID, fk_SubstationID, fk_FeederID, fk_CrewID, fk_WarehouseID, fk_FromlocationID, fk_TolocationID, reportTypeChecked, viewTypeChecked, notes]);

    useEffect(() => {
        if(fromDate == ""){
            setFromDate(null);
        }
    }, [fromDate]);

    useEffect(() => {
        if(toDate == ""){
            setToDate(null);
        }
    }, [toDate]);

    useEffect(() => {
        if(fk_JobID == "" || fk_JobID <= 0){
            setfk_JobID(null);
        }
    }, [fk_JobID]);

    useEffect(() => {
        if(fk_PartID == "" || fk_PartID <= 0){
            setfk_PartID(null);
        }
    }, [fk_PartID]);

    useEffect(() => {
        if(fk_SubstationID == "" || fk_SubstationID <= 0){
            setfk_SubstationID(null);
        }
    }, [fk_SubstationID]);

    useEffect(() => {
        if(fk_FeederID == "" || fk_FeederID <= 0){
            setfk_FeederID(null);
        }
    }, [fk_FeederID]);

    useEffect(() => {
        if(fk_CrewID == "" || fk_CrewID <= 0){
            setfk_CrewID(null);
        }
    }, [fk_CrewID]);

    useEffect(() => {
      if(fk_WarehouseID == "" || fk_WarehouseID <= 0){
          setfk_WarehouseID(null);
      }
  }, [fk_WarehouseID]);

  useEffect(() => {
    if(fk_FromlocationID == "" || fk_FromlocationID <= 0){
        setfk_FromlocationID(null);
    }
}, [fk_FromlocationID]);

useEffect(() => {
  if(fk_TolocationID == "" || fk_TolocationID <= 0){
      setfk_TolocationID(null);
  }
}, [fk_TolocationID]);

  useEffect(() => {
    if(notes == ""){
        setNotes(null);
    }
}, [notes]);


useEffect(() => {
  if(reportTypeChecked == reportTypesObject.Warehouse || reportTypeChecked == reportTypesObject.Part){
    setfk_SubstationID(null);
    setOptionValueOfSubstation(null);
    setfk_FeederID(null);
    setOptionValueOfFeeder(null);
    setfk_CrewID(null);
    setOptionValueOfCrew(null);
  }else if(reportTypeChecked == reportTypesObject.Substation || reportTypeChecked == reportTypesObject.Feeder){
    setfk_WarehouseID(null);
    setOptionValueOfWarehouse(null);
    setfk_FromlocationID(null);
    setOptionValueOfFromLocation(null);
    setfk_TolocationID(null);
    setOptionValueOfToLocation(null);
    setfk_PartID(null);
    setOptionValueOfPart(null);
    setfk_CrewID(null);
    setOptionValueOfCrew(null);
  }else if(reportTypeChecked == reportTypesObject.Crew){
    setfk_WarehouseID(null);
    setOptionValueOfWarehouse(null);
    setfk_FromlocationID(null);
    setOptionValueOfFromLocation(null);
    setfk_TolocationID(null);
    setOptionValueOfToLocation(null);
    setfk_PartID(null);
    setOptionValueOfPart(null);
    setfk_SubstationID(null);
    setOptionValueOfSubstation(null);
    setfk_FeederID(null);
    setOptionValueOfFeeder(null);
  }
}, [reportTypeChecked]);


 //useEffect(() => {
 //    setfk_WarehouseID("");
 //    setfk_WarehouseID(null);
 //    setOptionValueOfWarehouse(null);
 //    setfk_PartID("");
 //    setfk_PartID(null);
 //    setOptionValueOfPart(null);
 //    setfk_SubstationID("");
 //    setfk_SubstationID(null);
 //    setOptionValueOfSubstation(null);
 //    setfk_FeederID("");
 //    setfk_FeederID(null);
 //    setOptionValueOfFeeder(null);
 //    setfk_CrewID("");
 //    setfk_CrewID(null);
 //    setOptionValueOfCrew(null);
 //    setNotes("");
 //    setNotes(null);
 //    setfk_JobID("");
 //    setfk_JobID(null);
 //    setOptionValueOfJob(null);
 //    setFromDate("");
 //    setFromDate(null);
 //    setToDate("");
 //    setToDate(null);
 //}, [viewTypeChecked]);

 useEffect(() => {
  if(viewTypeChecked == viewTypesObject.Transaction && reportTypeChecked == reportTypesObject.Warehouse){
    setfk_WarehouseID("");
    setfk_WarehouseID(null);
    setOptionValueOfWarehouse(null);
  }else{
    setfk_FromlocationID("");
    setfk_FromlocationID(null);
    setOptionValueOfFromLocation(null);
    setfk_TolocationID("");
    setfk_TolocationID(null);
    setOptionValueOfToLocation(null);
  }
 }, [viewTypeChecked, reportTypeChecked]);

 useEffect(() => {
  if(viewTypeChecked == viewTypesObject.Totals && reportTypeChecked == reportTypesObject.Crew){
    setfk_JobID("");
    setfk_JobID(null);
    setOptionValueOfJob(null);
  }
 }, [viewTypeChecked, reportTypeChecked]);

 useEffect(() => {
    setNotes("");
 }, [viewTypeChecked]);


  const onChangeReportType = (value) => {
    setReportTypeChecked(value);
  };

  const onChangeViewType = (value) => {
    setViewTypeChecked(value);
  };

     // function which fetches the reports
     const getReports = async (values) => {
       //console.log("valuesofreports", values);
       if(values.viewTypeChecked == viewTypesObject.Transaction){
        setTransactionReportsLoading(true);
        try {
          const { data } = await getReportsCall(values);
          //setTransactionReportsLoading(false);
          if(data.success){
            // success scenario
          
            if(data.result.length >= 0){
             setFilteredTransactionReports(data.result);
            }
          }else{
            //setTransactionReportsLoading(false);
            setFilteredTransactionReports(null);
          }
        }
        catch {
            //setTransactionReportsLoading(false);
            setFilteredTransactionReports(null);
        }
        finally {
            setTransactionReportsLoading(false);
        }
       }else if(values.viewTypeChecked == viewTypesObject.Totals && (values.reportTypeChecked == reportTypesObject.Warehouse || values.reportTypeChecked == reportTypesObject.Part)){
        setwarehouseandpartTotalReportsLoading(true);
        try {
          const { data } = await getReportsCall(values);
          //setwarehouseandpartTotalReportsLoading(false);
          if(data.success){
            // success scenario
          
            if(data.result.length >= 0){
             setwarehouseandpartFilteredTotalReports(data.result);
            }
          }else{
            //setwarehouseandpartTotalReportsLoading(false);
            setwarehouseandpartFilteredTotalReports(null);
          }
        }
        catch {
            //setwarehouseandpartTotalReportsLoading(false);
            setwarehouseandpartFilteredTotalReports(null);
        }
        finally {
            setwarehouseandpartTotalReportsLoading(false);
        }
       }else if(values.viewTypeChecked == viewTypesObject.Totals && (values.reportTypeChecked == reportTypesObject.Substation || values.reportTypeChecked == reportTypesObject.Feeder)){
        setsubstationandfeederTotalReportsLoading(true);
        try {
          const { data } = await getReportsCall(values);
          //setsubstationandfeederTotalReportsLoading(false);
          if(data.success){
            // success scenario
          
            if(data.result.length >= 0){
             setsubstationandfeederFilteredTotalReports(data.result);
            }
          }else{
            //setsubstationandfeederTotalReportsLoading(false);
            setsubstationandfeederFilteredTotalReports(null);
          }
        }
        catch {
            //setsubstationandfeederTotalReportsLoading(false);
            setsubstationandfeederFilteredTotalReports(null);
        }
        finally {
            setsubstationandfeederTotalReportsLoading(false);
        }
       }else if(values.viewTypeChecked == viewTypesObject.Totals && (values.reportTypeChecked == reportTypesObject.Crew)){
        setcrewTotalReportsLoading(true);
        try {
          const { data } = await getReportsCall(values);
          //setcrewTotalReportsLoading(false);
          if(data.success){
            // success scenario
          
            if(data.result.length >= 0){
             setcrewFilteredTotalReports(data.result);
            }
          }else{
            //setcrewTotalReportsLoading(false);
             setcrewFilteredTotalReports(null);
          }
        }
        catch {
            //setcrewTotalReportsLoading(false);
            setcrewFilteredTotalReports(null);
        }
        finally {
            setcrewTotalReportsLoading(false);
        }
       }
    };

    useEffect(() => {
        formik.setValues({
            fromDate, 
            toDate, 
            fk_JobID, 
            fk_PartID,
            fk_SubstationID,
            fk_FeederID,
            fk_CrewID,
            fk_WarehouseID,
            fk_FromlocationID,
            fk_TolocationID,
            reportTypeChecked,
            viewTypeChecked,
            notes
        });
      }, [fromDate, toDate, fk_JobID, fk_PartID, fk_SubstationID, fk_FeederID, fk_CrewID, fk_WarehouseID, fk_FromlocationID, fk_TolocationID, reportTypeChecked, viewTypeChecked, notes]);

      const Schema = Yup.object().shape({
         fromDate: Yup.string().nullable(),
               //.typeError('Invalid Value')
         toDate: Yup.string().nullable().when('tdate', {
           is: tdate => (fromDate != null && toDate != null),
           then: Yup.string().nullable()
            
           //.typeError('Invalid Value')
           .test("cantBeLessThanFrom", "To Date should not be before From Date", function (item) {
             try {
               return (fromDate <= toDate);
             } catch {
               return false;
             }
           }),
         })
      });

      const formik = useFormik({
        initialValues: {
            fromDate,
            toDate,
            fk_JobID,
            fk_PartID,
            fk_SubstationID,
            fk_FeederID,
            fk_CrewID,
            fk_WarehouseID,
            fk_FromlocationID,
            fk_TolocationID,
            reportTypeChecked,
            viewTypeChecked,
            notes
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          handleValidSubmit();
          setSubmitting(false);
        },
      });

      // formik setup ends

      const handleValidSubmit = async e => {
        //e?.preventDefault();
        if(dataValues != null){
            getReports(dataValues);
        }
    }


    // get drop down values
    const [jobsDropDownLoading, setJobsDropDownLoading] = useState(false);
    const getAllJobs = async () => {
      setJobsDropDownLoading(true);
        try {
          const { data } = await getAllJobsCall(user?.id);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setjobsList(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
        setJobsDropDownLoading(false);
      };

      const [locationsDropDownLoading, setLocationsDropDownLoading] = useState(false);
      const getLocationsAll = async (id) => {
        setLocationsDropDownLoading(true);
        try {
          const { data } = await getAllLocationsApiCallCustomeAndNotCustom(id);
          if (data.success && data.result.length) {
            if(id == 0){
              setWarehouses(data.result);
            }else if(id == locationTypesObject.V){
              setFromLocations(data.result);
            }else if(id == locationTypesObject.W){
              setToLocations(data.result);
            }
          }
        }
        catch {
        }
        finally {
        }
        setLocationsDropDownLoading(false);
      };

      const [crewsDropDownLoading, setCrewsDropDownLoading] = useState(false);
      const getAllCrews = async () => {
        setCrewsDropDownLoading(true);
        try {
          const { data } = await getCrewsCall();
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setCrews(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
        setCrewsDropDownLoading(false);
      };

      const [substationsDropDownLoading, setSubstationsDropDownLoading] = useState(false);
      const getAllSubstations = async (val) => {
        setSubstationsDropDownLoading(true);
        try {
          const { data } = await getSubstationsCall(val);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setSubstations(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
        setSubstationsDropDownLoading(false);
      };

      const [feedersDropDownLoading, setFeedersDropDownLoading] = useState(false);
      const getAllFeeders = async (val) => {
        setFeedersDropDownLoading(true);
        try {
          const { data } = await getFeedersCall(val);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setFeeders(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
        setFeedersDropDownLoading(false);
      };

      const [partsDropDownLoading, setPartsDropDownLoading] = useState(false);
      const getAllParts = async (customerid) => {
        setPartsDropDownLoading(true);
        try {
          const { data } = await getPartsCall(customerid);
    
          if (data.success) {
            // success scenario
            if (data.result.length > 0) {
              await setParts(data.result);
              // dispatch(setJobs(data.result));
            }
          } else {
          }
        } catch {
        } finally {
          
        }
        setPartsDropDownLoading(false);
      };

      const [inventoryAttachmentsInside, setInventoryAttachmentsInside] = useState(null);

      const [loadingReportDetailsParts, setLoadingReportDetailsParts] = useState(false);
      const getReportDetailsPartsCall = async (values) => {
        setInventoryAttachments([]);
        setLoadingReportDetailsParts(true);
        try {
          const { data } = await getReportDetails(values);
          if(data.success && data.result.length > 0){
            setReportDetailsParts(data.result);
            for(let i=0; i< data.result.length; i++){
              
                getInventoryAttachments(data.result[i]?.ID, Common.EntityIds.InventoryTransfer);
              
            }
            
          }
        }
        catch {
        }
        finally {
        }
        setLoadingReportDetailsParts(false);
      };

      const [loadingInventoryAttachments, setLoadingInventoryAttachments] = useState(false);
      const getInventoryAttachments = async (recordId, entityId) => {
        setLoadingInventoryAttachments(true);
        try {
            const { data } = await getAttachmentsCall(recordId, entityId);
            
            if (data.success) {
                // success scenario
                if (data.result.length > 0) {
                    // const filesList = data.result?.map((el, idx) => {
                    //   return el;
                    //     // return {
                    //     //     id: el.id,
                    //     //     name: el.fileUrl,
                    //     //     url: el.fileUrl,
                    //     //     type: el.fileType,
                    //     //     size: el.fileSize
                    //     // };
                    // });
                    //console.log("fl", filesList);
                    setInventoryAttachmentsInside(data.result[0]);
                    
                }
            } else {

            }
        }
        catch {

        }
        finally {
          setLoadingInventoryAttachments(false);
        }
    };

    useEffect(() => {
      if(inventoryAttachmentsInside != null){
        setInventoryAttachments([...inventoryAttachments, inventoryAttachmentsInside]);
      }
    }, [inventoryAttachmentsInside]);



      useEffect(() => {
        getAllJobs();
        getAllCrews();
        getAllParts();
        getLocationsAll(0);
        getLocationsAll(locationTypesObject.V);
        getLocationsAll(locationTypesObject.W);
    }, []);

    

     useEffect(() => {
         if(fk_JobID > 0){
             getAllSubstations(fk_JobID);
             getAllParts(customerID);
         }else{
           setfk_SubstationID("");
           setfk_SubstationID(null);
           setOptionValueOfSubstation(null);
           setfk_PartID("");
           setfk_PartID(null);
           setOptionValueOfPart(null);
           setSubstations([]);
           setParts([]);
         }
     }, [fk_JobID]);

     useEffect(() => {
         if(fk_SubstationID > 0){
             getAllFeeders(fk_SubstationID);
         }else{
           setfk_FeederID("");
           setfk_FeederID(null);
           setOptionValueOfFeeder(null);
           setFeeders([]);
         }
     }, [fk_SubstationID]);


  const [allUsers, setallUsers] = useState([]);
  const getAllUsers = async () => {
    try {
      const { data } = await getAllUsersCall();

      if (data.success) {
        // success scenario

        if (data.result.length > 0) {
          await setallUsers(data.result);
        }
      } else {
      }
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);    

    return (
        <>
            {/* New reports filter area */}
            <div className="card card-custom mb-5">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="mb-0">
                            <img src="/media/svg/custom/report.svg" width="30" height="30" className="mr-3" alt="summary" />
                            Reports
                        </h3>
                    </div>
                    <div className="card-toolbar"> </div>
                </div>
                <div className="card-body pt-3">
                    <div role="alert" className="fade alert bg-light-primary show font-weight-bold mb-5">
                        <i className="fa fa-info-circle mr-4 text-primary"></i>
                        Fill out the search filters to view report.
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-8 col-xl-5">
                            <div className="form-group">
                                <label className="form-label">
                                    Report Type
                                </label>
                                <ul className="list-inline">
                                    {
                                      reportTypes?.map((rt, idx) => {
                                        return(
                                          <li className="list-inline-item mr-3 mr-lg-8" key={rt?.id}>
                                              <label className="checkbox">
                                                  <input 
                                                    type="checkbox" 
                                                    name={rt?.name} 
                                                    checked={reportTypeChecked === rt.id}
                                                    onChange={() => onChangeReportType(rt.id)}
                                                  />
                                                  <span></span>
                                                  &nbsp;&nbsp;{rt?.name}
                                              </label>
                                          </li>
                                        )
                                      })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-5">
                            <div className="form-group">
                                <label className="form-label">
                                    View Type
                                </label>
                                <ul className="list-inline">
                                {
                                      viewTypes?.map((vt, idx) => {
                                        return(
                                          <li className="list-inline-item mr-3 mr-lg-8" key={vt?.id}>
                                              <label className="checkbox">
                                                  <input 
                                                    type="checkbox" 
                                                    name={vt?.name} 
                                                    checked={viewTypeChecked === vt.id}
                                                    onChange={() => onChangeViewType(vt.id)}
                                                  />
                                                  <span></span>
                                                  &nbsp;&nbsp;{vt?.name}
                                              </label>
                                          </li>
                                        )
                                      })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row" /*style={{ display: viewTypeChecked == viewTypesObject.Totals ? "none" : "" }}*/>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="FromDateReport">From Date</label>
                                <input type="date" className="form-control" id="FromDateReport" name="FromDateReport" value={fromDate} onChange={changeFromDate} />
                                <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.fromDate}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="ToDateReport">To Date</label>
                                <input type="date" className="form-control" id="ToDateReport" name="ToDateReport" value={toDate} onChange={changeToDate} />
                                <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.toDate}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (viewTypeChecked == viewTypesObject.Transaction && reportTypeChecked == reportTypesObject.Warehouse) ? "none" : "" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_WarehouseID">
                                      Warehouse
                                    </label>
                                    {
                                      locationsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading warehouses..."
                                      value="Loading warehouses..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_WarehouseID"
                                      name="fk_WarehouseID"
                                      placeholder="Warehouse"
                                      value={optionValueOfWarehouse}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfWarehouse(null);
                                          setfk_WarehouseID("");
                                          setfk_WarehouseID(null);
                                        }else{
                                          setOptionValueOfWarehouse(val);
                                          setfk_WarehouseID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        warehouses?.map(({ ID, LocationName }) => (
                                          { value: ID, label: LocationName }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    {/* {
                                      locationsDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                        <option>Loading warehouses....</option>
                                      </select>
                                      :
                                    <select
                                      className="form-control"
                                      id="fk_WarehouseID"
                                      name="fk_WarehouseID"
                                      value={fk_WarehouseID}
                                      onChange={changeWarehouseID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        warehouses?.map(({ ID, LocationName }) => (
                                          <option value={ID}>{LocationName}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_WarehouseID}
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (viewTypeChecked == viewTypesObject.Transaction && reportTypeChecked == reportTypesObject.Warehouse) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_FromlocationID">
                                      From Location
                                    </label>
                                    {
                                      locationsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading locations..."
                                      value="Loading locations..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_FromlocationID"
                                      name="fk_FromlocationID"
                                      placeholder="From Location"
                                      value={optionValueOfFromLocation}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfFromLocation(null);
                                          setfk_FromlocationID("");
                                          setfk_FromlocationID(null);
                                        }else{
                                          setOptionValueOfFromLocation(val);
                                          setfk_FromlocationID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        fromLocations?.map(({ ID, LocationName }) => (
                                          { value: ID, label: LocationName }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_FromlocationID}
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (viewTypeChecked == viewTypesObject.Transaction && reportTypeChecked == reportTypesObject.Warehouse) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_TolocationID">
                                      To Location
                                    </label>
                                    {
                                      locationsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading locations..."
                                      value="Loading locations..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_TolocationID"
                                      name="fk_TolocationID"
                                      placeholder="To Location"
                                      value={optionValueOfToLocation}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfToLocation(null);
                                          setfk_TolocationID("");
                                          setfk_TolocationID(null);
                                        }else{
                                          setOptionValueOfToLocation(val);
                                          setfk_TolocationID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        toLocations?.map(({ ID, LocationName }) => (
                                          { value: ID, label: LocationName }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_TolocationID}
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (reportTypeChecked == reportTypesObject.Crew) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_CrewID">
                                      Crew
                                    </label>
                                    {
                                      crewsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading crews..."
                                      value="Loading crews..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_CrewID"
                                      name="fk_CrewID"
                                      placeholder="Crew"
                                      value={optionValueOfCrew}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfCrew(null);
                                          setfk_CrewID("");
                                          setfk_CrewID(null);
                                        }else{
                                          setOptionValueOfCrew(val);
                                          setfk_CrewID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        crews?.map(({ ID, CrewName }) => (
                                          { value: ID, label: CrewName }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    {/* {
                                      crewsDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                      <option>Loading crews....</option>
                                    </select>
                                    :
                                    <select
                                      className="form-control"
                                      id="fk_CrewID"
                                      name="fk_CrewID"
                                      value={fk_CrewID}
                                      onChange={changeCrewID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        crews?.map(({ ID, CrewName }) => (
                                          <option value={ID}>{CrewName}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_CrewID}
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (viewTypeChecked == viewTypesObject.Totals && reportTypeChecked == reportTypesObject.Crew) ? "none" : ""}}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_JobID">
                                      Job
                                    </label>
                                    {
                                      jobsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading jobs..."
                                      value="Loading jobs..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_JobID"
                                      name="fk_JobID"
                                      placeholder="Job"
                                      value={optionValueOfJob}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfJob(null);
                                          setfk_JobID("");
                                          setfk_JobID(null);
                                          setCustomerID(null);
                                        }else{
                                          setOptionValueOfJob(val);
                                          setfk_JobID(parseInt(val.value));
                                          setCustomerID(parseInt(val.fk_CustomerID));
                                        }
                                      }}
                                      options={
                                        jobsList?.map(({ ID, JobName, fk_CustomerID }) => (
                                          { value: ID, label: JobName, fk_CustomerID: fk_CustomerID }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    {/* {
                                      jobsDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                      <option>Loading jobs....</option>
                                    </select>
                                    :
                                    <select
                                      className="form-control"
                                      id="fk_JobID"
                                      name="fk_JobID"
                                      value={fk_JobID}
                                      onChange={changeJobID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        jobsList?.map(({ ID, JobName }) => (
                                          <option value={ID}>{JobName}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_JobID}
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (reportTypeChecked == reportTypesObject.Warehouse || reportTypeChecked == reportTypesObject.Part) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_PartID">
                                      Part
                                    </label>
                                    {
                                      partsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading parts..."
                                      value="Loading parts..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_PartID"
                                      name="fk_PartID"
                                      placeholder="Part"
                                      value={optionValueOfPart}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfPart(null);
                                          setfk_PartID("");
                                          setfk_PartID(null);
                                        }else{
                                          setOptionValueOfPart(val);
                                          setfk_PartID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        parts?.map(({ ID, CustomerPartNumber, VendorPartNumber, ClientPartNumber }) => (
                                          { value: ID, label: `${CustomerPartNumber}/${VendorPartNumber}/${ClientPartNumber}` }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }
                                    {/* {
                                      partsDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                        <option>Loading parts....</option>
                                      </select>
                                      :
                                    <select
                                      className="form-control"
                                      id="fk_PartID"
                                      name="fk_PartID"
                                      value={fk_PartID}
                                      onChange={changePartID}
                                    >
                                      <option value={0}>- Please Select -</option>
                                      {
                                        parts?.map(({ ID, CustomerPartNumber, VendorPartNumber, ClientPartNumber }) => (
                                          <option value={ID}>{`${CustomerPartNumber}/${VendorPartNumber}/${ClientPartNumber}`}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_PartID}
                                    </div>
                                    <div style={{ fontSize: ".9rem"}}>
                                      <strong>Note:</strong> To select a Part, you need to select a Job first.
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (reportTypeChecked == reportTypesObject.Substation || reportTypeChecked == reportTypesObject.Feeder) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_SubstationID">
                                      Substation
                                    </label>
                                    {
                                      substationsDropDownLoading ? 
                                      <Select
                                      placeholder="Loading substations..."
                                      value="Loading substations..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_SubstationID"
                                      name="fk_SubstationID"
                                      placeholder="Substation"
                                      value={optionValueOfSubstation}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfSubstation(null);
                                          setfk_SubstationID("");
                                          setfk_SubstationID(null);
                                        }else{
                                          setOptionValueOfSubstation(val);
                                          setfk_SubstationID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        substations?.map(({ ID, SubstationName }) => (
                                          { value: ID, label: SubstationName }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    {/* {
                                      substationsDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                        <option>Loading substations....</option>
                                      </select>
                                      :
                                    <select
                                      className="form-control"
                                      id="fk_SubstationID"
                                      name="fk_SubstationID"
                                      value={fk_SubstationID}
                                      onChange={changeSubstationID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        substations?.map(({ ID, SubstationName }) => (
                                          <option value={ID}>{SubstationName}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_SubstationID}
                                    </div>
                                    <div style={{ fontSize: ".9rem"}}>
                                      <strong>Note:</strong> To select a Substation, you need to select a Job first.
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3" style={{ display: (reportTypeChecked == reportTypesObject.Substation || reportTypeChecked == reportTypesObject.Feeder) ? "" : "none" }}>
                            <div className="form-group">
                                    <label className="form-label" htmlFor="fk_FeederID">
                                      Feeder
                                    </label>
                                    {
                                      feedersDropDownLoading ? 
                                      <Select
                                      placeholder="Loading feeders..."
                                      value="Loading feeders..."
                                      isDisabled={true}
                                    />
                                    :
                                    <Select
                                      id="fk_FeederID"
                                      name="fk_FeederID"
                                      placeholder="Feeder"
                                      value={optionValueOfFeeder}
                                      onChange={(val) => {
                                        if(val == null){
                                          setOptionValueOfFeeder(null);
                                          setfk_FeederID("");
                                          setfk_FeederID(null);
                                        }else{
                                          setOptionValueOfFeeder(val);
                                          setfk_FeederID(parseInt(val.value));
                                        }
                                      }}
                                      options={
                                        feeders?.map(({ ID, FeederName, SubstationName, FeederNumber }) => (
                                          { value: ID, label: `${FeederName}` }
                                        ))
                                      }
                                      isClearable
                                    />
                                    }

                                    {/* {
                                      feedersDropDownLoading ? 
                                      <select className="form-control" disabled> 
                                        <option>Loading feeders....</option>
                                      </select>
                                      :
                                    <select
                                      className="form-control"
                                      id="fk_FeederID"
                                      name="fk_FeederID"
                                      value={fk_FeederID}
                                      onChange={changeFeederID}
                                    >
                                    <option value={0}>- Please Select -</option>
                                      {
                                        feeders?.map(({ ID, FeederName, SubstationName, FeederNumber }) => (
                                          <option value={ID}>{`${FeederName}`}</option>
                                        ))
                                      }
                                    </select>
                                    } */}
                                    <div style={{ fontSize: ".8rem" }} className="text-danger">
                                            {formik.errors.fk_FeederID}
                                    </div>
                                    <div style={{ fontSize: ".9rem"}}>
                                      <strong>Note:</strong> To select a Feeder, you need to select a Substation first.
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-lg-6 col-xl-6" style={{ display: viewTypeChecked == viewTypesObject.Totals ? "none" : "" }}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="notes">
                                  Search within Notes
                                </label>
                                <input
                                  className="form-control"
                                  id="notes"
                                  name="notes"
                                  value={notes}
                                  onChange={changeNotes}
                                ></input>
                                <div style={{ fontSize: ".8rem" }} className="text-danger">
                                      {formik.errors.notes}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 my-auto">
                            <button disabled={!formik.isValid || formik.isSubmitting || transactionReportsLoading || warehouseandpartTotalReportsLoading || substationandfeederTotalReportsLoading || crewTotalReportsLoading}  className="btn btn-primary btn-md mt-4" onClick={() => {setFilteredTransactionReports(null); setwarehouseandpartFilteredTotalReports(null); formik.handleSubmit();}}>
                                <span>Search Result</span>
                                {(transactionReportsLoading || warehouseandpartTotalReportsLoading || substationandfeederTotalReportsLoading || crewTotalReportsLoading) && <span className="ml-2 pl-3 spinner spinner-white"></span>} 
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Transaction based reports card starts */}

            {
              <div style={{ display : viewTypeChecked == viewTypesObject.Transaction ? "" : "none" }}>
              {
                !transactionReportsLoading && filteredTransactionReports == null &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Transaction Based Reports
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No results
                        </div>
                    </div>
                </div>
            </div>
            }
             {
                transactionReportsLoading &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Transaction Based Reports
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               searching...
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                (!transactionReportsLoading && filteredTransactionReports != null)  &&
                <>
                {
                filteredTransactionReports?.length > 0 ?
                <TransactionBasedReportsListing
                    filteredValues={filteredTransactionReports}
                    handleShowReportDetailModal={handleShowReportDetailModal}
                    setReportDetails={setReportDetails}
                    getReportDetailsPartsCall={getReportDetailsPartsCall}
                    getInventoryAttachments={getInventoryAttachments}
                />
                :
                <>
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Transaction Based Reports
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No reports found!
                        </div>
                    </div>
                </div>
            </div>
                </>
                }
                </>
              }
            </div>
            }


            {/* Transaction based reports card ends */}


            
           {/* Warehouse and part total based reports card starts */}

           {
             <div style={{ display: (viewTypeChecked == viewTypesObject.Totals && (reportTypeChecked == reportTypesObject.Warehouse || reportTypeChecked == reportTypesObject.Part)) ? "" : "none" }}>
             {
                !warehouseandpartTotalReportsLoading && warehouseandpartFilteredTotalReports == null &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Warehouse and Part Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No results
                        </div>
                    </div>
                </div>
            </div>
            }
             {
                warehouseandpartTotalReportsLoading &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Warehouse and Part Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               searching...
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                (!warehouseandpartTotalReportsLoading && warehouseandpartFilteredTotalReports != null)  &&
                <>
                {
                warehouseandpartFilteredTotalReports?.length > 0 ?
                <WarehouseandPartTotalReportListing
                    filteredValues={warehouseandpartFilteredTotalReports}
                />
                :
                <>
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Warehouse and Part Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No reports found!
                        </div>
                    </div>
                </div>
            </div>
                </>
                }
                </>
                }
              </div>
            }


            {/* Warehouse and part total based reports card ends */}


             {/* substation and feeder total based reports card starts */}

           {
             <div style={{ display: (viewTypeChecked == viewTypesObject.Totals && (reportTypeChecked == reportTypesObject.Substation || reportTypeChecked == reportTypesObject.Feeder)) ? "" : "none" }}>
               {
                !substationandfeederTotalReportsLoading && substationandfeederFilteredTotalReports == null &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Substation and Feeder Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No results
                        </div>
                    </div>
                </div>
            </div>
            }
             {
                substationandfeederTotalReportsLoading &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Substation and Feeder Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               searching...
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                (!substationandfeederTotalReportsLoading && substationandfeederFilteredTotalReports != null)  &&
                <>
                {
                substationandfeederFilteredTotalReports?.length > 0 ?
                <SubstationandFeederTotalReportListing
                    filteredValues={substationandfeederFilteredTotalReports}
                />
                :
                <>
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Substation and Feeder Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No reports found!
                        </div>
                    </div>
                </div>
            </div>
                </>
                }
                </>
                }
                </div>
            }


            {/* substation and feeder total based reports card ends */}

              {/* crew total based reports card starts */}

           {
             <div style={{ display: (viewTypeChecked == viewTypesObject.Totals && (reportTypeChecked == reportTypesObject.Crew)) ? "" : "none" }}>
               {
                !crewTotalReportsLoading && crewFilteredTotalReports == null &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Crew Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No results
                        </div>
                    </div>
                </div>
                </div>
            }
             {
                crewTotalReportsLoading &&
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Crew Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               searching...
                        </div>
                    </div>
                </div>
            </div>
            }
            
            {
                (!crewTotalReportsLoading && crewFilteredTotalReports != null)  &&
                <>
                {
                crewFilteredTotalReports?.length > 0 ?
                <CrewTotalReportListing
                    filteredValues={crewFilteredTotalReports}
                />
                :
                <>
                <div className="row mb-5">
                <div className="col-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="mb-0">
                                    <img src="/media/svg/custom/search.svg" width="30" height="30" className="mr-3" alt="summary" />
                                    Crew Total Report
                                </h3>
                            </div>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                               No reports found!
                        </div>
                    </div>
                </div>
            </div>
                </>
                }
                </>
                }
              </div>
            }


            {/* crew total based reports card ends */}

            {/* report detail modal below */}

      <Modal
        show={reportDetailModal}
        onHide={handleCloseReportDetailModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title><i className="fa fa-shipping-fast mr-2"></i> Report Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="InventoryTransectionForm">
            <div className="row">
              <div className="col-md-6 form-group">
              <label  className="form-label" htmlFor="fk_TransferTypeID">
                  Transfer Type
                </label>

                <select
                  className="form-control"
                  disabled
                >
                  <option value=''>{reportDetails?.TransferType == TransferTypesObject.REC.abbrev ? "Receive to Warehouse (REC)" : reportDetails?.TransferType == TransferTypesObject.XFER.abbrev ? "Transfer to Feeder (XFER)" : reportDetails?.TransferType == TransferTypesObject.TCREW.abbrev ? "Transfer to Crew (TCREW)" : reportDetails?.TransferType == TransferTypesObject.FCREW.abbrev ? "Transfer from Crew (FCREW)" : reportDetails?.TransferType == TransferTypesObject.FXFER.abbrev ? "Transfer from Feeder (FXFER)" : ""}</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label" htmlFor="TransactionDate">
                  Date
                </label>
                <input 
                  type="text"
                  className="form-control"
                  value={moment(reportDetails?.TransactionDate).format("MM/DD/YYYY")}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-md-6 form-group">
                <label  className="form-label" htmlFor="fk_FromLocationID">
                  From
                </label>
                <select
                  className="form-control"
                  disabled
                >
                  <option value=''>{(reportDetails?.TransferType == TransferTypesObject.REC.abbrev || reportDetails?.TransferType == TransferTypesObject.XFER.abbrev) ? reportDetails?.FromLocationName :  (reportDetails?.TransferType == TransferTypesObject.TCREW.abbrev || reportDetails?.TransferType == TransferTypesObject.FXFER.abbrev) ? reportDetails?.Feeder : (reportDetails?.TransferType == TransferTypesObject.FCREW.abbrev) ? reportDetails?.CrewName : ""}</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label" htmlFor="fk_ToLocationID">
                  To
                </label>
                <select
                  className="form-control"
                  disabled
                >
                  <option value=''>{(reportDetails?.TransferType == TransferTypesObject.REC.abbrev || reportDetails?.TransferType == TransferTypesObject.FXFER.abbrev) ? reportDetails?.ToLocationName : (reportDetails?.TransferType == TransferTypesObject.XFER.abbrev || reportDetails?.TransferType == TransferTypesObject.FCREW.abbrev) ? reportDetails?.Feeder : (reportDetails?.TransferType == TransferTypesObject.TCREW.abbrev) ? reportDetails?.CrewName : ""}</option>
                </select>
              </div>
              <div className="col-12 form-group">
                <label className="form-label" htmlFor="Notes">Transfer Notes</label>
                <textarea
                  className="form-control"
                  rows="4"
                  value={reportDetails?.Notes}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <label className="form-label">Parts</label>
            <table
              id="UserRecordsGrid"
              className="table table-bordered table-hover table-striped table-sm w-100"
            >
              <thead>
                <tr>
                  <th>
                    <i className="fa fa-comments mr-1"></i> Part Description
                  </th>
                  <th>
                    <i className="fa fa-list-ul mr-1"></i> Part Number
                  </th>
                  <th>
                    <i className="fa fa-list-ol mr-1"></i> Qty
                  </th>
                  <th>
                    <i className="fa fa-bolt mr-1"></i> Created By
                  </th>
                  <th>
                    <i className="fa fa-bolt mr-1"></i> Created Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <>
             {
               loadingReportDetailsParts ? 
               <div className="p-5">
                 Loading parts...
               </div>
               :
               !loadingReportDetailsParts && reportDetailsParts?.map((el, idx) => {
                 return(
                  <tr key={idx}>
                  <td>{el?.PARTDESCRIPTION}</td>
                  <td>{el?.PARTNUM}</td>
                  <td>
                    <span className="label label-light-danger label-inline">
                      {el?.QTY}
                    </span>
                  </td>
                  <td>{
                    `${allUsers?.filter((au) => {
                      if(au?.id == el?.CreatedBy){
                        return au
                      }
                    })[0]?.FirstName} ${allUsers?.filter((au) => {
                      if(au?.id == el?.CreatedBy){
                        return au
                      }
                    })[0]?.LastName}`
                    }</td>
                  <td>{moment(el?.CreatedDate).format("MM/DD/YYYY")}</td>
                </tr>
                 )
               })
             }
             </>
              </tbody>
            </table>
            <div className="separator separator-solid separator-border-1 separator-primary my-4"></div>
            <label className="form-label">Attachments</label>
            <table className="table table-borderd table-sm w-100">
                <tbody>
                <>
                 {
                    loadingInventoryAttachments ? 
                    <div className="p-5">
                      Loading attachments...
                    </div>
                    :
                    !loadingInventoryAttachments && inventoryAttachments?.length === 0 ? 
                    <div className="p-5">
                      No attachments
                    </div>
                    :
                    inventoryAttachments?.map((el, idx) => {
                      return (
                        <tr key={idx}>
                                <td>{el?.path.split("/").pop()}</td>
                                <td>
                                    <span className="label label-light-primary label-inline text-uppercase">
                                        {(el?.path.split("/").pop()).split('.').pop()}
                                    </span>
                                </td>
                                <td>
                                    <a href={el?.path} download target="_blank">Download File</a>
                                </td>
                            </tr>
                      );
                    })
                    }
                    </>
                </tbody>
            </table>
          
          </form>
        </Modal.Body>
        <Modal.Footer className="py-3">
        </Modal.Footer>
      </Modal>

        </>
    )
}
export default NewReports;